/* eslint-disable no-console */
import _ from 'lodash';
import { HashMap } from '@appsignal/types';
import { HashMapValue } from '@appsignal/types/dist/cjs/types/common';

import { appsignalInstance } from 'config/_global_context/appsignal';
import { APP_RC } from 'config/APP_RC';

const categoryStyle = [
  'background: #2166cd',
  'color: #e9f0fa',
  'line-height: 20px',
  'padding: 0 5px',
  'border-radius: 3px',
  'margin-right: 5px',
  'font-size: 9px',
].join(';');

const actionStyle = [
  'background: #000000',
  'line-height: 20px',
  'color: #lightgrey',
  'padding: 0 5px',
  'border-radius: 3px',
  'font-size: 9px',
].join(';');

type Trace = (category: string, action: string, metadata?: HashMap<HashMapValue>) => void;

export const trace: Trace = (category, action, unsafe_metadata = {}) => {
  const metadata = _.cloneDeep(unsafe_metadata);
  if (!window.playwright && APP_RC.env === 'dev') {
    if (Object.keys(metadata).length) {
      console.log(`%c${category} %c${action}`, categoryStyle, actionStyle, metadata);
    } else {
      console.log(`%c${category} %c${action}`, categoryStyle, actionStyle);
    }
  }
  if (!window.playwright) {
    appsignalInstance.addBreadcrumb({ action, category, metadata });
  }
};
