import { IErrorWithDescription } from '../types';

export const isErrorWithDescription = (response: unknown): response is IErrorWithDescription => {
  return (
    !!response &&
    typeof response === 'object' &&
    typeof (response as IErrorWithDescription).response?.data?.error === 'string' &&
    typeof (response as IErrorWithDescription).response?.data?.error_description === 'string'
  );
};
