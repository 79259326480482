import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { ApolloLink } from '@apollo/client';
// @ts-expect-error there are no type definitions for cable
import { createConsumer } from '@rails/actioncable';
import { isSubscriptionOperation } from '@apollo/client/utilities';

import { APP_RC } from 'config/APP_RC';
import { Http } from './Http';

const cable = createConsumer(APP_RC.cable_url);

const cableLink = new ActionCableLink({ cable });

export const WebSocket = ApolloLink.split(({ query }) => isSubscriptionOperation(query), cableLink, Http);
