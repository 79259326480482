export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: string; output: string };
  /** A URN that globally identifies an object by including its type and ID */
  GlobalID: { input: string; output: string };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
  /** Merchant Category Code, must contain exactly 4 digits and be String. Example: "0012" */
  MerchantCategoryCodeCode: { input: any; output: any };
  /**
   * This value can be a converted plain string if conversion is necessary:
   *   - if "reference value" represents enum options, the original BE values
   *     ​​will be converted to GQL enum values. Also in the opposite direction,
   *     arguments with a value from the GQL enums will be converted into BE values.
   */
  ReferenceValue: { input: any; output: any };
  /** [IANA timezone ID](https://www.iana.org/time-zones) e.g. `Europe/Copenhagen` */
  TimezoneID: { input: string; output: string };
};

/** An object with an GlobalID. */
export type Account = {
  accountType: AccountUseType;
  address?: Maybe<Address>;
  companyName: Scalars['String']['output'];
  coverImage?: Maybe<Attachment>;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  profileImage?: Maybe<Attachment>;
  publicEmail?: Maybe<Scalars['String']['output']>;
  publicPhone?: Maybe<Scalars['String']['output']>;
  publicWebsite?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type AccountLocalisationSettings = {
  __typename?: 'AccountLocalisationSettings';
  displayInCurrency: Currency;
  interfaceLanguage: Language;
  timezone: Scalars['TimezoneID']['output'];
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  categorisation: CategorisationSettings;
  localisation: AccountLocalisationSettings;
  payment: PaymentSettings;
};

export type AccountSettingsInput = {
  categorisation?: InputMaybe<CategorisationAccountSettingsInput>;
  localisation?: InputMaybe<UpdateLocalisationSettingInputAttributes>;
  payment?: InputMaybe<PaymentAccountSettingsInput>;
};

export enum AccountUseType {
  Buyer = 'BUYER',
  Supplier = 'SUPPLIER',
}

/**
 * An activity is a noteworthy event in the system where an *actor* performed
 * and action on an *entity*.
 *
 * For example if a user receives a virtual card, the *actor* is the `User`
 * and the `VirtualCard` is the `entity`.
 */
export type Activity = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Activity';
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * An activity typically contains multiple references such as to the actor,
     * the object they interacted with (trackable), and possibly also the event subject.
     *
     * Use this URL as the default when a user clicks on the activity.
     */
    defaultUrl?: Maybe<Url>;
    displayStyle: ActivityDisplayStyle;
    eventSubject?: Maybe<EventSubject>;
    /**
     * Returns the activity message, translated, and broken-up into structured fragments.
     *
     * There are two kinds of fragments: `TEXT` and `REFERENCE`.
     *
     * A `TEXT` fragment is translated plain text.
     * A `REFERENCE` fragment includes information on how to link that text to another object.
     *
     * This allows the UI to join the fragments together into one sentence, but have some
     * parts linkable to other objects/views.
     */
    fragments: Array<StringFragment>;
    icon: ActivityIcon;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** A unique and structured identifier for this kind of event, e.g. `account.created` */
    key: Scalars['String']['output'];
    relationExists: Scalars['Boolean']['output'];
    trackable?: Maybe<HasActivities>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/**
 * An activity is a noteworthy event in the system where an *actor* performed
 * and action on an *entity*.
 *
 * For example if a user receives a virtual card, the *actor* is the `User`
 * and the `VirtualCard` is the `entity`.
 */
export type ActivityRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for Activity. */
export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Activity>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum ActivityDisplayStyle {
  Danger = 'DANGER',
  Info = 'INFO',
  Passive = 'PASSIVE',
  Primary = 'PRIMARY',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

/** An edge in a connection. */
export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Activity>;
};

export enum ActivityIcon {
  Add = 'ADD',
  Approval = 'APPROVAL',
  Calendar = 'CALENDAR',
  Checkmark = 'CHECKMARK',
  Close = 'CLOSE',
  Comment = 'COMMENT',
  CreditCard = 'CREDIT_CARD',
  Edit = 'EDIT',
  Email = 'EMAIL',
  Play = 'PLAY',
  Send = 'SEND',
  Star = 'STAR',
  ThumbsUp = 'THUMBS_UP',
  Trash = 'TRASH',
  Undo = 'UNDO',
  Unlock = 'UNLOCK',
  Upload = 'UPLOAD',
  Wait = 'WAIT',
  Warning = 'WARNING',
}

export type ActivitySortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/**
 * In some cases there are polymorphic relations to the logged-in entity that
 * performed an action. This is either an `Administrator` or `User`.
 */
export type Actor = {
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  /**
   * *First* and/or *Last* names combined, e.g. John Smith.
   *
   * Blank name components are removed, so with a blank last name,
   * the full name would just be "John".
   */
  fullName?: Maybe<Scalars['String']['output']>;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AddRoleToUser */
export type AddRoleToUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  role: UserSecurityRole;
  userId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of AddRoleToUser. */
export type AddRoleToUserPayload = {
  __typename?: 'AddRoleToUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

/**
 * A standalone object with the usual fields that make up a street or mailing address.
 *
 * Each has a polymorphic relationship to an `addressable`: that is any
 * object in the system that needs an address associated with it.
 *
 * Addresses are *not* shared across multiple addressable objects.
 */
export type Address = GlobalIdField &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']['output']>;
    country: Country;
    createdAt: Scalars['ISO8601DateTime']['output'];
    formattedLines: Array<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    postalCode?: Maybe<Scalars['String']['output']>;
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    /** National sub-region e.g. state or province. */
    regionName?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    streetName?: Maybe<Scalars['String']['output']>;
    streetNumber?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/**
 * A standalone object with the usual fields that make up a street or mailing address.
 *
 * Each has a polymorphic relationship to an `addressable`: that is any
 * object in the system that needs an address associated with it.
 *
 * Addresses are *not* shared across multiple addressable objects.
 */
export type AddressReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * A standalone object with the usual fields that make up a street or mailing address.
 *
 * Each has a polymorphic relationship to an `addressable`: that is any
 * object in the system that needs an address associated with it.
 *
 * Addresses are *not* shared across multiple addressable objects.
 */
export type AddressRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type AppearanceSettings = {
  __typename?: 'AppearanceSettings';
  theme: Theme;
};

export type Approvable = {
  approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum ApprovableGraphqlInterfaceApprovalStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type Approval = Commentable &
  FlowStepResultCreatedRecords &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Approval';
    activities?: Maybe<ActivityConnection>;
    approvable?: Maybe<Approvable>;
    approvalsUsers: ApprovalsUserConnection;
    approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * @deprecated Instead, use the 'approvalsUsers' field to get assignees. The new field contains
     * more information about the assignees, such as whether they are delegated or not.
     *
     * (Since 2023-03-13. To be removed by MP-6573)
     *
     */
    assignees: UserConnection;
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    comments: CommentConnection;
    confirmedBy?: Maybe<User>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    flowStepResult?: Maybe<FlowStepResult>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    relationExists: Scalars['Boolean']['output'];
    status: ApprovalStatus;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: ApprovalRecordViewerPermissions;
  };

export type ApprovalActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type ApprovalApprovalsUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ApprovalAssigneesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ApprovalCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type ApprovalRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum ApprovalApprovableType {
  PurchaseRequestItem = 'PURCHASE_REQUEST_ITEM',
  SupplierRequest = 'SUPPLIER_REQUEST',
}

export type ApprovalCollectionViewerPermissions = {
  __typename?: 'ApprovalCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for Approval. */
export type ApprovalConnection = {
  __typename?: 'ApprovalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApprovalEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Approval>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: ApprovalCollectionViewerPermissions;
};

export type ApprovalDelegation = Archivable &
  Commentable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'ApprovalDelegation';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    comments: CommentConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    endsOn: Scalars['ISO8601Date']['output'];
    from: User;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    startsOn: Scalars['ISO8601Date']['output'];
    status: ApprovalDelegationStatus;
    to: User;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: ApprovalDelegationRecordViewerPermissions;
  };

export type ApprovalDelegationActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type ApprovalDelegationCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type ApprovalDelegationRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for ApprovalDelegation. */
export type ApprovalDelegationConnection = {
  __typename?: 'ApprovalDelegationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApprovalDelegationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ApprovalDelegation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApprovalDelegationEdge = {
  __typename?: 'ApprovalDelegationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ApprovalDelegation>;
};

export type ApprovalDelegationRecordViewerPermissions = {
  __typename?: 'ApprovalDelegationRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type ApprovalDelegationSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  endsOn?: InputMaybe<SortDirection>;
  from?: InputMaybe<SortDirection>;
  startsOn?: InputMaybe<SortDirection>;
  to?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ApprovalDelegationStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Planned = 'PLANNED',
}

/** An edge in a connection. */
export type ApprovalEdge = {
  __typename?: 'ApprovalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Approval>;
};

export type ApprovalRecordViewerPermissions = {
  __typename?: 'ApprovalRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  approve: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  reject: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type ApprovalSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type ApprovalsUser = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'ApprovalsUser';
    approval: Approval;
    approvalDelegation?: Maybe<ApprovalDelegation>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    user: User;
  };

export type ApprovalsUserRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for ApprovalsUser. */
export type ApprovalsUserConnection = {
  __typename?: 'ApprovalsUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApprovalsUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ApprovalsUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApprovalsUserEdge = {
  __typename?: 'ApprovalsUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ApprovalsUser>;
};

/** Autogenerated input type of ApproveApproval */
export type ApproveApprovalInput = {
  approvalId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ApproveApproval. */
export type ApproveApprovalPayload = {
  __typename?: 'ApproveApprovalPayload';
  approval?: Maybe<Approval>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type ApproverCustomField = Archivable &
  Auditable &
  CustomField &
  DataScopingRules &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'ApproverCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    dataScopingRules: DataScopingRuleConnection;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    options: UserConnection;
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type ApproverCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type ApproverCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type ApproverCustomFieldDataScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ApproverCustomFieldOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scopeToFormableId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ApproverCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type ApproverSummary = {
  __typename?: 'ApproverSummary';
  approvalCount: Scalars['Int']['output'];
  approver: User;
  totalApprovedAmount: Money;
};

export type Archivable = {
  /**
   * Whether or not the record is archived.
   * Archived records are usually omitted from query results unless specifically included.
   */
  archived: Scalars['Boolean']['output'];
  archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of ArchiveRecord */
export type ArchiveRecordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of ArchiveRecord. */
export type ArchiveRecordPayload = {
  __typename?: 'ArchiveRecordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  record?: Maybe<Archivable>;
  status: MutationStatus;
};

export type AssociationCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'AssociationCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value: AssociationCustomFieldValueValue;
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type AssociationCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type AssociationCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** Custom field value that references another record */
export type AssociationCustomFieldValueValue = Category | CostCentre | DataSourceItem | OrganisationUnit | User;

/** Autogenerated input type of AttachFileToCustomFieldValue */
export type AttachFileToCustomFieldValueInput = {
  blobId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of AttachFileToCustomFieldValue. */
export type AttachFileToCustomFieldValuePayload = {
  __typename?: 'AttachFileToCustomFieldValuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customFieldValue?: Maybe<CustomFieldValue>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** An object to which a file can be attached. */
export type Attachable =
  | AttachmentCustomFieldValue
  | BuyerAccount
  | Document
  | IncomingEmailAttachment
  | Report
  | SupplierAccount
  | User;

export type Attachment = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Attachment';
    /** The ID of attached file */
    blobId: Scalars['GlobalID']['output'];
    /** Size of the attachment in bytes */
    byteSize: Scalars['Int']['output'];
    /** MIME type of the attachment */
    contentType: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    downloadUrl: Scalars['String']['output'];
    filename: Scalars['String']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Unique identifier for the uploaded file */
    key: Scalars['String']['output'];
    /** Name of the attachment to the parent record */
    name: Scalars['String']['output'];
    parent?: Maybe<Attachable>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type AttachmentRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type AttachmentCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'AttachmentCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type AttachmentCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type AttachmentCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type AttachmentCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type AttachmentCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'AttachmentCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    files?: Maybe<Array<Attachment>>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type AttachmentCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type AttachmentCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type Audit = {
  __typename?: 'Audit';
  action?: Maybe<AuditAction>;
  /** The object on which the changes were made */
  auditable: Auditable;
  auditedChanges: Array<AuditedChange>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export enum AuditAction {
  Create = 'CREATE',
  Destroy = 'DESTROY',
  Update = 'UPDATE',
}

/** The connection type for Audit. */
export type AuditConnection = {
  __typename?: 'AuditConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuditEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Audit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AuditEdge = {
  __typename?: 'AuditEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Audit>;
};

export type AuditSortInput = {
  createdAt?: InputMaybe<SortDirection>;
};

/** An object with an GlobalID. */
export type Auditable = {
  /**
   * Returns all audits of the object.
   *
   * The exception is draftable objects:
   *  - If the object is draft -- no audits will be returned
   *  - If the object is submitted -- only audits created after the submit date will be returned.
   *   Setting createdAfter before the submit date will have no effect.
   */
  audits?: Maybe<AuditConnection>;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
};

/** An object with an GlobalID. */
export type AuditableAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type AuditedChange = {
  __typename?: 'AuditedChange';
  name: Scalars['String']['output'];
  newValue?: Maybe<Value>;
  oldValue?: Maybe<Value>;
};

export type AvailableOptions = {
  __typename?: 'AvailableOptions';
  /**
   * For flow conditions, the input attribute must be one of these configured
   * input attributes.
   */
  flowConditionInputAttributes: Array<InputAttribute>;
  /**
   * For invoice payments, the receiver's bank account must be one of these
   * configured and supported currencies.
   */
  invoicePayoutCurrencies: Array<Currency>;
  /** Available purchase types for the current account based on configured payment integrations. */
  purchaseRequestTypes?: Maybe<Array<PurchaseType>>;
  /** List of countries that are supported for suppliers. */
  supportedSupplierCountries: Array<Country>;
  /** A list of workflow base resources with the trigger events that can be used for them */
  workflowBaseResources: Array<WorkflowBaseResource>;
};

export type AvailableTypes = DataViewField;

export type BankAccount = Archivable &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'BankAccount';
    accountHolderName?: Maybe<Scalars['String']['output']>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * This field cannot be null for own account, but some supplier bank accounts are also available
     * for buyer account and we don't want to allow this field for buyer. So this is nullable
     */
    createdBy?: Maybe<User>;
    currency: Currency;
    iban: Scalars['String']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    verifiedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    viewerPermissions: BankAccountRecordViewerPermissions;
  };

export type BankAccountRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type BankAccountCollectionViewerPermissions = {
  __typename?: 'BankAccountCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for BankAccount. */
export type BankAccountConnection = {
  __typename?: 'BankAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BankAccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BankAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: BankAccountCollectionViewerPermissions;
};

/** An edge in a connection. */
export type BankAccountEdge = {
  __typename?: 'BankAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BankAccount>;
};

export type BankAccountRecordViewerPermissions = {
  __typename?: 'BankAccountRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type Blob = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Blob';
    /** Size of the file in bytes */
    byteSize: Scalars['Int']['output'];
    /** MIME type of the file */
    contentType: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    filename: Scalars['String']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type BlobRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type BooleanCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'BooleanCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value?: Maybe<Scalars['Boolean']['output']>;
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type BooleanCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type BooleanCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type Branding = {
  __typename?: 'Branding';
  key: BrandingBrandingKey;
  label: Scalars['String']['output'];
};

export enum BrandingBrandingKey {
  Airplus = 'AIRPLUS',
  Default = 'DEFAULT',
  Eurocard = 'EUROCARD',
  Nordea = 'NORDEA',
}

export type BuyerAccount = Account &
  Auditable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'BuyerAccount';
    accountType: AccountUseType;
    activities?: Maybe<ActivityConnection>;
    address?: Maybe<Address>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    branding: Branding;
    companyName: Scalars['String']['output'];
    coverImage?: Maybe<Attachment>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    parsedId?: Maybe<IParsedGlobalId>;
    profileImage?: Maybe<Attachment>;
    publicEmail?: Maybe<Scalars['String']['output']>;
    publicPhone?: Maybe<Scalars['String']['output']>;
    publicWebsite?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    settings?: Maybe<AccountSettings>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    vatNumber?: Maybe<Scalars['String']['output']>;
    viewerPermissions: BuyerAccountRecordViewerPermissions;
  };

export type BuyerAccountActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type BuyerAccountAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type BuyerAccountRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type BuyerAccountRecordViewerPermissions = {
  __typename?: 'BuyerAccountRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateSettings: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CancelInvoicePayment */
export type CancelInvoicePaymentInput = {
  /** The reason why the invoice payment was cancelled, leave blank to apply the default value. */
  cancellationComment?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoicePaymentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CancelInvoicePayment. */
export type CancelInvoicePaymentPayload = {
  __typename?: 'CancelInvoicePaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  invoicePayment?: Maybe<InvoicePayment>;
  status: MutationStatus;
};

/** Autogenerated input type of CancelPurchaseRequestItem */
export type CancelPurchaseRequestItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentBody: Scalars['String']['input'];
  purchaseRequestItemId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CancelPurchaseRequestItem. */
export type CancelPurchaseRequestItemPayload = {
  __typename?: 'CancelPurchaseRequestItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

export type CardAuthorisation = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CardAuthorisation';
    amount: Money;
    authorisationId: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    date: Scalars['ISO8601Date']['output'];
    declineCode?: Maybe<Scalars['String']['output']>;
    declineMessage?: Maybe<Scalars['String']['output']>;
    declined: Scalars['Boolean']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    issuerName: CardAuthorisationIssuerName;
    merchantName?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: CardAuthorisationRecordViewerPermissions;
    virtualCard?: Maybe<VirtualCard>;
  };

export type CardAuthorisationRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type CardAuthorisationCollectionViewerPermissions = {
  __typename?: 'CardAuthorisationCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for CardAuthorisation. */
export type CardAuthorisationConnection = {
  __typename?: 'CardAuthorisationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CardAuthorisationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CardAuthorisation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: CardAuthorisationCollectionViewerPermissions;
};

/** An edge in a connection. */
export type CardAuthorisationEdge = {
  __typename?: 'CardAuthorisationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CardAuthorisation>;
};

export enum CardAuthorisationIssuerName {
  AirPlus = 'AIR_PLUS',
  Dummy = 'DUMMY',
  Eurocard = 'EUROCARD',
  Mastercard = 'MASTERCARD',
}

export type CardAuthorisationRecordViewerPermissions = {
  __typename?: 'CardAuthorisationRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type CardAuthorisationSortInput = {
  amount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  declined?: InputMaybe<SortDirection>;
  merchantName?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CardPayment = {
  __typename?: 'CardPayment';
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  scheduledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type CardSubscription = Commentable &
  Documentable &
  GlobalIdField &
  HasActivities &
  Inboxable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CardSubscription';
    activities?: Maybe<ActivityConnection>;
    cardStatus: CardSubscriptionCardStatus;
    closedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    closedBy?: Maybe<User>;
    comments: CommentConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    displayCurrency: Currency;
    documents?: Maybe<DocumentConnection>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /**
     * Returns the inbox with the given key. Since inbox keys are not an enum, the following list may help for the keys:
     *
     * CardSubscription:
     *   - receipts
     */
    inboxForKey?: Maybe<Inbox>;
    inboxes: InboxConnection;
    label: Scalars['String']['output'];
    owner: User;
    primaryVirtualCard?: Maybe<VirtualCard>;
    purchaseOrders: PurchaseOrderConnection;
    purchaseRequestItems?: Maybe<PurchaseRequestItemConnection>;
    reconciliationStatus?: Maybe<PurchaseOrderReconciliationStatus>;
    relationExists: Scalars['Boolean']['output'];
    similarSubscriptions: CardSubscriptionConnection;
    totalApprovedBaseAmount?: Maybe<Money>;
    totalApprovedDisplayAmount?: Maybe<Money>;
    totalSpentBaseAmount?: Maybe<Money>;
    totalSpentDisplayAmount?: Maybe<Money>;
    transactions: TransactionConnection;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: CardSubscriptionRecordViewerPermissions;
  };

export type CardSubscriptionActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CardSubscriptionCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type CardSubscriptionDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CardSubscriptionInboxForKeyArgs = {
  key: Scalars['String']['input'];
};

export type CardSubscriptionInboxesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CardSubscriptionPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CardSubscriptionPurchaseRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CardSubscriptionRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type CardSubscriptionSimilarSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CardSubscriptionTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardSubscriptionCardStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  RunningOut = 'RUNNING_OUT',
}

export type CardSubscriptionCollectionViewerPermissions = {
  __typename?: 'CardSubscriptionCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for CardSubscription. */
export type CardSubscriptionConnection = {
  __typename?: 'CardSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CardSubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CardSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: CardSubscriptionCollectionViewerPermissions;
};

/** An edge in a connection. */
export type CardSubscriptionEdge = {
  __typename?: 'CardSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CardSubscription>;
};

export type CardSubscriptionRecordViewerPermissions = {
  __typename?: 'CardSubscriptionRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  close: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  reopen: Scalars['Boolean']['output'];
  requestRenewal: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type CardSubscriptionSortInput = {
  cardStatus?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  ownerName?: InputMaybe<SortDirection>;
  reconciliationStatus?: InputMaybe<SortDirection>;
  totalSpentBaseAmountCents?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CategoriesImportMappingsAttributesInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<Array<CustomFieldValuesCategoriesImportMappingsAttributesInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  parentCode?: InputMaybe<Scalars['String']['input']>;
};

export type CategorisationAccountSettingsInput = {
  categorySetId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type CategorisationSettings = {
  __typename?: 'CategorisationSettings';
  categorySetId?: Maybe<Scalars['GlobalID']['output']>;
};

export type Category = Archivable &
  Auditable &
  CustomFieldValuesParent &
  GlobalIdField &
  HasActivities &
  HasAncestry &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'Category';
    activities?: Maybe<ActivityConnection>;
    ancestors?: Maybe<Array<Category>>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    categorySet?: Maybe<CategorySet>;
    /** Use this if you only need the ID of category set */
    categorySetId: Scalars['GlobalID']['output'];
    childrenCount: Scalars['Int']['output'];
    code: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    parentId?: Maybe<Scalars['GlobalID']['output']>;
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: CategoryRecordViewerPermissions;
  };

export type CategoryActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CategoryAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CategoryChildrenCountArgs = {
  archived?: InputMaybe<OptionalBoolean>;
};

export type CategoryCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CategoryReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type CategoryCollectionViewerPermissions = {
  __typename?: 'CategoryCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: CategoryCollectionViewerPermissions;
};

export type CategoryCustomField = Archivable &
  Auditable &
  CustomField &
  DataScopingRules &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CategoryCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    dataScopingRules: DataScopingRuleConnection;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    options: CategoryConnection;
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type CategoryCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CategoryCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CategoryCustomFieldDataScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCustomFieldOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scopeToFormableId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

export type CategoryRecordViewerPermissions = {
  __typename?: 'CategoryRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  createChild?: Maybe<Scalars['Boolean']['output']>;
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
  updateParent: Scalars['Boolean']['output'];
};

export type CategorySet = Archivable &
  Auditable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CategorySet';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    categories: CategoryConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: CategorySetRecordViewerPermissions;
  };

export type CategorySetActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CategorySetAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CategorySetCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CategorySetRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type CategorySetCollectionViewerPermissions = {
  __typename?: 'CategorySetCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for CategorySet. */
export type CategorySetConnection = {
  __typename?: 'CategorySetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategorySetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CategorySet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: CategorySetCollectionViewerPermissions;
};

/** An edge in a connection. */
export type CategorySetEdge = {
  __typename?: 'CategorySetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CategorySet>;
};

export type CategorySetRecordViewerPermissions = {
  __typename?: 'CategorySetRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  import: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type CategorySetSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CategorySortInput = {
  code?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated input type of ChangeCardDeliveryPin */
export type ChangeCardDeliveryPinInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pin: Scalars['String']['input'];
};

/** Autogenerated return type of ChangeCardDeliveryPin. */
export type ChangeCardDeliveryPinPayload = {
  __typename?: 'ChangeCardDeliveryPinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

export type CheckboxCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CheckboxCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type CheckboxCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CheckboxCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CheckboxCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** Autogenerated input type of CloseCardSubscription */
export type CloseCardSubscriptionInput = {
  cardSubscriptionId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  closeComment: Scalars['String']['input'];
};

/** Autogenerated return type of CloseCardSubscription. */
export type CloseCardSubscriptionPayload = {
  __typename?: 'CloseCardSubscriptionPayload';
  cardSubscription?: Maybe<CardSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of ClosePurchaseOrder */
export type ClosePurchaseOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of ClosePurchaseOrder. */
export type ClosePurchaseOrderPayload = {
  __typename?: 'ClosePurchaseOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  status: MutationStatus;
};

export type Comment = FlowStepResultCreatedRecords &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Comment';
    activities?: Maybe<ActivityConnection>;
    body?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /**
     * Optional classification key that's unique to the entity type being commented on.
     *
     * For example if the entity is an `Approval`, the key might be `approval.reject_comment`.
     */
    key?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** User that made the comment. */
    user: User;
    viewerPermissions: CommentRecordViewerPermissions;
  };

export type CommentActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CommentRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for Comment. */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Comment>;
};

export type CommentRecordViewerPermissions = {
  __typename?: 'CommentRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type CommentSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Commentable = {
  comments: CommentConnection;
};

export type CommentableCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type CompanyLookup = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CompanyLookup';
    companyName?: Maybe<Scalars['String']['output']>;
    country: Country;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    /** This shows how many companies have returned from Penneo. */
    responseResult: CompanyLookupResponseResult;
    supplier?: Maybe<SupplierAccount>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    vatNumber: Scalars['String']['output'];
    viewerPermissions: CompanyLookupRecordViewerPermissions;
  };

export type CompanyLookupRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type CompanyLookupRecordViewerPermissions = {
  __typename?: 'CompanyLookupRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum CompanyLookupResponseResult {
  Multi = 'MULTI',
  NotFound = 'NOT_FOUND',
  Single = 'SINGLE',
}

export type Comparator = {
  __typename?: 'Comparator';
  i18nName: Scalars['String']['output'];
  name: FlowStepConditionComparatorName;
};

/** Autogenerated input type of ConfirmEnable2fa */
export type ConfirmEnable2faInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpCode: Scalars['String']['input'];
};

/** Autogenerated return type of ConfirmEnable2fa. */
export type ConfirmEnable2faPayload = {
  __typename?: 'ConfirmEnable2faPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type CostCentre = Archivable &
  Auditable &
  CustomFieldValuesParent &
  GlobalIdField &
  HasActivities &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'CostCentre';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    code: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: CostCentreRecordViewerPermissions;
  };

export type CostCentreActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CostCentreAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CostCentreCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CostCentreReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CostCentreRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type CostCentreCollectionViewerPermissions = {
  __typename?: 'CostCentreCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for CostCentre. */
export type CostCentreConnection = {
  __typename?: 'CostCentreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CostCentreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CostCentre>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: CostCentreCollectionViewerPermissions;
};

export type CostCentreCustomField = Archivable &
  Auditable &
  CustomField &
  DataScopingRules &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'CostCentreCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    dataScopingRules: DataScopingRuleConnection;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    options: CostCentreConnection;
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type CostCentreCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type CostCentreCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CostCentreCustomFieldDataScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CostCentreCustomFieldOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scopeToFormableId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CostCentreCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** An edge in a connection. */
export type CostCentreEdge = {
  __typename?: 'CostCentreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CostCentre>;
};

export type CostCentreRecordViewerPermissions = {
  __typename?: 'CostCentreRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  import: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
};

export type CostCentreSortInput = {
  /**
   * Sort by archived status of records:
   * - Ascending - archived records in order from oldest archived, then active.
   * - Descending - active first, then archived records, in order from most recently archived.
   */
  archived?: InputMaybe<SortDirection>;
  code?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CostCentresImportMappingsAttributesInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<Array<CustomFieldValuesCostCentresImportMappingsAttributesInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Standard ISO country information. Read-only. */
export type Country = {
  __typename?: 'Country';
  /** ISO 2-char code, e.g. `DK` */
  code: CountryCode;
  /** Country name in the viewer's language */
  name: Scalars['String']['output'];
};

export enum CountryCode {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia (Plurinational State of) */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo, Democratic Republic of the */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran (Islamic Republic of) */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea (Democratic People's Republic of) */
  Kp = 'KP',
  /** Korea, Republic of */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova, Republic of */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Türkiye */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela (Bolivarian Republic of) */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export type CreateAddressInputAttributes = {
  city: Scalars['String']['input'];
  /** ISO-3166 two-character country code, e.g. `DK` */
  countryCode: CountryCode;
  postalCode: Scalars['String']['input'];
  /** National sub-region, such as a US state or Canadian province. */
  regionName?: InputMaybe<Scalars['String']['input']>;
  streetName: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAmountDataValuesInputAttributes = {
  key: Scalars['String']['input'];
  valueCents: Scalars['BigInt']['input'];
  valueCurrency: CurrencyCode;
};

/** Autogenerated input type of CreateApprovalDelegation */
export type CreateApprovalDelegationInput = {
  attributes: CreateApprovalDelegationInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApprovalDelegationInputAttributes = {
  delegateFromId?: InputMaybe<Scalars['GlobalID']['input']>;
  delegateToId: Scalars['GlobalID']['input'];
  endsOn: Scalars['ISO8601Date']['input'];
  startsOn: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of CreateApprovalDelegation. */
export type CreateApprovalDelegationPayload = {
  __typename?: 'CreateApprovalDelegationPayload';
  approvalDelegation?: Maybe<ApprovalDelegation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateApproverCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateApproverCustomField */
export type CreateApproverCustomFieldInput = {
  attributes: CreateApproverCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateApproverCustomField. */
export type CreateApproverCustomFieldPayload = {
  __typename?: 'CreateApproverCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateAssociationCustomFieldValuesInputAttributes = {
  associationId?: InputMaybe<Scalars['GlobalID']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

export type CreateAttachmentCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateAttachmentCustomField */
export type CreateAttachmentCustomFieldInput = {
  attributes: CreateAttachmentCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAttachmentCustomField. */
export type CreateAttachmentCustomFieldPayload = {
  __typename?: 'CreateAttachmentCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateBankAccount */
export type CreateBankAccountInput = {
  attributes: CreateBankAccountInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBankAccountInputAttributes = {
  currency: CurrencyCode;
  iban: Scalars['String']['input'];
};

/** Autogenerated return type of CreateBankAccount. */
export type CreateBankAccountPayload = {
  __typename?: 'CreateBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateBooleanCustomFieldValuesInputAttributes = {
  customFieldId: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCategoryAttributesInput = {
  categorySetId: Scalars['GlobalID']['input'];
  code: Scalars['String']['input'];
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type CreateCategoryCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateCategoryCustomField */
export type CreateCategoryCustomFieldInput = {
  attributes: CreateCategoryCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCategoryCustomField. */
export type CreateCategoryCustomFieldPayload = {
  __typename?: 'CreateCategoryCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateCategory */
export type CreateCategoryInput = {
  attributes: CreateCategoryAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCategory. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateCategorySetAttributesInput = {
  label: Scalars['String']['input'];
};

/** Autogenerated input type of CreateCategorySet */
export type CreateCategorySetInput = {
  attributes: CreateCategorySetAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCategorySet. */
export type CreateCategorySetPayload = {
  __typename?: 'CreateCategorySetPayload';
  categorySet?: Maybe<CategorySet>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateCheckboxCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateCheckboxCustomField */
export type CreateCheckboxCustomFieldInput = {
  attributes: CreateCheckboxCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCheckboxCustomField. */
export type CreateCheckboxCustomFieldPayload = {
  __typename?: 'CreateCheckboxCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateComment */
export type CreateCommentInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Parent object that is being commented on. */
  parentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CreateComment. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Comment>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateCostCentreAttributesInput = {
  code: Scalars['String']['input'];
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
};

export type CreateCostCentreCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateCostCentreCustomField */
export type CreateCostCentreCustomFieldInput = {
  attributes: CreateCostCentreCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCostCentreCustomField. */
export type CreateCostCentreCustomFieldPayload = {
  __typename?: 'CreateCostCentreCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateCostCentre */
export type CreateCostCentreInput = {
  attributes: CreateCostCentreAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCostCentre. */
export type CreateCostCentrePayload = {
  __typename?: 'CreateCostCentrePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  costCentre?: Maybe<CostCentre>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateCustomFieldValueInputAttributes = {
  associations?: InputMaybe<Array<CreateAssociationCustomFieldValuesInputAttributes>>;
  booleans?: InputMaybe<Array<CreateBooleanCustomFieldValuesInputAttributes>>;
  datetimes?: InputMaybe<Array<CreateDateTimeCustomFieldValuesInputAttributes>>;
  files?: InputMaybe<Array<CreateFilesCustomFieldValuesInputAttributes>>;
  integers?: InputMaybe<Array<CreateIntegerCustomFieldValuesInputAttributes>>;
  money?: InputMaybe<Array<CreateMoneyCustomFieldValuesInputAttributes>>;
  strings?: InputMaybe<Array<CreateStringCustomFieldValuesInputAttributes>>;
};

/** Autogenerated input type of CreateDataFilter */
export type CreateDataFilterInput = {
  attributes: CreateDataFilterInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataFilterInputAttributes = {
  comparator: FlowStepConditionComparatorName;
  dataViewId: Scalars['GlobalID']['input'];
  referenceValue: Scalars['String']['input'];
};

/** Autogenerated return type of CreateDataFilter. */
export type CreateDataFilterPayload = {
  __typename?: 'CreateDataFilterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataFilter?: Maybe<DataFilter>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateDataPathDataValuesInputAttributes = {
  key: Scalars['String']['input'];
  path: Scalars['String']['input'];
  rootResourceType: MetaModelResourceType;
};

/** Autogenerated input type of CreateDataPath */
export type CreateDataPathInput = {
  attributes: CreateDataPathInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataPathInputAttributes = {
  key: Scalars['String']['input'];
  path: Scalars['String']['input'];
  recordId: Scalars['GlobalID']['input'];
  rootResourceType: MetaModelResourceType;
};

/** Autogenerated return type of CreateDataPath. */
export type CreateDataPathPayload = {
  __typename?: 'CreateDataPathPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataPath?: Maybe<DataPath>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateDataScopingRule */
export type CreateDataScopingRuleInput = {
  attributes: CreateDataScopingRuleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataScopingRuleInputAttributes = {
  customFieldId: Scalars['GlobalID']['input'];
  operator: DataScopingRuleOperator;
  position?: InputMaybe<Scalars['Int']['input']>;
  scopeByCustomFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CreateDataScopingRule. */
export type CreateDataScopingRulePayload = {
  __typename?: 'CreateDataScopingRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataScopingRule?: Maybe<DataScopingRule>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateDataSourceAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<CreateNestedDataSourceItemAttributesInput>>;
  label: Scalars['String']['input'];
};

/** Autogenerated input type of CreateDataSource */
export type CreateDataSourceInput = {
  attributes: CreateDataSourceAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateDataSourceItem */
export type CreateDataSourceItemInput = {
  attributes: CreateDataSourceItemInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataSourceItemInputAttributes = {
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  dataSourceId: Scalars['GlobalID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
};

/** Autogenerated return type of CreateDataSourceItem. */
export type CreateDataSourceItemPayload = {
  __typename?: 'CreateDataSourceItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataSourceItem?: Maybe<DataSourceItem>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated return type of CreateDataSource. */
export type CreateDataSourcePayload = {
  __typename?: 'CreateDataSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<DataSource>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateDataValueForParentInputAttributes = {
  amount?: InputMaybe<Array<CreateAmountDataValuesInputAttributes>>;
  dataPath?: InputMaybe<Array<CreateDataPathDataValuesInputAttributes>>;
  date?: InputMaybe<Array<CreateDateDataValuesInputAttributes>>;
  record?: InputMaybe<Array<CreateRecordDataValuesInputAttributes>>;
  static?: InputMaybe<Array<CreateStaticDataValuesInputAttributes>>;
};

/** Autogenerated input type of CreateDataValue */
export type CreateDataValueInput = {
  attributes: CreateDataValueInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataValueInputAttributes = {
  amountValueCents?: InputMaybe<Scalars['BigInt']['input']>;
  amountValueCurrency?: InputMaybe<CurrencyCode>;
  dateValue?: InputMaybe<Scalars['ISO8601Date']['input']>;
  key: Scalars['String']['input'];
  pathValue?: InputMaybe<CreateDataValuePathValueInputAttributes>;
  recordId: Scalars['GlobalID']['input'];
  recordValueId?: InputMaybe<Scalars['GlobalID']['input']>;
  staticValue?: InputMaybe<Scalars['String']['input']>;
  valueSource: DataValueValueSource;
};

export type CreateDataValuePathValueInputAttributes = {
  path: Scalars['String']['input'];
  rootResourceType: MetaModelResourceType;
};

/** Autogenerated return type of CreateDataValue. */
export type CreateDataValuePayload = {
  __typename?: 'CreateDataValuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataValue?: Maybe<DataValue>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateDataViewField */
export type CreateDataViewFieldInput = {
  attributes: CreateDataViewFieldInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataViewFieldInputAttributes = {
  dataValues?: InputMaybe<CreateDataValueForParentInputAttributes>;
  dataViewId: Scalars['GlobalID']['input'];
  /** Optional override format to use when the data is exported to a flat file. */
  formatString?: InputMaybe<DataViewFieldFormatString>;
  label: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateDataViewField. */
export type CreateDataViewFieldPayload = {
  __typename?: 'CreateDataViewFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataViewField?: Maybe<DataViewField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateDataView */
export type CreateDataViewInput = {
  attributes: CreateDataViewInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataViewInputAttributes = {
  description?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<CreateNestedDataViewFieldInputAttributes>>;
  label: Scalars['String']['input'];
  resourceType: DataViewResourceType;
};

/** Autogenerated return type of CreateDataView. */
export type CreateDataViewPayload = {
  __typename?: 'CreateDataViewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataView?: Maybe<DataView>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateDateCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateDateCustomField */
export type CreateDateCustomFieldInput = {
  attributes: CreateDateCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDateCustomField. */
export type CreateDateCustomFieldPayload = {
  __typename?: 'CreateDateCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateDateDataValuesInputAttributes = {
  key: Scalars['String']['input'];
  value: Scalars['ISO8601Date']['input'];
};

export type CreateDateTimeCustomFieldValuesInputAttributes = {
  customFieldId: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type CreateDocumentTransactionAttributesInput = {
  documentId: Scalars['GlobalID']['input'];
  transactionId: Scalars['GlobalID']['input'];
};

/** Autogenerated input type of CreateDocumentTransaction */
export type CreateDocumentTransactionInput = {
  attributes: CreateDocumentTransactionAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDocumentTransaction. */
export type CreateDocumentTransactionPayload = {
  __typename?: 'CreateDocumentTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentTransaction?: Maybe<DocumentTransaction>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateDraftDocument */
export type CreateDraftDocumentInput = {
  attributes: CreateDraftDocumentMutationsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDraftDocumentMutationsInput = {
  blobId: Scalars['GlobalID']['input'];
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  documentType?: InputMaybe<DocumentClassification>;
  purchaseOrderId: Scalars['GlobalID']['input'];
  receiverIban?: InputMaybe<Scalars['String']['input']>;
  transactionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

/** Autogenerated return type of CreateDraftDocument. */
export type CreateDraftDocumentPayload = {
  __typename?: 'CreateDraftDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateFilesCustomFieldValuesInputAttributes = {
  customFieldId: Scalars['GlobalID']['input'];
  fileIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type CreateFlowStepActionInputAttributes = {
  type: FlowStepType;
  workflowId: Scalars['GlobalID']['input'];
  xPosition?: InputMaybe<Scalars['Int']['input']>;
  yPosition?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateFlowStepConditionInputAttributes = {
  workflowId: Scalars['GlobalID']['input'];
  xPosition?: InputMaybe<Scalars['Int']['input']>;
  yPosition?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateIntegerCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateIntegerCustomField */
export type CreateIntegerCustomFieldInput = {
  attributes: CreateIntegerCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateIntegerCustomField. */
export type CreateIntegerCustomFieldPayload = {
  __typename?: 'CreateIntegerCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateIntegerCustomFieldValuesInputAttributes = {
  customFieldId: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['BigInt']['input']>;
};

/** Autogenerated input type of CreateInvoicePayment */
export type CreateInvoicePaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CreateInvoicePayment. */
export type CreateInvoicePaymentPayload = {
  __typename?: 'CreateInvoicePaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  invoicePayment?: Maybe<InvoicePayment>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateIssuerIntegrationConsumer */
export type CreateIssuerIntegrationConsumerInput = {
  attributes: CreateIssuerIntegrationConsumerInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIssuerIntegrationConsumerInputAttributes = {
  cardUseInvoiceFallback?: InputMaybe<Scalars['Boolean']['input']>;
  cardUseMultiFallback?: InputMaybe<Scalars['Boolean']['input']>;
  cardUseSingleFallback?: InputMaybe<Scalars['Boolean']['input']>;
  consumerId: Scalars['GlobalID']['input'];
  issuerIntegrationId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CreateIssuerIntegrationConsumer. */
export type CreateIssuerIntegrationConsumerPayload = {
  __typename?: 'CreateIssuerIntegrationConsumerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  issuerIntegrationConsumer?: Maybe<IssuerIntegrationConsumer>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateIssuerIntegrationRule */
export type CreateIssuerIntegrationRuleInput = {
  attributes: CreateIssuerIntegrationRuleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIssuerIntegrationRuleInputAttributes = {
  cardUseType: IssuerIntegrationRuleCardUseType;
  criteria: IssuerIntegrationRuleCriteria;
  position?: InputMaybe<Scalars['Int']['input']>;
  strict: Scalars['Boolean']['input'];
};

/** Autogenerated return type of CreateIssuerIntegrationRule. */
export type CreateIssuerIntegrationRulePayload = {
  __typename?: 'CreateIssuerIntegrationRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  issuerIntegrationRule?: Maybe<IssuerIntegrationRule>;
  status: MutationStatus;
};

export type CreateLocationAttributesInput = {
  address: CreateAddressInputAttributes;
  label: Scalars['String']['input'];
};

/** Autogenerated input type of CreateLocation */
export type CreateLocationInput = {
  attributes: CreateLocationAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateLocation. */
export type CreateLocationPayload = {
  __typename?: 'CreateLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  location?: Maybe<Location>;
  status: MutationStatus;
};

export type CreateMoneyCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateMoneyCustomField */
export type CreateMoneyCustomFieldInput = {
  attributes: CreateMoneyCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateMoneyCustomField. */
export type CreateMoneyCustomFieldPayload = {
  __typename?: 'CreateMoneyCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateMoneyCustomFieldValuesInputAttributes = {
  cents: Scalars['BigInt']['input'];
  currencyCode: CurrencyCode;
  customFieldId: Scalars['GlobalID']['input'];
};

export type CreateNestedDataSourceItemAttributesInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
};

export type CreateNestedDataViewFieldInputAttributes = {
  /** Optional override format to use when the data is exported to a flat file. */
  formatString?: InputMaybe<DataViewFieldFormatString>;
  label: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type CreateOrganisationUnitCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateOrganisationUnitCustomField */
export type CreateOrganisationUnitCustomFieldInput = {
  attributes: CreateOrganisationUnitCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateOrganisationUnitCustomField. */
export type CreateOrganisationUnitCustomFieldPayload = {
  __typename?: 'CreateOrganisationUnitCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateOrganisationUnit */
export type CreateOrganisationUnitInput = {
  attributes: CreateOrganisationUnitInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganisationUnitInputAttributes = {
  classification: OrganisationUnitClassification;
  code: Scalars['String']['input'];
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  directLocationId?: InputMaybe<Scalars['GlobalID']['input']>;
  directVatNumber?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  parentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CreateOrganisationUnit. */
export type CreateOrganisationUnitPayload = {
  __typename?: 'CreateOrganisationUnitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  organisationUnit?: Maybe<OrganisationUnit>;
  status: MutationStatus;
};

/** Autogenerated input type of CreatePurchaseRequestItem */
export type CreatePurchaseRequestItemInput = {
  attributes: CreatePurchaseRequestItemInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePurchaseRequestItemInputAttributes = {
  cardSubscriptionId?: InputMaybe<Scalars['GlobalID']['input']>;
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  purchaseType: PurchaseType;
  requestedAmountCents?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmountCurrency?: InputMaybe<CurrencyCode>;
  requestedCardTtlDays?: InputMaybe<Scalars['Int']['input']>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `invoice_management`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  supplierId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of CreatePurchaseRequestItem. */
export type CreatePurchaseRequestItemPayload = {
  __typename?: 'CreatePurchaseRequestItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

export type CreateRecordDataValuesInputAttributes = {
  key: Scalars['String']['input'];
  recordValueId: Scalars['GlobalID']['input'];
};

/** Autogenerated input type of CreateReferenceAssociation */
export type CreateReferenceAssociationInput = {
  attributes: CreateReferenceAssociationInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReferenceAssociationInputAttributes = {
  flowStepConditionId: Scalars['GlobalID']['input'];
  referenceRecordId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of CreateReferenceAssociation. */
export type CreateReferenceAssociationPayload = {
  __typename?: 'CreateReferenceAssociationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  referenceAssociation?: Maybe<ReferenceAssociation>;
  status: MutationStatus;
};

export type CreateReportAttributesInput = {
  dataViewId: Scalars['GlobalID']['input'];
  dateFrom: Scalars['ISO8601DateTime']['input'];
  dateTo: Scalars['ISO8601DateTime']['input'];
  fileFormat: ReportScheduleExportFormat;
  /** Set the report label manually, if left blank it will be filled from the data view label. */
  label?: InputMaybe<Scalars['String']['input']>;
  /**
   * Path to the field on the data view used to filter by date.
   *
   * This must be a valid data view field path. For example on a Transaction-based report,
   * `date_of_transaction` would be a valid path.
   */
  rangeCriterion?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateReport */
export type CreateReportInput = {
  attributes: CreateReportAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateReport. */
export type CreateReportPayload = {
  __typename?: 'CreateReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  report?: Maybe<Report>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateReportSchedule */
export type CreateReportScheduleInput = {
  attributes: CreateReportScheduleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportScheduleInputAttributes = {
  dataViewId: Scalars['GlobalID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  exportFormat: ReportScheduleExportFormat;
  label: Scalars['String']['input'];
  /**
   * Path to the field on the data view used to filter by date.
   *
   * This must be a valid data view field path. For example on a Transaction-based report,
   * `date_of_transaction` would be a valid path.
   */
  rangeCriterion?: InputMaybe<Scalars['String']['input']>;
  /**
   * End of the time range used to filter `range_criterion`.
   *
   * This value cannot be set without a criteria.
   */
  rangeEnd?: InputMaybe<ReportScheduleRelativeTime>;
  /**
   * Start of the time range used to filter `range_criterion`.
   *
   * This value cannot be set without a criteria.
   */
  rangeStart?: InputMaybe<ReportScheduleRelativeTime>;
  schedule?: InputMaybe<CreateScheduleInputAttributes>;
};

/** Autogenerated return type of CreateReportSchedule. */
export type CreateReportSchedulePayload = {
  __typename?: 'CreateReportSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  reportSchedule?: Maybe<ReportSchedule>;
  status: MutationStatus;
};

export type CreateScheduleInputAttributes = {
  definition: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  timezone: Scalars['TimezoneID']['input'];
};

export type CreateSelectCustomFieldAttributesInput = {
  dataSourceId: Scalars['GlobalID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateSelectCustomField */
export type CreateSelectCustomFieldInput = {
  attributes: CreateSelectCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSelectCustomField. */
export type CreateSelectCustomFieldPayload = {
  __typename?: 'CreateSelectCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateSpendLimit */
export type CreateSpendLimitInput = {
  attributes: CreateSpendLimitInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSpendLimitInputAttributes = {
  amountCents: Scalars['BigInt']['input'];
  amountCurrency: CurrencyCode;
  earlyWarningAmountCents: Scalars['BigInt']['input'];
  earlyWarningAmountCurrency: CurrencyCode;
  runningPeriodInDays: SpendLimitRunningPeriodInDays;
  scope: SpendLimitScope;
  spendLimitableId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of CreateSpendLimit. */
export type CreateSpendLimitPayload = {
  __typename?: 'CreateSpendLimitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  spendLimit?: Maybe<SpendLimit>;
  status: MutationStatus;
};

export type CreateStaticDataValuesInputAttributes = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateStringCustomFieldValuesInputAttributes = {
  customFieldId: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateSubscriptionRenewalRequest */
export type CreateSubscriptionRenewalRequestInput = {
  cardSubscriptionId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSubscriptionRenewalRequest. */
export type CreateSubscriptionRenewalRequestPayload = {
  __typename?: 'CreateSubscriptionRenewalRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateSupplierRequest */
export type CreateSupplierRequestInput = {
  attributes: CreateSupplierRequestInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSupplierRequestInputAttributes = {
  companyLookupId: Scalars['GlobalID']['input'];
  messageToSupplier?: InputMaybe<Scalars['String']['input']>;
  supplierContactEmail?: InputMaybe<Scalars['String']['input']>;
  supplierContactName?: InputMaybe<Scalars['String']['input']>;
  supplierContactPhone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSupplierRequest. */
export type CreateSupplierRequestPayload = {
  __typename?: 'CreateSupplierRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  supplierRequest?: Maybe<SupplierRequest>;
};

export type CreateTextAreaCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  validationRegex?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateTextAreaCustomField */
export type CreateTextAreaCustomFieldInput = {
  attributes: CreateTextAreaCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTextAreaCustomField. */
export type CreateTextAreaCustomFieldPayload = {
  __typename?: 'CreateTextAreaCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateTextCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  validationRegex?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateTextCustomField */
export type CreateTextCustomFieldInput = {
  attributes: CreateTextCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTextCustomField. */
export type CreateTextCustomFieldPayload = {
  __typename?: 'CreateTextCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type CreateUrlCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentId?: InputMaybe<Scalars['GlobalID']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required: Scalars['Boolean']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateUrlCustomField */
export type CreateUrlCustomFieldInput = {
  attributes: CreateUrlCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateUrlCustomField. */
export type CreateUrlCustomFieldPayload = {
  __typename?: 'CreateUrlCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  attributes: CreateUserInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Optionally send an invitation to the user as they're created. */
  invite: Scalars['Boolean']['input'];
  roles: Array<UserSecurityRole>;
};

export type CreateUserInputAttributes = {
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['GlobalID']['input']>;
  organisationUnitId?: InputMaybe<Scalars['GlobalID']['input']>;
  personalInvitationMessage?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateWorkflowAction */
export type CreateWorkflowActionInput = {
  attributes: CreateFlowStepActionInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateWorkflowAction. */
export type CreateWorkflowActionPayload = {
  __typename?: 'CreateWorkflowActionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  flowAction?: Maybe<FlowStepAction>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateWorkflowCondition */
export type CreateWorkflowConditionInput = {
  attributes: CreateFlowStepConditionInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateWorkflowCondition. */
export type CreateWorkflowConditionPayload = {
  __typename?: 'CreateWorkflowConditionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  flowCondition?: Maybe<FlowStepCondition>;
  status: MutationStatus;
};

/** Autogenerated input type of CreateWorkflow */
export type CreateWorkflowInput = {
  attributes: CreateWorkflowInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkflowInputAttributes = {
  baseResource: WorkflowBaseResourceName;
  label: Scalars['String']['input'];
  triggerEvent: WorkflowTriggerEventKey;
};

/** Autogenerated return type of CreateWorkflow. */
export type CreateWorkflowPayload = {
  __typename?: 'CreateWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  workflow?: Maybe<Workflow>;
};

/** ISO currency information. Read-only. */
export type Currency = {
  __typename?: 'Currency';
  code: CurrencyCode;
  /** Number of decimal places, e.g. `2` for `€ 1.00` which is `100` cents */
  decimalPlaces: Scalars['Int']['output'];
  /** Name in English, e.g. `Danish Krone` */
  name: Scalars['String']['output'];
  /** Symbol or short text, e.g `$` or `kr` */
  symbol: Scalars['String']['output'];
};

/**
 * ISO-4217 currency codes.
 *
 * Only currencies supported by the application are included.
 */
export enum CurrencyCode {
  /** United Arab Emirates Dirham */
  Aed = 'AED',
  /** Afghan Afghani */
  Afn = 'AFN',
  /** Albanian Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Gulden */
  Ang = 'ANG',
  /** Angolan Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijani Manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark */
  Bam = 'BAM',
  /** Barbadian Dollar */
  Bbd = 'BBD',
  /** Bitcoin Cash */
  Bch = 'BCH',
  /** Bangladeshi Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundian Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Bolivian Boliviano */
  Bob = 'BOB',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Bitcoin */
  Btc = 'BTC',
  /** Bhutanese Ngultrum */
  Btn = 'BTN',
  /** Botswana Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble */
  Byn = 'BYN',
  /** Belarusian Ruble */
  Byr = 'BYR',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** Swiss Franc */
  Chf = 'CHF',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Chinese Renminbi Yuan Offshore */
  Cnh = 'CNH',
  /** Chinese Renminbi Yuan */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Costa Rican Colón */
  Crc = 'CRC',
  /** Cuban Convertible Peso */
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verdean Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djiboutian Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Estonian Kroon */
  Eek = 'EEK',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Eritrean Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fijian Dollar */
  Fjd = 'FJD',
  /** Falkland Pound */
  Fkp = 'FKP',
  /** British Pound */
  Gbp = 'GBP',
  /** British Penny */
  Gbx = 'GBX',
  /** Georgian Lari */
  Gel = 'GEL',
  /** Guernsey Pound */
  Ggp = 'GGP',
  /** Ghanaian Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Gambian Dalasi */
  Gmd = 'GMD',
  /** Guinean Franc */
  Gnf = 'GNF',
  /** Guatemalan Quetzal */
  Gtq = 'GTQ',
  /** Guyanese Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Honduran Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian Gourde */
  Htg = 'HTG',
  /** Hungarian Forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli New Sheqel */
  Ils = 'ILS',
  /** Isle of Man Pound */
  Imp = 'IMP',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Icelandic Króna */
  Isk = 'ISK',
  /** Jersey Pound */
  Jep = 'JEP',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Japanese Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Kyrgyzstani Som */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** South Korean Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Kazakhstani Tenge */
  Kzt = 'KZT',
  /** Lao Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lankan Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho Loti */
  Lsl = 'LSL',
  /** Lithuanian Litas */
  Ltl = 'LTL',
  /** Latvian Lats */
  Lvl = 'LVL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Macedonian Denar */
  Mkd = 'MKD',
  /** Myanmar Kyat */
  Mmk = 'MMK',
  /** Mongolian Tögrög */
  Mnt = 'MNT',
  /** Macanese Pataca */
  Mop = 'MOP',
  /** Mauritanian Ouguiya */
  Mro = 'MRO',
  /** Mauritanian Ouguiya */
  Mru = 'MRU',
  /** Maltese Lira */
  Mtl = 'MTL',
  /** Mauritian Rupee */
  Mur = 'MUR',
  /** Maldivian Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Omani Rial */
  Omr = 'OMR',
  /** Panamanian Balboa */
  Pab = 'PAB',
  /** Peruvian Sol */
  Pen = 'PEN',
  /** Papua New Guinean Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistani Rupee */
  Pkr = 'PKR',
  /** Polish Złoty */
  Pln = 'PLN',
  /** Paraguayan Guaraní */
  Pyg = 'PYG',
  /** Qatari Riyal */
  Qar = 'QAR',
  /** Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwandan Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychellois Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helenian Pound */
  Shp = 'SHP',
  /** Slovak Koruna */
  Skk = 'SKK',
  /** Sierra Leonean Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinamese Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe Dobra */
  Std = 'STD',
  /** Salvadoran Colón */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Swazi Lilangeni */
  Szl = 'SZL',
  /** Thai Baht */
  Thb = 'THB',
  /** Tajikistani Somoni */
  Tjs = 'TJS',
  /** Turkmenistani Manat */
  Tmm = 'TMM',
  /** Turkmenistani Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Tongan Paʻanga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia */
  Uah = 'UAH',
  /** Ugandan Shilling */
  Ugx = 'UGX',
  /** United States Dollar */
  Usd = 'USD',
  /** Uruguayan Peso */
  Uyu = 'UYU',
  /** Uzbekistan Som */
  Uzs = 'UZS',
  /** Venezuelan Bolívar */
  Vef = 'VEF',
  /** Venezuelan Bolívar Soberano */
  Ves = 'VES',
  /** Vietnamese Đồng */
  Vnd = 'VND',
  /** Vanuatu Vatu */
  Vuv = 'VUV',
  /** Samoan Tala */
  Wst = 'WST',
  /** Central African Cfa Franc */
  Xaf = 'XAF',
  /** Silver (Troy Ounce) */
  Xag = 'XAG',
  /** Gold (Troy Ounce) */
  Xau = 'XAU',
  /** European Composite Unit */
  Xba = 'XBA',
  /** European Monetary Unit */
  Xbb = 'XBB',
  /** European Unit of Account 9 */
  Xbc = 'XBC',
  /** European Unit of Account 17 */
  Xbd = 'XBD',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** Special Drawing Rights */
  Xdr = 'XDR',
  /** UIC Franc */
  Xfu = 'XFU',
  /** West African Cfa Franc */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** Cfp Franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** Yemeni Rial */
  Yer = 'YER',
  /** South African Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmk = 'ZMK',
  /** Zambian Kwacha */
  Zmw = 'ZMW',
  /** Zimbabwean Dollar */
  Zwd = 'ZWD',
  /** Zimbabwean Dollar */
  Zwl = 'ZWL',
  /** Zimbabwean Dollar */
  Zwn = 'ZWN',
  /** Zimbabwean Dollar */
  Zwr = 'ZWR',
}

/** An object with an GlobalID. */
export type CustomField = {
  activities?: Maybe<ActivityConnection>;
  /**
   * Whether or not the record is archived.
   * Archived records are usually omitted from query results unless specifically included.
   */
  archived: Scalars['Boolean']['output'];
  archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * Returns all audits of the object.
   *
   * The exception is draftable objects:
   *  - If the object is draft -- no audits will be returned
   *  - If the object is submitted -- only audits created after the submit date will be returned.
   *   Setting createdAfter before the submit date will have no effect.
   */
  audits?: Maybe<AuditConnection>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  formableClass: CustomFieldFormableClass;
  formableParent?: Maybe<FormableParent>;
  /** Icon to use in the UI */
  iconName?: Maybe<Scalars['String']['output']>;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  includeInSearch: Scalars['Boolean']['output'];
  inputType: CustomFieldInputType;
  label: Scalars['String']['output'];
  /** Placeholder text to display on inputs for text and integer fields. */
  placeholder?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validationRequiredValue?: Maybe<CustomFieldValidator>;
  valueType: CustomFieldValueType;
  viewerPermissions: CustomFieldRecordViewerPermissions;
  /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
  width: Scalars['Int']['output'];
};

/** An object with an GlobalID. */
export type CustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

/** An object with an GlobalID. */
export type CustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CustomFieldCollectionViewerPermissions = {
  __typename?: 'CustomFieldCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for CustomField. */
export type CustomFieldConnection = {
  __typename?: 'CustomFieldConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomFieldEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CustomField>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: CustomFieldCollectionViewerPermissions;
};

/** An edge in a connection. */
export type CustomFieldEdge = {
  __typename?: 'CustomFieldEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomField>;
};

export enum CustomFieldFormableClass {
  Category = 'CATEGORY',
  CostCentre = 'COST_CENTRE',
  DataSourceItem = 'DATA_SOURCE_ITEM',
  Document = 'DOCUMENT',
  OrganisationUnit = 'ORGANISATION_UNIT',
  RequestItem = 'REQUEST_ITEM',
  User = 'USER',
}

export enum CustomFieldInputType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  File = 'FILE',
  Integer = 'INTEGER',
  Money = 'MONEY',
  Select = 'SELECT',
  /** Single-line text input */
  Text = 'TEXT',
  /** Multi-line text input */
  Textarea = 'TEXTAREA',
}

export type CustomFieldRecordViewerPermissions = {
  __typename?: 'CustomFieldRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePosition: Scalars['Boolean']['output'];
};

export type CustomFieldSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  createdByName?: InputMaybe<SortDirection>;
  formableClass?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  /** Sort by position, default order - ASC */
  position?: InputMaybe<SortDirection>;
  required?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum CustomFieldType {
  Approver = 'APPROVER',
  Attachment = 'ATTACHMENT',
  Category = 'CATEGORY',
  Checkbox = 'CHECKBOX',
  CostCentre = 'COST_CENTRE',
  Date = 'DATE',
  Integer = 'INTEGER',
  Money = 'MONEY',
  OrganisationUnit = 'ORGANISATION_UNIT',
  Select = 'SELECT',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  Url = 'URL',
}

export type CustomFieldValidator = {
  __typename?: 'CustomFieldValidator';
  constraint?: Maybe<Scalars['String']['output']>;
  dataType: CustomFieldValidatorDataType;
  errorMessageTranslated: Scalars['String']['output'];
  errorMessageTranslationKey: Scalars['String']['output'];
  labelTranslated: Scalars['String']['output'];
  labelTranslationKey: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum CustomFieldValidatorDataType {
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
}

/** An object with an GlobalID. */
export type CustomFieldValue = {
  /**
   * Returns all audits of the object.
   *
   * The exception is draftable objects:
   *  - If the object is draft -- no audits will be returned
   *  - If the object is submitted -- only audits created after the submit date will be returned.
   *   Setting createdAfter before the submit date will have no effect.
   */
  audits?: Maybe<AuditConnection>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customField: CustomField;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewerPermissions: CustomFieldValueRecordViewerPermissions;
};

/** An object with an GlobalID. */
export type CustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type CustomFieldValueRecordViewerPermissions = {
  __typename?: 'CustomFieldValueRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum CustomFieldValueType {
  Association = 'ASSOCIATION',
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  File = 'FILE',
  Integer = 'INTEGER',
  Money = 'MONEY',
  String = 'STRING',
}

export type CustomFieldValuesCategoriesImportMappingsAttributesInput = {
  columnName: Scalars['String']['input'];
  customFieldId: Scalars['GlobalID']['input'];
};

export type CustomFieldValuesCostCentresImportMappingsAttributesInput = {
  columnName: Scalars['String']['input'];
  customFieldId: Scalars['GlobalID']['input'];
};

export type CustomFieldValuesDataSourceItemsImportMappingsAttributesInput = {
  columnName: Scalars['String']['input'];
  customFieldId: Scalars['GlobalID']['input'];
};

export type CustomFieldValuesParent = {
  customFieldValues?: Maybe<Array<CustomFieldValue>>;
};

export type CustomFieldValuesParentCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldValuesUsersImportMappingsAttributesInput = {
  columnName: Scalars['String']['input'];
  customFieldId: Scalars['GlobalID']['input'];
};

export enum CustomTransactionReconciliationStatus {
  CreditNoteMissing = 'CREDIT_NOTE_MISSING',
  CreditNoteUnavailable = 'CREDIT_NOTE_UNAVAILABLE',
  ReceiptMissing = 'RECEIPT_MISSING',
  ReceiptUnavailable = 'RECEIPT_UNAVAILABLE',
  Reconciled = 'RECONCILED',
}

export type DataFilter = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataFilter';
    comparator: FlowStepConditionComparatorName;
    createdAt: Scalars['ISO8601DateTime']['output'];
    dataView: DataView;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    inputPath?: Maybe<DataPath>;
    referenceValue?: Maybe<Scalars['ReferenceValue']['output']>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type DataFilterRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for DataFilter. */
export type DataFilterConnection = {
  __typename?: 'DataFilterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataFilterEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataFilter>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DataFilterEdge = {
  __typename?: 'DataFilterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataFilter>;
};

export type DataPath = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataPath';
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    key: Scalars['String']['output'];
    path: Scalars['String']['output'];
    relationExists: Scalars['Boolean']['output'];
    rootResourceType: MetaModelResourceType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: DataPathRecordViewerPermissions;
  };

export type DataPathRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DataPathCollectionViewerPermissions = {
  __typename?: 'DataPathCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for DataPath. */
export type DataPathConnection = {
  __typename?: 'DataPathConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataPathEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataPath>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: DataPathCollectionViewerPermissions;
};

/** An edge in a connection. */
export type DataPathEdge = {
  __typename?: 'DataPathEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataPath>;
};

export type DataPathRecordViewerPermissions = {
  __typename?: 'DataPathRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type DataScopingRule = GlobalIdField &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataScopingRule';
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    operator: DataScopingRuleOperator;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    scopeByCustomField: CustomField;
    scopeToPath?: Maybe<DataPath>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: DataScopingRuleRecordViewerPermissions;
  };

export type DataScopingRuleRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DataScopingRuleCollectionViewerPermissions = {
  __typename?: 'DataScopingRuleCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for DataScopingRule. */
export type DataScopingRuleConnection = {
  __typename?: 'DataScopingRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataScopingRuleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataScopingRule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: DataScopingRuleCollectionViewerPermissions;
};

/** An edge in a connection. */
export type DataScopingRuleEdge = {
  __typename?: 'DataScopingRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataScopingRule>;
};

export enum DataScopingRuleOperator {
  And = 'AND',
  Or = 'OR',
}

export type DataScopingRuleRecordViewerPermissions = {
  __typename?: 'DataScopingRuleRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePosition: Scalars['Boolean']['output'];
};

export type DataScopingRules = {
  dataScopingRules: DataScopingRuleConnection;
};

export type DataScopingRulesDataScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DataSource = Archivable &
  Auditable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataSource';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    items: DataSourceItemConnection;
    label: Scalars['String']['output'];
    parsedId?: Maybe<IParsedGlobalId>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: DataSourceRecordViewerPermissions;
  };

export type DataSourceActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type DataSourceAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type DataSourceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DataSourceItemSortInput>>;
};

export type DataSourceRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DataSourceCollectionViewerPermissions = {
  __typename?: 'DataSourceCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for DataSource. */
export type DataSourceConnection = {
  __typename?: 'DataSourceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataSourceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataSource>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: DataSourceCollectionViewerPermissions;
};

/** An edge in a connection. */
export type DataSourceEdge = {
  __typename?: 'DataSourceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataSource>;
};

/**
 * A data source is a custom list of pre-defined values. A data source item
 * is one of the values in this list.
 *
 * For example a data source might be a "Team" at a company and an item would
 * be "Red Team".
 */
export type DataSourceItem = Archivable &
  Auditable &
  CustomFieldValuesParent &
  GlobalIdField &
  HasActivities &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'DataSourceItem';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    /** Parent data source. */
    dataSource: DataSource;
    /** Optional user-facing description of the item. */
    description?: Maybe<Scalars['String']['output']>;
    /** Optional identifier used in the customers' own internal systems. */
    externalId?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Primary identifier or value. */
    label: Scalars['String']['output'];
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: DataSourceItemRecordViewerPermissions;
  };

/**
 * A data source is a custom list of pre-defined values. A data source item
 * is one of the values in this list.
 *
 * For example a data source might be a "Team" at a company and an item would
 * be "Red Team".
 */
export type DataSourceItemActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

/**
 * A data source is a custom list of pre-defined values. A data source item
 * is one of the values in this list.
 *
 * For example a data source might be a "Team" at a company and an item would
 * be "Red Team".
 */
export type DataSourceItemAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

/**
 * A data source is a custom list of pre-defined values. A data source item
 * is one of the values in this list.
 *
 * For example a data source might be a "Team" at a company and an item would
 * be "Red Team".
 */
export type DataSourceItemCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * A data source is a custom list of pre-defined values. A data source item
 * is one of the values in this list.
 *
 * For example a data source might be a "Team" at a company and an item would
 * be "Red Team".
 */
export type DataSourceItemReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * A data source is a custom list of pre-defined values. A data source item
 * is one of the values in this list.
 *
 * For example a data source might be a "Team" at a company and an item would
 * be "Red Team".
 */
export type DataSourceItemRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for DataSourceItem. */
export type DataSourceItemConnection = {
  __typename?: 'DataSourceItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataSourceItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataSourceItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DataSourceItemEdge = {
  __typename?: 'DataSourceItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataSourceItem>;
};

export type DataSourceItemRecordViewerPermissions = {
  __typename?: 'DataSourceItemRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
};

export type DataSourceItemSortInput = {
  /** Sort by external_id of Data Source Item */
  externalId?: InputMaybe<SortDirection>;
  /** Sort by label of Data Source Item */
  label?: InputMaybe<SortDirection>;
};

export type DataSourceItemsImportMappingsAttributesInput = {
  customFieldValues?: InputMaybe<Array<CustomFieldValuesDataSourceItemsImportMappingsAttributesInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type DataSourceRecordViewerPermissions = {
  __typename?: 'DataSourceRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  import: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type DataSourceSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type DataValue = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataValue';
    amountValue?: Maybe<Money>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    dateValue?: Maybe<Scalars['ISO8601Date']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    key: Scalars['String']['output'];
    pathValue?: Maybe<DataPath>;
    record: AvailableTypes;
    recordValue?: Maybe<ValueTypes>;
    relationExists: Scalars['Boolean']['output'];
    staticValue?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    valueSource: DataValueValueSource;
    viewerPermissions: DataValueRecordViewerPermissions;
  };

export type DataValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DataValueRecordViewerPermissions = {
  __typename?: 'DataValueRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum DataValueValueSource {
  /**
   * When a DataValue's ValueSource is `AMOUNT`, the DataValue will have a `amount_value` field that contains the
   * amount value in Money object.
   */
  Amount = 'AMOUNT',
  /**
   * This type of DataValue records has a DataPath to resolve the value. If the ValueSource is `DATA_PATH`,
   * the DataValue will have a `path_value` field that contains the DataPath.
   */
  DataPath = 'DATA_PATH',
  /**
   * When a DataValue's ValueSource is `DATE`, the DataValue will have a `date_value` field that contains the
   * date object in ISO8601 format.
   */
  Date = 'DATE',
  /**
   * When a DataValue's ValueSource is `RECORD`, the DataValue will be associated to a record from database and
   * will return the record object as its value.
   */
  Record = 'RECORD',
  /**
   * When a DataValue's ValueSource is `STATIC`, the DataValue will have a `static_value` field that contains the
   * static value. This static value will return always string but the value may containt any type of data including
   * numbers, booleans etc. but in string type.
   */
  Static = 'STATIC',
}

export type DataView = Archivable &
  Auditable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataView';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    fields: DataViewFieldConnection;
    filters: DataFilterConnection;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    parsedId?: Maybe<IParsedGlobalId>;
    relationExists: Scalars['Boolean']['output'];
    /** Root resource model name */
    resourceType: DataViewResourceType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: DataViewRecordViewerPermissions;
  };

export type DataViewActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type DataViewAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type DataViewFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DataViewFieldSortInput>>;
};

export type DataViewFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DataViewRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DataViewCollectionViewerPermissions = {
  __typename?: 'DataViewCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for DataView. */
export type DataViewConnection = {
  __typename?: 'DataViewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataViewEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataView>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: DataViewCollectionViewerPermissions;
};

/** An edge in a connection. */
export type DataViewEdge = {
  __typename?: 'DataViewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataView>;
};

export type DataViewField = Auditable &
  GlobalIdField &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DataViewField';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    dataValue?: Maybe<DataValue>;
    dataView: DataView;
    /** Optional override format to use when the data is exported to a flat file. */
    formatString?: Maybe<DataViewFieldFormatString>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Unique field label */
    label: Scalars['String']['output'];
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: DataViewFieldRecordViewerPermissions;
  };

export type DataViewFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type DataViewFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for DataViewField. */
export type DataViewFieldConnection = {
  __typename?: 'DataViewFieldConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataViewFieldEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DataViewField>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DataViewFieldEdge = {
  __typename?: 'DataViewFieldEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DataViewField>;
};

export enum DataViewFieldFormatString {
  /** 0 */
  BooleanBit = 'BOOLEAN_BIT',
  /** true */
  BooleanTf = 'BOOLEAN_TF',
  /** yes */
  BooleanYn = 'BOOLEAN_YN',
  /** 2022-06-22 15:45:09 */
  Datetime = 'DATETIME',
  /** 2022-06-22T15:45:09+00:00 */
  DatetimeIso = 'DATETIME_ISO',
  /** 2022-06-22 15:45:09 +00:00 */
  DatetimeWithOffset = 'DATETIME_WITH_OFFSET',
  /** 2022-06-22 15:45:09 UTC */
  DatetimeWithTzName = 'DATETIME_WITH_TZ_NAME',
  /** 22-06-2022 */
  DateDmyDash = 'DATE_DMY_DASH',
  /** 22.06.2022 */
  DateDmyDot = 'DATE_DMY_DOT',
  /** 22/06/2022 */
  DateDmySlash = 'DATE_DMY_SLASH',
  /** 2022-06-22 */
  DateIso = 'DATE_ISO',
  /** 1234.57 */
  Decimal_2 = 'DECIMAL_2',
  /** 1234,57 */
  Decimal_2Comma = 'DECIMAL_2_COMMA',
  /** 1234.57 */
  Decimal_2Dot = 'DECIMAL_2_DOT',
  /** 1234.56789 */
  Decimal_5 = 'DECIMAL_5',
  /** 1234,56789 */
  Decimal_5Comma = 'DECIMAL_5_COMMA',
  /** 1234.56789 */
  Decimal_5Dot = 'DECIMAL_5_DOT',
  /** 03:45 PM */
  Time_12H = 'TIME_12H',
  /** 03:45:09 PM */
  Time_12HS = 'TIME_12H_S',
  /** 15:45 */
  Time_24H = 'TIME_24H',
  /** 15:45:09 */
  Time_24HS = 'TIME_24H_S',
}

export type DataViewFieldRecordViewerPermissions = {
  __typename?: 'DataViewFieldRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePosition: Scalars['Boolean']['output'];
};

export type DataViewFieldSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by position, default order - ASC */
  position?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type DataViewRecordViewerPermissions = {
  __typename?: 'DataViewRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  duplicate: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum DataViewResourceType {
  CardSubscription = 'CARD_SUBSCRIPTION',
  Category = 'CATEGORY',
  CostCentre = 'COST_CENTRE',
  DataSource = 'DATA_SOURCE',
  DataSourceItem = 'DATA_SOURCE_ITEM',
  Document = 'DOCUMENT',
  DocumentParseResult = 'DOCUMENT_PARSE_RESULT',
  InvoicePayment = 'INVOICE_PAYMENT',
  IssuerIntegration = 'ISSUER_INTEGRATION',
  Merchant = 'MERCHANT',
  OrganizationUnit = 'ORGANIZATION_UNIT',
  PurchaseOrder = 'PURCHASE_ORDER',
  RequestItem = 'REQUEST_ITEM',
  Supplier = 'SUPPLIER',
  SupplierRequest = 'SUPPLIER_REQUEST',
  Transaction = 'TRANSACTION',
  User = 'USER',
  VirtualCard = 'VIRTUAL_CARD',
}

export type DataViewSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Date = {
  __typename?: 'Date';
  from?: Maybe<Scalars['ISO8601DateTime']['output']>;
  to?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type DateCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DateCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type DateCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type DateCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type DateCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DateTimeCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DateTimeCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value?: Maybe<Scalars['ISO8601DateTime']['output']>;
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type DateTimeCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type DateTimeCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** Autogenerated input type of DeleteDocumentTransaction */
export type DeleteDocumentTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['GlobalID']['input'];
  transactionId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DeleteDocumentTransaction. */
export type DeleteDocumentTransactionPayload = {
  __typename?: 'DeleteDocumentTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentTransaction?: Maybe<DocumentTransaction>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of DeleteRecord */
export type DeleteRecordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  recordId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DeleteRecord. */
export type DeleteRecordPayload = {
  __typename?: 'DeleteRecordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<ErrorMessage>>;
  status: MutationStatus;
};

/** Autogenerated input type of DeliverVirtualCardInApp */
export type DeliverVirtualCardInAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  virtualCardId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DeliverVirtualCardInApp. */
export type DeliverVirtualCardInAppPayload = {
  __typename?: 'DeliverVirtualCardInAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deliveryInfo?: Maybe<VirtualCardInAppDeliveryInfo>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type DestroyCustomFieldValueInputAttributes = {
  id: Scalars['GlobalID']['input'];
};

export type DestroyDataValueForParentInputAttributes = {
  dataValueId: Scalars['GlobalID']['input'];
};

/** Autogenerated input type of DetachFileFromCustomFieldValue */
export type DetachFileFromCustomFieldValueInput = {
  attachmentId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DetachFileFromCustomFieldValue. */
export type DetachFileFromCustomFieldValuePayload = {
  __typename?: 'DetachFileFromCustomFieldValuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customFieldValue?: Maybe<CustomFieldValue>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type DeviceRegistration = Timestamps & {
  __typename?: 'DeviceRegistration';
  createdAt: Scalars['ISO8601DateTime']['output'];
  firebaseDeviceToken: Scalars['String']['output'];
  os: DeviceRegistrationDeviceOs;
  osVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
};

export enum DeviceRegistrationDeviceOs {
  Android = 'ANDROID',
  Ios = 'IOS',
}

/** Autogenerated input type of Disable2fa */
export type Disable2faInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpCode: Scalars['String']['input'];
};

/** Autogenerated return type of Disable2fa. */
export type Disable2faPayload = {
  __typename?: 'Disable2faPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

/** Autogenerated input type of DisableWorkflow */
export type DisableWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  workflowId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DisableWorkflow. */
export type DisableWorkflowPayload = {
  __typename?: 'DisableWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  workflow?: Maybe<Workflow>;
};

export type Document = Archivable &
  Auditable &
  Commentable &
  CustomFieldValuesParent &
  Draftable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'Document';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    cardSubscription?: Maybe<CardSubscription>;
    classification: DocumentClassification;
    comments: CommentConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    currency?: Maybe<Currency>;
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    documentNumber?: Maybe<Scalars['String']['output']>;
    file?: Maybe<Attachment>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    incomingEmailAttachment?: Maybe<IncomingEmailAttachment>;
    invalidatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    invalidatedBy?: Maybe<User>;
    /**
     * This field cannot be null for the buyer account, but the Document type is also available for the supplier
     * account and we do not want to allow this field to suppliers. So this is a nullable value
     */
    invoicePayments?: Maybe<Array<InvoicePayment>>;
    /**
     * Whether or not the record is draft.
     * Draft records are usually omitted from query results unless specifically included.
     */
    isDraft: Scalars['Boolean']['output'];
    /** Date of issue of the attached invoice. */
    issuedOn?: Maybe<Scalars['ISO8601Date']['output']>;
    messageToSupplier?: Maybe<Scalars['String']['output']>;
    parseResult?: Maybe<DocumentParseResult>;
    /** Date of payment of the attached invoice. */
    paymentDate?: Maybe<Scalars['ISO8601Date']['output']>;
    purchaseOrder?: Maybe<PurchaseOrder>;
    purchaseRequestItem?: Maybe<PurchaseRequestItem>;
    receiverIban?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    source?: Maybe<DocumentSource>;
    submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    supplier?: Maybe<SupplierAccount>;
    /**
     * A list of transactions that the document can be matched with.
     *
     * This field cannot be null for the buyer account, but the Document type is also available for the supplier
     * account and we do not want to allow this field to suppliers. So this is a nullable value
     */
    supportedTransactions?: Maybe<TransactionConnection>;
    /**
     * This field returns possible types for purchase order document:
     * - if document has any invoice payments it will be [`invoice`]
     * - if document doesn't have a purchase order it will be [`credit_note`, `other`, `receipt`]
     * - if purchase order is invoice:
     *   - if purchase order is not closed and doesn't have other invoice it will be [`invoice`, `other`]
     *   - otherwise it will be [`other`]
     * - if purchase order is not invoice it will be [`credit_note`, `other`, `receipt`]
     */
    supportedTypes?: Maybe<Array<DocumentClassification>>;
    totalAmount?: Maybe<Money>;
    /**
     * This field cannot be null for the buyer account, but the Document type is also available for the supplier
     * account and we do not want to pass this field to suppliers. So this is a nullable value
     */
    transactions?: Maybe<Array<Transaction>>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    vatAmount?: Maybe<Money>;
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: DocumentRecordViewerPermissions;
  };

export type DocumentActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type DocumentAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type DocumentCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type DocumentCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type DocumentSupportedTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum DocumentClassification {
  /** A document is issued from a business to a customer **if for some reason the purchase is refunded**. */
  CreditNote = 'CREDIT_NOTE',
  /**
   * A document is issued from a business to a customer **once it's time for the customer to pay**
   * for the goods or services they have ordered.
   */
  Invoice = 'INVOICE',
  /** Any other document type other than receipt or invoice. For example, a purchasing proposal for goods or services. */
  Other = 'OTHER',
  /**
   * A document is issued from a business to a customer **after the customer has paid** for
   * goods or services.
   */
  Receipt = 'RECEIPT',
}

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

export type DocumentParseResult = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DocumentParseResult';
    amountDue?: Maybe<Money>;
    buyerTaxId?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    currency?: Maybe<Scalars['String']['output']>;
    deliveryDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
    document: Document;
    documentDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
    documentNumber?: Maybe<Scalars['String']['output']>;
    documentType: DocumentClassification;
    dueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    mlModelId: Scalars['String']['output'];
    mlStatus: DocumentParseResultMlStatus;
    paymentTerms?: Maybe<Scalars['String']['output']>;
    purchaseOrder?: Maybe<Scalars['String']['output']>;
    receiverAddress?: Maybe<Scalars['String']['output']>;
    receiverEmail?: Maybe<Scalars['String']['output']>;
    receiverName?: Maybe<Scalars['String']['output']>;
    receiverPhone?: Maybe<Scalars['String']['output']>;
    receiverTaxId?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    shipFromAddress?: Maybe<Scalars['String']['output']>;
    shipToAddress?: Maybe<Scalars['String']['output']>;
    shipToName?: Maybe<Scalars['String']['output']>;
    shippingAmount?: Maybe<Money>;
    supplierAddress?: Maybe<Scalars['String']['output']>;
    supplierEmail?: Maybe<Scalars['String']['output']>;
    supplierIban?: Maybe<Scalars['String']['output']>;
    supplierName?: Maybe<Scalars['String']['output']>;
    supplierPaymentRef?: Maybe<Scalars['String']['output']>;
    supplierPhone?: Maybe<Scalars['String']['output']>;
    supplierRegistration?: Maybe<Scalars['String']['output']>;
    supplierTaxId?: Maybe<Scalars['String']['output']>;
    supplierWebsite?: Maybe<Url>;
    totalAmount?: Maybe<Money>;
    totalTaxAmount?: Maybe<Money>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    vatAmount?: Maybe<Money>;
    vatCategory?: Maybe<Scalars['String']['output']>;
    vatTaxRate?: Maybe<Scalars['String']['output']>;
  };

export type DocumentParseResultRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum DocumentParseResultMlStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export type DocumentRecordViewerPermissions = {
  __typename?: 'DocumentRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  download: Scalars['Boolean']['output'];
  invalidate: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  subscribeToNotifications: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  undoInvalidate: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
};

export type DocumentSortInput = {
  cardSubscriptionLabel?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  createdByName?: InputMaybe<SortDirection>;
  documentType?: InputMaybe<SortDirection>;
  issuedOn?: InputMaybe<SortDirection>;
  paymentDate?: InputMaybe<SortDirection>;
  submittedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum DocumentSource {
  DirectUpload = 'DIRECT_UPLOAD',
  EmailAttachment = 'EMAIL_ATTACHMENT',
  EmailBody = 'EMAIL_BODY',
}

export type DocumentTransaction = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'DocumentTransaction';
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    document: Document;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    transaction: Transaction;
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type DocumentTransactionRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type Documentable = {
  documents?: Maybe<DocumentConnection>;
};

export type DocumentableDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DownloadAttachment */
export type DownloadAttachmentInput = {
  attachmentId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DownloadAttachment. */
export type DownloadAttachmentPayload = {
  __typename?: 'DownloadAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Url>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of DownloadPurchaseOrderDocuments */
export type DownloadPurchaseOrderDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DownloadPurchaseOrderDocuments. */
export type DownloadPurchaseOrderDocumentsPayload = {
  __typename?: 'DownloadPurchaseOrderDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /**
   * Direct download URL of the zipped file from the server for all documents. The file
   * will be deleted one hour later after the generation.
   */
  downloadUrl?: Maybe<Url>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type Draftable = {
  /**
   * Whether or not the record is draft.
   * Draft records are usually omitted from query results unless specifically included.
   */
  isDraft: Scalars['Boolean']['output'];
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of DuplicateDataView */
export type DuplicateDataViewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataViewId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of DuplicateDataView. */
export type DuplicateDataViewPayload = {
  __typename?: 'DuplicateDataViewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataView?: Maybe<DataView>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of Enable2fa */
export type Enable2faInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Enable2fa. */
export type Enable2faPayload = {
  __typename?: 'Enable2faPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  codes: Array<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  otpProvisioningUri: Scalars['String']['output'];
  status: MutationStatus;
};

/** Autogenerated input type of EnableSupplier */
export type EnableSupplierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of EnableSupplier. */
export type EnableSupplierPayload = {
  __typename?: 'EnableSupplierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  supplier?: Maybe<SupplierAccount>;
};

/** Autogenerated input type of EnableWorkflow */
export type EnableWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  workflowId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of EnableWorkflow. */
export type EnableWorkflowPayload = {
  __typename?: 'EnableWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  workflow?: Maybe<Workflow>;
};

/**
 * Generic error object with a message at a path.
 *
 * For example if you're creating a user and their address post code is bad:
 *
 * ```json
 * {
 *   "path": "address.postal_code",
 *   "message": "invalid format"
 * }
 * ```
 */
export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  /** Human-readable message, already translated. */
  message: Scalars['String']['output'];
  /**
   * Attribute path relative to the parent object.
   *
   * When the attribute is local, it's a plain string.
   * When the attribute is on a child/related object, dot-notation is used.
   */
  path?: Maybe<Scalars['String']['output']>;
};

/** An object that caused an activity to be created. */
export type EventSubject =
  | Approval
  | Attachment
  | BuyerAccount
  | Comment
  | Document
  | IncomingEmail
  | PurchaseOrder
  | PurchaseRequestItem
  | SupplierAccount
  | Transaction
  | User
  | VirtualCard
  | Workflow;

export type Failures = {
  __typename?: 'Failures';
  count: Scalars['Int']['output'];
  /**
   * The number of 'rows' is limited to 50.
   * But 'count' can be greater than 50.
   */
  rows: Array<Row>;
};

export type FeatureToggle = {
  __typename?: 'FeatureToggle';
  /**
   * Whether or not this feature toggle is enabled for the viewer.
   *
   * Toggles can be switched on or off globally, at the account level,
   * or per individual user.
   */
  enabled: Scalars['Boolean']['output'];
};

/**
 * Feature toggles, sometimes called feature switches or flags,
 * allow applications to control what features of an application
 * are available.
 *
 * Toggles can be switched on globally, by account, or by individual user.
 *
 * Toggles are typically used to control the rollout of a new feature to
 * a group of beta testers or limit access to only certain environments
 * or accounts where it's needed.
 */
export type FeatureToggles = {
  __typename?: 'FeatureToggles';
  /**
   * Allows users to connect to the "/cable" endpoint and subscribe to and receive GQL subscription events.
   *
   * When this toggle is on, the "/cable" endpoint can be reached.
   * GQL subscriptions will be added to the schema and subscription events in
   * commands will be triggered at runtime.
   *
   * When this toggle is off, the "/cable" endpoint will return an unauthorized message
   * when attempting to connect. There will no GQL subscriptions in the schema,
   * and subscription events will not triggered in the code.
   *
   * Since 2024-07
   */
  actionCableSubscriptions: FeatureToggle;
  /**
   * Allow marking line items as "goods received"
   *
   * When this toggle is on, users will be able to mark line items as "goods received" to track
   * which purchases have been received.
   *
   * When this toggle is off, this tracking will not be available to view or update.
   *
   * Since 2022-05
   */
  goodsReceivedTracking: FeatureToggle;
  /**
   * Allow sync with Intercom when account/user is created/updated, and sync with Intercom at all.
   * Allow to get the intercomUserHash in the "me" query.
   *
   * When this toggle is on, users will be able to get the intercomUserHash from the "me" query
   * so they can authenticate with Intercom. Also sync with Intercom, when creating/updating
   * an account/user, allow Intercom::Jobs::SyncMissing to perform sync.
   *
   * When this toggle is off, intercomUserHash field will be hidden on me query.
   * Prevents all the syncs from happening when users and accounts are created/updated and
   * prevents the job 'Intercom::Jobs::SyncMissing' from doing anything
   */
  intercom: FeatureToggle;
  /**
   * Allow usage suppliers query and enables the ability to invite and onboard new suppliers.
   *
   * When this toggle is on, users will be able to use "suppliers" query in GraphQL and filter
   * by "supplier_id" on "transactions" query and invite new suppliers if they do not exist yet.
   *
   * When this toggle is off, "suppliers" query and filtering by "supplier_id" on transactions
   * will not be available and users will not be able to invite new suppliers.
   *
   * Since 2022-06
   */
  invoiceManagement: FeatureToggle;
};

export type FlowActionApprove = FlowStep &
  FlowStepAction &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowActionApprove';
    availableOutputSteps: Array<FlowStepType>;
    coordinates: Coordinates;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputFalseSteps?: Maybe<FlowStepConnection>;
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputTrueSteps?: Maybe<FlowStepConnection>;
    lastEditedBy?: Maybe<User>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputFalseStep?: Maybe<FlowStep>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputTrueStep?: Maybe<FlowStep>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: FlowStepSoftValidation;
    targetPath?: Maybe<DataPath>;
    type: FlowStepType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: FlowStepActionRecordViewerPermissions;
    workflow: Workflow;
  };

export type FlowActionApproveInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionApproveInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionApproveRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type FlowActionComment = FlowStep &
  FlowStepAction &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowActionComment';
    availableOutputSteps: Array<FlowStepType>;
    commentAuthor?: Maybe<User>;
    commentText?: Maybe<Scalars['String']['output']>;
    coordinates: Coordinates;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputFalseSteps?: Maybe<FlowStepConnection>;
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputTrueSteps?: Maybe<FlowStepConnection>;
    lastEditedBy?: Maybe<User>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputFalseStep?: Maybe<FlowStep>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputTrueStep?: Maybe<FlowStep>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: FlowStepSoftValidation;
    targetPath?: Maybe<DataPath>;
    type: FlowStepType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: FlowStepActionRecordViewerPermissions;
    workflow: Workflow;
  };

export type FlowActionCommentInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionCommentInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionCommentRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type FlowActionCreatePurchaseOrder = FlowStep &
  FlowStepAction &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowActionCreatePurchaseOrder';
    availableOutputSteps: Array<FlowStepType>;
    coordinates: Coordinates;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputFalseSteps?: Maybe<FlowStepConnection>;
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputTrueSteps?: Maybe<FlowStepConnection>;
    lastEditedBy?: Maybe<User>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputFalseStep?: Maybe<FlowStep>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputTrueStep?: Maybe<FlowStep>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: FlowStepSoftValidation;
    type: FlowStepType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: FlowStepActionRecordViewerPermissions;
    workflow: Workflow;
  };

export type FlowActionCreatePurchaseOrderInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionCreatePurchaseOrderInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionCreatePurchaseOrderRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type FlowActionGetApproval = FlowStep &
  FlowStepAction &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowActionGetApproval';
    approvalType?: Maybe<FlowActionGetApprovalApprovalType>;
    approverUsers: UserConnection;
    /**
     * When the field is `true` it shows that `ApproveBy` step
     * will create Approvals for all users that have `approve` role.
     */
    assignAll: Scalars['Boolean']['output'];
    assigneePaths: DataPathConnection;
    availableOutputSteps: Array<FlowStepType>;
    coordinates: Coordinates;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputFalseSteps?: Maybe<FlowStepConnection>;
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputTrueSteps?: Maybe<FlowStepConnection>;
    lastEditedBy?: Maybe<User>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputFalseStep?: Maybe<FlowStep>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputTrueStep?: Maybe<FlowStep>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: FlowStepSoftValidation;
    targetPath?: Maybe<DataPath>;
    type: FlowStepType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: FlowStepActionRecordViewerPermissions;
    workflow: Workflow;
  };

export type FlowActionGetApprovalApproverUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionGetApprovalAssigneePathsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionGetApprovalInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionGetApprovalInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionGetApprovalRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum FlowActionGetApprovalApprovalType {
  All = 'ALL',
  Any = 'ANY',
}

export type FlowActionReject = FlowStep &
  FlowStepAction &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowActionReject';
    availableOutputSteps: Array<FlowStepType>;
    coordinates: Coordinates;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputFalseSteps?: Maybe<FlowStepConnection>;
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputTrueSteps?: Maybe<FlowStepConnection>;
    lastEditedBy?: Maybe<User>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputFalseStep?: Maybe<FlowStep>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputTrueStep?: Maybe<FlowStep>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: FlowStepSoftValidation;
    targetPath?: Maybe<DataPath>;
    type: FlowStepType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: FlowStepActionRecordViewerPermissions;
    workflow: Workflow;
  };

export type FlowActionRejectInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionRejectInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowActionRejectRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** An object with an GlobalID. */
export type FlowStep = {
  availableOutputSteps: Array<FlowStepType>;
  coordinates: Coordinates;
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
  inputFalseSteps?: Maybe<FlowStepConnection>;
  /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
  inputTrueSteps?: Maybe<FlowStepConnection>;
  lastEditedBy?: Maybe<User>;
  /** Use this field only to get the step ID, as more fields may cause strict loading issues */
  outputFalseStep?: Maybe<FlowStep>;
  /** Use this field only to get the step ID, as more fields may cause strict loading issues */
  outputTrueStep?: Maybe<FlowStep>;
  softValidation: FlowStepSoftValidation;
  type: FlowStepType;
  workflow: Workflow;
};

/** An object with an GlobalID. */
export type FlowStepInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object with an GlobalID. */
export type FlowStepInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object with an GlobalID. */
export type FlowStepAction = {
  availableOutputSteps: Array<FlowStepType>;
  coordinates: Coordinates;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
  inputFalseSteps?: Maybe<FlowStepConnection>;
  /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
  inputTrueSteps?: Maybe<FlowStepConnection>;
  lastEditedBy?: Maybe<User>;
  /** Use this field only to get the step ID, as more fields may cause strict loading issues */
  outputFalseStep?: Maybe<FlowStep>;
  /** Use this field only to get the step ID, as more fields may cause strict loading issues */
  outputTrueStep?: Maybe<FlowStep>;
  softValidation: FlowStepSoftValidation;
  type: FlowStepType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewerPermissions: FlowStepActionRecordViewerPermissions;
  workflow: Workflow;
};

/** An object with an GlobalID. */
export type FlowStepActionInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object with an GlobalID. */
export type FlowStepActionInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepActionCollectionViewerPermissions = {
  __typename?: 'FlowStepActionCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for FlowStepAction. */
export type FlowStepActionConnection = {
  __typename?: 'FlowStepActionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStepActionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FlowStepAction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: FlowStepActionCollectionViewerPermissions;
};

/** An edge in a connection. */
export type FlowStepActionEdge = {
  __typename?: 'FlowStepActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FlowStepAction>;
};

export type FlowStepActionRecordViewerPermissions = {
  __typename?: 'FlowStepActionRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePosition: Scalars['Boolean']['output'];
};

export type FlowStepCondition = FlowStep &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowStepCondition';
    availableOutputSteps: Array<FlowStepType>;
    comparator?: Maybe<FlowStepConditionComparatorName>;
    coordinates: Coordinates;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputFalseSteps?: Maybe<FlowStepConnection>;
    inputPath?: Maybe<DataPath>;
    /** Use this field only to get the step IDs, as more fields may cause strict loading issues */
    inputTrueSteps?: Maybe<FlowStepConnection>;
    lastEditedBy?: Maybe<User>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputFalseStep?: Maybe<FlowStep>;
    /** Use this field only to get the step ID, as more fields may cause strict loading issues */
    outputTrueStep?: Maybe<FlowStep>;
    referenceAssociations: ReferenceAssociationConnection;
    referenceDataType?: Maybe<FlowStepConditionInputAttributeDataType>;
    referencePaths: DataPathConnection;
    referencePlaceholder: Scalars['String']['output'];
    referenceUnit?: Maybe<Scalars['String']['output']>;
    referenceValue?: Maybe<Scalars['ReferenceValue']['output']>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: FlowStepSoftValidation;
    type: FlowStepType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: FlowStepConditionRecordViewerPermissions;
    workflow: Workflow;
  };

export type FlowStepConditionInputFalseStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepConditionInputTrueStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepConditionReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepConditionReferencePathsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepConditionRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type FlowStepConditionCollectionViewerPermissions = {
  __typename?: 'FlowStepConditionCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

export enum FlowStepConditionComparatorName {
  EqualTo = 'EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  IncludesOption = 'INCLUDES_OPTION',
  IncludesValue = 'INCLUDES_VALUE',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Truthy = 'TRUTHY',
}

/** The connection type for FlowStepCondition. */
export type FlowStepConditionConnection = {
  __typename?: 'FlowStepConditionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStepConditionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FlowStepCondition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: FlowStepConditionCollectionViewerPermissions;
};

/** An edge in a connection. */
export type FlowStepConditionEdge = {
  __typename?: 'FlowStepConditionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FlowStepCondition>;
};

export enum FlowStepConditionInputAttributeDataType {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Enum = 'ENUM',
  Integer = 'INTEGER',
  Money = 'MONEY',
  Object = 'OBJECT',
  String = 'STRING',
}

export enum FlowStepConditionInputAttributeName {
  CategoryCode = 'CATEGORY_CODE',
  CostCentreCode = 'COST_CENTRE_CODE',
  OrganisationUnitCode = 'ORGANISATION_UNIT_CODE',
  OrganisationUnitName = 'ORGANISATION_UNIT_NAME',
  RequesterSpendLimitReached = 'REQUESTER_SPEND_LIMIT_REACHED',
  TotalItemAmount = 'TOTAL_ITEM_AMOUNT',
  TotalRequestAmount = 'TOTAL_REQUEST_AMOUNT',
}

export type FlowStepConditionRecordViewerPermissions = {
  __typename?: 'FlowStepConditionRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePosition: Scalars['Boolean']['output'];
};

/** The connection type for FlowStep. */
export type FlowStepConnection = {
  __typename?: 'FlowStepConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStepEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FlowStep>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FlowStepEdge = {
  __typename?: 'FlowStepEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FlowStep>;
};

export type FlowStepResult = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowStepResult';
    commentTargetValue?: Maybe<FlowStepResultValue>;
    completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    conditionInputValue?: Maybe<FlowStepResultValue>;
    conditionReferenceValues?: Maybe<FlowStepResultValueConnection>;
    conditionResult?: Maybe<Scalars['Boolean']['output']>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * Returns records created by the flow step result.
     *
     * - For `FlowActions::Comment` flow steps, this will return the `Comment` records created by this flow step
     *   result.
     * - For `FlowActions::ApproveBy` flow steps, this will return the `Approval` records created by this flow step
     *   result.
     */
    createdRecords: FlowStepResultCreatedRecordsConnection;
    failedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    flowStep?: Maybe<FlowStep>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    resultMessage?: Maybe<Scalars['String']['output']>;
    status: FlowStepResultStatus;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    workflowRun: WorkflowRun;
  };

export type FlowStepResultConditionReferenceValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepResultCreatedRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowStepResultRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for FlowStepResult. */
export type FlowStepResultConnection = {
  __typename?: 'FlowStepResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStepResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FlowStepResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/**
 * The createdRecords field on FlowStepResult type will return the records created by the flow step result.
 * And the interface will be implemented to the types which are created by the flow step result.
 * Initially, these types are `Comment` for FlowAction::Comment and `Approval` for FlowAction::ApproveBy.
 */
export type FlowStepResultCreatedRecords = {
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
};

/** The connection type for FlowStepResultCreatedRecords. */
export type FlowStepResultCreatedRecordsConnection = {
  __typename?: 'FlowStepResultCreatedRecordsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStepResultCreatedRecordsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FlowStepResultCreatedRecords>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FlowStepResultCreatedRecordsEdge = {
  __typename?: 'FlowStepResultCreatedRecordsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FlowStepResultCreatedRecords>;
};

/** An edge in a connection. */
export type FlowStepResultEdge = {
  __typename?: 'FlowStepResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FlowStepResult>;
};

export enum FlowStepResultStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
}

export type FlowStepResultValue = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'FlowStepResultValue';
    createdAt: Scalars['ISO8601DateTime']['output'];
    flowStepResult: FlowStepResult;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    key: FlowStepResultValueKey;
    record?: Maybe<FlowStepResultValueRecord>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value?: Maybe<Scalars['String']['output']>;
  };

export type FlowStepResultValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for FlowStepResultValue. */
export type FlowStepResultValueConnection = {
  __typename?: 'FlowStepResultValueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlowStepResultValueEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FlowStepResultValue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FlowStepResultValueEdge = {
  __typename?: 'FlowStepResultValueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FlowStepResultValue>;
};

export enum FlowStepResultValueKey {
  ApprovalValue = 'APPROVAL_VALUE',
  CommentTargetValue = 'COMMENT_TARGET_VALUE',
  CommentValue = 'COMMENT_VALUE',
  ConditionInputValue = 'CONDITION_INPUT_VALUE',
  ConditionReferenceValue = 'CONDITION_REFERENCE_VALUE',
}

export type FlowStepResultValueRecord =
  | Address
  | Approval
  | ApprovalDelegation
  | BuyerAccount
  | CardSubscription
  | Category
  | CostCentre
  | DataSource
  | DataSourceItem
  | Document
  | DocumentParseResult
  | InvoicePayment
  | IssuerIntegration
  | Merchant
  | OrganisationUnit
  | PurchaseOrder
  | PurchaseRequestItem
  | SupplierAccount
  | SupplierRequest
  | Transaction
  | User
  | VirtualCard;

/**
 * Performs a validation for specific actions on a record and returns error messages
 * indicating why the action could not be performed, or an empty array if everything is ok.
 */
export type FlowStepSoftValidation = {
  __typename?: 'FlowStepSoftValidation';
  publish: Array<ErrorMessage>;
};

export enum FlowStepType {
  Approve = 'APPROVE',
  Comment = 'COMMENT',
  Condition = 'CONDITION',
  CreatePurchaseOrder = 'CREATE_PURCHASE_ORDER',
  GetApproval = 'GET_APPROVAL',
  Reject = 'REJECT',
}

export type FormableParent = DataSource;

/** Autogenerated input type of GeneratePdfFromIncomingEmail */
export type GeneratePdfFromIncomingEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  incomingEmailId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of GeneratePdfFromIncomingEmail. */
export type GeneratePdfFromIncomingEmailPayload = {
  __typename?: 'GeneratePdfFromIncomingEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  incomingEmail?: Maybe<IncomingEmail>;
  status: MutationStatus;
};

/** Autogenerated input type of GetImportFileMetadata */
export type GetImportFileMetadataInput = {
  blobId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the delimiter when parsing the file. By default it will try to guess the delimiter. */
  delimiter?: InputMaybe<ImportFileDataDelimiter>;
};

/** Autogenerated return type of GetImportFileMetadata. */
export type GetImportFileMetadataPayload = {
  __typename?: 'GetImportFileMetadataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<ErrorMessage>>;
  importFileMetadata?: Maybe<ImportFileData>;
  status: MutationStatus;
};

/** An object with an GlobalID. */
export type GlobalIdField = {
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
};

export type HasActivities = {
  activities?: Maybe<ActivityConnection>;
};

export type HasActivitiesActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type HasAncestry = {
  childrenCount: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['GlobalID']['output']>;
};

export type HasAncestryChildrenCountArgs = {
  archived?: InputMaybe<OptionalBoolean>;
};

export type IParsedGlobalId = {
  __typename?: 'IParsedGlobalId';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ImportCategories */
export type ImportCategoriesInput = {
  /**
   * If set to true, the import will unarchive any archived records that are being updated. Otherwise, archived
   * records will be skipped and they will be added to the skipped section with a reason.
   */
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  categorySetId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the delimiter when parsing the file. By default it will try to guess the delimiter. */
  delimiter?: InputMaybe<ImportFileDataDelimiter>;
  mappings: CategoriesImportMappingsAttributesInput;
};

/** Autogenerated return type of ImportCategories. */
export type ImportCategoriesPayload = {
  __typename?: 'ImportCategoriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  importResult?: Maybe<ImportResult>;
  status: MutationStatus;
};

/** Autogenerated input type of ImportCostCentres */
export type ImportCostCentresInput = {
  /**
   * If set to true, the import will unarchive any archived records that are being updated. Otherwise, archived
   * records will be skipped and they will be added to the skipped section with a reason.
   */
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the delimiter when parsing the file. By default it will try to guess the delimiter. */
  delimiter?: InputMaybe<ImportFileDataDelimiter>;
  mappings: CostCentresImportMappingsAttributesInput;
};

/** Autogenerated return type of ImportCostCentres. */
export type ImportCostCentresPayload = {
  __typename?: 'ImportCostCentresPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  importResult?: Maybe<ImportResult>;
  status: MutationStatus;
};

/** Autogenerated input type of ImportDataSourceItems */
export type ImportDataSourceItemsInput = {
  /**
   * If set to true, the import will unarchive any archived records that are being updated. Otherwise, archived
   * records will be skipped and they will be added to the skipped section with a reason.
   */
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataSourceId: Scalars['GlobalID']['input'];
  /** Specifies the delimiter when parsing the file. By default it will try to guess the delimiter. */
  delimiter?: InputMaybe<ImportFileDataDelimiter>;
  mappings: DataSourceItemsImportMappingsAttributesInput;
};

/** Autogenerated return type of ImportDataSourceItems. */
export type ImportDataSourceItemsPayload = {
  __typename?: 'ImportDataSourceItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  importResult?: Maybe<ImportResult>;
  status: MutationStatus;
};

export type ImportFileData = {
  __typename?: 'ImportFileData';
  columnsNames: Array<Scalars['String']['output']>;
  rowCount: Scalars['Int']['output'];
};

export enum ImportFileDataDelimiter {
  Auto = 'AUTO',
  Comma = 'COMMA',
  Semicolon = 'SEMICOLON',
}

export type ImportResult = {
  __typename?: 'ImportResult';
  createdRecordsCount: Scalars['Int']['output'];
  failures: Failures;
  skipped: Skipped;
  updatedRecordsCount: Scalars['Int']['output'];
};

/** Autogenerated input type of ImportUsers */
export type ImportUsersInput = {
  /**
   * If set to true, the import will unarchive any archived records that are being updated. Otherwise, archived
   * records will be skipped and they will be added to the skipped section with a reason.
   */
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The viewer can only assign roles that he has */
  defaultRoles?: InputMaybe<Array<UserSecurityRole>>;
  /** Specifies the delimiter when parsing the file. By default it will try to guess the delimiter. */
  delimiter?: InputMaybe<ImportFileDataDelimiter>;
  invitationMessage?: InputMaybe<Scalars['String']['input']>;
  mappings: UsersImportMappingsAttributesInput;
};

/** Autogenerated return type of ImportUsers. */
export type ImportUsersPayload = {
  __typename?: 'ImportUsersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  importResult?: Maybe<ImportResult>;
  status: MutationStatus;
};

export type Inbox = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Inbox';
    /** The email local-part */
    address: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    incomingEmails?: Maybe<IncomingEmailConnection>;
    key: Scalars['String']['output'];
    owner: User;
    /** The resource whose transaction receipts will be processed by this inbox */
    record: Inboxable;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type InboxIncomingEmailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type InboxRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for Inbox. */
export type InboxConnection = {
  __typename?: 'InboxConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Inbox>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InboxEdge = {
  __typename?: 'InboxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Inbox>;
};

/** An object with an GlobalID. */
export type Inboxable = {
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
  /**
   * Returns the inbox with the given key. Since inbox keys are not an enum, the following list may help for the keys:
   *
   * CardSubscription:
   *   - receipts
   */
  inboxForKey?: Maybe<Inbox>;
  inboxes: InboxConnection;
};

/** An object with an GlobalID. */
export type InboxableInboxForKeyArgs = {
  key: Scalars['String']['input'];
};

/** An object with an GlobalID. */
export type InboxableInboxesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomingEmail = GlobalIdField &
  Node &
  RelationExists & {
    __typename?: 'IncomingEmail';
    bodyDocument?: Maybe<Document>;
    bodyText: Scalars['String']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    inbox: Inbox;
    incomingEmailAttachments: IncomingEmailAttachmentConnection;
    processedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    receivedAt: Scalars['ISO8601DateTime']['output'];
    relationExists: Scalars['Boolean']['output'];
    senderEmail: Scalars['String']['output'];
    subject?: Maybe<Scalars['String']['output']>;
    viewerPermissions: IncomingEmailRecordViewerPermissions;
  };

export type IncomingEmailIncomingEmailAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IncomingEmailAttachmentSortInput>>;
};

export type IncomingEmailRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type IncomingEmailAttachment = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'IncomingEmailAttachment';
    attachment?: Maybe<Attachment>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    document?: Maybe<Document>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    inbox: Inbox;
    incomingEmail: IncomingEmail;
    processingCompletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    processingFailedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type IncomingEmailAttachmentRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for IncomingEmailAttachment. */
export type IncomingEmailAttachmentConnection = {
  __typename?: 'IncomingEmailAttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IncomingEmailAttachmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IncomingEmailAttachment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IncomingEmailAttachmentEdge = {
  __typename?: 'IncomingEmailAttachmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IncomingEmailAttachment>;
};

export type IncomingEmailAttachmentSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** The connection type for IncomingEmail. */
export type IncomingEmailConnection = {
  __typename?: 'IncomingEmailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IncomingEmailEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IncomingEmail>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IncomingEmailEdge = {
  __typename?: 'IncomingEmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IncomingEmail>;
};

export type IncomingEmailRecordViewerPermissions = {
  __typename?: 'IncomingEmailRecordViewerPermissions';
  generatePdf: Scalars['Boolean']['output'];
};

export type IncomingEmailSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  processedAt?: InputMaybe<SortDirection>;
  receivedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type IndustryCode = GlobalIdField &
  HasAncestry &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'IndustryCode';
    ancestors?: Maybe<Array<IndustryCode>>;
    childrenCount: Scalars['Int']['output'];
    code: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    name: Scalars['String']['output'];
    parentId?: Maybe<Scalars['GlobalID']['output']>;
    relationExists: Scalars['Boolean']['output'];
    system: IndustryCodeSystem;
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type IndustryCodeChildrenCountArgs = {
  archived?: InputMaybe<OptionalBoolean>;
};

export type IndustryCodeRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type IndustryCodeSortInput = {
  code?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum IndustryCodeSystem {
  Nace2 = 'NACE2',
}

export type InputAttribute = {
  __typename?: 'InputAttribute';
  comparators: Array<Comparator>;
  dataType: FlowStepConditionInputAttributeDataType;
  i18nName: Scalars['String']['output'];
  name: FlowStepConditionInputAttributeName;
};

export type IntegerCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'IntegerCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type IntegerCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type IntegerCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type IntegerCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type IntegerCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'IntegerCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value?: Maybe<Scalars['BigInt']['output']>;
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type IntegerCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type IntegerCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** Autogenerated input type of InvalidateDocument */
export type InvalidateDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['GlobalID']['input'];
  invalidateComment: Scalars['String']['input'];
};

/** Autogenerated return type of InvalidateDocument. */
export type InvalidateDocumentPayload = {
  __typename?: 'InvalidateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of InviteAllDraftUsers */
export type InviteAllDraftUsersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of InviteAllDraftUsers. */
export type InviteAllDraftUsersPayload = {
  __typename?: 'InviteAllDraftUsersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  users?: Maybe<Array<User>>;
};

/** Autogenerated input type of InviteDraftUsers */
export type InviteDraftUsersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of InviteDraftUsers. */
export type InviteDraftUsersPayload = {
  __typename?: 'InviteDraftUsersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  users?: Maybe<Array<User>>;
};

/** Autogenerated input type of InviteUser */
export type InviteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of InviteUser. */
export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

export type InvoicePayment = GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'InvoicePayment';
    activities?: Maybe<ActivityConnection>;
    buyer?: Maybe<BuyerAccount>;
    cancellationComment?: Maybe<Scalars['String']['output']>;
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * It returns the user who canceled the payment, may return nil for the canceled payment
     * if it was canceled by the administrator or supplier user during bank account archiving.
     */
    cancelledBy?: Maybe<User>;
    /**
     * This field cannot be null for the buyer account, but the InvoicePayment type is also available for the supplier
     * account and we do not want to allow this field to suppliers. So this is a nullable value
     */
    cardPayment?: Maybe<CardPayment>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    document: Document;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    messageToSupplier?: Maybe<Scalars['String']['output']>;
    /**
     * Returns the related purchase request item for the invoice payment. The data is not expected to be null,
     * however this field is also available for the supplier users and they don't have permission to access the purchase
     * request item. So this is a nullable field.
     */
    purchaseRequestItem?: Maybe<PurchaseRequestItem>;
    relationExists: Scalars['Boolean']['output'];
    requestedPayoutAmount: Money;
    requestedPayoutDate: Scalars['ISO8601Date']['output'];
    status: InvoicePaymentStatus;
    supplier?: Maybe<SupplierAccount>;
    supplierPayment: SupplierPayment;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: InvoicePaymentRecordViewerPermissions;
  };

export type InvoicePaymentActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type InvoicePaymentRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type InvoicePaymentCollectionViewerPermissions = {
  __typename?: 'InvoicePaymentCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for InvoicePayment. */
export type InvoicePaymentConnection = {
  __typename?: 'InvoicePaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoicePaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvoicePayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: InvoicePaymentCollectionViewerPermissions;
};

/** An edge in a connection. */
export type InvoicePaymentEdge = {
  __typename?: 'InvoicePaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoicePayment>;
};

export type InvoicePaymentRecordViewerPermissions = {
  __typename?: 'InvoicePaymentRecordViewerPermissions';
  cancel: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  update: Scalars['Boolean']['output'];
};

export type InvoicePaymentSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  createdByName?: InputMaybe<SortDirection>;
  requestedForPayoutAt?: InputMaybe<SortDirection>;
  scheduledForPaymentAt?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  supplierName?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum InvoicePaymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InvalidStatus = 'INVALID_STATUS',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Scheduled = 'SCHEDULED',
}

export type IssuerIntegration = Archivable &
  GlobalIdField &
  HasActivities &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'IssuerIntegration';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    billingEntity?: Maybe<OrganisationUnit>;
    cardUseInvoice: Scalars['Boolean']['output'];
    cardUseMulti: Scalars['Boolean']['output'];
    cardUseSingle: Scalars['Boolean']['output'];
    consumers: IssuerIntegrationConsumerConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    currency: Currency;
    description: Scalars['String']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    issuerName: Scalars['String']['output'];
    /**
     * @deprecated Use `issuerName` instead.
     *
     * (Since 2023-05-23. To be removed by MP-6392)
     *
     */
    name: IssuerIntegrationServiceClass;
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: IssuerIntegrationRecordViewerPermissions;
  };

export type IssuerIntegrationActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type IssuerIntegrationConsumersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuerIntegrationReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuerIntegrationRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type IssuerIntegrationCollectionViewerPermissions = {
  __typename?: 'IssuerIntegrationCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for IssuerIntegration. */
export type IssuerIntegrationConnection = {
  __typename?: 'IssuerIntegrationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IssuerIntegrationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IssuerIntegration>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: IssuerIntegrationCollectionViewerPermissions;
};

export type IssuerIntegrationConsumer = GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'IssuerIntegrationConsumer';
    activities?: Maybe<ActivityConnection>;
    cardUseInvoiceFallback: Scalars['Boolean']['output'];
    cardUseMultiFallback: Scalars['Boolean']['output'];
    cardUseSingleFallback: Scalars['Boolean']['output'];
    /**
     * Name of consumer:
     * - *First* and/or *Last* names combined for user, if name blank, return email
     * - Label for organization_unit
     * - Label for cost_centre
     */
    consumerName: Scalars['String']['output'];
    costCentre?: Maybe<CostCentre>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    issuerIntegration: IssuerIntegration;
    organisationUnit?: Maybe<OrganisationUnit>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    user?: Maybe<User>;
    viewerPermissions: IssuerIntegrationConsumerRecordViewerPermissions;
  };

export type IssuerIntegrationConsumerActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type IssuerIntegrationConsumerRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type IssuerIntegrationConsumerCollectionViewerPermissions = {
  __typename?: 'IssuerIntegrationConsumerCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for IssuerIntegrationConsumer. */
export type IssuerIntegrationConsumerConnection = {
  __typename?: 'IssuerIntegrationConsumerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IssuerIntegrationConsumerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IssuerIntegrationConsumer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: IssuerIntegrationConsumerCollectionViewerPermissions;
};

export enum IssuerIntegrationConsumerConsumerRelation {
  All = 'ALL',
  Direct = 'DIRECT',
  Inherited = 'INHERITED',
}

export enum IssuerIntegrationConsumerConsumerType {
  CostCentre = 'COST_CENTRE',
  OrganisationUnit = 'ORGANISATION_UNIT',
  User = 'USER',
}

/** An edge in a connection. */
export type IssuerIntegrationConsumerEdge = {
  __typename?: 'IssuerIntegrationConsumerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IssuerIntegrationConsumer>;
};

export type IssuerIntegrationConsumerRecordViewerPermissions = {
  __typename?: 'IssuerIntegrationConsumerRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  makeInvoiceFallback?: Maybe<Scalars['Boolean']['output']>;
  makeMultiFallback?: Maybe<Scalars['Boolean']['output']>;
  makeSingleFallback?: Maybe<Scalars['Boolean']['output']>;
  update: Scalars['Boolean']['output'];
};

export type IssuerIntegrationConsumerSortInput = {
  /**
   * Sort by issuer integration consumer that integration support an invoice.
   * Ascending = false first
   */
  cardUseInvoiceFallback?: InputMaybe<SortDirection>;
  /**
   * Sort by issuer integration consumer that integration support  multi-use card issuance.
   * Ascending = false first
   */
  cardUseMultiFallback?: InputMaybe<SortDirection>;
  /**
   * Sort by issuer integration consumer that integration support  single-use card issuance.
   * Ascending = false first
   */
  cardUseSingleFallback?: InputMaybe<SortDirection>;
  /**
   * Sort by label of consumer:
   * - For user consumer - fullName `{first_name} {last_name}`
   * - For cost centre consumer - label
   * - For organisation unit consumer - label
   * Returns consumers by blocks:
   *  1) First block - sorted organisation units consumers.
   *  2) Second block - sorted cost_centres consumers.
   *  3) Third block - sorted users consumers.
   */
  consumerName?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** An edge in a connection. */
export type IssuerIntegrationEdge = {
  __typename?: 'IssuerIntegrationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IssuerIntegration>;
};

export type IssuerIntegrationRecordViewerPermissions = {
  __typename?: 'IssuerIntegrationRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type IssuerIntegrationRule = GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'IssuerIntegrationRule';
    activities?: Maybe<ActivityConnection>;
    /** For issuing which virtual cards the integration can be used. */
    cardUseType: IssuerIntegrationRuleCardUseType;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** The variable that will be used for filtering. */
    criteria: IssuerIntegrationRuleCriteria;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    /** When set to false, the rule will be ignored if no matches are found after filtering by rule criteria. */
    strict: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: IssuerIntegrationRuleRecordViewerPermissions;
  };

export type IssuerIntegrationRuleActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type IssuerIntegrationRuleRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum IssuerIntegrationRuleCardUseType {
  Invoice = 'INVOICE',
  Multi = 'MULTI',
  Single = 'SINGLE',
}

export type IssuerIntegrationRuleCollectionViewerPermissions = {
  __typename?: 'IssuerIntegrationRuleCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for IssuerIntegrationRule. */
export type IssuerIntegrationRuleConnection = {
  __typename?: 'IssuerIntegrationRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IssuerIntegrationRuleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IssuerIntegrationRule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: IssuerIntegrationRuleCollectionViewerPermissions;
};

export enum IssuerIntegrationRuleCriteria {
  CostCentre = 'COST_CENTRE',
  OrganisationUnit = 'ORGANISATION_UNIT',
  User = 'USER',
}

/** An edge in a connection. */
export type IssuerIntegrationRuleEdge = {
  __typename?: 'IssuerIntegrationRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IssuerIntegrationRule>;
};

export type IssuerIntegrationRuleRecordViewerPermissions = {
  __typename?: 'IssuerIntegrationRuleRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePosition: Scalars['Boolean']['output'];
};

export type IssuerIntegrationRuleSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by position, default order - ASC */
  position?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum IssuerIntegrationServiceClass {
  AirPlus = 'AIR_PLUS',
  /**
   * Does *not* issue real virtual cards. Generates valid-looking, but fake and
   * unusable cards. Only used for development, demos, and QA.
   */
  Dummy = 'DUMMY',
  Eurocard = 'EUROCARD',
  Mastercard = 'MASTERCARD',
}

export type IssuerIntegrationSortInput = {
  /** Sort by integrations that support an invoice. Ascending = false first */
  cardUseInvoice?: InputMaybe<SortDirection>;
  /** Sort by integrations that support multi-use card issuance. Ascending = false first */
  cardUseMulti?: InputMaybe<SortDirection>;
  /** Sort by integrations that support single-use card issuance. Ascending = false first */
  cardUseSingle?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  currencyCode?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Language = {
  __typename?: 'Language';
  /** ISO 639-1 language code e.g. `da` for Danish */
  code: LanguageCode;
  /** Name of the language in English */
  englishName: Scalars['String']['output'];
  /** Language name in its native language e.g. `dansk` for Danish */
  nativeName: Scalars['String']['output'];
};

export enum LanguageCode {
  /** dansk (Danish) */
  Da = 'DA',
  /** Deutsch (German) */
  De = 'DE',
  /** English */
  En = 'EN',
  /** suomi (Finnish) */
  Fi = 'FI',
  /** Français (French) */
  Fr = 'FR',
  /** Italiano (Italian) */
  It = 'IT',
  /** norsk (Norwegian) */
  No = 'NO',
  /** svenska (Swedish) */
  Sv = 'SV',
}

export type LocalisationSettings = {
  __typename?: 'LocalisationSettings';
  displayInCurrency: Currency;
  interfaceLanguage: Language;
  timezone: Scalars['TimezoneID']['output'];
};

export type Location = Archivable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Location';
    activities?: Maybe<ActivityConnection>;
    address: Address;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: LocationRecordViewerPermissions;
  };

export type LocationActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type LocationRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type LocationCollectionViewerPermissions = {
  __typename?: 'LocationCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for Location. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Location>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: LocationCollectionViewerPermissions;
};

/** An edge in a connection. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Location>;
};

export type LocationRecordViewerPermissions = {
  __typename?: 'LocationRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type LocationSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated input type of LookupCompany */
export type LookupCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCode: CountryCode;
  /**
   * We currently only allow the VAT number as a search term.
   * This behavior may be changed in the future.
   *
   * The VAT number must not contain a country code, i.e. `DK12345678` is not valid VAT number,
   * but `12345678` is valid.
   */
  vatNumber: Scalars['String']['input'];
};

/** Autogenerated return type of LookupCompany. */
export type LookupCompanyPayload = {
  __typename?: 'LookupCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  result?: Maybe<LookupCompanyResult>;
  status: MutationStatus;
};

/**
 * Represents result of lookupCompany mutation.
 *
 * This could be a supplier account from our database
 * or company lookup record with result from Penneo API
 */
export type LookupCompanyResult = {
  __typename?: 'LookupCompanyResult';
  companyLookup?: Maybe<CompanyLookup>;
  supplier?: Maybe<SupplierAccount>;
};

export type ManuallyOrderable = {
  position: Scalars['Int']['output'];
};

/** Autogenerated input type of MarkAllNotificationsAsRead */
export type MarkAllNotificationsAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MarkAllNotificationsAsRead. */
export type MarkAllNotificationsAsReadPayload = {
  __typename?: 'MarkAllNotificationsAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  notifications: Array<Notification>;
  status: MutationStatus;
};

/** Autogenerated input type of MarkNotificationAsRead */
export type MarkNotificationAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notificationId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of MarkNotificationAsRead. */
export type MarkNotificationAsReadPayload = {
  __typename?: 'MarkNotificationAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  notification: Notification;
  status: MutationStatus;
};

/** Autogenerated input type of MarkNotificationAsUnread */
export type MarkNotificationAsUnreadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notificationId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of MarkNotificationAsUnread. */
export type MarkNotificationAsUnreadPayload = {
  __typename?: 'MarkNotificationAsUnreadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  notification: Notification;
  status: MutationStatus;
};

/** Autogenerated input type of MarkRequestItemGoodsReceived */
export type MarkRequestItemGoodsReceivedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  requestItemId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of MarkRequestItemGoodsReceived. */
export type MarkRequestItemGoodsReceivedPayload = {
  __typename?: 'MarkRequestItemGoodsReceivedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  requestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

/** Autogenerated input type of MarkTransactionAsReceiptMissing */
export type MarkTransactionAsReceiptMissingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of MarkTransactionAsReceiptMissing. */
export type MarkTransactionAsReceiptMissingPayload = {
  __typename?: 'MarkTransactionAsReceiptMissingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  transaction: Transaction;
};

/** Autogenerated input type of MarkTransactionAsReceiptUnavailable */
export type MarkTransactionAsReceiptUnavailableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of MarkTransactionAsReceiptUnavailable. */
export type MarkTransactionAsReceiptUnavailablePayload = {
  __typename?: 'MarkTransactionAsReceiptUnavailablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  transaction: Transaction;
};

/** Fields that are only relevant to the current user. */
export type Me = GlobalIdField &
  Node &
  RelationExists & {
    __typename?: 'Me';
    /**
     * It could be SupplierAccount or BuyerAccount,
     * this field is always available.
     */
    account: Account;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /**
     * **NOTICE**: This is guarded by the feature toggle `intercom`.
     * If this toggle is switched off, this element will disappear from the schema and
     * trying to use it will result in a query error.
     *
     *
     * ------
     *
     * The field can be used for identify the user on Intercom.
     *
     * This hash should be sent to the Intercom with the user's email address, then
     * we'll be able to identify the user on Intercom platform. Intercom widget accepts both
     * `user_id` and `email`, since we are using `email` for creating the hash `user_id` must
     * not be sent to the Intercom widget.
     */
    intercomUserHash: Scalars['String']['output'];
    /** Notifications for the current user */
    notifications?: Maybe<NotificationConnection>;
    profile: User;
    relationExists: Scalars['Boolean']['output'];
    settings: UserSettings;
    /**
     * If users were created during the supplier account onboarding process,
     * they will have a onboarding contact. Each contact is related to Penneo
     * and contains a link to Penneo person and case.
     *
     * This contact also represents the onboarding status and type of contact:
     * representative or authorized signature.
     * We use this contact to refresh information about the supplier onboarding process.
     */
    supplierOnboardingContact?: Maybe<SupplierOnboardingContact>;
  };

/** Fields that are only relevant to the current user. */
export type MeNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<OptionalBoolean>;
};

/** Fields that are only relevant to the current user. */
export type MeRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type Merchant = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Merchant';
    address?: Maybe<PublicAddress>;
    city: Scalars['String']['output'];
    companyName: Scalars['String']['output'];
    country: Country;
    createdAt: Scalars['ISO8601DateTime']['output'];
    establishedOn?: Maybe<Scalars['ISO8601Date']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    lastUsedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    legalCorporateName?: Maybe<Scalars['String']['output']>;
    merchantCategoryCode?: Maybe<MerchantCategoryCode>;
    merchantName: Scalars['String']['output'];
    /** North American Industry Classification System */
    naicsCode?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    /** Total spent with this merchant by the current buyer account. */
    totalSpent?: Maybe<Money>;
    /** Total spent with this merchant by the current buyer account in base currency. */
    totalSpentBaseAmount?: Maybe<Money>;
    transactions: TransactionConnection;
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type MerchantRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type MerchantTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MerchantCategoryCode = GlobalIdField &
  Node &
  RelationExists & {
    __typename?: 'MerchantCategoryCode';
    category?: Maybe<Scalars['String']['output']>;
    code: Scalars['MerchantCategoryCodeCode']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    relationExists: Scalars['Boolean']['output'];
  };

export type MerchantCategoryCodeRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for MerchantCategoryCode. */
export type MerchantCategoryCodeConnection = {
  __typename?: 'MerchantCategoryCodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MerchantCategoryCodeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MerchantCategoryCode>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MerchantCategoryCodeEdge = {
  __typename?: 'MerchantCategoryCodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MerchantCategoryCode>;
};

export type MerchantCategoryCodeSortInput = {
  category?: InputMaybe<SortDirection>;
  code?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** The connection type for Merchant. */
export type MerchantConnection = {
  __typename?: 'MerchantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MerchantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Merchant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MerchantEdge = {
  __typename?: 'MerchantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Merchant>;
};

export type MerchantSortInput = {
  city?: InputMaybe<SortDirection>;
  code?: InputMaybe<SortDirection>;
  companyName?: InputMaybe<SortDirection>;
  country?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  lastUsedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type MetaModel = {
  __typename?: 'MetaModel';
  associations?: Maybe<Array<MetaModelAssociation>>;
  attributes?: Maybe<Array<MetaModelAttribute>>;
  /** Root resource model name */
  resourceType: MetaModelResourceType;
};

export type MetaModelAssociation = {
  __typename?: 'MetaModelAssociation';
  /** Display archived status for custom field, will be nil for other associations */
  archived?: Maybe<Scalars['Boolean']['output']>;
  customField?: Maybe<CustomField>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** Root resource model name */
  resourceType: MetaModelResourceType;
};

export type MetaModelAttribute = {
  __typename?: 'MetaModelAttribute';
  /** Display archived status for custom field, will be nil for other attributes */
  archived?: Maybe<Scalars['Boolean']['output']>;
  customField?: Maybe<CustomField>;
  dataType?: Maybe<MetaModelAttributeDataType>;
  i18nKey?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** Returns a list of value options for enum attributes */
  options: Array<Scalars['String']['output']>;
};

export enum MetaModelAttributeDataType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Enum = 'ENUM',
  File = 'FILE',
  Integer = 'INTEGER',
  Jsonb = 'JSONB',
  Money = 'MONEY',
  String = 'STRING',
}

/** The connection type for MetaModel. */
export type MetaModelConnection = {
  __typename?: 'MetaModelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MetaModelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MetaModel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MetaModelEdge = {
  __typename?: 'MetaModelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MetaModel>;
};

export enum MetaModelResourceType {
  Account = 'ACCOUNT',
  Address = 'ADDRESS',
  CardSubscription = 'CARD_SUBSCRIPTION',
  Category = 'CATEGORY',
  CostCentre = 'COST_CENTRE',
  Currency = 'CURRENCY',
  DataSource = 'DATA_SOURCE',
  DataSourceItem = 'DATA_SOURCE_ITEM',
  Document = 'DOCUMENT',
  DocumentParseResult = 'DOCUMENT_PARSE_RESULT',
  InvoicePayment = 'INVOICE_PAYMENT',
  IssuerIntegration = 'ISSUER_INTEGRATION',
  Merchant = 'MERCHANT',
  Money = 'MONEY',
  OrganizationUnit = 'ORGANIZATION_UNIT',
  PurchaseOrder = 'PURCHASE_ORDER',
  RequestItem = 'REQUEST_ITEM',
  Supplier = 'SUPPLIER',
  SupplierRequest = 'SUPPLIER_REQUEST',
  Transaction = 'TRANSACTION',
  User = 'USER',
  VirtualCard = 'VIRTUAL_CARD',
}

/** A money amount that is stored in cents and with a currency. */
export type Money = {
  __typename?: 'Money';
  /**
   * Whole amount as an integer including the cents.
   * For example, `$123.45` is `12345` cents.
   *
   * This is returned as a `string` because JSON uses signed 32-bit integers
   * which overflows at amount ~21 million since we're numbers storing in cents.
   */
  cents: Scalars['BigInt']['output'];
  currency: Currency;
  /**
   * Formatted consistent with local norms, but could be ambiguous in an
   * international or multi-currency environment.
   *
   * For example, `123.45 kr.` could be DKK, SEK, NOK.
   *
   * The thousands and decimal separators are local to the currency.
   *
   * Examples:
   *   - `1,234.56 kr.`
   *   - `$1,234.56`
   *   - `€1,234.56`
   *   - `1,234.56 Kč`
   *   ` `123,456 Ft`
   */
  localFormat: Scalars['String']['output'];
  /**
   * The `cents` value divided by currency's decimal places. This will return a
   * value that can be used for calculations. These are for the different currencies:
   *
   * - `DKK` and `EUR` have 2 decimal places, so it will return 1234.56
   * - `JPY` has 0 decimal places, so it will return 123456.0
   * - `BHD` has 3 decimal places, so it will return 123.456
   */
  rounded: Scalars['Float']['output'];
  /**
   * Unambiguous and consistent universal format of the amount.
   *
   * For example, `$123.45` could be dollars from many places, but `CAD 123.45`
   * is unambiguously Canadian Dollars.
   *
   * Amount is always rounded to two decimals.
   *
   * A comma `,` is used for the thousands separator, and dot `.` for decimal separator.
   *
   * Currencies that do not use decimals have no decimal separator or decimals.
   *
   * Examples:
   *   - `DKK 1,234.56`
   *   - `USD 1,234.56`
   *   - `EUR 1,234.56`
   *   - `CZK 1,234.56`
   *   - `HUF 123,456`
   */
  universalFormat: Scalars['String']['output'];
};

export type MoneyCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'MoneyCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type MoneyCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type MoneyCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type MoneyCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type MoneyCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'MoneyCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value?: Maybe<Money>;
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type MoneyCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type MoneyCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type MultiUseCard = {
  __typename?: 'MultiUseCard';
  /** Default lifetime in days of a virtual multi-use credit card. */
  defaultTtlDays: Scalars['Int']['output'];
  /** Max lifetime in days of a virtual multi-use credit card. */
  maxTtlDays: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addRoleToUser?: Maybe<AddRoleToUserPayload>;
  approveApproval?: Maybe<ApproveApprovalPayload>;
  archiveRecord?: Maybe<ArchiveRecordPayload>;
  attachFileToCustomFieldValue?: Maybe<AttachFileToCustomFieldValuePayload>;
  cancelInvoicePayment?: Maybe<CancelInvoicePaymentPayload>;
  cancelPurchaseRequestItem?: Maybe<CancelPurchaseRequestItemPayload>;
  /**
   * In order for a user to be able to receive virtual cards via iframe delivery,
   * they need to have a delivery PIN set.
   *
   * This mutation will change the user's card delivery PIN.
   * Changing an existing PIN will lock-out the user's ability to use the PIN.
   */
  changeCardDeliveryPin?: Maybe<ChangeCardDeliveryPinPayload>;
  closeCardSubscription?: Maybe<CloseCardSubscriptionPayload>;
  closePurchaseOrder?: Maybe<ClosePurchaseOrderPayload>;
  confirmEnable2fa?: Maybe<ConfirmEnable2faPayload>;
  createApprovalDelegation?: Maybe<CreateApprovalDelegationPayload>;
  createApproverCustomField?: Maybe<CreateApproverCustomFieldPayload>;
  createAttachmentCustomField?: Maybe<CreateAttachmentCustomFieldPayload>;
  createBankAccount?: Maybe<CreateBankAccountPayload>;
  createCategory?: Maybe<CreateCategoryPayload>;
  createCategoryCustomField?: Maybe<CreateCategoryCustomFieldPayload>;
  createCategorySet?: Maybe<CreateCategorySetPayload>;
  createCheckboxCustomField?: Maybe<CreateCheckboxCustomFieldPayload>;
  createComment?: Maybe<CreateCommentPayload>;
  createCostCentre?: Maybe<CreateCostCentrePayload>;
  createCostCentreCustomField?: Maybe<CreateCostCentreCustomFieldPayload>;
  createDataFilter?: Maybe<CreateDataFilterPayload>;
  createDataPath?: Maybe<CreateDataPathPayload>;
  createDataScopingRule?: Maybe<CreateDataScopingRulePayload>;
  createDataSource?: Maybe<CreateDataSourcePayload>;
  createDataSourceItem?: Maybe<CreateDataSourceItemPayload>;
  createDataValue?: Maybe<CreateDataValuePayload>;
  createDataView?: Maybe<CreateDataViewPayload>;
  createDataViewField?: Maybe<CreateDataViewFieldPayload>;
  createDateCustomField?: Maybe<CreateDateCustomFieldPayload>;
  createDocumentTransaction?: Maybe<CreateDocumentTransactionPayload>;
  createDraftDocument?: Maybe<CreateDraftDocumentPayload>;
  createIntegerCustomField?: Maybe<CreateIntegerCustomFieldPayload>;
  createInvoicePayment?: Maybe<CreateInvoicePaymentPayload>;
  createIssuerIntegrationConsumer?: Maybe<CreateIssuerIntegrationConsumerPayload>;
  createIssuerIntegrationRule?: Maybe<CreateIssuerIntegrationRulePayload>;
  createLocation?: Maybe<CreateLocationPayload>;
  createMoneyCustomField?: Maybe<CreateMoneyCustomFieldPayload>;
  createOrganisationUnit?: Maybe<CreateOrganisationUnitPayload>;
  createOrganisationUnitCustomField?: Maybe<CreateOrganisationUnitCustomFieldPayload>;
  createPurchaseRequestItem?: Maybe<CreatePurchaseRequestItemPayload>;
  createReferenceAssociation?: Maybe<CreateReferenceAssociationPayload>;
  createReport?: Maybe<CreateReportPayload>;
  createReportSchedule?: Maybe<CreateReportSchedulePayload>;
  createSelectCustomField?: Maybe<CreateSelectCustomFieldPayload>;
  createSpendLimit?: Maybe<CreateSpendLimitPayload>;
  createSubscriptionRenewalRequest?: Maybe<CreateSubscriptionRenewalRequestPayload>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `invoice_management`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  createSupplierRequest?: Maybe<CreateSupplierRequestPayload>;
  createTextAreaCustomField?: Maybe<CreateTextAreaCustomFieldPayload>;
  createTextCustomField?: Maybe<CreateTextCustomFieldPayload>;
  createUrlCustomField?: Maybe<CreateUrlCustomFieldPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createWorkflow?: Maybe<CreateWorkflowPayload>;
  createWorkflowAction?: Maybe<CreateWorkflowActionPayload>;
  createWorkflowCondition?: Maybe<CreateWorkflowConditionPayload>;
  deleteDocumentTransaction?: Maybe<DeleteDocumentTransactionPayload>;
  deleteRecord?: Maybe<DeleteRecordPayload>;
  deliverVirtualCardInApp?: Maybe<DeliverVirtualCardInAppPayload>;
  detachFileFromCustomFieldValue?: Maybe<DetachFileFromCustomFieldValuePayload>;
  disable2fa?: Maybe<Disable2faPayload>;
  disableWorkflow?: Maybe<DisableWorkflowPayload>;
  downloadAttachment?: Maybe<DownloadAttachmentPayload>;
  downloadPurchaseOrderDocuments?: Maybe<DownloadPurchaseOrderDocumentsPayload>;
  duplicateDataView?: Maybe<DuplicateDataViewPayload>;
  enable2fa?: Maybe<Enable2faPayload>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `invoice_management`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  enableSupplier?: Maybe<EnableSupplierPayload>;
  /**
   * Enable the workflow so it will be selected when a new trigger event is fired for baseResource and triggerEven.
   * Note: other workflows with the same baseResource and triggerEvent will be automatically disabled
   */
  enableWorkflow?: Maybe<EnableWorkflowPayload>;
  generatePdfFromIncomingEmail?: Maybe<GeneratePdfFromIncomingEmailPayload>;
  getImportFileMetadata?: Maybe<GetImportFileMetadataPayload>;
  importCategories?: Maybe<ImportCategoriesPayload>;
  importCostCentres?: Maybe<ImportCostCentresPayload>;
  importDataSourceItems?: Maybe<ImportDataSourceItemsPayload>;
  /**
   * It creates draft users for the given blob.
   * If a user with the same email address already exists, this line will be ignored.
   */
  importUsers?: Maybe<ImportUsersPayload>;
  invalidateDocument?: Maybe<InvalidateDocumentPayload>;
  inviteAllDraftUsers?: Maybe<InviteAllDraftUsersPayload>;
  inviteDraftUsers?: Maybe<InviteDraftUsersPayload>;
  /**
   * Invite a user that has already been created, but has not been sent an invitation.
   *
   * This will generate an invitation token and send it to the user by email.
   */
  inviteUser?: Maybe<InviteUserPayload>;
  /**
   * Checks if supplier already exists in Mazepay, if yes return existing supplier as result
   * If no, forward country and search term to Penneo api and return ID of CompanyLookup record.
   * This record contains data from Penneo API and companyName.
   */
  lookupCompany?: Maybe<LookupCompanyPayload>;
  markAllNotificationsAsRead?: Maybe<MarkAllNotificationsAsReadPayload>;
  markNotificationAsRead?: Maybe<MarkNotificationAsReadPayload>;
  markNotificationAsUnread?: Maybe<MarkNotificationAsUnreadPayload>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `goods_received_tracking`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  markRequestItemGoodsReceived?: Maybe<MarkRequestItemGoodsReceivedPayload>;
  /**
   * When a transaction is in **receipt_unavailable** status, the user marked receipt as unavailable manually.
   * If a transaction is in this status, the user will not receive any reminders.
   *
   * To undo this action and start sending reminders, we allow the managing user to mark the transaction as
   * receipt_missing again. This revert the 'markTransactionAsReceiptUnavailable' mutation
   */
  markTransactionAsReceiptMissing?: Maybe<MarkTransactionAsReceiptMissingPayload>;
  /**
   * When a transaction is in **receipt_missing** status, the user is reminded to upload a receipt.
   * Until they do, the transaction is considered unreconciled.
   *
   * In some cases a receipt may not be possible to obtain and a managing user can
   * mark the reconciliation status as "receipt unavailable" effectively resolving the reconciliation.
   */
  markTransactionAsReceiptUnavailable?: Maybe<MarkTransactionAsReceiptUnavailablePayload>;
  /**
   * @deprecated Never use this mutation, it does nothing, we need it to fix a bug with new input types in changesets.
   * Without this, the new input will be hidden from the schema and will not be present.
   *
   * Will be removed when the bug is fixed in the original gem. (See issue https://github.com/rmosolgo/graphql-ruby/issues/4562)
   * Before that, we need this mutation to be present.
   *
   * (Since 2023-07-19. To be removed by MP-6869)
   *
   */
  newInputsForChangesets?: Maybe<NewInputsForChangesetsPayload>;
  /**
   * Process the Penneo case in our system when the representative completes the onboarding.
   * It gets a case from Penneo based on the SupplierOnboardingCase and saves
   * all added contacts persons as users in the supplier account. Send an invitation to all new users.
   */
  processOnboardingCase?: Maybe<ProcessOnboardingCasePayload>;
  publishWorkflow?: Maybe<PublishWorkflowPayload>;
  /**
   * Refresh the onboarding status for a person who has a Penneo contact
   * based on the status of the goals. If all goals are completed, the status will change to 'complete'.
   */
  refreshOnboardingContact?: Maybe<RefreshOnboardingContactPayload>;
  registerDevice?: Maybe<RegisterDevicePayload>;
  rejectApproval?: Maybe<RejectApprovalPayload>;
  removeRoleFromUser?: Maybe<RemoveRoleFromUserPayload>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `invoice_management`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  removeSupplier?: Maybe<RemoveSupplierPayload>;
  reopenCardSubscription?: Maybe<ReopenCardSubscriptionPayload>;
  revokeVirtualCard?: Maybe<RevokeVirtualCardPayload>;
  snoozeRemindersForTransaction?: Maybe<SnoozeRemindersForTransactionPayload>;
  submitDocument?: Maybe<SubmitDocumentPayload>;
  /** Submit a draft request item and initiate the approval process. */
  submitPurchaseRequestItem?: Maybe<SubmitPurchaseRequestItemPayload>;
  subscribeToNotifications?: Maybe<SubscribeToNotificationsPayload>;
  unarchiveRecord?: Maybe<UnarchiveRecordPayload>;
  undoCancelPurchaseRequestItem?: Maybe<UndoCancelPurchaseRequestItemPayload>;
  undoInvalidateDocument?: Maybe<UndoInvalidateDocumentPayload>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `goods_received_tracking`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  undoMarkRequestItemGoodsReceived?: Maybe<UndoMarkRequestItemGoodsReceivedPayload>;
  unsubscribeFromNotifications?: Maybe<UnsubscribeFromNotificationsPayload>;
  updateAccountProfile?: Maybe<UpdateAccountProfilePayload>;
  updateAccountSettings?: Maybe<UpdateAccountSettingsPayload>;
  updateApprovalDelegation?: Maybe<UpdateApprovalDelegationPayload>;
  updateApproverCustomField?: Maybe<UpdateApproverCustomFieldPayload>;
  updateAttachmentCustomField?: Maybe<UpdateAttachmentCustomFieldPayload>;
  updateCardSubscription?: Maybe<UpdateCardSubscriptionPayload>;
  updateCategory?: Maybe<UpdateCategoryPayload>;
  updateCategoryCustomField?: Maybe<UpdateCategoryCustomFieldPayload>;
  updateCategorySet?: Maybe<UpdateCategorySetPayload>;
  updateCheckboxCustomField?: Maybe<UpdateCheckboxCustomFieldPayload>;
  updateCostCentre?: Maybe<UpdateCostCentrePayload>;
  updateCostCentreCustomField?: Maybe<UpdateCostCentreCustomFieldPayload>;
  updateDataFilter?: Maybe<UpdateDataFilterPayload>;
  updateDataPath?: Maybe<UpdateDataPathPayload>;
  updateDataScopingRule?: Maybe<UpdateDataScopingRulePayload>;
  updateDataSource?: Maybe<UpdateDataSourcePayload>;
  updateDataSourceItem?: Maybe<UpdateDataSourceItemPayload>;
  updateDataValue?: Maybe<UpdateDataValuePayload>;
  updateDataView?: Maybe<UpdateDataViewPayload>;
  updateDataViewField?: Maybe<UpdateDataViewFieldPayload>;
  updateDateCustomField?: Maybe<UpdateDateCustomFieldPayload>;
  updateDocument?: Maybe<UpdateDocumentPayload>;
  updateFlowStepCoordinates?: Maybe<UpdateFlowStepCoordinatesPayload>;
  updateIntegerCustomField?: Maybe<UpdateIntegerCustomFieldPayload>;
  updateIssuerIntegration?: Maybe<UpdateIssuerIntegrationPayload>;
  updateIssuerIntegrationConsumer?: Maybe<UpdateIssuerIntegrationConsumerPayload>;
  updateIssuerIntegrationRule?: Maybe<UpdateIssuerIntegrationRulePayload>;
  updateLocation?: Maybe<UpdateLocationPayload>;
  updateMoneyCustomField?: Maybe<UpdateMoneyCustomFieldPayload>;
  updateOrganisationUnit?: Maybe<UpdateOrganisationUnitPayload>;
  updateOrganisationUnitCustomField?: Maybe<UpdateOrganisationUnitCustomFieldPayload>;
  /**
   * This mutation allows updating purchase request items.
   * Once an item has been **submitted**, only file attachments are allowed to be changed.
   * Other attributes cannot be updated
   */
  updatePurchaseRequestItem?: Maybe<UpdatePurchaseRequestItemPayload>;
  /**
   * Updates the parent of a record with a new parent or nullify it which means makes the record root.
   * The purpose of the mutation is to update the parent of a record without running custom field validations, or any
   * other validations that will block the update of the parent.
   */
  updateRecordParent?: Maybe<UpdateRecordParentPayload>;
  updateRecordPosition?: Maybe<UpdateRecordPositionPayload>;
  updateReferenceAssociation?: Maybe<UpdateReferenceAssociationPayload>;
  updateReportSchedule?: Maybe<UpdateReportSchedulePayload>;
  updateSchedule?: Maybe<UpdateSchedulePayload>;
  updateSelectCustomField?: Maybe<UpdateSelectCustomFieldPayload>;
  updateSpendLimit?: Maybe<UpdateSpendLimitPayload>;
  updateTextAreaCustomField?: Maybe<UpdateTextAreaCustomFieldPayload>;
  updateTextCustomField?: Maybe<UpdateTextCustomFieldPayload>;
  updateUrlCustomField?: Maybe<UpdateUrlCustomFieldPayload>;
  updateUserNotificationSetting?: Maybe<UpdateUserNotificationSettingPayload>;
  updateUserProfile?: Maybe<UpdateUserProfilePayload>;
  updateUserSettings?: Maybe<UpdateUserSettingsPayload>;
  updateWorkflow?: Maybe<UpdateWorkflowPayload>;
  updateWorkflowAction?: Maybe<UpdateWorkflowActionPayload>;
  updateWorkflowCondition?: Maybe<UpdateWorkflowConditionPayload>;
};

export type MutationAddRoleToUserArgs = {
  input: AddRoleToUserInput;
};

export type MutationApproveApprovalArgs = {
  input: ApproveApprovalInput;
};

export type MutationArchiveRecordArgs = {
  input: ArchiveRecordInput;
};

export type MutationAttachFileToCustomFieldValueArgs = {
  input: AttachFileToCustomFieldValueInput;
};

export type MutationCancelInvoicePaymentArgs = {
  input: CancelInvoicePaymentInput;
};

export type MutationCancelPurchaseRequestItemArgs = {
  input: CancelPurchaseRequestItemInput;
};

export type MutationChangeCardDeliveryPinArgs = {
  input: ChangeCardDeliveryPinInput;
};

export type MutationCloseCardSubscriptionArgs = {
  input: CloseCardSubscriptionInput;
};

export type MutationClosePurchaseOrderArgs = {
  input: ClosePurchaseOrderInput;
};

export type MutationConfirmEnable2faArgs = {
  input: ConfirmEnable2faInput;
};

export type MutationCreateApprovalDelegationArgs = {
  input: CreateApprovalDelegationInput;
};

export type MutationCreateApproverCustomFieldArgs = {
  input: CreateApproverCustomFieldInput;
};

export type MutationCreateAttachmentCustomFieldArgs = {
  input: CreateAttachmentCustomFieldInput;
};

export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationCreateCategoryCustomFieldArgs = {
  input: CreateCategoryCustomFieldInput;
};

export type MutationCreateCategorySetArgs = {
  input: CreateCategorySetInput;
};

export type MutationCreateCheckboxCustomFieldArgs = {
  input: CreateCheckboxCustomFieldInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationCreateCostCentreArgs = {
  input: CreateCostCentreInput;
};

export type MutationCreateCostCentreCustomFieldArgs = {
  input: CreateCostCentreCustomFieldInput;
};

export type MutationCreateDataFilterArgs = {
  input: CreateDataFilterInput;
};

export type MutationCreateDataPathArgs = {
  input: CreateDataPathInput;
};

export type MutationCreateDataScopingRuleArgs = {
  input: CreateDataScopingRuleInput;
};

export type MutationCreateDataSourceArgs = {
  input: CreateDataSourceInput;
};

export type MutationCreateDataSourceItemArgs = {
  input: CreateDataSourceItemInput;
};

export type MutationCreateDataValueArgs = {
  input: CreateDataValueInput;
};

export type MutationCreateDataViewArgs = {
  input: CreateDataViewInput;
};

export type MutationCreateDataViewFieldArgs = {
  input: CreateDataViewFieldInput;
};

export type MutationCreateDateCustomFieldArgs = {
  input: CreateDateCustomFieldInput;
};

export type MutationCreateDocumentTransactionArgs = {
  input: CreateDocumentTransactionInput;
};

export type MutationCreateDraftDocumentArgs = {
  input: CreateDraftDocumentInput;
};

export type MutationCreateIntegerCustomFieldArgs = {
  input: CreateIntegerCustomFieldInput;
};

export type MutationCreateInvoicePaymentArgs = {
  input: CreateInvoicePaymentInput;
};

export type MutationCreateIssuerIntegrationConsumerArgs = {
  input: CreateIssuerIntegrationConsumerInput;
};

export type MutationCreateIssuerIntegrationRuleArgs = {
  input: CreateIssuerIntegrationRuleInput;
};

export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateMoneyCustomFieldArgs = {
  input: CreateMoneyCustomFieldInput;
};

export type MutationCreateOrganisationUnitArgs = {
  input: CreateOrganisationUnitInput;
};

export type MutationCreateOrganisationUnitCustomFieldArgs = {
  input: CreateOrganisationUnitCustomFieldInput;
};

export type MutationCreatePurchaseRequestItemArgs = {
  input: CreatePurchaseRequestItemInput;
};

export type MutationCreateReferenceAssociationArgs = {
  input: CreateReferenceAssociationInput;
};

export type MutationCreateReportArgs = {
  input: CreateReportInput;
};

export type MutationCreateReportScheduleArgs = {
  input: CreateReportScheduleInput;
};

export type MutationCreateSelectCustomFieldArgs = {
  input: CreateSelectCustomFieldInput;
};

export type MutationCreateSpendLimitArgs = {
  input: CreateSpendLimitInput;
};

export type MutationCreateSubscriptionRenewalRequestArgs = {
  input: CreateSubscriptionRenewalRequestInput;
};

export type MutationCreateSupplierRequestArgs = {
  input: CreateSupplierRequestInput;
};

export type MutationCreateTextAreaCustomFieldArgs = {
  input: CreateTextAreaCustomFieldInput;
};

export type MutationCreateTextCustomFieldArgs = {
  input: CreateTextCustomFieldInput;
};

export type MutationCreateUrlCustomFieldArgs = {
  input: CreateUrlCustomFieldInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput;
};

export type MutationCreateWorkflowActionArgs = {
  input: CreateWorkflowActionInput;
};

export type MutationCreateWorkflowConditionArgs = {
  input: CreateWorkflowConditionInput;
};

export type MutationDeleteDocumentTransactionArgs = {
  input: DeleteDocumentTransactionInput;
};

export type MutationDeleteRecordArgs = {
  input: DeleteRecordInput;
};

export type MutationDeliverVirtualCardInAppArgs = {
  input: DeliverVirtualCardInAppInput;
};

export type MutationDetachFileFromCustomFieldValueArgs = {
  input: DetachFileFromCustomFieldValueInput;
};

export type MutationDisable2faArgs = {
  input: Disable2faInput;
};

export type MutationDisableWorkflowArgs = {
  input: DisableWorkflowInput;
};

export type MutationDownloadAttachmentArgs = {
  input: DownloadAttachmentInput;
};

export type MutationDownloadPurchaseOrderDocumentsArgs = {
  input: DownloadPurchaseOrderDocumentsInput;
};

export type MutationDuplicateDataViewArgs = {
  input: DuplicateDataViewInput;
};

export type MutationEnable2faArgs = {
  input: Enable2faInput;
};

export type MutationEnableSupplierArgs = {
  input: EnableSupplierInput;
};

export type MutationEnableWorkflowArgs = {
  input: EnableWorkflowInput;
};

export type MutationGeneratePdfFromIncomingEmailArgs = {
  input: GeneratePdfFromIncomingEmailInput;
};

export type MutationGetImportFileMetadataArgs = {
  input: GetImportFileMetadataInput;
};

export type MutationImportCategoriesArgs = {
  input: ImportCategoriesInput;
};

export type MutationImportCostCentresArgs = {
  input: ImportCostCentresInput;
};

export type MutationImportDataSourceItemsArgs = {
  input: ImportDataSourceItemsInput;
};

export type MutationImportUsersArgs = {
  input: ImportUsersInput;
};

export type MutationInvalidateDocumentArgs = {
  input: InvalidateDocumentInput;
};

export type MutationInviteAllDraftUsersArgs = {
  input: InviteAllDraftUsersInput;
};

export type MutationInviteDraftUsersArgs = {
  input: InviteDraftUsersInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationLookupCompanyArgs = {
  input: LookupCompanyInput;
};

export type MutationMarkAllNotificationsAsReadArgs = {
  input: MarkAllNotificationsAsReadInput;
};

export type MutationMarkNotificationAsReadArgs = {
  input: MarkNotificationAsReadInput;
};

export type MutationMarkNotificationAsUnreadArgs = {
  input: MarkNotificationAsUnreadInput;
};

export type MutationMarkRequestItemGoodsReceivedArgs = {
  input: MarkRequestItemGoodsReceivedInput;
};

export type MutationMarkTransactionAsReceiptMissingArgs = {
  input: MarkTransactionAsReceiptMissingInput;
};

export type MutationMarkTransactionAsReceiptUnavailableArgs = {
  input: MarkTransactionAsReceiptUnavailableInput;
};

export type MutationNewInputsForChangesetsArgs = {
  input: NewInputsForChangesetsInput;
};

export type MutationProcessOnboardingCaseArgs = {
  input: ProcessOnboardingCaseInput;
};

export type MutationPublishWorkflowArgs = {
  input: PublishWorkflowInput;
};

export type MutationRefreshOnboardingContactArgs = {
  input: RefreshOnboardingContactInput;
};

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};

export type MutationRejectApprovalArgs = {
  input: RejectApprovalInput;
};

export type MutationRemoveRoleFromUserArgs = {
  input: RemoveRoleFromUserInput;
};

export type MutationRemoveSupplierArgs = {
  input: RemoveSupplierInput;
};

export type MutationReopenCardSubscriptionArgs = {
  input: ReopenCardSubscriptionInput;
};

export type MutationRevokeVirtualCardArgs = {
  input: RevokeVirtualCardInput;
};

export type MutationSnoozeRemindersForTransactionArgs = {
  input: SnoozeRemindersForTransactionInput;
};

export type MutationSubmitDocumentArgs = {
  input: SubmitDocumentInput;
};

export type MutationSubmitPurchaseRequestItemArgs = {
  input: SubmitPurchaseRequestItemInput;
};

export type MutationSubscribeToNotificationsArgs = {
  input: SubscribeToNotificationsInput;
};

export type MutationUnarchiveRecordArgs = {
  input: UnarchiveRecordInput;
};

export type MutationUndoCancelPurchaseRequestItemArgs = {
  input: UndoCancelPurchaseRequestItemInput;
};

export type MutationUndoInvalidateDocumentArgs = {
  input: UndoInvalidateDocumentInput;
};

export type MutationUndoMarkRequestItemGoodsReceivedArgs = {
  input: UndoMarkRequestItemGoodsReceivedInput;
};

export type MutationUnsubscribeFromNotificationsArgs = {
  input: UnsubscribeFromNotificationsInput;
};

export type MutationUpdateAccountProfileArgs = {
  input: UpdateAccountProfileInput;
};

export type MutationUpdateAccountSettingsArgs = {
  input: UpdateAccountSettingsInput;
};

export type MutationUpdateApprovalDelegationArgs = {
  input: UpdateApprovalDelegationInput;
};

export type MutationUpdateApproverCustomFieldArgs = {
  input: UpdateApproverCustomFieldInput;
};

export type MutationUpdateAttachmentCustomFieldArgs = {
  input: UpdateAttachmentCustomFieldInput;
};

export type MutationUpdateCardSubscriptionArgs = {
  input: UpdateCardSubscriptionInput;
};

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type MutationUpdateCategoryCustomFieldArgs = {
  input: UpdateCategoryCustomFieldInput;
};

export type MutationUpdateCategorySetArgs = {
  input: UpdateCategorySetInput;
};

export type MutationUpdateCheckboxCustomFieldArgs = {
  input: UpdateCheckboxCustomFieldInput;
};

export type MutationUpdateCostCentreArgs = {
  input: UpdateCostCentreInput;
};

export type MutationUpdateCostCentreCustomFieldArgs = {
  input: UpdateCostCentreCustomFieldInput;
};

export type MutationUpdateDataFilterArgs = {
  input: UpdateDataFilterInput;
};

export type MutationUpdateDataPathArgs = {
  input: UpdateDataPathInput;
};

export type MutationUpdateDataScopingRuleArgs = {
  input: UpdateDataScopingRuleInput;
};

export type MutationUpdateDataSourceArgs = {
  input: UpdateDataSourceInput;
};

export type MutationUpdateDataSourceItemArgs = {
  input: UpdateDataSourceItemInput;
};

export type MutationUpdateDataValueArgs = {
  input: UpdateDataValueInput;
};

export type MutationUpdateDataViewArgs = {
  input: UpdateDataViewInput;
};

export type MutationUpdateDataViewFieldArgs = {
  input: UpdateDataViewFieldInput;
};

export type MutationUpdateDateCustomFieldArgs = {
  input: UpdateDateCustomFieldInput;
};

export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};

export type MutationUpdateFlowStepCoordinatesArgs = {
  input: UpdateFlowStepCoordinatesInput;
};

export type MutationUpdateIntegerCustomFieldArgs = {
  input: UpdateIntegerCustomFieldInput;
};

export type MutationUpdateIssuerIntegrationArgs = {
  input: UpdateIssuerIntegrationInput;
};

export type MutationUpdateIssuerIntegrationConsumerArgs = {
  input: UpdateIssuerIntegrationConsumerInput;
};

export type MutationUpdateIssuerIntegrationRuleArgs = {
  input: UpdateIssuerIntegrationRuleInput;
};

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};

export type MutationUpdateMoneyCustomFieldArgs = {
  input: UpdateMoneyCustomFieldInput;
};

export type MutationUpdateOrganisationUnitArgs = {
  input: UpdateOrganisationUnitInput;
};

export type MutationUpdateOrganisationUnitCustomFieldArgs = {
  input: UpdateOrganisationUnitCustomFieldInput;
};

export type MutationUpdatePurchaseRequestItemArgs = {
  input: UpdatePurchaseRequestItemInput;
};

export type MutationUpdateRecordParentArgs = {
  input: UpdateRecordParentInput;
};

export type MutationUpdateRecordPositionArgs = {
  input: UpdateRecordPositionInput;
};

export type MutationUpdateReferenceAssociationArgs = {
  input: UpdateReferenceAssociationInput;
};

export type MutationUpdateReportScheduleArgs = {
  input: UpdateReportScheduleInput;
};

export type MutationUpdateScheduleArgs = {
  input: UpdateScheduleInput;
};

export type MutationUpdateSelectCustomFieldArgs = {
  input: UpdateSelectCustomFieldInput;
};

export type MutationUpdateSpendLimitArgs = {
  input: UpdateSpendLimitInput;
};

export type MutationUpdateTextAreaCustomFieldArgs = {
  input: UpdateTextAreaCustomFieldInput;
};

export type MutationUpdateTextCustomFieldArgs = {
  input: UpdateTextCustomFieldInput;
};

export type MutationUpdateUrlCustomFieldArgs = {
  input: UpdateUrlCustomFieldInput;
};

export type MutationUpdateUserNotificationSettingArgs = {
  input: UpdateUserNotificationSettingInput;
};

export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};

export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowInput;
};

export type MutationUpdateWorkflowActionArgs = {
  input: UpdateWorkflowActionInput;
};

export type MutationUpdateWorkflowConditionArgs = {
  input: UpdateWorkflowConditionInput;
};

export enum MutationStatus {
  /** Operation failed. See `errors` object. */
  Error = 'ERROR',
  /** No-Operation. Nothing was done. */
  Noop = 'NOOP',
  /** Operation completed successfully. */
  Ok = 'OK',
}

/** Autogenerated input type of NewInputsForChangesets */
export type NewInputsForChangesetsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldValueCreate: CreateCustomFieldValueInputAttributes;
  customFieldValueUpdate: UpdatableCustomFieldValueInputAttributes;
};

/** Autogenerated return type of NewInputsForChangesets. */
export type NewInputsForChangesetsPayload = {
  __typename?: 'NewInputsForChangesetsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** An object with an GlobalID. */
export type Node = {
  /** GlobalID of the object. */
  id: Scalars['GlobalID']['output'];
};

export type Notification = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Notification';
    activity: Activity;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    isRead: Scalars['Boolean']['output'];
    readAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: NotificationRecordViewerPermissions;
  };

export type NotificationRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for Notification. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  totalUnreadCount: Scalars['Int']['output'];
};

/** Autogenerated return type of NotificationCreated. */
export type NotificationCreatedPayload = {
  __typename?: 'NotificationCreatedPayload';
  notification: Notification;
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Notification>;
};

export type NotificationRecordViewerPermissions = {
  __typename?: 'NotificationRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  markAsRead: Scalars['Boolean']['output'];
  markAsUnread: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  activityKey: Scalars['String']['output'];
  email: Scalars['Boolean']['output'];
  /** When grouping settings, use this as a grouping key and user-facing label. */
  groupLabel: Scalars['String']['output'];
  /** Optional text to explain the role of this setting in the system. */
  helpText?: Maybe<Scalars['String']['output']>;
  /** Label for this setting to use in the UI */
  label: Scalars['String']['output'];
  mobile: Scalars['Boolean']['output'];
  web: Scalars['Boolean']['output'];
};

export enum NotificationTarget {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
  Web = 'WEB',
}

/**
 * When filtering on boolean values and being explicit about your choice, a third option
 * or "any" is desired to completely omit boolean filtering. This is more clear than passing
 * a `null` to a field that otherwise seems to be a boolean.
 */
export enum OptionalBoolean {
  Any = 'ANY',
  No = 'NO',
  Yes = 'YES',
}

export type OrganisationUnit = Archivable &
  Auditable &
  CustomFieldValuesParent &
  GlobalIdField &
  HasActivities &
  HasAncestry &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'OrganisationUnit';
    activities?: Maybe<ActivityConnection>;
    ancestors?: Maybe<Array<OrganisationUnit>>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    /**
     * Returns the count of users that the OU has. It **only** counts the users that **directly** assigned to the OU.
     * Archived users not included to the result.
     */
    childUsersCount: Scalars['Int']['output'];
    childrenCount: Scalars['Int']['output'];
    classification: OrganisationUnitClassification;
    code: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    /**
     * Returns the count of users that the record and its descendants have. It counts both the users assigned to
     * the OU and its descendants. Archived users not included to the result.
     */
    descendantUsersCount: Scalars['Int']['output'];
    description?: Maybe<Scalars['String']['output']>;
    directLocation?: Maybe<Location>;
    directVatNumber?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    inheritedLocation?: Maybe<Location>;
    inheritedVatNumber: Scalars['String']['output'];
    label: Scalars['String']['output'];
    legalEntity: OrganisationUnit;
    /** Returns the direct location if present, otherwise returns the inherited location from the parent. */
    location: Location;
    parentId?: Maybe<Scalars['GlobalID']['output']>;
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    relationExists: Scalars['Boolean']['output'];
    spendLimits: SpendLimitConnection;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Returns the direct VAT number if present, otherwise returns the inherited VAT number from the parent. */
    vatNumber: Scalars['String']['output'];
    viewerPermissions: OrganisationUnitRecordViewerPermissions;
  };

export type OrganisationUnitActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type OrganisationUnitAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type OrganisationUnitChildrenCountArgs = {
  archived?: InputMaybe<OptionalBoolean>;
};

export type OrganisationUnitCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganisationUnitReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganisationUnitRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type OrganisationUnitSpendLimitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum OrganisationUnitClassification {
  BusinessUnit = 'BUSINESS_UNIT',
  Department = 'DEPARTMENT',
  Division = 'DIVISION',
  LegalEntity = 'LEGAL_ENTITY',
}

export type OrganisationUnitCollectionViewerPermissions = {
  __typename?: 'OrganisationUnitCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for OrganisationUnit. */
export type OrganisationUnitConnection = {
  __typename?: 'OrganisationUnitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganisationUnitEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrganisationUnit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: OrganisationUnitCollectionViewerPermissions;
};

export type OrganisationUnitCustomField = Archivable &
  Auditable &
  CustomField &
  DataScopingRules &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'OrganisationUnitCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    dataScopingRules: DataScopingRuleConnection;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    options: OrganisationUnitConnection;
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type OrganisationUnitCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type OrganisationUnitCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type OrganisationUnitCustomFieldDataScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganisationUnitCustomFieldOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scopeToFormableId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationUnitCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** An edge in a connection. */
export type OrganisationUnitEdge = {
  __typename?: 'OrganisationUnitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganisationUnit>;
};

export type OrganisationUnitRecordViewerPermissions = {
  __typename?: 'OrganisationUnitRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  createChild?: Maybe<Scalars['Boolean']['output']>;
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
  updateParent: Scalars['Boolean']['output'];
};

export type OrganisationUnitSortInput = {
  code?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentAccountSettingsInput = {
  baseCurrencyCode?: InputMaybe<CurrencyCode>;
  virtualCardPayments?: InputMaybe<VirtualCardPaymentsPaymentAccountSettingsInput>;
};

export type PaymentSettings = {
  __typename?: 'PaymentSettings';
  baseCurrency?: Maybe<Currency>;
  virtualCardPayments: VirtualCardPaymentSettings;
};

/** Autogenerated input type of ProcessOnboardingCase */
export type ProcessOnboardingCaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  supplierOnboardingCaseId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of ProcessOnboardingCase. */
export type ProcessOnboardingCasePayload = {
  __typename?: 'ProcessOnboardingCasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  supplierOnboardingCase?: Maybe<SupplierOnboardingCase>;
};

/**
 * Similar to `Address` but represented by a different model in the database.
 * The reason for this is that public addresses are global and not specific to an account.
 * This means they have a different security model.
 */
export type PublicAddress = GlobalIdField &
  Node &
  RelationExists & {
    __typename?: 'PublicAddress';
    city: Scalars['String']['output'];
    country: Country;
    formattedLines: Array<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    latitude?: Maybe<Scalars['Float']['output']>;
    longitude?: Maybe<Scalars['Float']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    regionName?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    streetAddress: Scalars['String']['output'];
  };

/**
 * Similar to `Address` but represented by a different model in the database.
 * The reason for this is that public addresses are global and not specific to an account.
 * This means they have a different security model.
 */
export type PublicAddressRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** Autogenerated input type of PublishWorkflow */
export type PublishWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  publishNotes?: InputMaybe<Scalars['String']['input']>;
  workflowId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of PublishWorkflow. */
export type PublishWorkflowPayload = {
  __typename?: 'PublishWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  workflow?: Maybe<Workflow>;
};

export type PurchaseOrder = Auditable &
  Commentable &
  Documentable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'PurchaseOrder';
    activities?: Maybe<ActivityConnection>;
    approverAmounts?: Maybe<Array<ApproverSummary>>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    cardSubscription?: Maybe<CardSubscription>;
    cardTtlDays?: Maybe<Scalars['Int']['output']>;
    comments: CommentConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    currencyConversionRate?: Maybe<Scalars['Float']['output']>;
    documents?: Maybe<DocumentConnection>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    poNumber: Scalars['String']['output'];
    purchaseAmount: Money;
    purchaseRequestItem: PurchaseRequestItem;
    purchaseType: PurchaseType;
    reconciliationStatus: PurchaseOrderReconciliationStatus;
    relationExists: Scalars['Boolean']['output'];
    requestedBy?: Maybe<User>;
    status: PurchaseOrderStatus;
    supportedDocumentTypes: Array<DocumentClassification>;
    totalSpent: Money;
    transactions?: Maybe<Array<Transaction>>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: PurchaseOrderRecordViewerPermissions;
    virtualCard?: Maybe<VirtualCard>;
  };

export type PurchaseOrderActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type PurchaseOrderAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type PurchaseOrderCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type PurchaseOrderDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type PurchaseOrderRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type PurchaseOrderCollectionViewerPermissions = {
  __typename?: 'PurchaseOrderCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for PurchaseOrder. */
export type PurchaseOrderConnection = {
  __typename?: 'PurchaseOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: PurchaseOrderCollectionViewerPermissions;
};

/** An edge in a connection. */
export type PurchaseOrderEdge = {
  __typename?: 'PurchaseOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseOrder>;
};

export enum PurchaseOrderReconciliationStatus {
  /** Purchase order will be **no_transactions** if there are no transactions */
  NoTransactions = 'NO_TRANSACTIONS',
  /** Purchase order will be **receipt_missing** if there are transactions without a receipt or an invoice. */
  ReceiptMissing = 'RECEIPT_MISSING',
  /** Purchase order will be **reconciled** if all transactions have a receipt or an invoice. */
  Reconciled = 'RECONCILED',
}

export type PurchaseOrderRecordViewerPermissions = {
  __typename?: 'PurchaseOrderRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  close: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  subscribeToNotifications: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type PurchaseOrderSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  purchaseAmount?: InputMaybe<SortDirection>;
  purchaseType?: InputMaybe<SortDirection>;
  reconciliationStatus?: InputMaybe<SortDirection>;
  /** Requestor's full name i.e. {first name} {last name} */
  requestedByName?: InputMaybe<SortDirection>;
  supplierName?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum PurchaseOrderStatus {
  Closed = 'CLOSED',
  ErrorIssuingCard = 'ERROR_ISSUING_CARD',
  PaidWithVirtualCard = 'PAID_WITH_VIRTUAL_CARD',
  ProcessingPayment = 'PROCESSING_PAYMENT',
  VirtualCardIssued = 'VIRTUAL_CARD_ISSUED',
}

export type PurchaseRequestItem = Approvable &
  Auditable &
  Commentable &
  CustomFieldValuesParent &
  Draftable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps &
  WorkflowRecord & {
    __typename?: 'PurchaseRequestItem';
    activities?: Maybe<ActivityConnection>;
    approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
    approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    baseCurrency: Currency;
    cancelComment?: Maybe<Scalars['String']['output']>;
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    cancelledBy?: Maybe<User>;
    cardSubscription?: Maybe<CardSubscription>;
    comments: CommentConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    description?: Maybe<Scalars['String']['output']>;
    goodsReceivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    goodsReceivedBy?: Maybe<User>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /**
     * Whether or not the record is draft.
     * Draft records are usually omitted from query results unless specifically included.
     */
    isDraft: Scalars['Boolean']['output'];
    parsedId?: Maybe<IParsedGlobalId>;
    purchaseMadeAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    purchaseOrder?: Maybe<PurchaseOrder>;
    purchaseType: PurchaseType;
    rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    relationExists: Scalars['Boolean']['output'];
    requestedAmount?: Maybe<Money>;
    requestedAmountCurrency?: Maybe<Currency>;
    requestedBaseAmount?: Maybe<Money>;
    requestedBy: User;
    requestedCardTtlDays?: Maybe<Scalars['Int']['output']>;
    status: PurchaseRequestItemStatus;
    statusLastChangedAt: Scalars['ISO8601DateTime']['output'];
    submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    supplier?: Maybe<SupplierAccount>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: PurchaseRequestItemRecordViewerPermissions;
    workflowRuns?: Maybe<WorkflowRunConnection>;
  };

export type PurchaseRequestItemActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type PurchaseRequestItemAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type PurchaseRequestItemCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type PurchaseRequestItemCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PurchaseRequestItemRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type PurchaseRequestItemWorkflowRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for PurchaseRequestItem. */
export type PurchaseRequestItemConnection = {
  __typename?: 'PurchaseRequestItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseRequestItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseRequestItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PurchaseRequestItemEdge = {
  __typename?: 'PurchaseRequestItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseRequestItem>;
};

export type PurchaseRequestItemRecordViewerPermissions = {
  __typename?: 'PurchaseRequestItemRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  approve: Scalars['Boolean']['output'];
  cancel: Scalars['Boolean']['output'];
  closePurchaseOrder?: Maybe<Scalars['Boolean']['output']>;
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  markGoodsReceived: Scalars['Boolean']['output'];
  reject: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  subscribeToNotifications: Scalars['Boolean']['output'];
  undoCancel: Scalars['Boolean']['output'];
  undoMarkGoodsReceived: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
};

export enum PurchaseRequestItemStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  PendingApproval = 'PENDING_APPROVAL',
  PurchaseMade = 'PURCHASE_MADE',
  Rejected = 'REJECTED',
}

export enum PurchaseType {
  Invoice = 'INVOICE',
  Subscription = 'SUBSCRIPTION',
  Webshop = 'WEBSHOP',
}

export type Query = {
  __typename?: 'Query';
  /**
   * Recent activities the current user is allowed to see.
   *
   * For unprivileged users, that will only be activities that they have created.
   * For account managers, all activities in their account.
   */
  activities?: Maybe<ActivityConnection>;
  approvalDelegations?: Maybe<ApprovalDelegationConnection>;
  approvals?: Maybe<ApprovalConnection>;
  availableOptions: AvailableOptions;
  bankAccounts?: Maybe<BankAccountConnection>;
  cardAuthorisations?: Maybe<CardAuthorisationConnection>;
  cardSubscriptions?: Maybe<CardSubscriptionConnection>;
  /**
   * Fetch all or one level of the categories tree.
   *
   * When no `parentId` is given, returns the all categories.
   * When `null` is given for `parentId`, returns all root categories.
   * When a `parentId` is given, its direct children are returned.
   */
  categories: CategoryConnection;
  categorySets: CategorySetConnection;
  comparators: Array<FlowStepConditionComparatorName>;
  costCentres?: Maybe<CostCentreConnection>;
  /** List all ISO-3166 countries, ordered by name in the viewer's language. */
  countries: Array<Country>;
  /** List of supported currencies */
  currencies: Array<Currency>;
  customFields?: Maybe<CustomFieldConnection>;
  dataSources: DataSourceConnection;
  dataViews?: Maybe<DataViewConnection>;
  /** Returns data views meta data sorted by resource */
  dataViewsMetadata?: Maybe<MetaModelConnection>;
  documents?: Maybe<DocumentConnection>;
  featureToggles?: Maybe<FeatureToggles>;
  inboxes?: Maybe<InboxConnection>;
  incomingEmails?: Maybe<IncomingEmailConnection>;
  /**
   * Fetch all or one level of the industry codes hierarchy.
   *
   * When no `parentId` is given, returns the all industry codes.
   * When `null` is given for `parentId``, returns only the root industry codes.
   * When a `parentId` is given, its direct children are returned.
   */
  industryCodes?: Maybe<Array<IndustryCode>>;
  invoicePayments?: Maybe<InvoicePaymentConnection>;
  issuerIntegrationConsumers?: Maybe<IssuerIntegrationConsumerConnection>;
  issuerIntegrationRules?: Maybe<IssuerIntegrationRuleConnection>;
  issuerIntegrations?: Maybe<IssuerIntegrationConnection>;
  locations?: Maybe<LocationConnection>;
  /** My user profile */
  me: Me;
  merchantCategoryCodes: MerchantCategoryCodeConnection;
  merchants: MerchantConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /**
   * Fetch all or one level of the organisation unit hierarchy.
   *
   * When no `parentId` is given, returns the all OUs.
   * When `null` is given for `parentId``, returns only the root OU.
   * When a `parentId` is given, its direct children are returned.
   */
  organisationUnits: OrganisationUnitConnection;
  /** Fetch all purchase orders. */
  purchaseOrders?: Maybe<PurchaseOrderConnection>;
  purchaseRequestItems?: Maybe<PurchaseRequestItemConnection>;
  reportSchedules?: Maybe<ReportScheduleConnection>;
  reports?: Maybe<ReportConnection>;
  spendLimits?: Maybe<SpendLimitConnection>;
  supplierPayouts?: Maybe<SupplierPayoutConnection>;
  supplierRequests?: Maybe<SupplierRequestConnection>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `invoice_management`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  suppliers?: Maybe<SupplierAccountConnection>;
  timezones: Array<Timezone>;
  transactions?: Maybe<TransactionConnection>;
  /**
   * Fetch all users
   *
   * When no `sort` is given, returns data in first_name ASC and last_name ASC order.
   * When a `sort` is given, returns data in given order.
   */
  users: UserConnection;
  virtualCards?: Maybe<VirtualCardConnection>;
  workflowRuns?: Maybe<WorkflowRunConnection>;
  workflows?: Maybe<WorkflowConnection>;
};

export type QueryActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
  trackableId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type QueryApprovalDelegationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  delegateFromIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  delegateToIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  endsBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ApprovalDelegationSortInput>>;
  startsAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  statuses?: InputMaybe<Array<ApprovalDelegationStatus>>;
};

export type QueryApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvableIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  approvableTypes?: InputMaybe<Array<ApprovalApprovableType>>;
  approvalDelegationIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  assigneeIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  delegatedFromIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  delegatedToIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDelegated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ApprovalSortInput>>;
  statuses?: InputMaybe<Array<ApprovalStatus>>;
};

export type QueryBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCardAuthorisationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amountCentsGreaterThan?: InputMaybe<Scalars['Int']['input']>;
  amountCentsLessThan?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  dateAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  dateBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
  declined?: InputMaybe<OptionalBoolean>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CardAuthorisationSortInput>>;
  virtualCardIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type QueryCardSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  anyCardStatus?: InputMaybe<Array<CardSubscriptionCardStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  closed?: InputMaybe<OptionalBoolean>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CardSubscriptionSortInput>>;
  withSimilarSubscriptions?: InputMaybe<OptionalBoolean>;
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  categorySetId?: InputMaybe<Scalars['GlobalID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CategorySortInput>>;
};

export type QueryCategorySetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategorySetSortInput>>;
};

export type QueryComparatorsArgs = {
  dataType?: InputMaybe<FlowStepConditionInputAttributeDataType>;
};

export type QueryCostCentresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CostCentreSortInput>>;
};

export type QueryCustomFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  formableClasses?: InputMaybe<Array<CustomFieldFormableClass>>;
  formableParentIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  required?: InputMaybe<OptionalBoolean>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CustomFieldSortInput>>;
  types?: InputMaybe<Array<CustomFieldType>>;
};

export type QueryDataSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DataSourceSortInput>>;
};

export type QueryDataViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  resourceTypes?: InputMaybe<Array<DataViewResourceType>>;
  sort?: InputMaybe<Array<DataViewSortInput>>;
};

export type QueryDataViewsMetadataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  documentTypes?: InputMaybe<Array<DocumentClassification>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasEmailAttachment?: InputMaybe<OptionalBoolean>;
  inboxIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  isDraft?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  paymentDateAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  paymentDateBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DocumentSortInput>>;
  transactionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  withTransactions?: InputMaybe<OptionalBoolean>;
};

export type QueryInboxesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  recordIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIncomingEmailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inboxIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  processed?: InputMaybe<OptionalBoolean>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<IncomingEmailSortInput>>;
  withAttachments?: InputMaybe<OptionalBoolean>;
};

export type QueryIndustryCodesArgs = {
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<IndustryCodeSortInput>>;
  system?: InputMaybe<IndustryCodeSystem>;
};

export type QueryInvoicePaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  issuerIntegrationIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InvoicePaymentSortInput>>;
  statuses?: InputMaybe<Array<InvoicePaymentStatus>>;
  supplierBankAccountIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type QueryIssuerIntegrationConsumersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardUseInvoiceFallback?: InputMaybe<OptionalBoolean>;
  cardUseMultiFallback?: InputMaybe<OptionalBoolean>;
  cardUseSingleFallback?: InputMaybe<OptionalBoolean>;
  consumerRelation?: InputMaybe<IssuerIntegrationConsumerConsumerRelation>;
  consumerTypes?: InputMaybe<Array<IssuerIntegrationConsumerConsumerType>>;
  costCentreIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  issuerIntegrationIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  sort?: InputMaybe<Array<IssuerIntegrationConsumerSortInput>>;
  userIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type QueryIssuerIntegrationRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardUseTypes?: InputMaybe<Array<IssuerIntegrationRuleCardUseType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IssuerIntegrationRuleSortInput>>;
};

export type QueryIssuerIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  anyCurrencyCode?: InputMaybe<Array<CurrencyCode>>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardUseInvoice?: InputMaybe<OptionalBoolean>;
  cardUseMulti?: InputMaybe<OptionalBoolean>;
  cardUseSingle?: InputMaybe<OptionalBoolean>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<IssuerIntegrationServiceClass>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<IssuerIntegrationSortInput>>;
};

export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<LocationSortInput>>;
};

export type QueryMerchantCategoryCodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MerchantCategoryCodeSortInput>>;
};

export type QueryMerchantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  categoryCodes?: InputMaybe<Array<Scalars['MerchantCategoryCodeCode']['input']>>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MerchantSortInput>>;
  transactionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type QueryNodeArgs = {
  id: Scalars['GlobalID']['input'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['GlobalID']['input']>;
};

export type QueryOrganisationUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['GlobalID']['input']>;
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<OrganisationUnitSortInput>>;
};

export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  purchaseType?: InputMaybe<Array<PurchaseType>>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus>>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<PurchaseOrderSortInput>>;
  supplierId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type QueryPurchaseRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  anyApprovalAssignee?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  anyApprovalStatus?: InputMaybe<Array<ApprovalStatus>>;
  anyPurchaseTypes?: InputMaybe<Array<PurchaseType>>;
  anyStatus?: InputMaybe<Array<PurchaseRequestItemStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  isRequestedByMe?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus>>;
  requestedBaseAmountCentsGreaterThan?: InputMaybe<Scalars['Int']['input']>;
  requestedBaseAmountCentsLessThan?: InputMaybe<Scalars['Int']['input']>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<RequestItemSortInput>>;
  withPurchaseOrders?: InputMaybe<OptionalBoolean>;
};

export type QueryReportSchedulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ReportScheduleSortInput>>;
};

export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  dataViewIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ReportSortInput>>;
};

export type QuerySpendLimitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<SpendLimitScope>;
  sort?: InputMaybe<Array<SpendLimitSortInput>>;
  spendLimitable?: InputMaybe<SpendLimitableType>;
};

export type QuerySupplierPayoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  bankAccountIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierPayoutSortInput>>;
};

export type QuerySupplierRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvalStatuses?: InputMaybe<Array<ApprovableGraphqlInterfaceApprovalStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  sort?: InputMaybe<Array<SupplierRequestSortInput>>;
};

export type QuerySuppliersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  enableStatuses?: InputMaybe<Array<SupplierAccountEnableStatus>>;
  enabledByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  industryCodeIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onboardingStatuses?: InputMaybe<Array<SupplierOnboardingCaseOnboardingStatus>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierAccountSortInput>>;
};

export type QueryTimezonesArgs = {
  region?: InputMaybe<TimezoneRegion>;
  sort?: InputMaybe<Array<SortingTimezoneAttribute>>;
};

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  documentId?: InputMaybe<Scalars['GlobalID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  merchantCategoryCodes?: InputMaybe<Array<Scalars['MerchantCategoryCodeCode']['input']>>;
  merchantCountryCodes?: InputMaybe<Array<CountryCode>>;
  merchantIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  purchaseOrderId?: InputMaybe<Scalars['GlobalID']['input']>;
  reconciliationStatus?: InputMaybe<Array<TransactionReconciliationStatus>>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<TransactionSortInput>>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  supplierId?: InputMaybe<Scalars['GlobalID']['input']>;
  types?: InputMaybe<Array<TransactionType>>;
  virtualCardId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  anyStatus?: InputMaybe<Array<UserStatus>>;
  awaitingInvitations?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  permissions?: InputMaybe<Array<UserSecurityRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<UserSortInput>>;
  systemUsers?: InputMaybe<OptionalBoolean>;
};

export type QueryVirtualCardsArgs = {
  aboutToExpire?: InputMaybe<OptionalBoolean>;
  after?: InputMaybe<Scalars['String']['input']>;
  anyCurrencyCodes?: InputMaybe<Array<CurrencyCode>>;
  anyDeliveryMethod?: InputMaybe<Array<VirtualCardDeliveryMethod>>;
  anyVirtualCardStatus?: InputMaybe<Array<VirtualCardStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasTransactions?: InputMaybe<OptionalBoolean>;
  issuedToIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lowAmount?: InputMaybe<OptionalBoolean>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<VirtualCardSortInput>>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type QueryWorkflowRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  anyStatus?: InputMaybe<Array<WorkflowRunStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  recordIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  sort?: InputMaybe<Array<WorkflowRunSortInput>>;
  workflowIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
};

export type QueryWorkflowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<OptionalBoolean>;
  isPublished?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<WorkflowSortInput>>;
  statuses?: InputMaybe<Array<WorkflowStatus>>;
};

/**
 * These optional values represent the range within which the
 * results should be displayed in the report.
 *
 * Example: If report will contain transactions, we can use 'date_of_transaction'
 * as criterion, START_OF_LAST_WEEK as start of the range and
 * END_OF_LAST_WEEK as end of the range.
 * For this setting, the report will only contain transactions from the previous week.
 */
export type Range = {
  __typename?: 'Range';
  criterion?: Maybe<Scalars['String']['output']>;
  end?: Maybe<ReportScheduleRelativeTime>;
  start?: Maybe<ReportScheduleRelativeTime>;
};

export type ReferenceAssociable = {
  referenceAssociations?: Maybe<ReferenceAssociationConnection>;
};

export type ReferenceAssociableReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferenceAssociation = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'ReferenceAssociation';
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    flowStepCondition: FlowStepCondition;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    referenceRecord: ReferenceAssociable;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type ReferenceAssociationRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for ReferenceAssociation. */
export type ReferenceAssociationConnection = {
  __typename?: 'ReferenceAssociationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReferenceAssociationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReferenceAssociation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReferenceAssociationEdge = {
  __typename?: 'ReferenceAssociationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReferenceAssociation>;
};

export type ReferenceFragment = StringFragment & {
  __typename?: 'ReferenceFragment';
  text: Scalars['String']['output'];
  type: StringFragmentFragmentType;
  /**
   * Frontend URL for linking to the referenced object.
   *
   * This may be different to the `urn`. Some objects can't be linked to directly,
   * such as request items, so they are linked to with the context of their request.
   * Example: `/requests/<REQUEST_ID>?request_item=<REQUEST_ITEM_ID>`
   *
   * If we don't know how to generate a URL for the object type, `NULL` is returned.
   */
  url?: Maybe<Url>;
  urn: Scalars['GlobalID']['output'];
};

/** Autogenerated input type of RefreshOnboardingContact */
export type RefreshOnboardingContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  supplierOnboardingContactId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of RefreshOnboardingContact. */
export type RefreshOnboardingContactPayload = {
  __typename?: 'RefreshOnboardingContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  supplierOnboardingContact?: Maybe<SupplierOnboardingContact>;
};

export type RegisterDeviceAttributesInput = {
  firebaseDeviceToken: Scalars['String']['input'];
  os: DeviceRegistrationDeviceOs;
  osVersion?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RegisterDevice */
export type RegisterDeviceInput = {
  attributes: RegisterDeviceAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RegisterDevice. */
export type RegisterDevicePayload = {
  __typename?: 'RegisterDevicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deviceRegistration?: Maybe<DeviceRegistration>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of RejectApproval */
export type RejectApprovalInput = {
  approvalId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentBody?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RejectApproval. */
export type RejectApprovalPayload = {
  __typename?: 'RejectApprovalPayload';
  approval?: Maybe<Approval>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type RelationExists = {
  relationExists: Scalars['Boolean']['output'];
};

export type RelationExistsRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** Autogenerated input type of RemoveRoleFromUser */
export type RemoveRoleFromUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  role: UserSecurityRole;
  userId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of RemoveRoleFromUser. */
export type RemoveRoleFromUserPayload = {
  __typename?: 'RemoveRoleFromUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  user?: Maybe<User>;
};

/** Autogenerated input type of RemoveSupplier */
export type RemoveSupplierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  disableComment: Scalars['String']['input'];
  supplierId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of RemoveSupplier. */
export type RemoveSupplierPayload = {
  __typename?: 'RemoveSupplierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  supplier?: Maybe<SupplierAccount>;
};

/** Autogenerated input type of ReopenCardSubscription */
export type ReopenCardSubscriptionInput = {
  cardSubscriptionId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ReopenCardSubscription. */
export type ReopenCardSubscriptionPayload = {
  __typename?: 'ReopenCardSubscriptionPayload';
  cardSubscription?: Maybe<CardSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type Report = GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'Report';
    activities?: Maybe<ActivityConnection>;
    attachmentCount: Scalars['Int']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    criterion?: Maybe<Scalars['String']['output']>;
    dataView: DataView;
    date: Date;
    exportFile?: Maybe<Attachment>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /**
     * Report name, it is set from:
     *  - report template label for scheduled reports
     *  - manually specified label for on-demand reports if the title was specified in the arguments
     *  - data view label for on-demand reports if the label was not specified manually
     */
    label: Scalars['String']['output'];
    parsedId?: Maybe<IParsedGlobalId>;
    recordCount: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    reportSchedule?: Maybe<ReportSchedule>;
    resource: DataViewResourceType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: ReportRecordViewerPermissions;
  };

export type ReportActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type ReportRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type ReportCollectionViewerPermissions = {
  __typename?: 'ReportCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for Report. */
export type ReportConnection = {
  __typename?: 'ReportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Report>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: ReportCollectionViewerPermissions;
};

/** An edge in a connection. */
export type ReportEdge = {
  __typename?: 'ReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Report>;
};

export type ReportRecordViewerPermissions = {
  __typename?: 'ReportRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  download: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  show?: Maybe<Scalars['Boolean']['output']>;
  subscribeToNotifications: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type ReportSchedule = Archivable &
  Auditable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'ReportSchedule';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    dataView: DataView;
    description?: Maybe<Scalars['String']['output']>;
    exportFormat: ReportScheduleExportFormat;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    label: Scalars['String']['output'];
    notificationSubscribers?: Maybe<UserConnection>;
    parsedId?: Maybe<IParsedGlobalId>;
    range?: Maybe<Range>;
    relationExists: Scalars['Boolean']['output'];
    schedule?: Maybe<Schedule>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: ReportScheduleRecordViewerPermissions;
  };

export type ReportScheduleActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type ReportScheduleAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type ReportScheduleNotificationSubscribersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportScheduleRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type ReportScheduleCollectionViewerPermissions = {
  __typename?: 'ReportScheduleCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for ReportSchedule. */
export type ReportScheduleConnection = {
  __typename?: 'ReportScheduleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportScheduleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReportSchedule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: ReportScheduleCollectionViewerPermissions;
};

/** An edge in a connection. */
export type ReportScheduleEdge = {
  __typename?: 'ReportScheduleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReportSchedule>;
};

export enum ReportScheduleExportFormat {
  /** Comma-separated values. (Plain text file). */
  Csv = 'CSV',
  /** Excel Spreadsheet */
  Xlsx = 'XLSX',
}

export type ReportScheduleRecordViewerPermissions = {
  __typename?: 'ReportScheduleRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  subscribeToNotifications: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum ReportScheduleRelativeTime {
  EndOfLastMonth = 'END_OF_LAST_MONTH',
  EndOfLastWeek = 'END_OF_LAST_WEEK',
  StartOfLastMonth = 'START_OF_LAST_MONTH',
  StartOfLastWeek = 'START_OF_LAST_WEEK',
}

export type ReportScheduleSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type ReportSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  dataViewLabel?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type RequestItemSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  /** Requestor's full name i.e. {first name} {last name} */
  requestedByName?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  submittedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated input type of RevokeVirtualCard */
export type RevokeVirtualCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  revokeComment: Scalars['String']['input'];
  virtualCardId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of RevokeVirtualCard. */
export type RevokeVirtualCardPayload = {
  __typename?: 'RevokeVirtualCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  virtualCard?: Maybe<VirtualCard>;
};

export type Row = {
  __typename?: 'Row';
  errors: Array<ErrorMessage>;
  rowNumber: Scalars['Int']['output'];
  urn?: Maybe<Scalars['GlobalID']['output']>;
};

export type Schedule = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Schedule';
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * Schedule recurrence definition in
     * [RFC 5545](https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.10) format.
     *
     * See the `starts_at` description for its relation to the definition.
     *
     * Rules should be as specific as possible.
     * For example if you want a monthly schedule, consider setting the day of the
     * month and time as well. `FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=9;BYMINUTE=0`
     * is specific and says on the 1st of every month at 09:00 (in the `timezone`).
     */
    definition: Scalars['String']['output'];
    enabled: Scalars['Boolean']['output'];
    endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    /**
     * The starting timestamp serves two purposes:
     *
     *   * Time from when the schedule is active.
     *   * Seed/start point for recurrence.
     *
     * For example if the recurrence rule is simple and only specifies `FREQ=MONTHLY`
     * to be a monthly schedule, the start date is used as the starting point.
     * So if your start date is the 14th of the month at 15:35, every future instance will
     * be on the 14th of the month at 15:35.
     */
    startsAt: Scalars['ISO8601DateTime']['output'];
    timezone: Scalars['TimezoneID']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type ScheduleRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type SelectCustomField = Archivable &
  Auditable &
  CustomField &
  DataScopingRules &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'SelectCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    dataScopingRules: DataScopingRuleConnection;
    /** The `DataSource` provides options for `Select` fields. */
    dataSource?: Maybe<DataSource>;
    /** Use this if you only need the ID of data source */
    dataSourceId: Scalars['GlobalID']['output'];
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    options: DataSourceItemConnection;
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type SelectCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type SelectCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type SelectCustomFieldDataScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SelectCustomFieldOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scopeToFormableId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SelectCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type SingleUseCard = {
  __typename?: 'SingleUseCard';
  /** Default lifetime in days of a virtual credit card. */
  defaultTtlDays: Scalars['Int']['output'];
  /** Max lifetime in days of a virtual credit card */
  maxTtlDays: Scalars['Int']['output'];
};

export type Skipped = {
  __typename?: 'Skipped';
  count: Scalars['Int']['output'];
  rows: Array<Row>;
};

/** Autogenerated input type of SnoozeRemindersForTransaction */
export type SnoozeRemindersForTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  snoozeRemindersUntil: Scalars['ISO8601Date']['input'];
  transactionId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of SnoozeRemindersForTransaction. */
export type SnoozeRemindersForTransactionPayload = {
  __typename?: 'SnoozeRemindersForTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  transaction?: Maybe<Transaction>;
};

/** Used in sorting input object to control the output sort direction */
export enum SortDirection {
  /** e.g. A-Z, 0-9 */
  Asc = 'ASC',
  /** e.g. Z-A, 9-0 */
  Desc = 'DESC',
}

export enum SortingTimezoneAttribute {
  Id = 'ID',
  UtcOffset = 'UTC_OFFSET',
}

export type SpendLimit = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'SpendLimit';
    /** Settled amount after currency conversion, if any. */
    amount: Money;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** Settled early warning amount after currency conversion, if any. */
    earlyWarningAmount: Money;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    runningPeriodInDays: SpendLimitRunningPeriodInDays;
    scope: SpendLimitScope;
    spendLimitable: SpendLimitable;
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type SpendLimitRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for SpendLimit. */
export type SpendLimitConnection = {
  __typename?: 'SpendLimitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpendLimitEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SpendLimit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SpendLimitEdge = {
  __typename?: 'SpendLimitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SpendLimit>;
};

export enum SpendLimitRunningPeriodInDays {
  OneDay = 'ONE_DAY',
  SevenDays = 'SEVEN_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
}

export enum SpendLimitScope {
  Total = 'TOTAL',
  User = 'USER',
}

export type SpendLimitSortInput = {
  amount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  runningPeriodInDays?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type SpendLimitable = OrganisationUnit | User;

export enum SpendLimitableType {
  OrganisationUnit = 'ORGANISATION_UNIT',
  User = 'USER',
}

export type SpendSummary = {
  __typename?: 'SpendSummary';
  amount: Money;
  /** Number of past days this spend summary describes. */
  runningPeriodInDays: SpendLimitRunningPeriodInDays;
};

export type StringCustomFieldValue = Auditable &
  CustomFieldValue &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'StringCustomFieldValue';
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customField: CustomField;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value?: Maybe<Scalars['String']['output']>;
    viewerPermissions: CustomFieldValueRecordViewerPermissions;
  };

export type StringCustomFieldValueAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type StringCustomFieldValueRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type StringFragment = {
  text: Scalars['String']['output'];
  type: StringFragmentFragmentType;
};

export enum StringFragmentFragmentType {
  Reference = 'REFERENCE',
  Text = 'TEXT',
}

/** Autogenerated input type of SubmitDocument */
export type SubmitDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of SubmitDocument. */
export type SubmitDocumentPayload = {
  __typename?: 'SubmitDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of SubmitPurchaseRequestItem */
export type SubmitPurchaseRequestItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  purchaseRequestItemId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of SubmitPurchaseRequestItem. */
export type SubmitPurchaseRequestItemPayload = {
  __typename?: 'SubmitPurchaseRequestItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

export type Subscribable = {
  /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
  viewerIsSubscribed: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SubscribeToNotifications */
export type SubscribeToNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
  userId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of SubscribeToNotifications. */
export type SubscribeToNotificationsPayload = {
  __typename?: 'SubscribeToNotificationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  record?: Maybe<Subscribable>;
  status: MutationStatus;
};

export type Subscription = {
  __typename?: 'Subscription';
  /**
   * **NOTICE**: This is guarded by the feature toggle `action_cable_subscriptions`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  notificationCreated: NotificationCreatedPayload;
};

export type SupplierAccount = Account &
  Auditable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'SupplierAccount';
    accountType: AccountUseType;
    activities?: Maybe<ActivityConnection>;
    addedBy?: Maybe<User>;
    address?: Maybe<Address>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    bankAccounts?: Maybe<BankAccountConnection>;
    /**
     * The field is only available to buyer accounts. If a supplier account fetchs this field,
     * it will always return nil.
     */
    companyLookup?: Maybe<CompanyLookup>;
    companyName: Scalars['String']['output'];
    coverImage?: Maybe<Attachment>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    disableComment?: Maybe<Scalars['String']['output']>;
    /** Field is only accessible by buyers, so will be nil for suppliers. */
    enableStatus?: Maybe<SupplierAccountEnableStatus>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    industryCodes: Array<IndustryCode>;
    onboardingCase?: Maybe<SupplierOnboardingCase>;
    /**
     * Onboarding status from the onboarding case.
     * Only the supplier can read their own invitation information, i.e. `onboarding_case` field.
     * The status however is public, so reading this specific attribute is always allowed
     */
    onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
    parsedId?: Maybe<IParsedGlobalId>;
    profileImage?: Maybe<Attachment>;
    publicEmail?: Maybe<Scalars['String']['output']>;
    publicPhone?: Maybe<Scalars['String']['output']>;
    publicWebsite?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    supportedCurrencies?: Maybe<Array<Currency>>;
    transactions: TransactionConnection;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    vatNumber?: Maybe<Scalars['String']['output']>;
    viewerPermissions: SupplierAccountRecordViewerPermissions;
  };

export type SupplierAccountActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type SupplierAccountAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type SupplierAccountBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SupplierAccountRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type SupplierAccountTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for SupplierAccount. */
export type SupplierAccountConnection = {
  __typename?: 'SupplierAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierAccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SupplierAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SupplierAccountEdge = {
  __typename?: 'SupplierAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplierAccount>;
};

export enum SupplierAccountEnableStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Requested = 'REQUESTED',
}

export type SupplierAccountRecordViewerPermissions = {
  __typename?: 'SupplierAccountRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  enable: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  showDetails?: Maybe<Scalars['Boolean']['output']>;
  update: Scalars['Boolean']['output'];
};

export type SupplierAccountSortInput = {
  companyName?: InputMaybe<SortDirection>;
  /** Country code from address of supplier */
  countryCode?: InputMaybe<SortDirection>;
  /** Country name in the viewer's language. */
  countryName?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  /** The time when the supplier was enabled for current account */
  enabledAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  vatNumber?: InputMaybe<SortDirection>;
};

export type SupplierOnboardingCase = GlobalIdField &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'SupplierOnboardingCase';
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /** Returns the account of the buyer who created the invitation. */
    invitedBy: BuyerAccount;
    onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
    penneoStatus: SupplierOnboardingCasePenneoStatus;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: SupplierOnboardingCaseRecordViewerPermissions;
  };

export type SupplierOnboardingCaseRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum SupplierOnboardingCaseOnboardingStatus {
  Identification = 'IDENTIFICATION',
  InvitedForOnboarding = 'INVITED_FOR_ONBOARDING',
  OnboardingSubmitted = 'ONBOARDING_SUBMITTED',
  /**
   * When the SupplierOnboardingCase is not created yet, it returns pending as Supplier's onboardingStatus.
   * If the Supplier's onboarding case is created, it is not possible to have a pending status.
   */
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
}

export enum SupplierOnboardingCasePenneoStatus {
  Archived = 'ARCHIVED',
  Fulfilled = 'FULFILLED',
  Pending = 'PENDING',
}

export type SupplierOnboardingCaseRecordViewerPermissions = {
  __typename?: 'SupplierOnboardingCaseRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  initiate: Scalars['Boolean']['output'];
  submitForReview: Scalars['Boolean']['output'];
  subscribeToNotifications: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type SupplierOnboardingContact = Archivable &
  GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'SupplierOnboardingContact';
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    contactType: SupplierOnboardingContactContactType;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    onboardingStatus: SupplierOnboardingContactOnboardingStatus;
    onboardingUrl?: Maybe<Url>;
    relationExists: Scalars['Boolean']['output'];
    supplierOnboardingCase: SupplierOnboardingCase;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: SupplierOnboardingContactRecordViewerPermissions;
  };

export type SupplierOnboardingContactRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum SupplierOnboardingContactContactType {
  AuthorisedSignatory = 'AUTHORISED_SIGNATORY',
  Representative = 'REPRESENTATIVE',
}

export enum SupplierOnboardingContactOnboardingStatus {
  Complete = 'COMPLETE',
  Invited = 'INVITED',
  InProgress = 'IN_PROGRESS',
}

export type SupplierOnboardingContactRecordViewerPermissions = {
  __typename?: 'SupplierOnboardingContactRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type SupplierPayment = {
  __typename?: 'SupplierPayment';
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  scheduledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  transactionReference?: Maybe<Scalars['String']['output']>;
};

export type SupplierPayout = GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'SupplierPayout';
    activities?: Maybe<ActivityConnection>;
    amount: Money;
    bankAccount: BankAccount;
    buyerName: Scalars['String']['output'];
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    initiatedAt: Scalars['ISO8601DateTime']['output'];
    invoice: Attachment;
    invoiceNumber?: Maybe<Scalars['String']['output']>;
    relationExists: Scalars['Boolean']['output'];
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: SupplierPayoutRecordViewerPermissions;
  };

export type SupplierPayoutActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type SupplierPayoutRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type SupplierPayoutCollectionViewerPermissions = {
  __typename?: 'SupplierPayoutCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for SupplierPayout. */
export type SupplierPayoutConnection = {
  __typename?: 'SupplierPayoutConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierPayoutEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SupplierPayout>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: SupplierPayoutCollectionViewerPermissions;
};

/** An edge in a connection. */
export type SupplierPayoutEdge = {
  __typename?: 'SupplierPayoutEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplierPayout>;
};

export type SupplierPayoutRecordViewerPermissions = {
  __typename?: 'SupplierPayoutRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type SupplierPayoutSortInput = {
  buyerName?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  initiatedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type SupplierRequest = Approvable &
  Commentable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Subscribable &
  Timestamps & {
    __typename?: 'SupplierRequest';
    activities?: Maybe<ActivityConnection>;
    approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
    approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    comments: CommentConnection;
    companyLookup: CompanyLookup;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    messageToSupplier?: Maybe<Scalars['String']['output']>;
    rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    relationExists: Scalars['Boolean']['output'];
    requestedBy: User;
    supplierContactEmail?: Maybe<Scalars['String']['output']>;
    supplierContactName?: Maybe<Scalars['String']['output']>;
    supplierContactPhone?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Whether the current viewer is subscribed to receive activity notifications related to this record. */
    viewerIsSubscribed: Scalars['Boolean']['output'];
    viewerPermissions: SupplierRequestRecordViewerPermissions;
  };

export type SupplierRequestActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type SupplierRequestCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CommentSortInput>>;
};

export type SupplierRequestRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type SupplierRequestCollectionViewerPermissions = {
  __typename?: 'SupplierRequestCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for SupplierRequest. */
export type SupplierRequestConnection = {
  __typename?: 'SupplierRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SupplierRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: SupplierRequestCollectionViewerPermissions;
};

/** An edge in a connection. */
export type SupplierRequestEdge = {
  __typename?: 'SupplierRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplierRequest>;
};

export type SupplierRequestRecordViewerPermissions = {
  __typename?: 'SupplierRequestRecordViewerPermissions';
  addComment: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  subscribeToNotifications: Scalars['Boolean']['output'];
  unsubscribeFromNotifications: Scalars['Boolean']['output'];
};

export type SupplierRequestSortInput = {
  approvalStatus?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  requestedByName?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type TextAreaCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'TextAreaCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type TextAreaCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type TextAreaCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type TextAreaCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type TextCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'TextCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type TextCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type TextCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type TextCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type TextFragment = StringFragment & {
  __typename?: 'TextFragment';
  text: Scalars['String']['output'];
  type: StringFragmentFragmentType;
};

export enum Theme {
  Auto = 'AUTO',
  Dark = 'DARK',
  Light = 'LIGHT',
}

export type Timestamps = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Timezone = {
  __typename?: 'Timezone';
  id: Scalars['TimezoneID']['output'];
  /**
   * Name without the region, suitable for display.
   *
   * Example `America/Argentina/Buenos_Aires` => `Argentina > Buenos Aires`
   */
  localName: Scalars['String']['output'];
  region: TimezoneRegion;
  /** Offset formatted e.g `+01:00`, `+04:30` */
  utcOffsetFormatted: Scalars['String']['output'];
  utcOffsetSeconds: Scalars['Int']['output'];
};

export enum TimezoneRegion {
  Africa = 'AFRICA',
  Americas = 'AMERICAS',
  Antarctica = 'ANTARCTICA',
  Asia = 'ASIA',
  Atlantic = 'ATLANTIC',
  Australia = 'AUSTRALIA',
  Europe = 'EUROPE',
  IndianOcean = 'INDIAN_OCEAN',
  Pacific = 'PACIFIC',
}

export type Transaction = Documentable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Transaction';
    activities?: Maybe<ActivityConnection>;
    /** Settled amount after currency conversion, if any. */
    billedAmount: Money;
    /** Settled amount in the base currency of the account. */
    billedBaseAmount: Money;
    createdAt: Scalars['ISO8601DateTime']['output'];
    customReconciliationStatus?: Maybe<CustomTransactionReconciliationStatus>;
    documents?: Maybe<DocumentConnection>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    manuallyReconciledBy?: Maybe<User>;
    merchant?: Maybe<Merchant>;
    merchantName: Scalars['String']['output'];
    /** Amount charged by the merchant in their local currency. */
    originalAmount: Money;
    parsedId?: Maybe<IParsedGlobalId>;
    postingDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
    purchaseDocumentation?: Maybe<Document>;
    purchaseOrder?: Maybe<PurchaseOrder>;
    reconciliationStatus: TransactionReconciliationStatus;
    relationExists: Scalars['Boolean']['output'];
    remindersSnoozed: Scalars['Boolean']['output'];
    remindersSnoozedUntil?: Maybe<Scalars['ISO8601Date']['output']>;
    supportedDocumentTypes: Array<DocumentClassification>;
    supportedDocuments: DocumentConnection;
    transactionDate: Scalars['ISO8601DateTime']['output'];
    /** External transaction ID from the card provider. */
    transactionId: Scalars['String']['output'];
    type: TransactionType;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    vatMarket?: Maybe<TransactionVatMarket>;
    viewerPermissions: TransactionRecordViewerPermissions;
    virtualCard?: Maybe<VirtualCard>;
  };

export type TransactionActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type TransactionDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type TransactionSupportedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DocumentSortInput>>;
};

export type TransactionCollectionViewerPermissions = {
  __typename?: 'TransactionCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for Transaction. */
export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Transaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: TransactionCollectionViewerPermissions;
};

/** An edge in a connection. */
export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Transaction>;
};

export enum TransactionReconciliationStatus {
  /** Transaction will be **receipt_missing** when no receipt attached */
  ReceiptMissing = 'RECEIPT_MISSING',
  /** Transaction will be **receipt_unavailable** when a user marked receipt is unavailable */
  ReceiptUnavailable = 'RECEIPT_UNAVAILABLE',
  /** Transaction will be **reconciled** when a receipt attached */
  Reconciled = 'RECONCILED',
}

export type TransactionRecordViewerPermissions = {
  __typename?: 'TransactionRecordViewerPermissions';
  addDocument?: Maybe<Scalars['Boolean']['output']>;
  destroy: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  markAsReceiptMissing: Scalars['Boolean']['output'];
  markAsReceiptUnavailable: Scalars['Boolean']['output'];
  snoozeReminders: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type TransactionSortInput = {
  billedAmount?: InputMaybe<SortDirection>;
  billedBaseAmount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  /** Sorts by the two letter [ISO country code](https://www.iban.com/country-codes) of the merchant's country. */
  merchantCountryCode?: InputMaybe<SortDirection>;
  originalAmount?: InputMaybe<SortDirection>;
  postingDate?: InputMaybe<SortDirection>;
  reconciliationStatus?: InputMaybe<SortDirection>;
  supplierName?: InputMaybe<SortDirection>;
  transactionDate?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum TransactionType {
  /** Money was spent on a purchase. */
  Charge = 'CHARGE',
  /** Refunds and other credits back to the card. */
  Credit = 'CREDIT',
}

export enum TransactionVatMarket {
  Domestic = 'DOMESTIC',
  Eu = 'EU',
  International = 'INTERNATIONAL',
}

export type TriggerEvent = {
  __typename?: 'TriggerEvent';
  description: Scalars['String']['output'];
  key: WorkflowTriggerEventKey;
};

export type Url = {
  __typename?: 'URL';
  hostname: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  port: Scalars['Int']['output'];
  /** Query string. Does not include `?` at the start. */
  query?: Maybe<Scalars['String']['output']>;
  scheme: UrlScheme;
  /**
   * The part of the URL that is sent to the server in the HTTP request
   * after you have already connected. This is everything from the first `/` and beyond.
   *
   * In the URL `https://example.org:1234/users/1?sort=name&q=john` the URI is:
   * `/users/1?sort=name&q=john`
   */
  uri: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Autogenerated input type of UnarchiveRecord */
export type UnarchiveRecordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UnarchiveRecord. */
export type UnarchiveRecordPayload = {
  __typename?: 'UnarchiveRecordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  record?: Maybe<Archivable>;
  status: MutationStatus;
};

/** Autogenerated input type of UndoCancelPurchaseRequestItem */
export type UndoCancelPurchaseRequestItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  purchaseRequestItemId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UndoCancelPurchaseRequestItem. */
export type UndoCancelPurchaseRequestItemPayload = {
  __typename?: 'UndoCancelPurchaseRequestItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

/** Autogenerated input type of UndoInvalidateDocument */
export type UndoInvalidateDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UndoInvalidateDocument. */
export type UndoInvalidateDocumentPayload = {
  __typename?: 'UndoInvalidateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UndoMarkRequestItemGoodsReceived */
export type UndoMarkRequestItemGoodsReceivedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  requestItemId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UndoMarkRequestItemGoodsReceived. */
export type UndoMarkRequestItemGoodsReceivedPayload = {
  __typename?: 'UndoMarkRequestItemGoodsReceivedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  requestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

/** Autogenerated input type of UnsubscribeFromNotifications */
export type UnsubscribeFromNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
  userId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of UnsubscribeFromNotifications. */
export type UnsubscribeFromNotificationsPayload = {
  __typename?: 'UnsubscribeFromNotificationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  record?: Maybe<Subscribable>;
  status: MutationStatus;
};

/** Used to create/update/destroy custom field values in a single input object. */
export type UpdatableCustomFieldValueInputAttributes = {
  toCreate?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  toDestroy?: InputMaybe<Array<DestroyCustomFieldValueInputAttributes>>;
  toUpdate?: InputMaybe<UpdateCustomFieldValueInputAttributes>;
};

/** Used to create/update/destroy data values in a single input object. */
export type UpdatableDataValueForParentInputAttributes = {
  toCreate?: InputMaybe<CreateDataValueForParentInputAttributes>;
  toDestroy?: InputMaybe<Array<DestroyDataValueForParentInputAttributes>>;
  toUpdate?: InputMaybe<UpdateDataValueForParentInputAttributes>;
};

export type UpdateAccountAttributesInput = {
  address?: InputMaybe<UpdateAddressInputAttributes>;
  /**
   * @deprecated The field uses signed_id and we wish to migrate this usage to Blob.
   * Please use `cover_image_blob_id` with Blob object URN.
   * (Since 2023-04-03. To be removed by MP-6069)
   *
   */
  coverImage?: InputMaybe<Scalars['String']['input']>;
  coverImageBlobId?: InputMaybe<Scalars['GlobalID']['input']>;
  profileImageBlobId?: InputMaybe<Scalars['GlobalID']['input']>;
  publicEmail?: InputMaybe<Scalars['String']['input']>;
  publicPhone?: InputMaybe<Scalars['String']['input']>;
  publicWebsite?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateAccountProfile */
export type UpdateAccountProfileInput = {
  attributes: UpdateAccountAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateAccountProfile. */
export type UpdateAccountProfilePayload = {
  __typename?: 'UpdateAccountProfilePayload';
  account: BuyerAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<ErrorMessage>>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateAccountSettings */
export type UpdateAccountSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<AccountSettingsInput>;
};

/** Autogenerated return type of UpdateAccountSettings. */
export type UpdateAccountSettingsPayload = {
  __typename?: 'UpdateAccountSettingsPayload';
  account?: Maybe<BuyerAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateAddressInputAttributes = {
  city?: InputMaybe<Scalars['String']['input']>;
  /** ISO-3166 two-character country code, e.g. `DK` */
  countryCode?: InputMaybe<CountryCode>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** National sub-region, such as a US state or Canadian province. */
  regionName?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAmountDataValuesInputAttributes = {
  dataValueId: Scalars['GlobalID']['input'];
  valueCents?: InputMaybe<Scalars['BigInt']['input']>;
  valueCurrency?: InputMaybe<CurrencyCode>;
};

export type UpdateAppearanceSettingInputAttributes = {
  theme?: InputMaybe<Theme>;
};

/** Autogenerated input type of UpdateApprovalDelegation */
export type UpdateApprovalDelegationInput = {
  approvalDelegationId: Scalars['GlobalID']['input'];
  attributes: UpdateApprovalDelegationInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApprovalDelegationInputAttributes = {
  delegateToId?: InputMaybe<Scalars['GlobalID']['input']>;
  endsOn?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startsOn?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Autogenerated return type of UpdateApprovalDelegation. */
export type UpdateApprovalDelegationPayload = {
  __typename?: 'UpdateApprovalDelegationPayload';
  approvalDelegation?: Maybe<ApprovalDelegation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateApproverCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateApproverCustomField */
export type UpdateApproverCustomFieldInput = {
  attributes: UpdateApproverCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateApproverCustomField. */
export type UpdateApproverCustomFieldPayload = {
  __typename?: 'UpdateApproverCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateAssociationCustomFieldValuesInputAttributes = {
  associationId?: InputMaybe<Scalars['GlobalID']['input']>;
  id: Scalars['GlobalID']['input'];
};

export type UpdateAttachmentCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateAttachmentCustomField */
export type UpdateAttachmentCustomFieldInput = {
  attributes: UpdateAttachmentCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateAttachmentCustomField. */
export type UpdateAttachmentCustomFieldPayload = {
  __typename?: 'UpdateAttachmentCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateBooleanCustomFieldValuesInputAttributes = {
  id: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCardSubscriptionAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayCurrency?: InputMaybe<CurrencyCode>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateCardSubscription */
export type UpdateCardSubscriptionInput = {
  attributes: UpdateCardSubscriptionAttributesInput;
  cardSubscriptionId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCardSubscription. */
export type UpdateCardSubscriptionPayload = {
  __typename?: 'UpdateCardSubscriptionPayload';
  cardSubscription?: Maybe<CardSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateCategoryAttributesInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateCategoryCustomField */
export type UpdateCategoryCustomFieldInput = {
  attributes: UpdateCategoryCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateCategoryCustomField. */
export type UpdateCategoryCustomFieldPayload = {
  __typename?: 'UpdateCategoryCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateCategory */
export type UpdateCategoryInput = {
  attributes: UpdateCategoryAttributesInput;
  categoryId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCategory. */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateCategorySetAttributesInput = {
  label: Scalars['String']['input'];
};

/** Autogenerated input type of UpdateCategorySet */
export type UpdateCategorySetInput = {
  attributes: UpdateCategorySetAttributesInput;
  categorySetId: Scalars['GlobalID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCategorySet. */
export type UpdateCategorySetPayload = {
  __typename?: 'UpdateCategorySetPayload';
  categorySet?: Maybe<CategorySet>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateCheckboxCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateCheckboxCustomField */
export type UpdateCheckboxCustomFieldInput = {
  attributes: UpdateCheckboxCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateCheckboxCustomField. */
export type UpdateCheckboxCustomFieldPayload = {
  __typename?: 'UpdateCheckboxCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateCostCentreAttributesInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCostCentreCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateCostCentreCustomField */
export type UpdateCostCentreCustomFieldInput = {
  attributes: UpdateCostCentreCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateCostCentreCustomField. */
export type UpdateCostCentreCustomFieldPayload = {
  __typename?: 'UpdateCostCentreCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateCostCentre */
export type UpdateCostCentreInput = {
  attributes: UpdateCostCentreAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  costCentreId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateCostCentre. */
export type UpdateCostCentrePayload = {
  __typename?: 'UpdateCostCentrePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  costCentre?: Maybe<CostCentre>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateCustomFieldValueInputAttributes = {
  associations?: InputMaybe<Array<UpdateAssociationCustomFieldValuesInputAttributes>>;
  booleans?: InputMaybe<Array<UpdateBooleanCustomFieldValuesInputAttributes>>;
  datetimes?: InputMaybe<Array<UpdateDateTimeCustomFieldValuesInputAttributes>>;
  files?: InputMaybe<Array<UpdateFilesCustomFieldValuesInputAttributes>>;
  integers?: InputMaybe<Array<UpdateIntegerCustomFieldValuesInputAttributes>>;
  money?: InputMaybe<Array<UpdateMoneyCustomFieldValuesInputAttributes>>;
  strings?: InputMaybe<Array<UpdateStringCustomFieldValuesInputAttributes>>;
};

/** Autogenerated input type of UpdateDataFilter */
export type UpdateDataFilterInput = {
  attributes: UpdateDataFilterInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataFilterId: Scalars['GlobalID']['input'];
};

export type UpdateDataFilterInputAttributes = {
  comparator?: InputMaybe<FlowStepConditionComparatorName>;
  referenceValue?: InputMaybe<Scalars['ReferenceValue']['input']>;
};

/** Autogenerated return type of UpdateDataFilter. */
export type UpdateDataFilterPayload = {
  __typename?: 'UpdateDataFilterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataFilter?: Maybe<DataFilter>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateDataPathDataValuesInputAttributes = {
  dataValueId: Scalars['GlobalID']['input'];
  path: Scalars['String']['input'];
};

/** Autogenerated input type of UpdateDataPath */
export type UpdateDataPathInput = {
  attributes: UpdateDataPathInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataPathId: Scalars['GlobalID']['input'];
};

export type UpdateDataPathInputAttributes = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateDataPath. */
export type UpdateDataPathPayload = {
  __typename?: 'UpdateDataPathPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataPath?: Maybe<DataPath>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateDataScopingRule */
export type UpdateDataScopingRuleInput = {
  attributes: UpdateDataScopingRuleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataScopingRuleId: Scalars['GlobalID']['input'];
};

export type UpdateDataScopingRuleInputAttributes = {
  operator?: InputMaybe<DataScopingRuleOperator>;
  position?: InputMaybe<Scalars['Int']['input']>;
  scopeByCustomFieldId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of UpdateDataScopingRule. */
export type UpdateDataScopingRulePayload = {
  __typename?: 'UpdateDataScopingRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataScopingRule?: Maybe<DataScopingRule>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateDataSourceAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateDataSource */
export type UpdateDataSourceInput = {
  attributes: UpdateDataSourceAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataSourceId: Scalars['GlobalID']['input'];
};

/** Autogenerated input type of UpdateDataSourceItem */
export type UpdateDataSourceItemInput = {
  attributes: UpdateDataSourceItemInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataSourceItemId: Scalars['GlobalID']['input'];
};

export type UpdateDataSourceItemInputAttributes = {
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateDataSourceItem. */
export type UpdateDataSourceItemPayload = {
  __typename?: 'UpdateDataSourceItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataSourceItem?: Maybe<DataSourceItem>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated return type of UpdateDataSource. */
export type UpdateDataSourcePayload = {
  __typename?: 'UpdateDataSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<DataSource>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateDataValueForParentInputAttributes = {
  amount?: InputMaybe<Array<UpdateAmountDataValuesInputAttributes>>;
  dataPath?: InputMaybe<Array<UpdateDataPathDataValuesInputAttributes>>;
  date?: InputMaybe<Array<UpdateDateDataValuesInputAttributes>>;
  record?: InputMaybe<Array<UpdateRecordDataValuesInputAttributes>>;
  static?: InputMaybe<Array<UpdateStaticDataValuesInputAttributes>>;
};

/** Autogenerated input type of UpdateDataValue */
export type UpdateDataValueInput = {
  attributes: UpdateDataValueInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataValueId: Scalars['GlobalID']['input'];
};

export type UpdateDataValueInputAttributes = {
  amountValueCents?: InputMaybe<Scalars['BigInt']['input']>;
  amountValueCurrency?: InputMaybe<CurrencyCode>;
  dateValue?: InputMaybe<Scalars['ISO8601Date']['input']>;
  pathValue?: InputMaybe<UpdateDataValuePathValueInputAttributes>;
  recordValueId?: InputMaybe<Scalars['GlobalID']['input']>;
  staticValue?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataValuePathValueInputAttributes = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateDataValue. */
export type UpdateDataValuePayload = {
  __typename?: 'UpdateDataValuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataValue?: Maybe<DataValue>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateDataViewField */
export type UpdateDataViewFieldInput = {
  attributes: UpdateDataViewFieldInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataViewFieldId: Scalars['GlobalID']['input'];
};

export type UpdateDataViewFieldInputAttributes = {
  dataValues?: InputMaybe<UpdatableDataValueForParentInputAttributes>;
  /** Optional override format to use when the data is exported to a flat file. */
  formatString?: InputMaybe<DataViewFieldFormatString>;
  label?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateDataViewField. */
export type UpdateDataViewFieldPayload = {
  __typename?: 'UpdateDataViewFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataViewField?: Maybe<DataViewField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateDataView */
export type UpdateDataViewInput = {
  attributes: UpdateDataViewInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dataViewId: Scalars['GlobalID']['input'];
};

export type UpdateDataViewInputAttributes = {
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateDataView. */
export type UpdateDataViewPayload = {
  __typename?: 'UpdateDataViewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dataView?: Maybe<DataView>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateDateCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateDateCustomField */
export type UpdateDateCustomFieldInput = {
  attributes: UpdateDateCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateDateCustomField. */
export type UpdateDateCustomFieldPayload = {
  __typename?: 'UpdateDateCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateDateDataValuesInputAttributes = {
  dataValueId: Scalars['GlobalID']['input'];
  value: Scalars['ISO8601Date']['input'];
};

export type UpdateDateTimeCustomFieldValuesInputAttributes = {
  id: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of UpdateDocument */
export type UpdateDocumentInput = {
  attributes: UpdateDocumentInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['GlobalID']['input'];
};

export type UpdateDocumentInputAttributes = {
  currency?: InputMaybe<CurrencyCode>;
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<DocumentClassification>;
  issuedOn?: InputMaybe<Scalars['ISO8601Date']['input']>;
  messageToSupplier?: InputMaybe<Scalars['String']['input']>;
  /**
   * Date of payment of the attached invoice.
   * This date will be used to set the scheduled payment date for the invoice payment.
   */
  paymentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  receiverIban?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['GlobalID']['input']>;
  totalAmountCents?: InputMaybe<Scalars['BigInt']['input']>;
  vatAmountCents?: InputMaybe<Scalars['BigInt']['input']>;
};

/** Autogenerated return type of UpdateDocument. */
export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateFilesCustomFieldValuesInputAttributes = {
  fileIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  id: Scalars['GlobalID']['input'];
};

export type UpdateFlowStepActionInputAttributes = {
  approvalType?: InputMaybe<FlowActionGetApprovalApprovalType>;
  approverUserIds?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  /**
   * When the field is `true` it shows that `ApproveBy` step
   * will create Approvals for all users that have `approve` role.
   *
   * If the field is `false`, Approvals will be created for users from
   * other sources such as `approverUserIds`, `approverCustomFieldIds` or `dataPaths`.
   */
  assignAll?: InputMaybe<Scalars['Boolean']['input']>;
  commentAuthorId?: InputMaybe<Scalars['GlobalID']['input']>;
  commentText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  outputFalseStepId?: InputMaybe<Scalars['GlobalID']['input']>;
  outputTrueStepId?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type UpdateFlowStepConditionInputAttributes = {
  comparator?: InputMaybe<FlowStepConditionComparatorName>;
  description?: InputMaybe<Scalars['String']['input']>;
  inputAttribute?: InputMaybe<FlowStepConditionInputAttributeName>;
  outputFalseStepId?: InputMaybe<Scalars['GlobalID']['input']>;
  outputTrueStepId?: InputMaybe<Scalars['GlobalID']['input']>;
  referenceValue?: InputMaybe<Scalars['ReferenceValue']['input']>;
};

export type UpdateFlowStepCoordinatesAttributesInput = {
  xPosition?: InputMaybe<Scalars['Int']['input']>;
  yPosition?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateFlowStepCoordinates */
export type UpdateFlowStepCoordinatesInput = {
  attributes: UpdateFlowStepCoordinatesAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  flowStepId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateFlowStepCoordinates. */
export type UpdateFlowStepCoordinatesPayload = {
  __typename?: 'UpdateFlowStepCoordinatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  flowStep?: Maybe<FlowStep>;
  status: MutationStatus;
};

export type UpdateIntegerCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateIntegerCustomField */
export type UpdateIntegerCustomFieldInput = {
  attributes: UpdateIntegerCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateIntegerCustomField. */
export type UpdateIntegerCustomFieldPayload = {
  __typename?: 'UpdateIntegerCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateIntegerCustomFieldValuesInputAttributes = {
  id: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['BigInt']['input']>;
};

export type UpdateIssuerIntegrationAttributesInput = {
  billingEntityId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated input type of UpdateIssuerIntegrationConsumer */
export type UpdateIssuerIntegrationConsumerInput = {
  attributes: UpdateIssuerIntegrationConsumerInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  issuerIntegrationConsumerId: Scalars['GlobalID']['input'];
};

export type UpdateIssuerIntegrationConsumerInputAttributes = {
  cardUseInvoiceFallback?: InputMaybe<Scalars['Boolean']['input']>;
  cardUseMultiFallback?: InputMaybe<Scalars['Boolean']['input']>;
  cardUseSingleFallback?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateIssuerIntegrationConsumer. */
export type UpdateIssuerIntegrationConsumerPayload = {
  __typename?: 'UpdateIssuerIntegrationConsumerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  issuerIntegrationConsumer?: Maybe<IssuerIntegrationConsumer>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateIssuerIntegration */
export type UpdateIssuerIntegrationInput = {
  attributes: UpdateIssuerIntegrationAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  issuerIntegrationId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateIssuerIntegration. */
export type UpdateIssuerIntegrationPayload = {
  __typename?: 'UpdateIssuerIntegrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  issuerIntegration?: Maybe<IssuerIntegration>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateIssuerIntegrationRule */
export type UpdateIssuerIntegrationRuleInput = {
  attributes: UpdateIssuerIntegrationRuleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  issuerIntegrationRuleId: Scalars['GlobalID']['input'];
};

export type UpdateIssuerIntegrationRuleInputAttributes = {
  cardUseType?: InputMaybe<IssuerIntegrationRuleCardUseType>;
  criteria?: InputMaybe<IssuerIntegrationRuleCriteria>;
  position?: InputMaybe<Scalars['Int']['input']>;
  strict?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateIssuerIntegrationRule. */
export type UpdateIssuerIntegrationRulePayload = {
  __typename?: 'UpdateIssuerIntegrationRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  issuerIntegrationRule?: Maybe<IssuerIntegrationRule>;
  status: MutationStatus;
};

export type UpdateLocalisationSettingInputAttributes = {
  displayInCurrencyCode?: InputMaybe<CurrencyCode>;
  interfaceLanguage?: InputMaybe<LanguageCode>;
  timezone?: InputMaybe<Scalars['TimezoneID']['input']>;
};

export type UpdateLocationAttributesInput = {
  address?: InputMaybe<UpdateAddressInputAttributes>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateLocation */
export type UpdateLocationInput = {
  attributes: UpdateLocationAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateLocation. */
export type UpdateLocationPayload = {
  __typename?: 'UpdateLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  location?: Maybe<Location>;
  status: MutationStatus;
};

export type UpdateMoneyCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateMoneyCustomField */
export type UpdateMoneyCustomFieldInput = {
  attributes: UpdateMoneyCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateMoneyCustomField. */
export type UpdateMoneyCustomFieldPayload = {
  __typename?: 'UpdateMoneyCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateMoneyCustomFieldValuesInputAttributes = {
  cents: Scalars['BigInt']['input'];
  currencyCode: CurrencyCode;
  id: Scalars['GlobalID']['input'];
};

export type UpdateOrganisationUnitCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateOrganisationUnitCustomField */
export type UpdateOrganisationUnitCustomFieldInput = {
  attributes: UpdateOrganisationUnitCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateOrganisationUnitCustomField. */
export type UpdateOrganisationUnitCustomFieldPayload = {
  __typename?: 'UpdateOrganisationUnitCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateOrganisationUnit */
export type UpdateOrganisationUnitInput = {
  attributes: UpdateOrganisationUnitInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organisationUnitId: Scalars['GlobalID']['input'];
};

export type UpdateOrganisationUnitInputAttributes = {
  classification?: InputMaybe<OrganisationUnitClassification>;
  code?: InputMaybe<Scalars['String']['input']>;
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  directLocationId?: InputMaybe<Scalars['GlobalID']['input']>;
  directVatNumber?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateOrganisationUnit. */
export type UpdateOrganisationUnitPayload = {
  __typename?: 'UpdateOrganisationUnitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  organisationUnit?: Maybe<OrganisationUnit>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdatePurchaseRequestItem */
export type UpdatePurchaseRequestItemInput = {
  attributes: UpdatePurchaseRequestItemInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  purchaseRequestItemId: Scalars['GlobalID']['input'];
};

export type UpdatePurchaseRequestItemInputAttributes = {
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  requestedAmountCents?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmountCurrency?: InputMaybe<CurrencyCode>;
  requestedCardTtlDays?: InputMaybe<Scalars['Int']['input']>;
  /**
   * **NOTICE**: This is guarded by the feature toggle `invoice_management`.
   * If this toggle is switched off, this element will disappear from the schema and
   * trying to use it will result in a query error.
   */
  supplierId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of UpdatePurchaseRequestItem. */
export type UpdatePurchaseRequestItemPayload = {
  __typename?: 'UpdatePurchaseRequestItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  purchaseRequestItem?: Maybe<PurchaseRequestItem>;
  status: MutationStatus;
};

export type UpdateRecordDataValuesInputAttributes = {
  dataValueId: Scalars['GlobalID']['input'];
  recordValueId: Scalars['GlobalID']['input'];
};

/** Autogenerated input type of UpdateRecordParent */
export type UpdateRecordParentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
  /**
   * The parent record to be set for the record. If the argument is not sent or sent as `null`,
   * the record will be made root.
   */
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of UpdateRecordParent. */
export type UpdateRecordParentPayload = {
  __typename?: 'UpdateRecordParentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  record?: Maybe<HasAncestry>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateRecordPosition */
export type UpdateRecordPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
  position: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateRecordPosition. */
export type UpdateRecordPositionPayload = {
  __typename?: 'UpdateRecordPositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  record?: Maybe<ManuallyOrderable>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateReferenceAssociation */
export type UpdateReferenceAssociationInput = {
  attributes: UpdateReferenceAssociationInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referenceAssociationId: Scalars['GlobalID']['input'];
};

export type UpdateReferenceAssociationInputAttributes = {
  referenceRecordId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated return type of UpdateReferenceAssociation. */
export type UpdateReferenceAssociationPayload = {
  __typename?: 'UpdateReferenceAssociationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  referenceAssociation?: Maybe<ReferenceAssociation>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateReportSchedule */
export type UpdateReportScheduleInput = {
  attributes: UpdateReportScheduleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportScheduleId: Scalars['GlobalID']['input'];
};

export type UpdateReportScheduleInputAttributes = {
  dataViewId?: InputMaybe<Scalars['GlobalID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exportFormat?: InputMaybe<ReportScheduleExportFormat>;
  label?: InputMaybe<Scalars['String']['input']>;
  /**
   * Path to the field on the data view used to filter by date.
   *
   * This must be a valid data view field path. For example on a Transaction-based report,
   * `date_of_transaction` would be a valid path.
   */
  rangeCriterion?: InputMaybe<Scalars['String']['input']>;
  /**
   * End of the time range used to filter `range_criterion`.
   *
   * This value cannot be set without a criteria.
   */
  rangeEnd?: InputMaybe<ReportScheduleRelativeTime>;
  /**
   * Start of the time range used to filter `range_criterion`.
   *
   * This value cannot be set without a criteria.
   */
  rangeStart?: InputMaybe<ReportScheduleRelativeTime>;
};

/** Autogenerated return type of UpdateReportSchedule. */
export type UpdateReportSchedulePayload = {
  __typename?: 'UpdateReportSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  reportSchedule?: Maybe<ReportSchedule>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateSchedule */
export type UpdateScheduleInput = {
  attributes: UpdateScheduleInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  scheduleId: Scalars['GlobalID']['input'];
};

export type UpdateScheduleInputAttributes = {
  definition?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  timezone?: InputMaybe<Scalars['TimezoneID']['input']>;
};

/** Autogenerated return type of UpdateSchedule. */
export type UpdateSchedulePayload = {
  __typename?: 'UpdateSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  schedule?: Maybe<Schedule>;
  status: MutationStatus;
};

export type UpdateSelectCustomFieldAttributesInput = {
  dataSourceId?: InputMaybe<Scalars['GlobalID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateSelectCustomField */
export type UpdateSelectCustomFieldInput = {
  attributes: UpdateSelectCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateSelectCustomField. */
export type UpdateSelectCustomFieldPayload = {
  __typename?: 'UpdateSelectCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateSpendLimit */
export type UpdateSpendLimitInput = {
  attributes: UpdateSpendLimitInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  spendLimitId: Scalars['GlobalID']['input'];
};

export type UpdateSpendLimitInputAttributes = {
  amountCents: Scalars['BigInt']['input'];
  earlyWarningAmountCents: Scalars['BigInt']['input'];
};

/** Autogenerated return type of UpdateSpendLimit. */
export type UpdateSpendLimitPayload = {
  __typename?: 'UpdateSpendLimitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  spendLimit?: Maybe<SpendLimit>;
  status: MutationStatus;
};

export type UpdateStaticDataValuesInputAttributes = {
  dataValueId: Scalars['GlobalID']['input'];
  value: Scalars['String']['input'];
};

export type UpdateStringCustomFieldValuesInputAttributes = {
  id: Scalars['GlobalID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTextAreaCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  validationRegex?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateTextAreaCustomField */
export type UpdateTextAreaCustomFieldInput = {
  attributes: UpdateTextAreaCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateTextAreaCustomField. */
export type UpdateTextAreaCustomFieldPayload = {
  __typename?: 'UpdateTextAreaCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateTextCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  validationRegex?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateTextCustomField */
export type UpdateTextCustomFieldInput = {
  attributes: UpdateTextCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateTextCustomField. */
export type UpdateTextCustomFieldPayload = {
  __typename?: 'UpdateTextCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

export type UpdateUrlCustomFieldAttributesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  includeInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** Whether users are required to fill this value on any form it's used on. */
  required?: InputMaybe<Scalars['Boolean']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  /**
   * Set a preferred width on custom fields, with value between 1-4 representing the columns in the responsive grid.
   * The default value is 2.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateUrlCustomField */
export type UpdateUrlCustomFieldInput = {
  attributes: UpdateUrlCustomFieldAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFieldId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateUrlCustomField. */
export type UpdateUrlCustomFieldPayload = {
  __typename?: 'UpdateUrlCustomFieldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customField?: Maybe<CustomField>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateUserNotificationSetting */
export type UpdateUserNotificationSettingInput = {
  activityKey: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  target: NotificationTarget;
};

/** Autogenerated return type of UpdateUserNotificationSetting. */
export type UpdateUserNotificationSettingPayload = {
  __typename?: 'UpdateUserNotificationSettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  setting?: Maybe<NotificationSetting>;
  status: MutationStatus;
};

export type UpdateUserProfileAttributesInput = {
  customFieldValues?: InputMaybe<UpdatableCustomFieldValueInputAttributes>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['GlobalID']['input']>;
  organisationUnitId?: InputMaybe<Scalars['GlobalID']['input']>;
  profileImageBlobId?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Autogenerated input type of UpdateUserProfile */
export type UpdateUserProfileInput = {
  attributes: UpdateUserProfileAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateUserProfile. */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<ErrorMessage>>;
  status: MutationStatus;
  user: User;
};

export type UpdateUserSettingsAttributesInput = {
  appearance?: InputMaybe<UpdateAppearanceSettingInputAttributes>;
  localisation?: InputMaybe<UpdateLocalisationSettingInputAttributes>;
};

/** Autogenerated input type of UpdateUserSettings */
export type UpdateUserSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  settings: UpdateUserSettingsAttributesInput;
};

/** Autogenerated return type of UpdateUserSettings. */
export type UpdateUserSettingsPayload = {
  __typename?: 'UpdateUserSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  settings?: Maybe<UserSettings>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateWorkflowAction */
export type UpdateWorkflowActionInput = {
  attributes: UpdateFlowStepActionInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  flowActionId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateWorkflowAction. */
export type UpdateWorkflowActionPayload = {
  __typename?: 'UpdateWorkflowActionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  flowAction?: Maybe<FlowStepAction>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateWorkflowCondition */
export type UpdateWorkflowConditionInput = {
  attributes: UpdateFlowStepConditionInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  flowConditionId: Scalars['GlobalID']['input'];
};

/** Autogenerated return type of UpdateWorkflowCondition. */
export type UpdateWorkflowConditionPayload = {
  __typename?: 'UpdateWorkflowConditionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  flowCondition?: Maybe<FlowStepCondition>;
  status: MutationStatus;
};

/** Autogenerated input type of UpdateWorkflow */
export type UpdateWorkflowInput = {
  attributes: UpdateWorkflowInputAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  workflowId: Scalars['GlobalID']['input'];
};

export type UpdateWorkflowInputAttributes = {
  firstStepId?: InputMaybe<Scalars['GlobalID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateWorkflow. */
export type UpdateWorkflowPayload = {
  __typename?: 'UpdateWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ErrorMessage>;
  status: MutationStatus;
  workflow?: Maybe<Workflow>;
};

export type UrlCustomField = Archivable &
  Auditable &
  CustomField &
  GlobalIdField &
  HasActivities &
  ManuallyOrderable &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'UrlCustomField';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy: User;
    description?: Maybe<Scalars['String']['output']>;
    formableClass: CustomFieldFormableClass;
    formableParent?: Maybe<FormableParent>;
    /** Icon to use in the UI */
    iconName?: Maybe<Scalars['String']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    includeInSearch: Scalars['Boolean']['output'];
    inputType: CustomFieldInputType;
    label: Scalars['String']['output'];
    /** Placeholder text to display on inputs for text and integer fields. */
    placeholder?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    relationExists: Scalars['Boolean']['output'];
    required: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    tooltip?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    validationRequiredValue?: Maybe<CustomFieldValidator>;
    valueType: CustomFieldValueType;
    viewerPermissions: CustomFieldRecordViewerPermissions;
    /** Shows the preferred width for custom fields, with value between 1-4 representing the columns in the responsive grid. */
    width: Scalars['Int']['output'];
  };

export type UrlCustomFieldActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type UrlCustomFieldAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type UrlCustomFieldRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum UrlScheme {
  Http = 'HTTP',
  Https = 'HTTPS',
}

export type User = Actor &
  Archivable &
  Auditable &
  CustomFieldValuesParent &
  GlobalIdField &
  HasActivities &
  Node &
  ReferenceAssociable &
  RelationExists &
  Timestamps & {
    __typename?: 'User';
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * Returns all audits of the object.
     *
     * The exception is draftable objects:
     *  - If the object is draft -- no audits will be returned
     *  - If the object is submitted -- only audits created after the submit date will be returned.
     *   Setting createdAfter before the submit date will have no effect.
     */
    audits?: Maybe<AuditConnection>;
    coverImage?: Maybe<Attachment>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * Get a summary of the user's current spending over the course of the past day, week, and month.
     *
     * The spend is always summed-up and converted to the viewer's preferred display currency.
     * Since currency conversion is done with current rates, the numbers will vary as exchange rates vary.
     */
    currentSpend: Array<SpendSummary>;
    customFieldValues?: Maybe<Array<CustomFieldValue>>;
    deviceRegistrations: Array<DeviceRegistration>;
    email: Scalars['String']['output'];
    firstName?: Maybe<Scalars['String']['output']>;
    /**
     * *First* and/or *Last* names combined, e.g. John Smith.
     *
     * Blank name components are removed, so with a blank last name,
     * the full name would just be "John".
     */
    fullName?: Maybe<Scalars['String']['output']>;
    /** If the user has a PIN set, which is required for virtual card delivery via iframe */
    hasCardDeliveryPin?: Maybe<Scalars['Boolean']['output']>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    /**
     * Users with `manage_account` or `manage_users` roles can see all users' `is2faEnabled` status.
     * Other users can see only their `is2faEnabled`.
     */
    is2faEnabled?: Maybe<Scalars['Boolean']['output']>;
    isSystemUser: Scalars['Boolean']['output'];
    lastName?: Maybe<Scalars['String']['output']>;
    location?: Maybe<Location>;
    /**
     * When the user was created by the supplier account onboarding process,
     * the user will have a onboarding contact. The field shows the status of the onboarding process
     * on Penneo. If the user does not have any supplier onboarding contact, the field will be `COMPLETE`.
     */
    onboardingStatus?: Maybe<SupplierOnboardingContactOnboardingStatus>;
    organisationUnit: OrganisationUnit;
    parsedId?: Maybe<IParsedGlobalId>;
    profileImage?: Maybe<Attachment>;
    referenceAssociations?: Maybe<ReferenceAssociationConnection>;
    relationExists: Scalars['Boolean']['output'];
    securityRoles: Array<UserSecurityRole>;
    spendLimits: SpendLimitConnection;
    status: UserStatus;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    viewerPermissions: UserRecordViewerPermissions;
  };

export type UserActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type UserAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuditSortInput>>;
};

export type UserCustomFieldValuesArgs = {
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserReferenceAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type UserSpendLimitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserCollectionViewerPermissions = {
  __typename?: 'UserCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: UserCollectionViewerPermissions;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserRecordViewerPermissions = {
  __typename?: 'UserRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  createDelegationForUser: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  disable2fa: Scalars['Boolean']['output'];
  enable2fa: Scalars['Boolean']['output'];
  invite: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  showArchivedCustomFields?: Maybe<Scalars['Boolean']['output']>;
  showEmptyCustomFields?: Maybe<Scalars['Boolean']['output']>;
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateCustomFieldValues: Scalars['Boolean']['output'];
  updateNotificationSettings: Scalars['Boolean']['output'];
  updatePermissions: Scalars['Boolean']['output'];
};

export enum UserSecurityRole {
  Approve = 'APPROVE',
  Finance = 'FINANCE',
  ManageAccount = 'MANAGE_ACCOUNT',
  ManageSuppliers = 'MANAGE_SUPPLIERS',
  ManageUsers = 'MANAGE_USERS',
  Report = 'REPORT',
  Request = 'REQUEST',
}

export type UserSettings = {
  __typename?: 'UserSettings';
  appearance: AppearanceSettings;
  localisation: LocalisationSettings;
  notifications: Array<NotificationSetting>;
};

export type UserSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  organisationUnitName?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Invalid = 'INVALID',
  Invited = 'INVITED',
  Locked = 'LOCKED',
}

export type UsersImportMappingsAttributesInput = {
  customFieldValues?: InputMaybe<Array<CustomFieldValuesUsersImportMappingsAttributesInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationUnit?: InputMaybe<Scalars['String']['input']>;
};

export type Value = {
  __typename?: 'Value';
  /**
   * Representing audited changes for the record. The value can be returned in the format:
   * - Audited title string, when the change is associated with a record.
   * - Date as a formatted string. Example '2023-03-20'.
   * - Datetime as a formatted string. Example '2023-03-20 12:30:00'
   * - Money as formatted string in universal format. Example 'DKK 1,234.56'.
   * - Numerical values not related to money or associations.
   * - Simple string values.
   */
  description?: Maybe<Scalars['String']['output']>;
  /** This ID is populated when a change is associated with a record. */
  id?: Maybe<Scalars['GlobalID']['output']>;
};

export type ValueTypes = Category | CostCentre | OrganisationUnit | User;

export type VirtualCard = GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'VirtualCard';
    /**
     * The card is about to expire if expiration date in less than 7 days for sinlge use card
     * and less than 30 days for multi use card
     */
    aboutToExpire?: Maybe<Scalars['Boolean']['output']>;
    activities?: Maybe<ActivityConnection>;
    /**
     * The actual expiration date determined by the card issuer.
     * It may match the `requested_expiry_date` or be slightly off, depending on what
     * the card issuer supports.
     * This value will not be present until a card is issued.
     */
    actualExpiryDate?: Maybe<Scalars['ISO8601Date']['output']>;
    actualLimit?: Maybe<Money>;
    actualLimitBaseAmount?: Maybe<Money>;
    actualNameOnCard?: Maybe<Scalars['String']['output']>;
    cardNumberLastFour?: Maybe<Scalars['String']['output']>;
    cardUseType: VirtualCardCardUseType;
    createdAt: Scalars['ISO8601DateTime']['output'];
    currency?: Maybe<Currency>;
    deliveryMethod: VirtualCardDeliveryMethod;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    issuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    issuedTo: User;
    issuerIntegration?: Maybe<IssuerIntegration>;
    /**
     * The card is low amount if the remaining amount is less than 20%
     * of the actual limit and the card is an active multi card.
     */
    lowAmount: Scalars['Boolean']['output'];
    purchaseOrder: PurchaseOrder;
    relationExists: Scalars['Boolean']['output'];
    remainingAmount?: Maybe<Money>;
    remainingBaseAmount?: Maybe<Money>;
    /** The expiration date we requested as per configuration and request data. */
    requestedExpiryDate: Scalars['ISO8601Date']['output'];
    requestedLimit?: Maybe<Money>;
    requestedNameOnCard: Scalars['String']['output'];
    revokeComment?: Maybe<Scalars['String']['output']>;
    revokedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    revokedBy?: Maybe<User>;
    smsDeliveryStatus: VirtualCardSmsDeliveryStatus;
    spentAmount: Money;
    spentBaseAmount: Money;
    status: VirtualCardStatus;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** This data is not 'verified' by the issuers, but our assumption that they followed our requests. */
    validUntil: Scalars['ISO8601Date']['output'];
    viewerPermissions: VirtualCardRecordViewerPermissions;
  };

export type VirtualCardActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type VirtualCardRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export enum VirtualCardCardUseType {
  Multi = 'MULTI',
  Single = 'SINGLE',
}

export type VirtualCardCollectionViewerPermissions = {
  __typename?: 'VirtualCardCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for VirtualCard. */
export type VirtualCardConnection = {
  __typename?: 'VirtualCardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VirtualCardEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<VirtualCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: VirtualCardCollectionViewerPermissions;
};

export enum VirtualCardDeliveryMethod {
  Iframe = 'IFRAME',
  None = 'NONE',
  Sms = 'SMS',
}

/** An edge in a connection. */
export type VirtualCardEdge = {
  __typename?: 'VirtualCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<VirtualCard>;
};

export type VirtualCardInAppDeliveryInfo = {
  __typename?: 'VirtualCardInAppDeliveryInfo';
  iframeUrl: Url;
};

export type VirtualCardPaymentSettings = {
  __typename?: 'VirtualCardPaymentSettings';
  multiUseCard: MultiUseCard;
  singleUseCard: SingleUseCard;
};

export type VirtualCardPaymentsPaymentAccountSettingsInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Default lifetime in days of a virtual multi-use credit card. */
  multiUseCardDefaultTtlDays?: InputMaybe<Scalars['Int']['input']>;
  /** Max lifetime in days of a virtual multi-use credit card. */
  multiUseCardMaxTtlDays?: InputMaybe<Scalars['Int']['input']>;
  /** Default lifetime in days of a virtual credit card. */
  singleUseCardDefaultTtlDays?: InputMaybe<Scalars['Int']['input']>;
  /** Max lifetime in days of a virtual credit card */
  singleUseCardMaxTtlDays?: InputMaybe<Scalars['Int']['input']>;
};

export type VirtualCardRecordViewerPermissions = {
  __typename?: 'VirtualCardRecordViewerPermissions';
  destroy: Scalars['Boolean']['output'];
  initiateIframeDelivery: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  revoke: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export enum VirtualCardSmsDeliveryStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  NotScheduled = 'NOT_SCHEDULED',
  Sent = 'SENT',
}

export type VirtualCardSortInput = {
  actualExpiryDate?: InputMaybe<SortDirection>;
  actualLimit?: InputMaybe<SortDirection>;
  actualNameOnCard?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum VirtualCardStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Revoked = 'REVOKED',
  RevokePending = 'REVOKE_PENDING',
}

export type Workflow = Archivable &
  GlobalIdField &
  HasActivities &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'Workflow';
    actions: FlowStepActionConnection;
    activities?: Maybe<ActivityConnection>;
    /**
     * Whether or not the record is archived.
     * Archived records are usually omitted from query results unless specifically included.
     */
    archived: Scalars['Boolean']['output'];
    archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    availableFlowSteps: Array<FlowStepType>;
    baseResource: WorkflowBaseResourceName;
    conditions: FlowStepConditionConnection;
    createdAt: Scalars['ISO8601DateTime']['output'];
    createdBy?: Maybe<User>;
    /**
     * Workflows are triggered by events based on baseResource and triggerEvent, since multiple fields
     * can have the same value, the enabledAt field indicates which workflow will be used when the trigger
     * event occurs. Only one workflow can have enabledAt value set for a [baseResource, triggerEvent] pair.
     */
    enabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    firstStep?: Maybe<FlowStep>;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    isMaster: Scalars['Boolean']['output'];
    label: Scalars['String']['output'];
    master?: Maybe<Workflow>;
    publishNotes?: Maybe<Scalars['String']['output']>;
    publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    publishedBy?: Maybe<User>;
    relationExists: Scalars['Boolean']['output'];
    softValidation: WorkflowSoftValidation;
    triggerEvent: TriggerEvent;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    updatedBy?: Maybe<User>;
    version: Scalars['Int']['output'];
    versions: WorkflowConnection;
    viewerPermissions: WorkflowRecordViewerPermissions;
    workflowRuns: WorkflowRunConnection;
  };

export type WorkflowActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<FlowStepType>>;
};

export type WorkflowActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ActivitySortInput>>;
};

export type WorkflowConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

export type WorkflowVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkflowSortInput>>;
};

export type WorkflowWorkflowRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowBaseResource = {
  __typename?: 'WorkflowBaseResource';
  resource: WorkflowBaseResourceName;
  triggerEvents: Array<TriggerEvent>;
};

export enum WorkflowBaseResourceName {
  PurchaseRequestItem = 'PURCHASE_REQUEST_ITEM',
  SupplierRequest = 'SUPPLIER_REQUEST',
}

export type WorkflowCollectionViewerPermissions = {
  __typename?: 'WorkflowCollectionViewerPermissions';
  create: Scalars['Boolean']['output'];
  index: Scalars['Boolean']['output'];
};

/** The connection type for Workflow. */
export type WorkflowConnection = {
  __typename?: 'WorkflowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkflowEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Workflow>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewerPermissions: WorkflowCollectionViewerPermissions;
};

/** An edge in a connection. */
export type WorkflowEdge = {
  __typename?: 'WorkflowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Workflow>;
};

export type WorkflowRecord = {
  workflowRuns?: Maybe<WorkflowRunConnection>;
};

export type WorkflowRecordWorkflowRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowRecordViewerPermissions = {
  __typename?: 'WorkflowRecordViewerPermissions';
  archive: Scalars['Boolean']['output'];
  destroy: Scalars['Boolean']['output'];
  disable: Scalars['Boolean']['output'];
  enable: Scalars['Boolean']['output'];
  listActivities: Scalars['Boolean']['output'];
  publish: Scalars['Boolean']['output'];
  unarchive: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type WorkflowRun = GlobalIdField &
  Node &
  RelationExists &
  Timestamps & {
    __typename?: 'WorkflowRun';
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    createdAt: Scalars['ISO8601DateTime']['output'];
    failedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    flowStepResults: FlowStepResultConnection;
    /** GlobalID of the object. */
    id: Scalars['GlobalID']['output'];
    record?: Maybe<WorkflowRecord>;
    relationExists: Scalars['Boolean']['output'];
    status: WorkflowRunStatus;
    updatedAt: Scalars['ISO8601DateTime']['output'];
    workflow?: Maybe<Workflow>;
  };

export type WorkflowRunFlowStepResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowRunRelationExistsArgs = {
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
};

/** The connection type for WorkflowRun. */
export type WorkflowRunConnection = {
  __typename?: 'WorkflowRunConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkflowRunEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WorkflowRun>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WorkflowRunEdge = {
  __typename?: 'WorkflowRunEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WorkflowRun>;
};

export type WorkflowRunSortInput = {
  completedAt?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  workflowLabel?: InputMaybe<SortDirection>;
};

export enum WorkflowRunStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
}

/**
 * Performs a validation for specific actions on a record and returns error messages
 * indicating why the action could not be performed, or an empty array if everything is ok.
 */
export type WorkflowSoftValidation = {
  __typename?: 'WorkflowSoftValidation';
  publish: Array<ErrorMessage>;
};

export type WorkflowSortInput = {
  createdAt?: InputMaybe<SortDirection>;
  createdByName?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  publishedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum WorkflowStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum WorkflowTriggerEventKey {
  RequestItemApproved = 'REQUEST_ITEM_APPROVED',
  RequestItemCancelled = 'REQUEST_ITEM_CANCELLED',
  RequestItemRejected = 'REQUEST_ITEM_REJECTED',
  RequestItemSubmitted = 'REQUEST_ITEM_SUBMITTED',
  SupplierRequestApproved = 'SUPPLIER_REQUEST_APPROVED',
  SupplierRequestCancelled = 'SUPPLIER_REQUEST_CANCELLED',
  SupplierRequestRejected = 'SUPPLIER_REQUEST_REJECTED',
  SupplierRequestSubmitted = 'SUPPLIER_REQUEST_SUBMITTED',
}

type ReferenceRecord_Address_Fragment = { __typename?: 'Address'; id: string; formattedLines: Array<string> };

type ReferenceRecord_Category_Fragment = { __typename?: 'Category'; id: string; label: string };

type ReferenceRecord_CostCentre_Fragment = { __typename?: 'CostCentre'; id: string; label: string };

type ReferenceRecord_DataSourceItem_Fragment = { __typename?: 'DataSourceItem'; id: string; label: string };

type ReferenceRecord_IssuerIntegration_Fragment = { __typename?: 'IssuerIntegration'; id: string; description: string };

type ReferenceRecord_OrganisationUnit_Fragment = { __typename?: 'OrganisationUnit'; id: string; label: string };

type ReferenceRecord_User_Fragment = { __typename?: 'User'; id: string; fullName?: string };

export type ReferenceRecordFragment =
  | ReferenceRecord_Address_Fragment
  | ReferenceRecord_Category_Fragment
  | ReferenceRecord_CostCentre_Fragment
  | ReferenceRecord_DataSourceItem_Fragment
  | ReferenceRecord_IssuerIntegration_Fragment
  | ReferenceRecord_OrganisationUnit_Fragment
  | ReferenceRecord_User_Fragment;

export type CountCommentsQueryVariables = Exact<{
  parentId: Scalars['GlobalID']['input'];
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type CountCommentsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval'; id: string; connection: { __typename?: 'CommentConnection'; totalCount: number } }
    | {
        __typename?: 'ApprovalDelegation';
        id: string;
        connection: { __typename?: 'CommentConnection'; totalCount: number };
      }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        connection: { __typename?: 'CommentConnection'; totalCount: number };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document'; id: string; connection: { __typename?: 'CommentConnection'; totalCount: number } }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder'; id: string; connection: { __typename?: 'CommentConnection'; totalCount: number } }
    | {
        __typename?: 'PurchaseRequestItem';
        id: string;
        connection: { __typename?: 'CommentConnection'; totalCount: number };
      }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | {
        __typename?: 'SupplierRequest';
        id: string;
        connection: { __typename?: 'CommentConnection'; totalCount: number };
      }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexCommentsQueryVariables = Exact<{
  parentId: Scalars['GlobalID']['input'];
  startCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexCommentsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | {
        __typename?: 'Approval';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'ApprovalDelegation';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | {
        __typename?: 'Document';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | {
        __typename?: 'PurchaseOrder';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'PurchaseRequestItem';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | {
        __typename?: 'SupplierRequest';
        id: string;
        connection: {
          __typename?: 'CommentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CommentEdge';
            cursor: string;
            node?: {
              __typename?: 'Comment';
              body?: string;
              createdAt: string;
              id: string;
              key?: string;
              updatedAt: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexFlowStepConnectionsPanelInputsFalseQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexFlowStepConnectionsPanelInputsFalseQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | {
        __typename?: 'FlowActionApprove';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionComment';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionCreatePurchaseOrder';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionReject';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexFlowStepConnectionsPanelInputsTrueQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexFlowStepConnectionsPanelInputsTrueQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | {
        __typename?: 'FlowActionApprove';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionComment';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionCreatePurchaseOrder';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowActionReject';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        connection?: {
          __typename?: 'FlowStepConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepEdge';
            cursor: string;
            node?:
              | { __typename?: 'FlowActionApprove'; id: string }
              | { __typename?: 'FlowActionComment'; id: string }
              | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
              | { __typename?: 'FlowActionGetApproval'; id: string }
              | { __typename?: 'FlowActionReject'; id: string }
              | { __typename?: 'FlowStepCondition'; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PickFlowStepOutputsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickFlowStepOutputsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | {
        __typename?: 'FlowActionApprove';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        };
      }
    | {
        __typename?: 'FlowActionComment';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        };
      }
    | {
        __typename?: 'FlowActionCreatePurchaseOrder';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        };
      }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        };
      }
    | {
        __typename?: 'FlowActionReject';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        };
      }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

type AccountPreview_BuyerAccount_Fragment = {
  __typename?: 'BuyerAccount';
  accountType: AccountUseType;
  companyName: string;
  id: string;
  publicEmail?: string;
  publicPhone?: string;
  publicWebsite?: string;
  vatNumber?: string;
  address?: {
    __typename?: 'Address';
    city?: string;
    formattedLines: Array<string>;
    id: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
};

type AccountPreview_SupplierAccount_Fragment = {
  __typename?: 'SupplierAccount';
  accountType: AccountUseType;
  companyName: string;
  id: string;
  publicEmail?: string;
  publicPhone?: string;
  publicWebsite?: string;
  vatNumber?: string;
  address?: {
    __typename?: 'Address';
    city?: string;
    formattedLines: Array<string>;
    id: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
};

export type AccountPreviewFragment = AccountPreview_BuyerAccount_Fragment | AccountPreview_SupplierAccount_Fragment;

export type ActivityFragment = {
  __typename?: 'Activity';
  id: string;
  createdAt: string;
  displayStyle: ActivityDisplayStyle;
  icon: ActivityIcon;
  key: string;
  updatedAt: string;
  defaultUrl?: { __typename?: 'URL'; path?: string; query?: string };
  eventSubject?:
    | { __typename?: 'Approval' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'Comment'; body?: string; id: string }
    | { __typename?: 'Document' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' };
  fragments: Array<
    | {
        __typename?: 'ReferenceFragment';
        urn: string;
        text: string;
        type: StringFragmentFragmentType;
        url?: { __typename?: 'URL'; path?: string; query?: string };
      }
    | { __typename?: 'TextFragment'; text: string; type: StringFragmentFragmentType }
  >;
};

type ApprovableResourceTitle_PurchaseRequestItem_Fragment = {
  __typename?: 'PurchaseRequestItem';
  description?: string;
  id: string;
};

type ApprovableResourceTitle_SupplierRequest_Fragment = {
  __typename?: 'SupplierRequest';
  id: string;
  companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
};

export type ApprovableResourceTitleFragment =
  | ApprovableResourceTitle_PurchaseRequestItem_Fragment
  | ApprovableResourceTitle_SupplierRequest_Fragment;

export type ApprovalsMenuItemFragment = {
  __typename?: 'Approval';
  createdAt: string;
  id: string;
  approvable?:
    | {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        purchaseType: PurchaseType;
        status: PurchaseRequestItemStatus;
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          virtualCard?: {
            __typename?: 'VirtualCard';
            actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
            spentBaseAmount: {
              __typename?: 'Money';
              cents: string;
              universalFormat: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
          };
        };
      }
    | {
        __typename?: 'SupplierRequest';
        approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
        id: string;
        companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
      };
};

type ArchivablePayload_ApprovalDelegation_Fragment = {
  __typename?: 'ApprovalDelegation';
  id: string;
  viewerPermissions: {
    __typename?: 'ApprovalDelegationRecordViewerPermissions';
    archive: boolean;
    destroy: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_ApproverCustomField_Fragment = {
  __typename?: 'ApproverCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_AttachmentCustomField_Fragment = {
  __typename?: 'AttachmentCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_BankAccount_Fragment = { __typename?: 'BankAccount' };

type ArchivablePayload_Category_Fragment = {
  __typename?: 'Category';
  id: string;
  viewerPermissions: {
    __typename?: 'CategoryRecordViewerPermissions';
    archive: boolean;
    createChild?: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_CategoryCustomField_Fragment = {
  __typename?: 'CategoryCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_CategorySet_Fragment = {
  __typename?: 'CategorySet';
  id: string;
  viewerPermissions: {
    __typename?: 'CategorySetRecordViewerPermissions';
    archive: boolean;
    import: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_CheckboxCustomField_Fragment = {
  __typename?: 'CheckboxCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_CostCentre_Fragment = {
  __typename?: 'CostCentre';
  id: string;
  viewerPermissions: {
    __typename?: 'CostCentreRecordViewerPermissions';
    archive: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_CostCentreCustomField_Fragment = {
  __typename?: 'CostCentreCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_DataSource_Fragment = {
  __typename?: 'DataSource';
  id: string;
  viewerPermissions: {
    __typename?: 'DataSourceRecordViewerPermissions';
    archive: boolean;
    destroy: boolean;
    import: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_DataSourceItem_Fragment = {
  __typename?: 'DataSourceItem';
  id: string;
  viewerPermissions: {
    __typename?: 'DataSourceItemRecordViewerPermissions';
    archive: boolean;
    destroy: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_DataView_Fragment = {
  __typename?: 'DataView';
  id: string;
  viewerPermissions: {
    __typename?: 'DataViewRecordViewerPermissions';
    archive: boolean;
    duplicate: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_DateCustomField_Fragment = {
  __typename?: 'DateCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_Document_Fragment = {
  __typename?: 'Document';
  id: string;
  viewerPermissions: {
    __typename?: 'DocumentRecordViewerPermissions';
    archive: boolean;
    download: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_IntegerCustomField_Fragment = {
  __typename?: 'IntegerCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_IssuerIntegration_Fragment = {
  __typename?: 'IssuerIntegration';
  id: string;
  viewerPermissions: {
    __typename?: 'IssuerIntegrationRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_Location_Fragment = {
  __typename?: 'Location';
  id: string;
  viewerPermissions: {
    __typename?: 'LocationRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_MoneyCustomField_Fragment = {
  __typename?: 'MoneyCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_OrganisationUnit_Fragment = {
  __typename?: 'OrganisationUnit';
  id: string;
  viewerPermissions: {
    __typename?: 'OrganisationUnitRecordViewerPermissions';
    archive: boolean;
    createChild?: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_OrganisationUnitCustomField_Fragment = {
  __typename?: 'OrganisationUnitCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_ReportSchedule_Fragment = {
  __typename?: 'ReportSchedule';
  id: string;
  viewerPermissions: {
    __typename?: 'ReportScheduleRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_SelectCustomField_Fragment = {
  __typename?: 'SelectCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_SupplierOnboardingContact_Fragment = { __typename?: 'SupplierOnboardingContact' };

type ArchivablePayload_TextAreaCustomField_Fragment = {
  __typename?: 'TextAreaCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_TextCustomField_Fragment = {
  __typename?: 'TextCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_UrlCustomField_Fragment = {
  __typename?: 'UrlCustomField';
  id: string;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type ArchivablePayload_User_Fragment = {
  __typename?: 'User';
  id: string;
  viewerPermissions: {
    __typename?: 'UserRecordViewerPermissions';
    archive: boolean;
    createDelegationForUser: boolean;
    disable2fa: boolean;
    enable2fa: boolean;
    invite: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
    updatePermissions: boolean;
    updateNotificationSettings: boolean;
  };
};

type ArchivablePayload_Workflow_Fragment = {
  __typename?: 'Workflow';
  id: string;
  viewerPermissions: {
    __typename?: 'WorkflowRecordViewerPermissions';
    archive: boolean;
    destroy: boolean;
    disable: boolean;
    enable: boolean;
    publish: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type ArchivablePayloadFragment =
  | ArchivablePayload_ApprovalDelegation_Fragment
  | ArchivablePayload_ApproverCustomField_Fragment
  | ArchivablePayload_AttachmentCustomField_Fragment
  | ArchivablePayload_BankAccount_Fragment
  | ArchivablePayload_Category_Fragment
  | ArchivablePayload_CategoryCustomField_Fragment
  | ArchivablePayload_CategorySet_Fragment
  | ArchivablePayload_CheckboxCustomField_Fragment
  | ArchivablePayload_CostCentre_Fragment
  | ArchivablePayload_CostCentreCustomField_Fragment
  | ArchivablePayload_DataSource_Fragment
  | ArchivablePayload_DataSourceItem_Fragment
  | ArchivablePayload_DataView_Fragment
  | ArchivablePayload_DateCustomField_Fragment
  | ArchivablePayload_Document_Fragment
  | ArchivablePayload_IntegerCustomField_Fragment
  | ArchivablePayload_IssuerIntegration_Fragment
  | ArchivablePayload_Location_Fragment
  | ArchivablePayload_MoneyCustomField_Fragment
  | ArchivablePayload_OrganisationUnit_Fragment
  | ArchivablePayload_OrganisationUnitCustomField_Fragment
  | ArchivablePayload_ReportSchedule_Fragment
  | ArchivablePayload_SelectCustomField_Fragment
  | ArchivablePayload_SupplierOnboardingContact_Fragment
  | ArchivablePayload_TextAreaCustomField_Fragment
  | ArchivablePayload_TextCustomField_Fragment
  | ArchivablePayload_UrlCustomField_Fragment
  | ArchivablePayload_User_Fragment
  | ArchivablePayload_Workflow_Fragment;

export type AttachmentFileFragment = { __typename?: 'Attachment'; downloadUrl: string; filename: string; id: string };

export type AuditFragment = {
  __typename?: 'Audit';
  action?: AuditAction;
  createdAt: string;
  auditable:
    | { __typename?: 'ApproverCustomField'; id: string }
    | {
        __typename?: 'AssociationCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | {
        __typename?: 'AttachmentCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | {
        __typename?: 'BooleanCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'Category'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | {
        __typename?: 'DateTimeCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | {
        __typename?: 'IntegerCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | { __typename?: 'MoneyCustomField'; id: string }
    | {
        __typename?: 'MoneyCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | { __typename?: 'OrganisationUnit'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | {
        __typename?: 'StringCustomFieldValue';
        id: string;
        customField:
          | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
          | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
      }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string };
  auditedChanges: Array<{
    __typename?: 'AuditedChange';
    name: string;
    newValue?: { __typename?: 'Value'; description?: string; id?: string };
    oldValue?: { __typename?: 'Value'; description?: string; id?: string };
  }>;
  user?: { __typename?: 'User'; fullName?: string; id: string };
};

export type AvailableCurrencyFragment = {
  __typename?: 'Currency';
  code: CurrencyCode;
  decimalPlaces: number;
  name: string;
};

export type CardAuthorisationsIndexNodeFragment = {
  __typename?: 'CardAuthorisation';
  date: string;
  declineCode?: string;
  declined: boolean;
  declineMessage?: string;
  id: string;
  merchantName?: string;
  amount: { __typename?: 'Money'; universalFormat: string };
  virtualCard?: {
    __typename?: 'VirtualCard';
    cardNumberLastFour?: string;
    id: string;
    status: VirtualCardStatus;
    purchaseOrder: {
      __typename?: 'PurchaseOrder';
      id: string;
      purchaseRequestItem: {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
      };
    };
  };
};

export type CategoryFragment = {
  __typename?: 'Category';
  archived: boolean;
  childrenCount: number;
  code: string;
  id: string;
  label: string;
  parentId?: string;
  viewerPermissions: {
    __typename?: 'CategoryRecordViewerPermissions';
    archive: boolean;
    createChild?: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type CommentFragment = {
  __typename?: 'Comment';
  body?: string;
  createdAt: string;
  id: string;
  key?: string;
  updatedAt: string;
  user: {
    __typename?: 'User';
    fullName?: string;
    id: string;
    profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
  };
};

type Commentable_Approval_Fragment = {
  __typename?: 'Approval';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

type Commentable_ApprovalDelegation_Fragment = {
  __typename?: 'ApprovalDelegation';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

type Commentable_CardSubscription_Fragment = {
  __typename?: 'CardSubscription';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

type Commentable_Document_Fragment = {
  __typename?: 'Document';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

type Commentable_PurchaseOrder_Fragment = {
  __typename?: 'PurchaseOrder';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

type Commentable_PurchaseRequestItem_Fragment = {
  __typename?: 'PurchaseRequestItem';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

type Commentable_SupplierRequest_Fragment = {
  __typename?: 'SupplierRequest';
  connection: {
    __typename?: 'CommentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node?: {
        __typename?: 'Comment';
        body?: string;
        createdAt: string;
        id: string;
        key?: string;
        updatedAt: string;
        user: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type CommentableFragment =
  | Commentable_Approval_Fragment
  | Commentable_ApprovalDelegation_Fragment
  | Commentable_CardSubscription_Fragment
  | Commentable_Document_Fragment
  | Commentable_PurchaseOrder_Fragment
  | Commentable_PurchaseRequestItem_Fragment
  | Commentable_SupplierRequest_Fragment;

export type CountryFragment = { __typename?: 'Country'; code: CountryCode; name: string };

export type CurrencyFragment = { __typename?: 'Currency'; code: CurrencyCode; name: string };

type CustomFieldPreview_ApproverCustomField_Fragment = {
  __typename?: 'ApproverCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_AttachmentCustomField_Fragment = {
  __typename?: 'AttachmentCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_CategoryCustomField_Fragment = {
  __typename?: 'CategoryCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_CheckboxCustomField_Fragment = {
  __typename?: 'CheckboxCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_CostCentreCustomField_Fragment = {
  __typename?: 'CostCentreCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_DateCustomField_Fragment = {
  __typename?: 'DateCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_IntegerCustomField_Fragment = {
  __typename?: 'IntegerCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_MoneyCustomField_Fragment = {
  __typename?: 'MoneyCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_OrganisationUnitCustomField_Fragment = {
  __typename?: 'OrganisationUnitCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_SelectCustomField_Fragment = {
  __typename?: 'SelectCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_TextAreaCustomField_Fragment = {
  __typename?: 'TextAreaCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_TextCustomField_Fragment = {
  __typename?: 'TextCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type CustomFieldPreview_UrlCustomField_Fragment = {
  __typename?: 'UrlCustomField';
  archived: boolean;
  archivedAt?: string;
  description?: string;
  formableClass: CustomFieldFormableClass;
  iconName?: string;
  id: string;
  includeInSearch: boolean;
  label: string;
  placeholder?: string;
  required: boolean;
  tooltip?: string;
  width: number;
  viewerPermissions: {
    __typename?: 'CustomFieldRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type CustomFieldPreviewFragment =
  | CustomFieldPreview_ApproverCustomField_Fragment
  | CustomFieldPreview_AttachmentCustomField_Fragment
  | CustomFieldPreview_CategoryCustomField_Fragment
  | CustomFieldPreview_CheckboxCustomField_Fragment
  | CustomFieldPreview_CostCentreCustomField_Fragment
  | CustomFieldPreview_DateCustomField_Fragment
  | CustomFieldPreview_IntegerCustomField_Fragment
  | CustomFieldPreview_MoneyCustomField_Fragment
  | CustomFieldPreview_OrganisationUnitCustomField_Fragment
  | CustomFieldPreview_SelectCustomField_Fragment
  | CustomFieldPreview_TextAreaCustomField_Fragment
  | CustomFieldPreview_TextCustomField_Fragment
  | CustomFieldPreview_UrlCustomField_Fragment;

type CustomFieldShow_ApproverCustomField_Fragment = {
  __typename?: 'ApproverCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_AttachmentCustomField_Fragment = {
  __typename?: 'AttachmentCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_CategoryCustomField_Fragment = {
  __typename?: 'CategoryCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_CheckboxCustomField_Fragment = {
  __typename?: 'CheckboxCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_CostCentreCustomField_Fragment = {
  __typename?: 'CostCentreCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_DateCustomField_Fragment = {
  __typename?: 'DateCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_IntegerCustomField_Fragment = {
  __typename?: 'IntegerCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_MoneyCustomField_Fragment = {
  __typename?: 'MoneyCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_OrganisationUnitCustomField_Fragment = {
  __typename?: 'OrganisationUnitCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_SelectCustomField_Fragment = {
  __typename?: 'SelectCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_TextAreaCustomField_Fragment = {
  __typename?: 'TextAreaCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_TextCustomField_Fragment = {
  __typename?: 'TextCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

type CustomFieldShow_UrlCustomField_Fragment = {
  __typename?: 'UrlCustomField';
  archived: boolean;
  description?: string;
  iconName?: string;
  id: string;
  inputType: CustomFieldInputType;
  label: string;
  placeholder?: string;
  position: number;
  required: boolean;
  slug: string;
  valueType: CustomFieldValueType;
  width: number;
};

export type CustomFieldShowFragment =
  | CustomFieldShow_ApproverCustomField_Fragment
  | CustomFieldShow_AttachmentCustomField_Fragment
  | CustomFieldShow_CategoryCustomField_Fragment
  | CustomFieldShow_CheckboxCustomField_Fragment
  | CustomFieldShow_CostCentreCustomField_Fragment
  | CustomFieldShow_DateCustomField_Fragment
  | CustomFieldShow_IntegerCustomField_Fragment
  | CustomFieldShow_MoneyCustomField_Fragment
  | CustomFieldShow_OrganisationUnitCustomField_Fragment
  | CustomFieldShow_SelectCustomField_Fragment
  | CustomFieldShow_TextAreaCustomField_Fragment
  | CustomFieldShow_TextCustomField_Fragment
  | CustomFieldShow_UrlCustomField_Fragment;

export type AttachmentCustomFieldValueFragment = {
  __typename?: 'AttachmentCustomFieldValue';
  files?: Array<{
    __typename?: 'Attachment';
    blobId: string;
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
  }>;
};

type CustomFieldValuePreview_AssociationCustomFieldValue_Fragment = {
  __typename?: 'AssociationCustomFieldValue';
  id: string;
  updatedAt: string;
  value:
    | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
    | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreview_AttachmentCustomFieldValue_Fragment = {
  __typename?: 'AttachmentCustomFieldValue';
  id: string;
  updatedAt: string;
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
  files?: Array<{
    __typename?: 'Attachment';
    blobId: string;
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
  }>;
};

type CustomFieldValuePreview_BooleanCustomFieldValue_Fragment = {
  __typename?: 'BooleanCustomFieldValue';
  id: string;
  updatedAt: string;
  valueBoolean?: boolean;
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreview_DateTimeCustomFieldValue_Fragment = {
  __typename?: 'DateTimeCustomFieldValue';
  id: string;
  updatedAt: string;
  valueDateTime?: string;
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreview_IntegerCustomFieldValue_Fragment = {
  __typename?: 'IntegerCustomFieldValue';
  id: string;
  updatedAt: string;
  valueInteger?: string;
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreview_MoneyCustomFieldValue_Fragment = {
  __typename?: 'MoneyCustomFieldValue';
  id: string;
  updatedAt: string;
  valueMoney?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreview_StringCustomFieldValue_Fragment = {
  __typename?: 'StringCustomFieldValue';
  id: string;
  updatedAt: string;
  valueString?: string;
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

export type CustomFieldValuePreviewFragment =
  | CustomFieldValuePreview_AssociationCustomFieldValue_Fragment
  | CustomFieldValuePreview_AttachmentCustomFieldValue_Fragment
  | CustomFieldValuePreview_BooleanCustomFieldValue_Fragment
  | CustomFieldValuePreview_DateTimeCustomFieldValue_Fragment
  | CustomFieldValuePreview_IntegerCustomFieldValue_Fragment
  | CustomFieldValuePreview_MoneyCustomFieldValue_Fragment
  | CustomFieldValuePreview_StringCustomFieldValue_Fragment;

type CustomFieldValuePreviewWithPermissions_AssociationCustomFieldValue_Fragment = {
  __typename?: 'AssociationCustomFieldValue';
  id: string;
  updatedAt: string;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  value:
    | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
    | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreviewWithPermissions_AttachmentCustomFieldValue_Fragment = {
  __typename?: 'AttachmentCustomFieldValue';
  id: string;
  updatedAt: string;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
  files?: Array<{
    __typename?: 'Attachment';
    blobId: string;
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
  }>;
};

type CustomFieldValuePreviewWithPermissions_BooleanCustomFieldValue_Fragment = {
  __typename?: 'BooleanCustomFieldValue';
  id: string;
  updatedAt: string;
  valueBoolean?: boolean;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreviewWithPermissions_DateTimeCustomFieldValue_Fragment = {
  __typename?: 'DateTimeCustomFieldValue';
  id: string;
  updatedAt: string;
  valueDateTime?: string;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreviewWithPermissions_IntegerCustomFieldValue_Fragment = {
  __typename?: 'IntegerCustomFieldValue';
  id: string;
  updatedAt: string;
  valueInteger?: string;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreviewWithPermissions_MoneyCustomFieldValue_Fragment = {
  __typename?: 'MoneyCustomFieldValue';
  id: string;
  updatedAt: string;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  valueMoney?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

type CustomFieldValuePreviewWithPermissions_StringCustomFieldValue_Fragment = {
  __typename?: 'StringCustomFieldValue';
  id: string;
  updatedAt: string;
  valueString?: string;
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  customField:
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      };
};

export type CustomFieldValuePreviewWithPermissionsFragment =
  | CustomFieldValuePreviewWithPermissions_AssociationCustomFieldValue_Fragment
  | CustomFieldValuePreviewWithPermissions_AttachmentCustomFieldValue_Fragment
  | CustomFieldValuePreviewWithPermissions_BooleanCustomFieldValue_Fragment
  | CustomFieldValuePreviewWithPermissions_DateTimeCustomFieldValue_Fragment
  | CustomFieldValuePreviewWithPermissions_IntegerCustomFieldValue_Fragment
  | CustomFieldValuePreviewWithPermissions_MoneyCustomFieldValue_Fragment
  | CustomFieldValuePreviewWithPermissions_StringCustomFieldValue_Fragment;

type CustomFieldValuePreviewV2_AssociationCustomFieldValue_Fragment = {
  __typename?: 'AssociationCustomFieldValue';
  id: string;
  value:
    | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
    | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
    | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
};

type CustomFieldValuePreviewV2_AttachmentCustomFieldValue_Fragment = {
  __typename?: 'AttachmentCustomFieldValue';
  id: string;
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
  files?: Array<{
    __typename?: 'Attachment';
    blobId: string;
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
  }>;
};

type CustomFieldValuePreviewV2_BooleanCustomFieldValue_Fragment = {
  __typename?: 'BooleanCustomFieldValue';
  id: string;
  valueBoolean?: boolean;
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
};

type CustomFieldValuePreviewV2_DateTimeCustomFieldValue_Fragment = {
  __typename?: 'DateTimeCustomFieldValue';
  id: string;
  valueDateTime?: string;
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
};

type CustomFieldValuePreviewV2_IntegerCustomFieldValue_Fragment = {
  __typename?: 'IntegerCustomFieldValue';
  id: string;
  valueInteger?: string;
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
};

type CustomFieldValuePreviewV2_MoneyCustomFieldValue_Fragment = {
  __typename?: 'MoneyCustomFieldValue';
  id: string;
  valueMoney?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
};

type CustomFieldValuePreviewV2_StringCustomFieldValue_Fragment = {
  __typename?: 'StringCustomFieldValue';
  id: string;
  valueString?: string;
  customField:
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'UrlCustomField'; id: string };
  viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
};

export type CustomFieldValuePreviewV2Fragment =
  | CustomFieldValuePreviewV2_AssociationCustomFieldValue_Fragment
  | CustomFieldValuePreviewV2_AttachmentCustomFieldValue_Fragment
  | CustomFieldValuePreviewV2_BooleanCustomFieldValue_Fragment
  | CustomFieldValuePreviewV2_DateTimeCustomFieldValue_Fragment
  | CustomFieldValuePreviewV2_IntegerCustomFieldValue_Fragment
  | CustomFieldValuePreviewV2_MoneyCustomFieldValue_Fragment
  | CustomFieldValuePreviewV2_StringCustomFieldValue_Fragment;

export type DataFilterFragment = {
  __typename?: 'DataFilter';
  comparator: FlowStepConditionComparatorName;
  id: string;
  referenceValue?: any;
  updatedAt: string;
  inputPath?: { __typename?: 'DataPath'; id: string; path: string };
};

export type DataFilterPathFragment = { __typename?: 'DataPath'; id: string; path: string };

export type DataScopingRulePreviewFragment = {
  __typename?: 'DataScopingRule';
  id: string;
  operator: DataScopingRuleOperator;
  position: number;
  scopeByCustomField:
    | { __typename?: 'ApproverCustomField'; id: string; label: string }
    | { __typename?: 'AttachmentCustomField'; id: string; label: string }
    | { __typename?: 'CategoryCustomField'; id: string; label: string }
    | { __typename?: 'CheckboxCustomField'; id: string; label: string }
    | { __typename?: 'CostCentreCustomField'; id: string; label: string }
    | { __typename?: 'DateCustomField'; id: string; label: string }
    | { __typename?: 'IntegerCustomField'; id: string; label: string }
    | { __typename?: 'MoneyCustomField'; id: string; label: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
    | { __typename?: 'SelectCustomField'; id: string; label: string }
    | { __typename?: 'TextAreaCustomField'; id: string; label: string }
    | { __typename?: 'TextCustomField'; id: string; label: string }
    | { __typename?: 'UrlCustomField'; id: string; label: string };
  scopeToPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  viewerPermissions: {
    __typename?: 'DataScopingRuleRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

export type DataValueFragment = {
  __typename?: 'DataValue';
  dateValue?: string;
  id: string;
  staticValue?: string;
  valueSource: DataValueValueSource;
  amountValue?: { __typename?: 'Money'; universalFormat: string };
  pathValue?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  recordValue?:
    | { __typename?: 'Category'; code: string; id: string; label: string }
    | { __typename?: 'CostCentre'; code: string; id: string; label: string }
    | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
    | { __typename?: 'User'; fullName?: string; id: string };
};

export type DataViewFieldFragment = {
  __typename?: 'DataViewField';
  formatString?: DataViewFieldFormatString;
  id: string;
  label: string;
  position: number;
  updatedAt: string;
  dataValue?: {
    __typename?: 'DataValue';
    dateValue?: string;
    id: string;
    staticValue?: string;
    valueSource: DataValueValueSource;
    amountValue?: { __typename?: 'Money'; universalFormat: string };
    pathValue?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    recordValue?:
      | { __typename?: 'Category'; code: string; id: string; label: string }
      | { __typename?: 'CostCentre'; code: string; id: string; label: string }
      | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
      | { __typename?: 'User'; fullName?: string; id: string };
  };
  viewerPermissions: {
    __typename?: 'DataViewFieldRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

export type DocumentInvoicePaymentFragment = {
  __typename?: 'InvoicePayment';
  id: string;
  requestedPayoutDate: string;
  status: InvoicePaymentStatus;
  viewerPermissions: {
    __typename?: 'InvoicePaymentRecordViewerPermissions';
    cancel: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    update: boolean;
  };
};

export type DocumentsTabDocumentFragment = {
  __typename?: 'Document';
  archived: boolean;
  classification: DocumentClassification;
  createdAt: string;
  id: string;
  isDraft: boolean;
  paymentDate?: string;
  createdBy?: { __typename?: 'User'; fullName?: string; id: string };
  file?: { __typename?: 'Attachment'; downloadUrl: string; filename: string; id: string };
  totalAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  transactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    transactionDate: string;
    originalAmount: {
      __typename?: 'Money';
      cents: string;
      universalFormat: string;
      currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
    };
  }>;
  vatAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  viewerPermissions: {
    __typename?: 'DocumentRecordViewerPermissions';
    archive: boolean;
    download: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type DraftDocumentFragment = {
  __typename?: 'Document';
  classification: DocumentClassification;
  documentNumber?: string;
  id: string;
  issuedOn?: string;
  messageToSupplier?: string;
  paymentDate?: string;
  receiverIban?: string;
  supportedTypes?: Array<DocumentClassification>;
  currency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  customFieldValues?: Array<
    | {
        __typename?: 'AssociationCustomFieldValue';
        id: string;
        updatedAt: string;
        value:
          | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
          | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'AttachmentCustomFieldValue';
        id: string;
        updatedAt: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        files?: Array<{
          __typename?: 'Attachment';
          blobId: string;
          byteSize: number;
          contentType: string;
          downloadUrl: string;
          filename: string;
          id: string;
          key: string;
        }>;
      }
    | {
        __typename?: 'BooleanCustomFieldValue';
        id: string;
        updatedAt: string;
        valueBoolean?: boolean;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'DateTimeCustomFieldValue';
        id: string;
        updatedAt: string;
        valueDateTime?: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'IntegerCustomFieldValue';
        id: string;
        updatedAt: string;
        valueInteger?: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'MoneyCustomFieldValue';
        id: string;
        updatedAt: string;
        valueMoney?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'StringCustomFieldValue';
        id: string;
        updatedAt: string;
        valueString?: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
  >;
  file?: { __typename?: 'Attachment'; contentType: string; downloadUrl: string; filename: string; id: string };
  incomingEmailAttachment?: {
    __typename?: 'IncomingEmailAttachment';
    id: string;
    inbox: { __typename?: 'Inbox'; record: { __typename?: 'CardSubscription'; id: string; label: string } };
    incomingEmail: { __typename?: 'IncomingEmail'; id: string; receivedAt: string; subject?: string };
  };
  invoicePayments?: Array<{
    __typename?: 'InvoicePayment';
    id: string;
    requestedPayoutDate: string;
    status: InvoicePaymentStatus;
    viewerPermissions: {
      __typename?: 'InvoicePaymentRecordViewerPermissions';
      cancel: boolean;
      showArchivedCustomFields?: boolean;
      showEmptyCustomFields?: boolean;
      update: boolean;
    };
  }>;
  purchaseOrder?: {
    __typename?: 'PurchaseOrder';
    id: string;
    purchaseType: PurchaseType;
    purchaseRequestItem: {
      __typename?: 'PurchaseRequestItem';
      id: string;
      supplier?: { __typename?: 'SupplierAccount'; companyName: string; id: string };
    };
  };
  supplier?: { __typename?: 'SupplierAccount'; id: string };
  totalAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  transactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    merchantName: string;
    reconciliationStatus: TransactionReconciliationStatus;
    supportedDocumentTypes: Array<DocumentClassification>;
    transactionDate: string;
    originalAmount: {
      __typename?: 'Money';
      cents: string;
      universalFormat: string;
      currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
    };
  }>;
  vatAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type ErrorMessageFragment = { __typename?: 'ErrorMessage'; message: string; path?: string };

type FlowStepSoftValidation_FlowActionApprove_Fragment = {
  __typename?: 'FlowActionApprove';
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type FlowStepSoftValidation_FlowActionComment_Fragment = {
  __typename?: 'FlowActionComment';
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type FlowStepSoftValidation_FlowActionCreatePurchaseOrder_Fragment = {
  __typename?: 'FlowActionCreatePurchaseOrder';
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type FlowStepSoftValidation_FlowActionGetApproval_Fragment = {
  __typename?: 'FlowActionGetApproval';
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type FlowStepSoftValidation_FlowActionReject_Fragment = {
  __typename?: 'FlowActionReject';
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type FlowStepSoftValidation_FlowStepCondition_Fragment = {
  __typename?: 'FlowStepCondition';
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type FlowStepSoftValidationFragment =
  | FlowStepSoftValidation_FlowActionApprove_Fragment
  | FlowStepSoftValidation_FlowActionComment_Fragment
  | FlowStepSoftValidation_FlowActionCreatePurchaseOrder_Fragment
  | FlowStepSoftValidation_FlowActionGetApproval_Fragment
  | FlowStepSoftValidation_FlowActionReject_Fragment
  | FlowStepSoftValidation_FlowStepCondition_Fragment;

export type ImportResultFragment = {
  __typename?: 'ImportResult';
  createdRecordsCount: number;
  updatedRecordsCount: number;
  failures: {
    __typename?: 'Failures';
    count: number;
    rows: Array<{
      __typename?: 'Row';
      rowNumber: number;
      errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    }>;
  };
  skipped: {
    __typename?: 'Skipped';
    count: number;
    rows: Array<{
      __typename?: 'Row';
      rowNumber: number;
      errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    }>;
  };
};

export type IssuerIntegrationFragment = {
  __typename?: 'IssuerIntegration';
  archived: boolean;
  archivedAt?: string;
  cardUseInvoice: boolean;
  cardUseMulti: boolean;
  cardUseSingle: boolean;
  description: string;
  id: string;
  issuerName: string;
  currency: { __typename?: 'Currency'; code: CurrencyCode };
  viewerPermissions: {
    __typename?: 'IssuerIntegrationRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type LocationIndexRecordFragment = {
  __typename?: 'Location';
  archived: boolean;
  id: string;
  label: string;
  address: {
    __typename?: 'Address';
    city?: string;
    formattedLines: Array<string>;
    id: string;
    postalCode?: string;
    regionName?: string;
    streetName?: string;
    streetNumber?: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
  viewerPermissions: {
    __typename?: 'LocationRecordViewerPermissions';
    archive: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

type Account_BuyerAccount_Fragment = {
  __typename?: 'BuyerAccount';
  accountType: AccountUseType;
  companyName: string;
  id: string;
  publicEmail?: string;
  publicPhone?: string;
  publicWebsite?: string;
  vatNumber?: string;
  address?: {
    __typename?: 'Address';
    id: string;
    city?: string;
    postalCode?: string;
    streetName?: string;
    streetNumber?: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
  coverImage?: {
    __typename?: 'Attachment';
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
    name: string;
  };
  profileImage?: {
    __typename?: 'Attachment';
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
    name: string;
  };
};

type Account_SupplierAccount_Fragment = {
  __typename?: 'SupplierAccount';
  accountType: AccountUseType;
  companyName: string;
  id: string;
  publicEmail?: string;
  publicPhone?: string;
  publicWebsite?: string;
  vatNumber?: string;
  address?: {
    __typename?: 'Address';
    id: string;
    city?: string;
    postalCode?: string;
    streetName?: string;
    streetNumber?: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
  coverImage?: {
    __typename?: 'Attachment';
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
    name: string;
  };
  profileImage?: {
    __typename?: 'Attachment';
    byteSize: number;
    contentType: string;
    downloadUrl: string;
    filename: string;
    id: string;
    key: string;
    name: string;
  };
};

export type AccountFragment = Account_BuyerAccount_Fragment | Account_SupplierAccount_Fragment;

export type AccountSettingsFragment = {
  __typename?: 'AccountSettings';
  categorisation: { __typename?: 'CategorisationSettings'; categorySetId?: string };
  localisation: {
    __typename?: 'AccountLocalisationSettings';
    timezone: string;
    interfaceLanguage: { __typename?: 'Language'; code: LanguageCode; englishName: string; nativeName: string };
  };
  payment: {
    __typename?: 'PaymentSettings';
    baseCurrency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
    virtualCardPayments: {
      __typename?: 'VirtualCardPaymentSettings';
      multiUseCard: { __typename?: 'MultiUseCard'; defaultTtlDays: number; maxTtlDays: number };
      singleUseCard: { __typename?: 'SingleUseCard'; defaultTtlDays: number; maxTtlDays: number };
    };
  };
};

export type UserSettingsFragment = {
  __typename?: 'UserSettings';
  appearance: { __typename?: 'AppearanceSettings'; theme: Theme };
  localisation: {
    __typename?: 'LocalisationSettings';
    timezone: string;
    interfaceLanguage: { __typename?: 'Language'; code: LanguageCode; englishName: string; nativeName: string };
  };
  notifications: Array<{
    __typename?: 'NotificationSetting';
    activityKey: string;
    email: boolean;
    groupLabel: string;
    helpText?: string;
    label: string;
    mobile: boolean;
    web: boolean;
  }>;
};

export type MoneyFragment = {
  __typename?: 'Money';
  cents: string;
  universalFormat: string;
  currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
};

export type NotificationFragment = {
  __typename?: 'Notification';
  createdAt: string;
  id: string;
  isRead: boolean;
  readAt?: string;
  updatedAt: string;
  activity: {
    __typename?: 'Activity';
    id: string;
    createdAt: string;
    displayStyle: ActivityDisplayStyle;
    icon: ActivityIcon;
    key: string;
    updatedAt: string;
    defaultUrl?: { __typename?: 'URL'; path?: string; query?: string };
    eventSubject?:
      | { __typename?: 'Approval' }
      | { __typename?: 'Attachment' }
      | { __typename?: 'BuyerAccount' }
      | { __typename?: 'Comment'; body?: string; id: string }
      | { __typename?: 'Document' }
      | { __typename?: 'IncomingEmail' }
      | { __typename?: 'PurchaseOrder' }
      | { __typename?: 'PurchaseRequestItem' }
      | { __typename?: 'SupplierAccount' }
      | { __typename?: 'Transaction' }
      | { __typename?: 'User' }
      | { __typename?: 'VirtualCard' }
      | { __typename?: 'Workflow' };
    fragments: Array<
      | {
          __typename?: 'ReferenceFragment';
          urn: string;
          text: string;
          type: StringFragmentFragmentType;
          url?: { __typename?: 'URL'; path?: string; query?: string };
        }
      | { __typename?: 'TextFragment'; text: string; type: StringFragmentFragmentType }
    >;
  };
};

export type OrganisationUnitFragment = {
  __typename?: 'OrganisationUnit';
  archived: boolean;
  childrenCount: number;
  classification: OrganisationUnitClassification;
  descendantUsersCount: number;
  id: string;
  label: string;
  parentId?: string;
  viewerPermissions: {
    __typename?: 'OrganisationUnitRecordViewerPermissions';
    archive: boolean;
    createChild?: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type OrganisationUnitViewFragment = {
  __typename?: 'OrganisationUnit';
  archived: boolean;
  archivedAt?: string;
  classification: OrganisationUnitClassification;
  code: string;
  description?: string;
  id: string;
  label: string;
  parentId?: string;
  legalEntity: { __typename?: 'OrganisationUnit'; id: string; label: string };
  viewerPermissions: {
    __typename?: 'OrganisationUnitRecordViewerPermissions';
    archive: boolean;
    createChild?: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    unarchive: boolean;
    update: boolean;
  };
};

export type OverviewApprovalFragment = {
  __typename?: 'Approval';
  id: string;
  status: ApprovalStatus;
  approvable?:
    | {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        submittedAt?: string;
        purchaseType: PurchaseType;
        status: PurchaseRequestItemStatus;
        requestedAmount?: { __typename?: 'Money'; universalFormat: string };
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          virtualCard?: {
            __typename?: 'VirtualCard';
            actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
            spentBaseAmount: {
              __typename?: 'Money';
              cents: string;
              universalFormat: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
          };
        };
      }
    | {
        __typename?: 'SupplierRequest';
        createdAt: string;
        id: string;
        approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
        companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
      };
};

export type PageInfoFragment = {
  __typename?: 'PageInfo';
  endCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
};

export type DocumentResourceIconFragment = {
  __typename?: 'Document';
  archived: boolean;
  isDraft: boolean;
  invalidatedAt?: string;
};

export type PurchaseOrderResourceIconFragment = {
  __typename?: 'PurchaseOrder';
  reconciliationStatus: PurchaseOrderReconciliationStatus;
  status: PurchaseOrderStatus;
  purchaseRequestItem: {
    __typename?: 'PurchaseRequestItem';
    id: string;
    requestedAmount?: { __typename?: 'Money'; cents: string };
  };
  totalSpent: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type PurchaseRequestResourceIconFragment = {
  __typename?: 'PurchaseRequestItem';
  id: string;
  purchaseType: PurchaseType;
  status: PurchaseRequestItemStatus;
  purchaseOrder?: {
    __typename?: 'PurchaseOrder';
    id: string;
    virtualCard?: {
      __typename?: 'VirtualCard';
      actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
      spentBaseAmount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
  };
};

export type SupplierRequestResourceIconFragment = {
  __typename?: 'SupplierRequest';
  approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
  id: string;
};

export type VirtualCardResourceIconFragment = {
  __typename?: 'VirtualCard';
  aboutToExpire?: boolean;
  lowAmount: boolean;
  status: VirtualCardStatus;
  actualLimit?: { __typename?: 'Money'; cents: string };
  spentAmount: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type ResourcePreviewTransactionsItemFragment = {
  __typename?: 'Transaction';
  id: string;
  merchantName: string;
  transactionDate: string;
  originalAmount: { __typename?: 'Money'; universalFormat: string };
};

export type SupplierResourceTagFragment = {
  __typename?: 'SupplierAccount';
  enableStatus?: SupplierAccountEnableStatus;
  onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
};

export type WorkflowResourceTagFragment = {
  __typename?: 'Workflow';
  archivedAt?: string;
  enabledAt?: string;
  publishedAt?: string;
};

type ResourceTitle_Activity_Fragment = { __typename?: 'Activity'; id: string };

type ResourceTitle_Address_Fragment = {
  __typename?: 'Address';
  city?: string;
  id: string;
  country: { __typename?: 'Country'; code: CountryCode; name: string };
};

type ResourceTitle_Approval_Fragment = {
  __typename?: 'Approval';
  id: string;
  approvable?:
    | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
    | {
        __typename?: 'SupplierRequest';
        id: string;
        companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
      };
};

type ResourceTitle_ApprovalDelegation_Fragment = {
  __typename?: 'ApprovalDelegation';
  archived: boolean;
  id: string;
  from: { __typename?: 'User'; fullName?: string; id: string };
  to: { __typename?: 'User'; fullName?: string; id: string };
};

type ResourceTitle_ApprovalsUser_Fragment = { __typename?: 'ApprovalsUser'; id: string };

type ResourceTitle_ApproverCustomField_Fragment = { __typename?: 'ApproverCustomField'; id: string };

type ResourceTitle_AssociationCustomFieldValue_Fragment = { __typename?: 'AssociationCustomFieldValue'; id: string };

type ResourceTitle_Attachment_Fragment = { __typename?: 'Attachment'; id: string };

type ResourceTitle_AttachmentCustomField_Fragment = { __typename?: 'AttachmentCustomField'; id: string };

type ResourceTitle_AttachmentCustomFieldValue_Fragment = { __typename?: 'AttachmentCustomFieldValue'; id: string };

type ResourceTitle_BankAccount_Fragment = { __typename?: 'BankAccount'; id: string };

type ResourceTitle_Blob_Fragment = { __typename?: 'Blob'; id: string };

type ResourceTitle_BooleanCustomFieldValue_Fragment = { __typename?: 'BooleanCustomFieldValue'; id: string };

type ResourceTitle_BuyerAccount_Fragment = { __typename?: 'BuyerAccount'; companyName: string; id: string };

type ResourceTitle_CardAuthorisation_Fragment = { __typename?: 'CardAuthorisation'; id: string };

type ResourceTitle_CardSubscription_Fragment = { __typename?: 'CardSubscription'; label: string; id: string };

type ResourceTitle_Category_Fragment = {
  __typename?: 'Category';
  archived: boolean;
  code: string;
  label: string;
  id: string;
};

type ResourceTitle_CategoryCustomField_Fragment = { __typename?: 'CategoryCustomField'; id: string };

type ResourceTitle_CategorySet_Fragment = { __typename?: 'CategorySet'; archived: boolean; label: string; id: string };

type ResourceTitle_CheckboxCustomField_Fragment = { __typename?: 'CheckboxCustomField'; id: string };

type ResourceTitle_Comment_Fragment = { __typename?: 'Comment'; id: string };

type ResourceTitle_CompanyLookup_Fragment = { __typename?: 'CompanyLookup'; id: string };

type ResourceTitle_CostCentre_Fragment = {
  __typename?: 'CostCentre';
  archived: boolean;
  code: string;
  label: string;
  id: string;
};

type ResourceTitle_CostCentreCustomField_Fragment = { __typename?: 'CostCentreCustomField'; id: string };

type ResourceTitle_DataFilter_Fragment = { __typename?: 'DataFilter'; id: string };

type ResourceTitle_DataPath_Fragment = { __typename?: 'DataPath'; id: string };

type ResourceTitle_DataScopingRule_Fragment = { __typename?: 'DataScopingRule'; id: string };

type ResourceTitle_DataSource_Fragment = { __typename?: 'DataSource'; archived: boolean; label: string; id: string };

type ResourceTitle_DataSourceItem_Fragment = {
  __typename?: 'DataSourceItem';
  archived: boolean;
  label: string;
  id: string;
};

type ResourceTitle_DataValue_Fragment = { __typename?: 'DataValue'; id: string };

type ResourceTitle_DataView_Fragment = {
  __typename?: 'DataView';
  archived: boolean;
  label: string;
  resourceType: DataViewResourceType;
  id: string;
};

type ResourceTitle_DataViewField_Fragment = { __typename?: 'DataViewField'; id: string };

type ResourceTitle_DateCustomField_Fragment = { __typename?: 'DateCustomField'; id: string };

type ResourceTitle_DateTimeCustomFieldValue_Fragment = { __typename?: 'DateTimeCustomFieldValue'; id: string };

type ResourceTitle_Document_Fragment = {
  __typename?: 'Document';
  archived: boolean;
  documentNumber?: string;
  id: string;
};

type ResourceTitle_DocumentParseResult_Fragment = {
  __typename?: 'DocumentParseResult';
  documentNumber?: string;
  id: string;
};

type ResourceTitle_DocumentTransaction_Fragment = { __typename?: 'DocumentTransaction'; id: string };

type ResourceTitle_FlowActionApprove_Fragment = { __typename?: 'FlowActionApprove'; id: string };

type ResourceTitle_FlowActionComment_Fragment = { __typename?: 'FlowActionComment'; id: string };

type ResourceTitle_FlowActionCreatePurchaseOrder_Fragment = {
  __typename?: 'FlowActionCreatePurchaseOrder';
  id: string;
};

type ResourceTitle_FlowActionGetApproval_Fragment = { __typename?: 'FlowActionGetApproval'; id: string };

type ResourceTitle_FlowActionReject_Fragment = { __typename?: 'FlowActionReject'; id: string };

type ResourceTitle_FlowStepCondition_Fragment = { __typename?: 'FlowStepCondition'; id: string };

type ResourceTitle_FlowStepResult_Fragment = { __typename?: 'FlowStepResult'; id: string };

type ResourceTitle_FlowStepResultValue_Fragment = { __typename?: 'FlowStepResultValue'; id: string };

type ResourceTitle_Inbox_Fragment = { __typename?: 'Inbox'; id: string };

type ResourceTitle_IncomingEmail_Fragment = { __typename?: 'IncomingEmail'; id: string };

type ResourceTitle_IncomingEmailAttachment_Fragment = { __typename?: 'IncomingEmailAttachment'; id: string };

type ResourceTitle_IndustryCode_Fragment = { __typename?: 'IndustryCode'; id: string };

type ResourceTitle_IntegerCustomField_Fragment = { __typename?: 'IntegerCustomField'; id: string };

type ResourceTitle_IntegerCustomFieldValue_Fragment = { __typename?: 'IntegerCustomFieldValue'; id: string };

type ResourceTitle_InvoicePayment_Fragment = {
  __typename?: 'InvoicePayment';
  id: string;
  requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
};

type ResourceTitle_IssuerIntegration_Fragment = {
  __typename?: 'IssuerIntegration';
  archived: boolean;
  issuerName: string;
  id: string;
};

type ResourceTitle_IssuerIntegrationConsumer_Fragment = { __typename?: 'IssuerIntegrationConsumer'; id: string };

type ResourceTitle_IssuerIntegrationRule_Fragment = { __typename?: 'IssuerIntegrationRule'; id: string };

type ResourceTitle_Location_Fragment = { __typename?: 'Location'; archived: boolean; label: string; id: string };

type ResourceTitle_Me_Fragment = { __typename?: 'Me'; id: string };

type ResourceTitle_Merchant_Fragment = { __typename?: 'Merchant'; merchantName: string; id: string };

type ResourceTitle_MerchantCategoryCode_Fragment = { __typename?: 'MerchantCategoryCode'; id: string };

type ResourceTitle_MoneyCustomField_Fragment = { __typename?: 'MoneyCustomField'; id: string };

type ResourceTitle_MoneyCustomFieldValue_Fragment = { __typename?: 'MoneyCustomFieldValue'; id: string };

type ResourceTitle_Notification_Fragment = { __typename?: 'Notification'; id: string };

type ResourceTitle_OrganisationUnit_Fragment = {
  __typename?: 'OrganisationUnit';
  archived: boolean;
  code: string;
  label: string;
  id: string;
};

type ResourceTitle_OrganisationUnitCustomField_Fragment = { __typename?: 'OrganisationUnitCustomField'; id: string };

type ResourceTitle_PublicAddress_Fragment = { __typename?: 'PublicAddress'; id: string };

type ResourceTitle_PurchaseOrder_Fragment = { __typename?: 'PurchaseOrder'; label: string; id: string };

type ResourceTitle_PurchaseRequestItem_Fragment = {
  __typename?: 'PurchaseRequestItem';
  description?: string;
  id: string;
};

type ResourceTitle_ReferenceAssociation_Fragment = { __typename?: 'ReferenceAssociation'; id: string };

type ResourceTitle_Report_Fragment = { __typename?: 'Report'; id: string };

type ResourceTitle_ReportSchedule_Fragment = {
  __typename?: 'ReportSchedule';
  archived: boolean;
  label: string;
  id: string;
};

type ResourceTitle_Schedule_Fragment = { __typename?: 'Schedule'; id: string };

type ResourceTitle_SelectCustomField_Fragment = { __typename?: 'SelectCustomField'; id: string };

type ResourceTitle_SpendLimit_Fragment = { __typename?: 'SpendLimit'; id: string };

type ResourceTitle_StringCustomFieldValue_Fragment = { __typename?: 'StringCustomFieldValue'; id: string };

type ResourceTitle_SupplierAccount_Fragment = { __typename?: 'SupplierAccount'; companyName: string; id: string };

type ResourceTitle_SupplierOnboardingCase_Fragment = { __typename?: 'SupplierOnboardingCase'; id: string };

type ResourceTitle_SupplierOnboardingContact_Fragment = { __typename?: 'SupplierOnboardingContact'; id: string };

type ResourceTitle_SupplierPayout_Fragment = { __typename?: 'SupplierPayout'; id: string };

type ResourceTitle_SupplierRequest_Fragment = {
  __typename?: 'SupplierRequest';
  id: string;
  companyLookup: { __typename?: 'CompanyLookup'; companyName?: string; id: string };
};

type ResourceTitle_TextAreaCustomField_Fragment = { __typename?: 'TextAreaCustomField'; id: string };

type ResourceTitle_TextCustomField_Fragment = { __typename?: 'TextCustomField'; id: string };

type ResourceTitle_Transaction_Fragment = { __typename?: 'Transaction'; transactionId: string; id: string };

type ResourceTitle_UrlCustomField_Fragment = { __typename?: 'UrlCustomField'; id: string };

type ResourceTitle_User_Fragment = { __typename?: 'User'; archived: boolean; fullName?: string; id: string };

type ResourceTitle_VirtualCard_Fragment = { __typename?: 'VirtualCard'; cardNumberLastFour?: string; id: string };

type ResourceTitle_Workflow_Fragment = { __typename?: 'Workflow'; archived: boolean; label: string; id: string };

type ResourceTitle_WorkflowRun_Fragment = { __typename?: 'WorkflowRun'; id: string };

export type ResourceTitleFragment =
  | ResourceTitle_Activity_Fragment
  | ResourceTitle_Address_Fragment
  | ResourceTitle_Approval_Fragment
  | ResourceTitle_ApprovalDelegation_Fragment
  | ResourceTitle_ApprovalsUser_Fragment
  | ResourceTitle_ApproverCustomField_Fragment
  | ResourceTitle_AssociationCustomFieldValue_Fragment
  | ResourceTitle_Attachment_Fragment
  | ResourceTitle_AttachmentCustomField_Fragment
  | ResourceTitle_AttachmentCustomFieldValue_Fragment
  | ResourceTitle_BankAccount_Fragment
  | ResourceTitle_Blob_Fragment
  | ResourceTitle_BooleanCustomFieldValue_Fragment
  | ResourceTitle_BuyerAccount_Fragment
  | ResourceTitle_CardAuthorisation_Fragment
  | ResourceTitle_CardSubscription_Fragment
  | ResourceTitle_Category_Fragment
  | ResourceTitle_CategoryCustomField_Fragment
  | ResourceTitle_CategorySet_Fragment
  | ResourceTitle_CheckboxCustomField_Fragment
  | ResourceTitle_Comment_Fragment
  | ResourceTitle_CompanyLookup_Fragment
  | ResourceTitle_CostCentre_Fragment
  | ResourceTitle_CostCentreCustomField_Fragment
  | ResourceTitle_DataFilter_Fragment
  | ResourceTitle_DataPath_Fragment
  | ResourceTitle_DataScopingRule_Fragment
  | ResourceTitle_DataSource_Fragment
  | ResourceTitle_DataSourceItem_Fragment
  | ResourceTitle_DataValue_Fragment
  | ResourceTitle_DataView_Fragment
  | ResourceTitle_DataViewField_Fragment
  | ResourceTitle_DateCustomField_Fragment
  | ResourceTitle_DateTimeCustomFieldValue_Fragment
  | ResourceTitle_Document_Fragment
  | ResourceTitle_DocumentParseResult_Fragment
  | ResourceTitle_DocumentTransaction_Fragment
  | ResourceTitle_FlowActionApprove_Fragment
  | ResourceTitle_FlowActionComment_Fragment
  | ResourceTitle_FlowActionCreatePurchaseOrder_Fragment
  | ResourceTitle_FlowActionGetApproval_Fragment
  | ResourceTitle_FlowActionReject_Fragment
  | ResourceTitle_FlowStepCondition_Fragment
  | ResourceTitle_FlowStepResult_Fragment
  | ResourceTitle_FlowStepResultValue_Fragment
  | ResourceTitle_Inbox_Fragment
  | ResourceTitle_IncomingEmail_Fragment
  | ResourceTitle_IncomingEmailAttachment_Fragment
  | ResourceTitle_IndustryCode_Fragment
  | ResourceTitle_IntegerCustomField_Fragment
  | ResourceTitle_IntegerCustomFieldValue_Fragment
  | ResourceTitle_InvoicePayment_Fragment
  | ResourceTitle_IssuerIntegration_Fragment
  | ResourceTitle_IssuerIntegrationConsumer_Fragment
  | ResourceTitle_IssuerIntegrationRule_Fragment
  | ResourceTitle_Location_Fragment
  | ResourceTitle_Me_Fragment
  | ResourceTitle_Merchant_Fragment
  | ResourceTitle_MerchantCategoryCode_Fragment
  | ResourceTitle_MoneyCustomField_Fragment
  | ResourceTitle_MoneyCustomFieldValue_Fragment
  | ResourceTitle_Notification_Fragment
  | ResourceTitle_OrganisationUnit_Fragment
  | ResourceTitle_OrganisationUnitCustomField_Fragment
  | ResourceTitle_PublicAddress_Fragment
  | ResourceTitle_PurchaseOrder_Fragment
  | ResourceTitle_PurchaseRequestItem_Fragment
  | ResourceTitle_ReferenceAssociation_Fragment
  | ResourceTitle_Report_Fragment
  | ResourceTitle_ReportSchedule_Fragment
  | ResourceTitle_Schedule_Fragment
  | ResourceTitle_SelectCustomField_Fragment
  | ResourceTitle_SpendLimit_Fragment
  | ResourceTitle_StringCustomFieldValue_Fragment
  | ResourceTitle_SupplierAccount_Fragment
  | ResourceTitle_SupplierOnboardingCase_Fragment
  | ResourceTitle_SupplierOnboardingContact_Fragment
  | ResourceTitle_SupplierPayout_Fragment
  | ResourceTitle_SupplierRequest_Fragment
  | ResourceTitle_TextAreaCustomField_Fragment
  | ResourceTitle_TextCustomField_Fragment
  | ResourceTitle_Transaction_Fragment
  | ResourceTitle_UrlCustomField_Fragment
  | ResourceTitle_User_Fragment
  | ResourceTitle_VirtualCard_Fragment
  | ResourceTitle_Workflow_Fragment
  | ResourceTitle_WorkflowRun_Fragment;

export type ApprovalResourceFragment = {
  __typename?: 'Approval';
  approvedAt?: string;
  cancelledAt?: string;
  createdAt: string;
  id: string;
  rejectedAt?: string;
  status: ApprovalStatus;
  approvalsUsers: {
    __typename?: 'ApprovalsUserConnection';
    edges?: Array<{
      __typename?: 'ApprovalsUserEdge';
      cursor: string;
      node?: { __typename?: 'ApprovalsUser'; id: string; user: { __typename?: 'User'; fullName?: string; id: string } };
    }>;
  };
  comments: {
    __typename?: 'CommentConnection';
    edges?: Array<{
      __typename?: 'CommentEdge';
      node?: { __typename?: 'Comment'; body?: string; id: string; key?: string };
    }>;
  };
  confirmedBy?: { __typename?: 'User'; fullName?: string; id: string };
};

export type CardSubscriptionResourceFragment = {
  __typename?: 'CardSubscription';
  cardStatus: CardSubscriptionCardStatus;
  closedAt?: string;
  id: string;
  label: string;
  owner: { __typename?: 'User'; fullName?: string; id: string };
  primaryVirtualCard?: {
    __typename?: 'VirtualCard';
    id: string;
    validUntil: string;
    actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
    spentBaseAmount: { __typename?: 'Money'; cents: string };
  };
};

export type DataValueResourceFragment = {
  __typename?: 'DataValue';
  dateValue?: string;
  id: string;
  staticValue?: string;
  valueSource: DataValueValueSource;
  viewerPermissions: { __typename?: 'DataValueRecordViewerPermissions'; destroy: boolean; update: boolean };
  amountValue?: { __typename?: 'Money'; universalFormat: string };
  pathValue?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  recordValue?:
    | { __typename?: 'Category'; code: string; id: string; label: string }
    | { __typename?: 'CostCentre'; code: string; id: string; label: string }
    | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
    | { __typename?: 'User'; fullName?: string; id: string };
};

export type DocumentResourceFragment = {
  __typename?: 'Document';
  classification: DocumentClassification;
  createdAt: string;
  id: string;
  archived: boolean;
  isDraft: boolean;
  invalidatedAt?: string;
  createdBy?: { __typename?: 'User'; fullName?: string; id: string };
  totalAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type EmailResourceFragment = {
  __typename?: 'IncomingEmail';
  id: string;
  receivedAt: string;
  subject?: string;
  incomingEmailAttachments: { __typename?: 'IncomingEmailAttachmentConnection'; totalCount: number };
};

type FlowStepResource_FlowActionApprove_Fragment = { __typename?: 'FlowActionApprove'; id: string };

type FlowStepResource_FlowActionComment_Fragment = { __typename?: 'FlowActionComment'; id: string };

type FlowStepResource_FlowActionCreatePurchaseOrder_Fragment = {
  __typename?: 'FlowActionCreatePurchaseOrder';
  id: string;
};

type FlowStepResource_FlowActionGetApproval_Fragment = {
  __typename?: 'FlowActionGetApproval';
  approvalType?: FlowActionGetApprovalApprovalType;
  assignAll: boolean;
  id: string;
  approverUsers: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
    }>;
  };
  assigneePaths: {
    __typename?: 'DataPathConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataPathEdge';
      node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    }>;
  };
};

type FlowStepResource_FlowActionReject_Fragment = { __typename?: 'FlowActionReject'; id: string };

type FlowStepResource_FlowStepCondition_Fragment = {
  __typename?: 'FlowStepCondition';
  comparator?: FlowStepConditionComparatorName;
  referenceUnit?: string;
  referenceValue?: any;
  id: string;
  inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  referenceAssociations: {
    __typename?: 'ReferenceAssociationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ReferenceAssociationEdge';
      node?: {
        __typename?: 'ReferenceAssociation';
        id: string;
        referenceRecord:
          | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
          | { __typename?: 'Category'; id: string; label: string }
          | { __typename?: 'CostCentre'; id: string; label: string }
          | { __typename?: 'DataSourceItem'; id: string; label: string }
          | { __typename?: 'IssuerIntegration'; id: string; description: string }
          | { __typename?: 'OrganisationUnit'; id: string; label: string }
          | { __typename?: 'User'; id: string; fullName?: string };
      };
    }>;
  };
  referencePaths: {
    __typename?: 'DataPathConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataPathEdge';
      node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    }>;
  };
};

export type FlowStepResourceFragment =
  | FlowStepResource_FlowActionApprove_Fragment
  | FlowStepResource_FlowActionComment_Fragment
  | FlowStepResource_FlowActionCreatePurchaseOrder_Fragment
  | FlowStepResource_FlowActionGetApproval_Fragment
  | FlowStepResource_FlowActionReject_Fragment
  | FlowStepResource_FlowStepCondition_Fragment;

export type InboxResourceFragment = {
  __typename?: 'Inbox';
  id: string;
  incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
};

export type InvoicePaymentResourceFragment = {
  __typename?: 'InvoicePayment';
  cancelledAt?: string;
  id: string;
  requestedPayoutDate: string;
  status: InvoicePaymentStatus;
  requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
  supplierPayment: { __typename?: 'SupplierPayment'; completedAt?: string; transactionReference?: string };
};

export type MerchantResourceFragment = {
  __typename?: 'Merchant';
  city: string;
  companyName: string;
  id: string;
  country: { __typename?: 'Country'; code: CountryCode; name: string };
};

export type RequestResourceFragment = {
  __typename?: 'PurchaseRequestItem';
  description?: string;
  id: string;
  status: PurchaseRequestItemStatus;
  purchaseType: PurchaseType;
  requestedBy: { __typename?: 'User'; fullName?: string; id: string };
  purchaseOrder?: {
    __typename?: 'PurchaseOrder';
    id: string;
    virtualCard?: {
      __typename?: 'VirtualCard';
      actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
      spentBaseAmount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
  };
};

export type SupplierResourceFragment = {
  __typename?: 'SupplierAccount';
  companyName: string;
  id: string;
  enableStatus?: SupplierAccountEnableStatus;
  onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
  address?: {
    __typename?: 'Address';
    city?: string;
    id: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
};

export type TransactionResourceFragment = {
  __typename?: 'Transaction';
  customReconciliationStatus?: CustomTransactionReconciliationStatus;
  id: string;
  merchantName: string;
  reconciliationStatus: TransactionReconciliationStatus;
  transactionDate: string;
  type: TransactionType;
  originalAmount: { __typename?: 'Money'; universalFormat: string };
};

export type UserResourceFragment = {
  __typename?: 'User';
  fullName?: string;
  id: string;
  status: UserStatus;
  organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
  profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
};

export type VirtualCardResourceFragment = {
  __typename?: 'VirtualCard';
  cardNumberLastFour?: string;
  id: string;
  revokeComment?: string;
  status: VirtualCardStatus;
  validUntil: string;
  aboutToExpire?: boolean;
  lowAmount: boolean;
  actualLimitBaseAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  remainingBaseAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  spentBaseAmount: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  actualLimit?: { __typename?: 'Money'; cents: string };
  spentAmount: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type BillingInfoFragment = {
  __typename?: 'OrganisationUnit';
  id: string;
  label: string;
  vatNumber: string;
  location: {
    __typename?: 'Location';
    id: string;
    address: {
      __typename?: 'Address';
      city?: string;
      id: string;
      postalCode?: string;
      streetName?: string;
      country: { __typename?: 'Country'; code: CountryCode; name: string };
    };
  };
};

export type InvoicePaymentFragment = {
  __typename?: 'InvoicePayment';
  cancelledAt?: string;
  createdAt: string;
  id: string;
  requestedPayoutDate: string;
  status: InvoicePaymentStatus;
  cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
  cardPayment?: { __typename?: 'CardPayment'; completedAt?: string; scheduledAt?: string };
  createdBy?: { __typename?: 'User'; fullName?: string; id: string };
  document: {
    __typename?: 'Document';
    id: string;
    file?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
    viewerPermissions: {
      __typename?: 'DocumentRecordViewerPermissions';
      archive: boolean;
      download: boolean;
      showArchivedCustomFields?: boolean;
      showEmptyCustomFields?: boolean;
      unarchive: boolean;
      update: boolean;
    };
  };
  requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
  supplier?: { __typename?: 'SupplierAccount'; companyName: string; id: string };
  supplierPayment: { __typename?: 'SupplierPayment'; completedAt?: string; transactionReference?: string };
  viewerPermissions: {
    __typename?: 'InvoicePaymentRecordViewerPermissions';
    cancel: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    update: boolean;
  };
};

export type ShowPurchaseRequestItemContentFragment = {
  __typename?: 'PurchaseRequestItem';
  approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
  approvedAt?: string;
  cancelComment?: string;
  cancelledAt?: string;
  createdAt: string;
  purchaseMadeAt?: string;
  purchaseType: PurchaseType;
  rejectedAt?: string;
  cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
  cardSubscription?: {
    __typename?: 'CardSubscription';
    cardStatus: CardSubscriptionCardStatus;
    closedAt?: string;
    id: string;
    label: string;
    inboxForKey?: {
      __typename?: 'Inbox';
      address: string;
      id: string;
      incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
    };
    owner: { __typename?: 'User'; fullName?: string; id: string };
    primaryVirtualCard?: {
      __typename?: 'VirtualCard';
      id: string;
      validUntil: string;
      actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
      spentBaseAmount: { __typename?: 'Money'; cents: string };
    };
  };
  customFieldValues?: Array<
    | {
        __typename?: 'AssociationCustomFieldValue';
        id: string;
        updatedAt: string;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        value:
          | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
          | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'AttachmentCustomFieldValue';
        id: string;
        updatedAt: string;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        files?: Array<{
          __typename?: 'Attachment';
          blobId: string;
          byteSize: number;
          contentType: string;
          downloadUrl: string;
          filename: string;
          id: string;
          key: string;
        }>;
      }
    | {
        __typename?: 'BooleanCustomFieldValue';
        id: string;
        updatedAt: string;
        valueBoolean?: boolean;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'DateTimeCustomFieldValue';
        id: string;
        updatedAt: string;
        valueDateTime?: string;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'IntegerCustomFieldValue';
        id: string;
        updatedAt: string;
        valueInteger?: string;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'MoneyCustomFieldValue';
        id: string;
        updatedAt: string;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        valueMoney?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
    | {
        __typename?: 'StringCustomFieldValue';
        id: string;
        updatedAt: string;
        valueString?: string;
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        customField:
          | {
              __typename?: 'ApproverCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              archived: boolean;
              archivedAt?: string;
              iconName?: string;
              id: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
      }
  >;
  purchaseOrder?: {
    __typename?: 'PurchaseOrder';
    id: string;
    poNumber: string;
    supportedDocumentTypes: Array<DocumentClassification>;
    virtualCard?: {
      __typename?: 'VirtualCard';
      actualExpiryDate?: string;
      deliveryMethod: VirtualCardDeliveryMethod;
      issuedAt?: string;
      revokedAt?: string;
      smsDeliveryStatus: VirtualCardSmsDeliveryStatus;
      cardNumberLastFour?: string;
      id: string;
      revokeComment?: string;
      status: VirtualCardStatus;
      validUntil: string;
      aboutToExpire?: boolean;
      lowAmount: boolean;
      issuedTo: { __typename?: 'User'; fullName?: string; id: string };
      issuerIntegration?: {
        __typename?: 'IssuerIntegration';
        id: string;
        billingEntity?: {
          __typename?: 'OrganisationUnit';
          id: string;
          label: string;
          vatNumber: string;
          location: {
            __typename?: 'Location';
            id: string;
            address: {
              __typename?: 'Address';
              city?: string;
              id: string;
              postalCode?: string;
              streetName?: string;
              country: { __typename?: 'Country'; code: CountryCode; name: string };
            };
          };
        };
      };
      viewerPermissions: {
        __typename?: 'VirtualCardRecordViewerPermissions';
        initiateIframeDelivery: boolean;
        revoke: boolean;
      };
      actualLimitBaseAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      remainingBaseAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      spentBaseAmount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      actualLimit?: { __typename?: 'Money'; cents: string };
      spentAmount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
  };
  requestedBy: {
    __typename?: 'User';
    fullName?: string;
    id: string;
    status: UserStatus;
    organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
    profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
  };
  supplier?: {
    __typename?: 'SupplierAccount';
    companyName: string;
    id: string;
    enableStatus?: SupplierAccountEnableStatus;
    onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
    address?: {
      __typename?: 'Address';
      city?: string;
      id: string;
      country: { __typename?: 'Country'; code: CountryCode; name: string };
    };
  };
};

export type ShowPurchaseRequestItemHeaderFragment = {
  __typename?: 'PurchaseRequestItem';
  description?: string;
  id: string;
  submittedAt?: string;
  purchaseType: PurchaseType;
  status: PurchaseRequestItemStatus;
  purchaseOrder?: {
    __typename?: 'PurchaseOrder';
    currencyConversionRate?: number;
    id: string;
    label: string;
    reconciliationStatus: PurchaseOrderReconciliationStatus;
    status: PurchaseOrderStatus;
    purchaseAmount: {
      __typename?: 'Money';
      cents: string;
      universalFormat: string;
      currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
    };
    totalSpent: {
      __typename?: 'Money';
      cents: string;
      universalFormat: string;
      currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
    };
    viewerPermissions: { __typename?: 'PurchaseOrderRecordViewerPermissions'; close: boolean };
    virtualCard?: {
      __typename?: 'VirtualCard';
      id: string;
      lowAmount: boolean;
      currency?: { __typename?: 'Currency'; code: CurrencyCode };
      actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
      spentBaseAmount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
  };
  requestedAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  requestedBaseAmount?: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
  requestedBy: { __typename?: 'User'; fullName?: string; id: string };
};

export type ShowPurchaseRequestItemTabsFragment = {
  __typename?: 'PurchaseRequestItem';
  viewerIsSubscribed: boolean;
  purchaseOrder?: { __typename?: 'PurchaseOrder'; id: string };
  viewerPermissions: {
    __typename?: 'PurchaseRequestItemRecordViewerPermissions';
    cancel: boolean;
    closePurchaseOrder?: boolean;
    destroy: boolean;
    markGoodsReceived: boolean;
    showArchivedCustomFields?: boolean;
    showEmptyCustomFields?: boolean;
    subscribeToNotifications: boolean;
    undoMarkGoodsReceived: boolean;
    unsubscribeFromNotifications: boolean;
    update: boolean;
  };
};

export type SpendLimitFragment = {
  __typename?: 'SpendLimit';
  id: string;
  runningPeriodInDays: SpendLimitRunningPeriodInDays;
  scope: SpendLimitScope;
  amount: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type SpendSummaryFragment = {
  __typename?: 'SpendSummary';
  runningPeriodInDays: SpendLimitRunningPeriodInDays;
  amount: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type SupplierAccountPreviewFragment = {
  __typename?: 'SupplierAccount';
  enableStatus?: SupplierAccountEnableStatus;
  id: string;
  onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
  addedBy?: { __typename?: 'User'; fullName?: string; id: string };
  industryCodes: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
  viewerPermissions: {
    __typename?: 'SupplierAccountRecordViewerPermissions';
    enable: boolean;
    remove: boolean;
    showDetails?: boolean;
  };
};

export type SupportedTransactionFragment = {
  __typename?: 'Transaction';
  id: string;
  merchantName: string;
  reconciliationStatus: TransactionReconciliationStatus;
  supportedDocumentTypes: Array<DocumentClassification>;
  transactionDate: string;
  originalAmount: {
    __typename?: 'Money';
    cents: string;
    universalFormat: string;
    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
  };
};

export type TimezoneFragment = {
  __typename?: 'Timezone';
  id: string;
  localName: string;
  region: TimezoneRegion;
  utcOffsetFormatted: string;
};

export type TransactionRecordFragment = {
  __typename?: 'Transaction';
  customReconciliationStatus?: CustomTransactionReconciliationStatus;
  id: string;
  merchantName: string;
  reconciliationStatus: TransactionReconciliationStatus;
  remindersSnoozed: boolean;
  remindersSnoozedUntil?: string;
  transactionDate: string;
  type: TransactionType;
  billedBaseAmount: { __typename?: 'Money'; universalFormat: string };
  merchant?: {
    __typename?: 'Merchant';
    id: string;
    country: { __typename?: 'Country'; code: CountryCode; name: string };
  };
  originalAmount: { __typename?: 'Money'; universalFormat: string };
};

export type ApprovalDelegationViewerPermissionsFragment = {
  __typename?: 'ApprovalDelegationRecordViewerPermissions';
  archive: boolean;
  destroy: boolean;
  unarchive: boolean;
  update: boolean;
};

export type ApprovalViewerPermissionsFragment = {
  __typename?: 'ApprovalRecordViewerPermissions';
  approve: boolean;
  reject: boolean;
};

export type BankAccountViewerPermissionsFragment = {
  __typename?: 'BankAccountRecordViewerPermissions';
  destroy: boolean;
};

export type CardSubscriptionViewerPermissionsFragment = {
  __typename?: 'CardSubscriptionRecordViewerPermissions';
  close: boolean;
  requestRenewal: boolean;
  update: boolean;
};

export type CategorySetViewerPermissionsFragment = {
  __typename?: 'CategorySetRecordViewerPermissions';
  archive: boolean;
  import: boolean;
  unarchive: boolean;
  update: boolean;
};

export type CategoryViewerPermissionsFragment = {
  __typename?: 'CategoryRecordViewerPermissions';
  archive: boolean;
  createChild?: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  unarchive: boolean;
  update: boolean;
};

export type CostCentreViewerPermissionsFragment = {
  __typename?: 'CostCentreRecordViewerPermissions';
  archive: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  unarchive: boolean;
  update: boolean;
};

export type CustomFieldValueViewerPermissionsFragment = {
  __typename?: 'CustomFieldValueRecordViewerPermissions';
  update: boolean;
};

export type CustomFieldViewerPermissionsFragment = {
  __typename?: 'CustomFieldRecordViewerPermissions';
  archive: boolean;
  unarchive: boolean;
  update: boolean;
};

export type DataScopingRuleViewerPermissionsFragment = {
  __typename?: 'DataScopingRuleRecordViewerPermissions';
  destroy: boolean;
  update: boolean;
  updatePosition: boolean;
};

export type DataSourceItemViewerPermissionsFragment = {
  __typename?: 'DataSourceItemRecordViewerPermissions';
  archive: boolean;
  destroy: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  unarchive: boolean;
  update: boolean;
};

export type DataSourceViewerPermissionsFragment = {
  __typename?: 'DataSourceRecordViewerPermissions';
  archive: boolean;
  destroy: boolean;
  import: boolean;
  unarchive: boolean;
  update: boolean;
};

export type DataValueViewerPermissionsFragment = {
  __typename?: 'DataValueRecordViewerPermissions';
  destroy: boolean;
  update: boolean;
};

export type DataViewFieldViewerPermissionsFragment = {
  __typename?: 'DataViewFieldRecordViewerPermissions';
  destroy: boolean;
  update: boolean;
  updatePosition: boolean;
};

export type DataViewViewerPermissionsFragment = {
  __typename?: 'DataViewRecordViewerPermissions';
  archive: boolean;
  duplicate: boolean;
  unarchive: boolean;
  update: boolean;
};

export type DocumentViewerPermissionsFragment = {
  __typename?: 'DocumentRecordViewerPermissions';
  archive: boolean;
  download: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  unarchive: boolean;
  update: boolean;
};

export type FlowStepActionViewerPermissionsFragment = {
  __typename?: 'FlowStepActionRecordViewerPermissions';
  destroy: boolean;
  update: boolean;
  updatePosition: boolean;
};

export type FlowStepConditionViewerPermissionsFragment = {
  __typename?: 'FlowStepConditionRecordViewerPermissions';
  destroy: boolean;
  update: boolean;
  updatePosition: boolean;
};

export type InvoicePaymentViewerPermissionsFragment = {
  __typename?: 'InvoicePaymentRecordViewerPermissions';
  cancel: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  update: boolean;
};

export type IssuerIntegrationConsumerViewerPermissionsFragment = {
  __typename?: 'IssuerIntegrationConsumerRecordViewerPermissions';
  destroy: boolean;
  makeInvoiceFallback?: boolean;
  makeMultiFallback?: boolean;
  makeSingleFallback?: boolean;
  update: boolean;
};

export type IssuerIntegrationRuleViewerPermissionsFragment = {
  __typename?: 'IssuerIntegrationRuleRecordViewerPermissions';
  destroy: boolean;
  update: boolean;
  updatePosition: boolean;
};

export type IssuerIntegrationViewerPermissionsFragment = {
  __typename?: 'IssuerIntegrationRecordViewerPermissions';
  archive: boolean;
  unarchive: boolean;
  update: boolean;
};

export type LocationViewerPermissionsFragment = {
  __typename?: 'LocationRecordViewerPermissions';
  archive: boolean;
  unarchive: boolean;
  update: boolean;
};

export type OrganisationUnitViewerPermissionsFragment = {
  __typename?: 'OrganisationUnitRecordViewerPermissions';
  archive: boolean;
  createChild?: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  unarchive: boolean;
  update: boolean;
};

export type PurchaseOrderViewerPermissionsFragment = {
  __typename?: 'PurchaseOrderRecordViewerPermissions';
  close: boolean;
};

export type PurchaseRequestItemViewerPermissionsFragment = {
  __typename?: 'PurchaseRequestItemRecordViewerPermissions';
  cancel: boolean;
  closePurchaseOrder?: boolean;
  destroy: boolean;
  markGoodsReceived: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  subscribeToNotifications: boolean;
  undoMarkGoodsReceived: boolean;
  unsubscribeFromNotifications: boolean;
  update: boolean;
};

export type ReportConnectionViewerPermissionsFragment = {
  __typename?: 'ReportCollectionViewerPermissions';
  create: boolean;
  index: boolean;
};

export type ReportScheduleViewerPermissionsFragment = {
  __typename?: 'ReportScheduleRecordViewerPermissions';
  archive: boolean;
  unarchive: boolean;
  update: boolean;
};

export type ReportViewerPermissionsFragment = {
  __typename?: 'ReportRecordViewerPermissions';
  destroy: boolean;
  download: boolean;
  show?: boolean;
  update: boolean;
};

export type SupplierViewerPermissionsFragment = {
  __typename?: 'SupplierAccountRecordViewerPermissions';
  enable: boolean;
  remove: boolean;
  showDetails?: boolean;
};

export type TransactionViewerPermissionsFragment = {
  __typename?: 'TransactionRecordViewerPermissions';
  addDocument?: boolean;
  markAsReceiptMissing: boolean;
  markAsReceiptUnavailable: boolean;
  snoozeReminders: boolean;
};

export type UserViewerPermissionsFragment = {
  __typename?: 'UserRecordViewerPermissions';
  archive: boolean;
  createDelegationForUser: boolean;
  disable2fa: boolean;
  enable2fa: boolean;
  invite: boolean;
  showArchivedCustomFields?: boolean;
  showEmptyCustomFields?: boolean;
  unarchive: boolean;
  update: boolean;
  updatePermissions: boolean;
  updateNotificationSettings: boolean;
};

export type VirtualCardViewerPermissionsFragment = {
  __typename?: 'VirtualCardRecordViewerPermissions';
  initiateIframeDelivery: boolean;
  revoke: boolean;
};

export type WorkflowViewerPermissionsFragment = {
  __typename?: 'WorkflowRecordViewerPermissions';
  archive: boolean;
  destroy: boolean;
  disable: boolean;
  enable: boolean;
  publish: boolean;
  unarchive: boolean;
  update: boolean;
};

export type VirtualCardSectionFragment = {
  __typename?: 'VirtualCard';
  actualNameOnCard?: string;
  cardNumberLastFour?: string;
  id: string;
  issuedAt?: string;
};

export type ApproverUserFragment = {
  __typename?: 'User';
  archived: boolean;
  fullName?: string;
  id: string;
  status: UserStatus;
};

export type AssigneePathFragment = {
  __typename?: 'DataPath';
  id: string;
  path: string;
  rootResourceType: MetaModelResourceType;
};

export type FlowStepResultValueFragment = {
  __typename?: 'FlowStepResultValue';
  id: string;
  value?: string;
  record?:
    | {
        __typename?: 'Address';
        city?: string;
        id: string;
        country: { __typename?: 'Country'; code: CountryCode; name: string };
      }
    | {
        __typename?: 'Approval';
        id: string;
        approvable?:
          | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
          | {
              __typename?: 'SupplierRequest';
              id: string;
              companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
            };
      }
    | {
        __typename?: 'ApprovalDelegation';
        archived: boolean;
        id: string;
        from: { __typename?: 'User'; fullName?: string; id: string };
        to: { __typename?: 'User'; fullName?: string; id: string };
      }
    | { __typename?: 'BuyerAccount'; companyName: string; id: string }
    | { __typename?: 'CardSubscription'; label: string; id: string }
    | { __typename?: 'Category'; archived: boolean; code: string; label: string; id: string }
    | { __typename?: 'CostCentre'; archived: boolean; code: string; label: string; id: string }
    | { __typename?: 'DataSource'; archived: boolean; label: string; id: string }
    | { __typename?: 'DataSourceItem'; archived: boolean; label: string; id: string }
    | { __typename?: 'Document'; archived: boolean; documentNumber?: string; id: string }
    | { __typename?: 'DocumentParseResult'; documentNumber?: string; id: string }
    | {
        __typename?: 'InvoicePayment';
        id: string;
        requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
      }
    | { __typename?: 'IssuerIntegration'; archived: boolean; issuerName: string; id: string }
    | { __typename?: 'Merchant'; merchantName: string; id: string }
    | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; label: string; id: string }
    | { __typename?: 'PurchaseOrder'; label: string; id: string }
    | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
    | { __typename?: 'SupplierAccount'; companyName: string; id: string }
    | {
        __typename?: 'SupplierRequest';
        id: string;
        companyLookup: { __typename?: 'CompanyLookup'; companyName?: string; id: string };
      }
    | { __typename?: 'Transaction'; transactionId: string; id: string }
    | { __typename?: 'User'; archived: boolean; fullName?: string; id: string }
    | { __typename?: 'VirtualCard'; cardNumberLastFour?: string; id: string };
};

export type ReferenceAssociationFragment = {
  __typename?: 'ReferenceAssociation';
  id: string;
  referenceRecord:
    | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
    | { __typename?: 'Category'; id: string; label: string }
    | { __typename?: 'CostCentre'; id: string; label: string }
    | { __typename?: 'DataSourceItem'; id: string; label: string }
    | { __typename?: 'IssuerIntegration'; id: string; description: string }
    | { __typename?: 'OrganisationUnit'; id: string; label: string }
    | { __typename?: 'User'; id: string; fullName?: string };
};

export type TargetPathFragment = {
  __typename?: 'DataPath';
  id: string;
  path: string;
  rootResourceType: MetaModelResourceType;
};

type WorkflowAction_FlowActionApprove_Fragment = {
  __typename?: 'FlowActionApprove';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

type WorkflowAction_FlowActionComment_Fragment = {
  __typename?: 'FlowActionComment';
  description?: string;
  id: string;
  targetPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

type WorkflowAction_FlowActionCreatePurchaseOrder_Fragment = {
  __typename?: 'FlowActionCreatePurchaseOrder';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

type WorkflowAction_FlowActionGetApproval_Fragment = {
  __typename?: 'FlowActionGetApproval';
  approvalType?: FlowActionGetApprovalApprovalType;
  assignAll: boolean;
  description?: string;
  id: string;
  approverUsers: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
    }>;
  };
  assigneePaths: {
    __typename?: 'DataPathConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataPathEdge';
      node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    }>;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

type WorkflowAction_FlowActionReject_Fragment = {
  __typename?: 'FlowActionReject';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

export type WorkflowActionFragment =
  | WorkflowAction_FlowActionApprove_Fragment
  | WorkflowAction_FlowActionComment_Fragment
  | WorkflowAction_FlowActionCreatePurchaseOrder_Fragment
  | WorkflowAction_FlowActionGetApproval_Fragment
  | WorkflowAction_FlowActionReject_Fragment;

export type WorkflowConditionFragment = {
  __typename?: 'FlowStepCondition';
  comparator?: FlowStepConditionComparatorName;
  description?: string;
  id: string;
  referenceDataType?: FlowStepConditionInputAttributeDataType;
  referencePlaceholder: string;
  referenceUnit?: string;
  referenceValue?: any;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  referenceAssociations: {
    __typename?: 'ReferenceAssociationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ReferenceAssociationEdge';
      node?: {
        __typename?: 'ReferenceAssociation';
        id: string;
        referenceRecord:
          | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
          | { __typename?: 'Category'; id: string; label: string }
          | { __typename?: 'CostCentre'; id: string; label: string }
          | { __typename?: 'DataSourceItem'; id: string; label: string }
          | { __typename?: 'IssuerIntegration'; id: string; description: string }
          | { __typename?: 'OrganisationUnit'; id: string; label: string }
          | { __typename?: 'User'; id: string; fullName?: string };
      };
    }>;
  };
  referencePaths: {
    __typename?: 'DataPathConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataPathEdge';
      node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    }>;
  };
  viewerPermissions: {
    __typename?: 'FlowStepConditionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
};

export type WorkflowVersionFragment = {
  __typename?: 'Workflow';
  id: string;
  publishedAt?: string;
  publishNotes?: string;
  version: number;
  publishedBy?: { __typename?: 'User'; fullName?: string; id: string };
};

type WorkflowBuilderNode_FlowActionApprove_Fragment = {
  __typename?: 'FlowActionApprove';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNode_FlowActionComment_Fragment = {
  __typename?: 'FlowActionComment';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNode_FlowActionCreatePurchaseOrder_Fragment = {
  __typename?: 'FlowActionCreatePurchaseOrder';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNode_FlowActionGetApproval_Fragment = {
  __typename?: 'FlowActionGetApproval';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNode_FlowActionReject_Fragment = {
  __typename?: 'FlowActionReject';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNode_FlowStepCondition_Fragment = {
  __typename?: 'FlowStepCondition';
  description?: string;
  id: string;
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type WorkflowBuilderNodeFragment =
  | WorkflowBuilderNode_FlowActionApprove_Fragment
  | WorkflowBuilderNode_FlowActionComment_Fragment
  | WorkflowBuilderNode_FlowActionCreatePurchaseOrder_Fragment
  | WorkflowBuilderNode_FlowActionGetApproval_Fragment
  | WorkflowBuilderNode_FlowActionReject_Fragment
  | WorkflowBuilderNode_FlowStepCondition_Fragment;

type WorkflowBuilderNodeAction_FlowActionApprove_Fragment = {
  __typename?: 'FlowActionApprove';
  description?: string;
  id: string;
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNodeAction_FlowActionComment_Fragment = {
  __typename?: 'FlowActionComment';
  description?: string;
  id: string;
  targetPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNodeAction_FlowActionCreatePurchaseOrder_Fragment = {
  __typename?: 'FlowActionCreatePurchaseOrder';
  description?: string;
  id: string;
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNodeAction_FlowActionGetApproval_Fragment = {
  __typename?: 'FlowActionGetApproval';
  approvalType?: FlowActionGetApprovalApprovalType;
  assignAll: boolean;
  description?: string;
  id: string;
  approverUsers: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
    }>;
  };
  assigneePaths: {
    __typename?: 'DataPathConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataPathEdge';
      node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    }>;
  };
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

type WorkflowBuilderNodeAction_FlowActionReject_Fragment = {
  __typename?: 'FlowActionReject';
  description?: string;
  id: string;
  viewerPermissions: {
    __typename?: 'FlowStepActionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type WorkflowBuilderNodeActionFragment =
  | WorkflowBuilderNodeAction_FlowActionApprove_Fragment
  | WorkflowBuilderNodeAction_FlowActionComment_Fragment
  | WorkflowBuilderNodeAction_FlowActionCreatePurchaseOrder_Fragment
  | WorkflowBuilderNodeAction_FlowActionGetApproval_Fragment
  | WorkflowBuilderNodeAction_FlowActionReject_Fragment;

export type WorkflowBuilderNodeConditionFragment = {
  __typename?: 'FlowStepCondition';
  comparator?: FlowStepConditionComparatorName;
  referenceDataType?: FlowStepConditionInputAttributeDataType;
  referencePlaceholder: string;
  referenceUnit?: string;
  referenceValue?: any;
  description?: string;
  id: string;
  inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
  referenceAssociations: {
    __typename?: 'ReferenceAssociationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ReferenceAssociationEdge';
      node?: {
        __typename?: 'ReferenceAssociation';
        id: string;
        referenceRecord:
          | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
          | { __typename?: 'Category'; id: string; label: string }
          | { __typename?: 'CostCentre'; id: string; label: string }
          | { __typename?: 'DataSourceItem'; id: string; label: string }
          | { __typename?: 'IssuerIntegration'; id: string; description: string }
          | { __typename?: 'OrganisationUnit'; id: string; label: string }
          | { __typename?: 'User'; id: string; fullName?: string };
      };
    }>;
  };
  referencePaths: {
    __typename?: 'DataPathConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataPathEdge';
      node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
    }>;
  };
  viewerPermissions: {
    __typename?: 'FlowStepConditionRecordViewerPermissions';
    destroy: boolean;
    update: boolean;
    updatePosition: boolean;
  };
  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
  outputFalseStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  outputTrueStep?:
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string };
  softValidation: {
    __typename?: 'FlowStepSoftValidation';
    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type WorkflowRunFragment = {
  __typename?: 'WorkflowRun';
  completedAt?: string;
  failedAt?: string;
  id: string;
  status: WorkflowRunStatus;
  workflow?: {
    __typename?: 'Workflow';
    baseResource: WorkflowBaseResourceName;
    id: string;
    label: string;
    triggerEvent: { __typename?: 'TriggerEvent'; key: WorkflowTriggerEventKey };
  };
};

export type AddRoleToUserMutationVariables = Exact<{
  role: UserSecurityRole;
  userId: Scalars['GlobalID']['input'];
}>;

export type AddRoleToUserMutation = {
  __typename?: 'Mutation';
  addRoleToUser?: {
    __typename?: 'AddRoleToUserPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    user?: { __typename?: 'User'; id: string; securityRoles: Array<UserSecurityRole> };
  };
};

export type ApproveApprovalMutationVariables = Exact<{
  approvalId: Scalars['GlobalID']['input'];
}>;

export type ApproveApprovalMutation = {
  __typename?: 'Mutation';
  approveApproval?: {
    __typename?: 'ApproveApprovalPayload';
    status: MutationStatus;
    approval?: {
      __typename?: 'Approval';
      approvedAt?: string;
      cancelledAt?: string;
      createdAt: string;
      id: string;
      rejectedAt?: string;
      status: ApprovalStatus;
      approvalsUsers: {
        __typename?: 'ApprovalsUserConnection';
        edges?: Array<{
          __typename?: 'ApprovalsUserEdge';
          cursor: string;
          node?: {
            __typename?: 'ApprovalsUser';
            id: string;
            user: { __typename?: 'User'; fullName?: string; id: string };
          };
        }>;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges?: Array<{
          __typename?: 'CommentEdge';
          node?: { __typename?: 'Comment'; body?: string; id: string; key?: string };
        }>;
      };
      confirmedBy?: { __typename?: 'User'; fullName?: string; id: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type ArchiveRecordMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ArchiveRecordMutation = {
  __typename?: 'Mutation';
  archiveRecord?: {
    __typename?: 'ArchiveRecordPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    record?:
      | {
          __typename?: 'ApprovalDelegation';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'ApprovalDelegationRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | { __typename?: 'BankAccount'; archived: boolean; archivedAt?: string }
      | {
          __typename?: 'Category';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CategoryRecordViewerPermissions';
            archive: boolean;
            createChild?: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategorySet';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CategorySetRecordViewerPermissions';
            archive: boolean;
            import: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentre';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CostCentreRecordViewerPermissions';
            archive: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DataSource';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DataSourceRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            import: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DataSourceItem';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DataSourceItemRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DataView';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DataViewRecordViewerPermissions';
            archive: boolean;
            duplicate: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'Document';
          id: string;
          archived: boolean;
          archivedAt?: string;
          transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            reconciliationStatus: TransactionReconciliationStatus;
          }>;
          viewerPermissions: {
            __typename?: 'DocumentRecordViewerPermissions';
            archive: boolean;
            download: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IssuerIntegration';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'IssuerIntegrationRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'Location';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'LocationRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnit';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'OrganisationUnitRecordViewerPermissions';
            archive: boolean;
            createChild?: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'ReportSchedule';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'ReportScheduleRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | { __typename?: 'SupplierOnboardingContact'; archived: boolean; archivedAt?: string }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'User';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'UserRecordViewerPermissions';
            archive: boolean;
            createDelegationForUser: boolean;
            disable2fa: boolean;
            enable2fa: boolean;
            invite: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
            updatePermissions: boolean;
            updateNotificationSettings: boolean;
          };
        }
      | {
          __typename?: 'Workflow';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'WorkflowRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            disable: boolean;
            enable: boolean;
            publish: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
  };
};

export type AttachFileToCustomFieldValueMutationVariables = Exact<{
  input: AttachFileToCustomFieldValueInput;
}>;

export type AttachFileToCustomFieldValueMutation = {
  __typename?: 'Mutation';
  attachFileToCustomFieldValue?: {
    __typename?: 'AttachFileToCustomFieldValuePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CancelInvoicePaymentMutationVariables = Exact<{
  cancellationComment: Scalars['String']['input'];
  invoicePaymentId: Scalars['GlobalID']['input'];
}>;

export type CancelInvoicePaymentMutation = {
  __typename?: 'Mutation';
  cancelInvoicePayment?: {
    __typename?: 'CancelInvoicePaymentPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    invoicePayment?: {
      __typename?: 'InvoicePayment';
      cancellationComment?: string;
      cancelledAt?: string;
      id: string;
      status: InvoicePaymentStatus;
      cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
      viewerPermissions: {
        __typename?: 'InvoicePaymentRecordViewerPermissions';
        cancel: boolean;
        showArchivedCustomFields?: boolean;
        showEmptyCustomFields?: boolean;
        update: boolean;
      };
    };
  };
};

export type CancelPurchaseRequestItemMutationVariables = Exact<{
  purchaseRequestItemId: Scalars['GlobalID']['input'];
  commentBody: Scalars['String']['input'];
}>;

export type CancelPurchaseRequestItemMutation = {
  __typename?: 'Mutation';
  cancelPurchaseRequestItem?: {
    __typename?: 'CancelPurchaseRequestItemPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    purchaseRequestItem?: {
      __typename?: 'PurchaseRequestItem';
      cancelComment?: string;
      cancelledAt?: string;
      id: string;
      status: PurchaseRequestItemStatus;
      updatedAt: string;
      cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
    };
  };
};

export type ChangeCardDeliveryPinMutationVariables = Exact<{
  pin: Scalars['String']['input'];
}>;

export type ChangeCardDeliveryPinMutation = {
  __typename?: 'Mutation';
  changeCardDeliveryPin?: {
    __typename?: 'ChangeCardDeliveryPinPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    user?: { __typename?: 'User'; id: string; hasCardDeliveryPin?: boolean };
  };
};

export type CloseCardSubscriptionMutationVariables = Exact<{
  cardSubscriptionId: Scalars['GlobalID']['input'];
  closeComment: Scalars['String']['input'];
}>;

export type CloseCardSubscriptionMutation = {
  __typename?: 'Mutation';
  closeCardSubscription?: {
    __typename?: 'CloseCardSubscriptionPayload';
    status: MutationStatus;
    cardSubscription?: {
      __typename?: 'CardSubscription';
      closedAt?: string;
      id: string;
      viewerPermissions: {
        __typename?: 'CardSubscriptionRecordViewerPermissions';
        close: boolean;
        requestRenewal: boolean;
        update: boolean;
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type ClosePurchaseOrderMutationVariables = Exact<{
  purchaseOrderId: Scalars['GlobalID']['input'];
}>;

export type ClosePurchaseOrderMutation = {
  __typename?: 'Mutation';
  closePurchaseOrder?: {
    __typename?: 'ClosePurchaseOrderPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    purchaseOrder?: {
      __typename?: 'PurchaseOrder';
      id: string;
      status: PurchaseOrderStatus;
      purchaseRequestItem: { __typename?: 'PurchaseRequestItem'; id: string; status: PurchaseRequestItemStatus };
      viewerPermissions: { __typename?: 'PurchaseOrderRecordViewerPermissions'; close: boolean };
    };
  };
};

export type ConfirmEnable2faMutationVariables = Exact<{
  otpCode: Scalars['String']['input'];
}>;

export type ConfirmEnable2faMutation = {
  __typename?: 'Mutation';
  confirmEnable2fa?: {
    __typename?: 'ConfirmEnable2faPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    user?: {
      __typename?: 'User';
      id: string;
      is2faEnabled?: boolean;
      viewerPermissions: {
        __typename?: 'UserRecordViewerPermissions';
        archive: boolean;
        createDelegationForUser: boolean;
        disable2fa: boolean;
        enable2fa: boolean;
        invite: boolean;
        showArchivedCustomFields?: boolean;
        showEmptyCustomFields?: boolean;
        unarchive: boolean;
        update: boolean;
        updatePermissions: boolean;
        updateNotificationSettings: boolean;
      };
    };
  };
};

export type CreateApprovalDelegationMutationVariables = Exact<{
  delegateFromId?: InputMaybe<Scalars['GlobalID']['input']>;
  delegateToId: Scalars['GlobalID']['input'];
  endsOn: Scalars['ISO8601Date']['input'];
  startsOn: Scalars['ISO8601Date']['input'];
}>;

export type CreateApprovalDelegationMutation = {
  __typename?: 'Mutation';
  createApprovalDelegation?: {
    __typename?: 'CreateApprovalDelegationPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateApproverCustomFieldMutationVariables = Exact<{
  attributes: CreateApproverCustomFieldAttributesInput;
}>;

export type CreateApproverCustomFieldMutation = {
  __typename?: 'Mutation';
  createApproverCustomField?: {
    __typename?: 'CreateApproverCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateAttachmentCustomFieldMutationVariables = Exact<{
  attributes: CreateAttachmentCustomFieldAttributesInput;
}>;

export type CreateAttachmentCustomFieldMutation = {
  __typename?: 'Mutation';
  createAttachmentCustomField?: {
    __typename?: 'CreateAttachmentCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateBankAccountMutationVariables = Exact<{
  attributes: CreateBankAccountInputAttributes;
}>;

export type CreateBankAccountMutation = {
  __typename?: 'Mutation';
  createBankAccount?: {
    __typename?: 'CreateBankAccountPayload';
    status: MutationStatus;
    bankAccount?: { __typename?: 'BankAccount'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;

export type CreateCategoryMutation = {
  __typename?: 'Mutation';
  createCategory?: {
    __typename?: 'CreateCategoryPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateCategoryCustomFieldMutationVariables = Exact<{
  attributes: CreateCategoryCustomFieldAttributesInput;
}>;

export type CreateCategoryCustomFieldMutation = {
  __typename?: 'Mutation';
  createCategoryCustomField?: {
    __typename?: 'CreateCategoryCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateCategorySetMutationVariables = Exact<{
  attributes: CreateCategorySetAttributesInput;
}>;

export type CreateCategorySetMutation = {
  __typename?: 'Mutation';
  createCategorySet?: {
    __typename?: 'CreateCategorySetPayload';
    status: MutationStatus;
    categorySet?: { __typename?: 'CategorySet'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
  };
};

export type CreateCheckboxCustomFieldMutationVariables = Exact<{
  attributes: CreateCheckboxCustomFieldAttributesInput;
}>;

export type CreateCheckboxCustomFieldMutation = {
  __typename?: 'Mutation';
  createCheckboxCustomField?: {
    __typename?: 'CreateCheckboxCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: 'Mutation';
  createComment?: {
    __typename?: 'CreateCommentPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    comment?: { __typename?: 'Comment'; id: string };
  };
};

export type CreateCostCentreMutationVariables = Exact<{
  input: CreateCostCentreInput;
}>;

export type CreateCostCentreMutation = {
  __typename?: 'Mutation';
  createCostCentre?: {
    __typename?: 'CreateCostCentrePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateCostCentreCustomFieldMutationVariables = Exact<{
  attributes: CreateCostCentreCustomFieldAttributesInput;
}>;

export type CreateCostCentreCustomFieldMutation = {
  __typename?: 'Mutation';
  createCostCentreCustomField?: {
    __typename?: 'CreateCostCentreCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDataFilterMutationVariables = Exact<{
  attributes: CreateDataFilterInputAttributes;
}>;

export type CreateDataFilterMutation = {
  __typename?: 'Mutation';
  createDataFilter?: {
    __typename?: 'CreateDataFilterPayload';
    status: MutationStatus;
    dataFilter?: { __typename?: 'DataFilter'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDataPathMutationVariables = Exact<{
  key: Scalars['String']['input'];
  path: Scalars['String']['input'];
  recordId: Scalars['GlobalID']['input'];
  rootResourceType: MetaModelResourceType;
}>;

export type CreateDataPathMutation = {
  __typename?: 'Mutation';
  createDataPath?: {
    __typename?: 'CreateDataPathPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDataScopingRuleMutationVariables = Exact<{
  customFieldId: Scalars['GlobalID']['input'];
  operator: DataScopingRuleOperator;
  scopeByCustomFieldId: Scalars['GlobalID']['input'];
}>;

export type CreateDataScopingRuleMutation = {
  __typename?: 'Mutation';
  createDataScopingRule?: {
    __typename?: 'CreateDataScopingRulePayload';
    status: MutationStatus;
    dataScopingRule?: { __typename?: 'DataScopingRule'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDataSourceMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
}>;

export type CreateDataSourceMutation = {
  __typename?: 'Mutation';
  createDataSource?: {
    __typename?: 'CreateDataSourcePayload';
    status: MutationStatus;
    dataSource?: { __typename?: 'DataSource'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDataSourceItemMutationVariables = Exact<{
  input: CreateDataSourceItemInput;
}>;

export type CreateDataSourceItemMutation = {
  __typename?: 'Mutation';
  createDataSourceItem?: {
    __typename?: 'CreateDataSourceItemPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    dataSourceItem?: { __typename?: 'DataSourceItem'; id: string };
  };
};

export type CreateDataValueMutationVariables = Exact<{
  attributes: CreateDataValueInputAttributes;
}>;

export type CreateDataValueMutation = {
  __typename?: 'Mutation';
  createDataValue?: {
    __typename?: 'CreateDataValuePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDataViewMutationVariables = Exact<{
  attributes: CreateDataViewInputAttributes;
}>;

export type CreateDataViewMutation = {
  __typename?: 'Mutation';
  createDataView?: {
    __typename?: 'CreateDataViewPayload';
    status: MutationStatus;
    dataView?: { __typename?: 'DataView'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
  };
};

export type CreateDataViewFieldMutationVariables = Exact<{
  attributes: CreateDataViewFieldInputAttributes;
}>;

export type CreateDataViewFieldMutation = {
  __typename?: 'Mutation';
  createDataViewField?: {
    __typename?: 'CreateDataViewFieldPayload';
    status: MutationStatus;
    dataViewField?: { __typename?: 'DataViewField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDateCustomFieldMutationVariables = Exact<{
  attributes: CreateDateCustomFieldAttributesInput;
}>;

export type CreateDateCustomFieldMutation = {
  __typename?: 'Mutation';
  createDateCustomField?: {
    __typename?: 'CreateDateCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDocumentTransactionMutationVariables = Exact<{
  documentId: Scalars['GlobalID']['input'];
  transactionId: Scalars['GlobalID']['input'];
}>;

export type CreateDocumentTransactionMutation = {
  __typename?: 'Mutation';
  createDocumentTransaction?: {
    __typename?: 'CreateDocumentTransactionPayload';
    status: MutationStatus;
    documentTransaction?: {
      __typename?: 'DocumentTransaction';
      id: string;
      document: {
        __typename?: 'Document';
        id: string;
        cardSubscription?: {
          __typename?: 'CardSubscription';
          id: string;
          viewerPermissions: {
            __typename?: 'CardSubscriptionRecordViewerPermissions';
            close: boolean;
            requestRenewal: boolean;
            update: boolean;
          };
        };
        purchaseRequestItem?: { __typename?: 'PurchaseRequestItem'; id: string; status: PurchaseRequestItemStatus };
        transactions?: Array<{
          __typename?: 'Transaction';
          id: string;
          merchantName: string;
          reconciliationStatus: TransactionReconciliationStatus;
          supportedDocumentTypes: Array<DocumentClassification>;
          transactionDate: string;
          customReconciliationStatus?: CustomTransactionReconciliationStatus;
          type: TransactionType;
          originalAmount: {
            __typename?: 'Money';
            universalFormat: string;
            cents: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        }>;
      };
      transaction: {
        __typename?: 'Transaction';
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        supportedDocumentTypes: Array<DocumentClassification>;
        transactionDate: string;
        originalAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateDraftDocumentMutationVariables = Exact<{
  blobId: Scalars['GlobalID']['input'];
  purchaseOrderId: Scalars['GlobalID']['input'];
  transactionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CreateDraftDocumentMutation = {
  __typename?: 'Mutation';
  createDraftDocument?: {
    __typename?: 'CreateDraftDocumentPayload';
    status: MutationStatus;
    document?: {
      __typename?: 'Document';
      id: string;
      purchaseRequestItem?: {
        __typename?: 'PurchaseRequestItem';
        id: string;
        requestedAmountCurrency?: { __typename?: 'Currency'; code: CurrencyCode };
        supplier?: { __typename?: 'SupplierAccount'; id: string };
      };
      supplier?: { __typename?: 'SupplierAccount'; id: string };
      totalAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      vatAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateIntegerCustomFieldMutationVariables = Exact<{
  attributes: CreateIntegerCustomFieldAttributesInput;
}>;

export type CreateIntegerCustomFieldMutation = {
  __typename?: 'Mutation';
  createIntegerCustomField?: {
    __typename?: 'CreateIntegerCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateInvoicePaymentMutationVariables = Exact<{
  documentId: Scalars['GlobalID']['input'];
}>;

export type CreateInvoicePaymentMutation = {
  __typename?: 'Mutation';
  createInvoicePayment?: {
    __typename?: 'CreateInvoicePaymentPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateIssuerIntegrationConsumerMutationVariables = Exact<{
  attributes: CreateIssuerIntegrationConsumerInputAttributes;
}>;

export type CreateIssuerIntegrationConsumerMutation = {
  __typename?: 'Mutation';
  createIssuerIntegrationConsumer?: {
    __typename?: 'CreateIssuerIntegrationConsumerPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    issuerIntegrationConsumer?: { __typename?: 'IssuerIntegrationConsumer'; id: string };
  };
};

export type CreateIssuerIntegrationRuleMutationVariables = Exact<{
  attributes: CreateIssuerIntegrationRuleInputAttributes;
}>;

export type CreateIssuerIntegrationRuleMutation = {
  __typename?: 'Mutation';
  createIssuerIntegrationRule?: {
    __typename?: 'CreateIssuerIntegrationRulePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateLocationMutationVariables = Exact<{
  attributes: CreateLocationAttributesInput;
}>;

export type CreateLocationMutation = {
  __typename?: 'Mutation';
  createLocation?: {
    __typename?: 'CreateLocationPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateMoneyCustomFieldMutationVariables = Exact<{
  attributes: CreateMoneyCustomFieldAttributesInput;
}>;

export type CreateMoneyCustomFieldMutation = {
  __typename?: 'Mutation';
  createMoneyCustomField?: {
    __typename?: 'CreateMoneyCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateOrganisationUnitMutationVariables = Exact<{
  classification: OrganisationUnitClassification;
  code: Scalars['String']['input'];
  customFieldValues?: InputMaybe<CreateCustomFieldValueInputAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  directLocationId?: InputMaybe<Scalars['GlobalID']['input']>;
  label: Scalars['String']['input'];
  parentId: Scalars['GlobalID']['input'];
}>;

export type CreateOrganisationUnitMutation = {
  __typename?: 'Mutation';
  createOrganisationUnit?: {
    __typename?: 'CreateOrganisationUnitPayload';
    status: MutationStatus;
    organisationUnit?: { __typename?: 'OrganisationUnit'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
  };
};

export type CreateOrganisationUnitCustomFieldMutationVariables = Exact<{
  attributes: CreateOrganisationUnitCustomFieldAttributesInput;
}>;

export type CreateOrganisationUnitCustomFieldMutation = {
  __typename?: 'Mutation';
  createOrganisationUnitCustomField?: {
    __typename?: 'CreateOrganisationUnitCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreatePurchaseRequestItemMutationVariables = Exact<{
  attributes: CreatePurchaseRequestItemInputAttributes;
}>;

export type CreatePurchaseRequestItemMutation = {
  __typename?: 'Mutation';
  createPurchaseRequestItem?: {
    __typename?: 'CreatePurchaseRequestItemPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    purchaseRequestItem?: { __typename?: 'PurchaseRequestItem'; id: string };
  };
};

export type CreateReferenceAssociationMutationVariables = Exact<{
  attributes: CreateReferenceAssociationInputAttributes;
}>;

export type CreateReferenceAssociationMutation = {
  __typename?: 'Mutation';
  createReferenceAssociation?: {
    __typename?: 'CreateReferenceAssociationPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateReportMutationVariables = Exact<{
  attributes: CreateReportAttributesInput;
}>;

export type CreateReportMutation = {
  __typename?: 'Mutation';
  createReport?: {
    __typename?: 'CreateReportPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateReportScheduleMutationVariables = Exact<{
  attributes: CreateReportScheduleInputAttributes;
}>;

export type CreateReportScheduleMutation = {
  __typename?: 'Mutation';
  createReportSchedule?: {
    __typename?: 'CreateReportSchedulePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    reportSchedule?: { __typename?: 'ReportSchedule'; id: string };
  };
};

export type CreateSelectCustomFieldMutationVariables = Exact<{
  attributes: CreateSelectCustomFieldAttributesInput;
}>;

export type CreateSelectCustomFieldMutation = {
  __typename?: 'Mutation';
  createSelectCustomField?: {
    __typename?: 'CreateSelectCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateSpendLimitMutationVariables = Exact<{
  attributes: CreateSpendLimitInputAttributes;
}>;

export type CreateSpendLimitMutation = {
  __typename?: 'Mutation';
  createSpendLimit?: {
    __typename?: 'CreateSpendLimitPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    spendLimit?: { __typename?: 'SpendLimit'; id: string };
  };
};

export type CreateSubscriptionRenewalRequestMutationVariables = Exact<{
  cardSubscriptionId: Scalars['GlobalID']['input'];
}>;

export type CreateSubscriptionRenewalRequestMutation = {
  __typename?: 'Mutation';
  createSubscriptionRenewalRequest?: {
    __typename?: 'CreateSubscriptionRenewalRequestPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    purchaseRequestItem?: { __typename?: 'PurchaseRequestItem'; id: string };
  };
};

export type CreateSupplierRequestMutationVariables = Exact<{
  attributes: CreateSupplierRequestInputAttributes;
}>;

export type CreateSupplierRequestMutation = {
  __typename?: 'Mutation';
  createSupplierRequest?: {
    __typename?: 'CreateSupplierRequestPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    supplierRequest?: {
      __typename?: 'SupplierRequest';
      id: string;
      companyLookup: {
        __typename?: 'CompanyLookup';
        companyName?: string;
        id: string;
        supplier?: { __typename?: 'SupplierAccount'; id: string };
      };
    };
  };
};

export type CreateTextAreaCustomFieldMutationVariables = Exact<{
  attributes: CreateTextAreaCustomFieldAttributesInput;
}>;

export type CreateTextAreaCustomFieldMutation = {
  __typename?: 'Mutation';
  createTextAreaCustomField?: {
    __typename?: 'CreateTextAreaCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateTextCustomFieldMutationVariables = Exact<{
  attributes: CreateTextCustomFieldAttributesInput;
}>;

export type CreateTextCustomFieldMutation = {
  __typename?: 'Mutation';
  createTextCustomField?: {
    __typename?: 'CreateTextCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateUrlCustomFieldMutationVariables = Exact<{
  attributes: CreateUrlCustomFieldAttributesInput;
}>;

export type CreateUrlCustomFieldMutation = {
  __typename?: 'Mutation';
  createUrlCustomField?: {
    __typename?: 'CreateUrlCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | { __typename?: 'ApproverCustomField'; id: string }
      | { __typename?: 'AttachmentCustomField'; id: string }
      | { __typename?: 'CategoryCustomField'; id: string }
      | { __typename?: 'CheckboxCustomField'; id: string }
      | { __typename?: 'CostCentreCustomField'; id: string }
      | { __typename?: 'DateCustomField'; id: string }
      | { __typename?: 'IntegerCustomField'; id: string }
      | { __typename?: 'MoneyCustomField'; id: string }
      | { __typename?: 'OrganisationUnitCustomField'; id: string }
      | { __typename?: 'SelectCustomField'; id: string }
      | { __typename?: 'TextAreaCustomField'; id: string }
      | { __typename?: 'TextCustomField'; id: string }
      | { __typename?: 'UrlCustomField'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type CreateUserMutationVariables = Exact<{
  attributes: CreateUserInputAttributes;
  invite: Scalars['Boolean']['input'];
  roles: Array<UserSecurityRole> | UserSecurityRole;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'CreateUserPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    user?: { __typename?: 'User'; id: string };
  };
};

export type CreateWorkflowMutationVariables = Exact<{
  baseResource: WorkflowBaseResourceName;
  label: Scalars['String']['input'];
  triggerEvent: WorkflowTriggerEventKey;
}>;

export type CreateWorkflowMutation = {
  __typename?: 'Mutation';
  createWorkflow?: {
    __typename?: 'CreateWorkflowPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    workflow?: { __typename?: 'Workflow'; id: string };
  };
};

export type CreateWorkflowActionMutationVariables = Exact<{
  attributes: CreateFlowStepActionInputAttributes;
}>;

export type CreateWorkflowActionMutation = {
  __typename?: 'Mutation';
  createWorkflowAction?: {
    __typename?: 'CreateWorkflowActionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowAction?:
      | {
          __typename?: 'FlowActionApprove';
          description?: string;
          id: string;
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          outputFalseStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          outputTrueStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          viewerPermissions: {
            __typename?: 'FlowStepActionRecordViewerPermissions';
            destroy: boolean;
            update: boolean;
            updatePosition: boolean;
          };
        }
      | {
          __typename?: 'FlowActionComment';
          description?: string;
          id: string;
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
          targetPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          outputFalseStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          outputTrueStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          viewerPermissions: {
            __typename?: 'FlowStepActionRecordViewerPermissions';
            destroy: boolean;
            update: boolean;
            updatePosition: boolean;
          };
        }
      | {
          __typename?: 'FlowActionCreatePurchaseOrder';
          description?: string;
          id: string;
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          outputFalseStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          outputTrueStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          viewerPermissions: {
            __typename?: 'FlowStepActionRecordViewerPermissions';
            destroy: boolean;
            update: boolean;
            updatePosition: boolean;
          };
        }
      | {
          __typename?: 'FlowActionGetApproval';
          approvalType?: FlowActionGetApprovalApprovalType;
          assignAll: boolean;
          description?: string;
          id: string;
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
          approverUsers: {
            __typename?: 'UserConnection';
            totalCount: number;
            edges?: Array<{
              __typename?: 'UserEdge';
              node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
            }>;
          };
          assigneePaths: {
            __typename?: 'DataPathConnection';
            totalCount: number;
            edges?: Array<{
              __typename?: 'DataPathEdge';
              node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
            }>;
          };
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          outputFalseStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          outputTrueStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          viewerPermissions: {
            __typename?: 'FlowStepActionRecordViewerPermissions';
            destroy: boolean;
            update: boolean;
            updatePosition: boolean;
          };
        }
      | {
          __typename?: 'FlowActionReject';
          description?: string;
          id: string;
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          outputFalseStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          outputTrueStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
          viewerPermissions: {
            __typename?: 'FlowStepActionRecordViewerPermissions';
            destroy: boolean;
            update: boolean;
            updatePosition: boolean;
          };
        };
  };
};

export type CreateWorkflowConditionMutationVariables = Exact<{
  attributes: CreateFlowStepConditionInputAttributes;
}>;

export type CreateWorkflowConditionMutation = {
  __typename?: 'Mutation';
  createWorkflowCondition?: {
    __typename?: 'CreateWorkflowConditionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowCondition?: {
      __typename?: 'FlowStepCondition';
      comparator?: FlowStepConditionComparatorName;
      description?: string;
      id: string;
      referenceDataType?: FlowStepConditionInputAttributeDataType;
      referencePlaceholder: string;
      referenceUnit?: string;
      referenceValue?: any;
      workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
      coordinates: { __typename?: 'Coordinates'; x: number; y: number };
      inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
      outputFalseStep?:
        | { __typename?: 'FlowActionApprove'; id: string }
        | { __typename?: 'FlowActionComment'; id: string }
        | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
        | { __typename?: 'FlowActionGetApproval'; id: string }
        | { __typename?: 'FlowActionReject'; id: string }
        | { __typename?: 'FlowStepCondition'; id: string };
      outputTrueStep?:
        | { __typename?: 'FlowActionApprove'; id: string }
        | { __typename?: 'FlowActionComment'; id: string }
        | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
        | { __typename?: 'FlowActionGetApproval'; id: string }
        | { __typename?: 'FlowActionReject'; id: string }
        | { __typename?: 'FlowStepCondition'; id: string };
      referenceAssociations: {
        __typename?: 'ReferenceAssociationConnection';
        totalCount: number;
        edges?: Array<{
          __typename?: 'ReferenceAssociationEdge';
          node?: {
            __typename?: 'ReferenceAssociation';
            id: string;
            referenceRecord:
              | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
              | { __typename?: 'Category'; id: string; label: string }
              | { __typename?: 'CostCentre'; id: string; label: string }
              | { __typename?: 'DataSourceItem'; id: string; label: string }
              | { __typename?: 'IssuerIntegration'; id: string; description: string }
              | { __typename?: 'OrganisationUnit'; id: string; label: string }
              | { __typename?: 'User'; id: string; fullName?: string };
          };
        }>;
      };
      referencePaths: {
        __typename?: 'DataPathConnection';
        totalCount: number;
        edges?: Array<{
          __typename?: 'DataPathEdge';
          node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
        }>;
      };
      viewerPermissions: {
        __typename?: 'FlowStepConditionRecordViewerPermissions';
        destroy: boolean;
        update: boolean;
        updatePosition: boolean;
      };
    };
  };
};

export type DeleteDocumentTransactionMutationVariables = Exact<{
  documentId: Scalars['GlobalID']['input'];
  transactionId: Scalars['GlobalID']['input'];
}>;

export type DeleteDocumentTransactionMutation = {
  __typename?: 'Mutation';
  deleteDocumentTransaction?: {
    __typename?: 'DeleteDocumentTransactionPayload';
    status: MutationStatus;
    documentTransaction?: {
      __typename?: 'DocumentTransaction';
      id: string;
      document: {
        __typename?: 'Document';
        id: string;
        cardSubscription?: {
          __typename?: 'CardSubscription';
          cardStatus: CardSubscriptionCardStatus;
          closedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CardSubscriptionRecordViewerPermissions';
            close: boolean;
            requestRenewal: boolean;
            update: boolean;
          };
        };
        purchaseRequestItem?: { __typename?: 'PurchaseRequestItem'; id: string; status: PurchaseRequestItemStatus };
        transactions?: Array<{
          __typename?: 'Transaction';
          id: string;
          merchantName: string;
          reconciliationStatus: TransactionReconciliationStatus;
          supportedDocumentTypes: Array<DocumentClassification>;
          transactionDate: string;
          originalAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        }>;
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type DeleteRecordMutationVariables = Exact<{
  recordId: Scalars['GlobalID']['input'];
}>;

export type DeleteRecordMutation = {
  __typename?: 'Mutation';
  deleteRecord?: {
    __typename?: 'DeleteRecordPayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
  };
};

export type DeliverVirtualCardInAppMutationVariables = Exact<{
  virtualCardId: Scalars['GlobalID']['input'];
}>;

export type DeliverVirtualCardInAppMutation = {
  __typename?: 'Mutation';
  deliverVirtualCardInApp?: {
    __typename?: 'DeliverVirtualCardInAppPayload';
    status: MutationStatus;
    deliveryInfo?: { __typename?: 'VirtualCardInAppDeliveryInfo'; iframeUrl: { __typename?: 'URL'; url: string } };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type DetachFileFromCustomFieldValueMutationVariables = Exact<{
  input: DetachFileFromCustomFieldValueInput;
}>;

export type DetachFileFromCustomFieldValueMutation = {
  __typename?: 'Mutation';
  detachFileFromCustomFieldValue?: {
    __typename?: 'DetachFileFromCustomFieldValuePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type Disable2faMutationVariables = Exact<{
  otpCode: Scalars['String']['input'];
}>;

export type Disable2faMutation = {
  __typename?: 'Mutation';
  disable2fa?: {
    __typename?: 'Disable2faPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    user?: {
      __typename?: 'User';
      id: string;
      is2faEnabled?: boolean;
      viewerPermissions: {
        __typename?: 'UserRecordViewerPermissions';
        archive: boolean;
        createDelegationForUser: boolean;
        disable2fa: boolean;
        enable2fa: boolean;
        invite: boolean;
        showArchivedCustomFields?: boolean;
        showEmptyCustomFields?: boolean;
        unarchive: boolean;
        update: boolean;
        updatePermissions: boolean;
        updateNotificationSettings: boolean;
      };
    };
  };
};

export type DisableWorkflowMutationVariables = Exact<{
  workflowId: Scalars['GlobalID']['input'];
}>;

export type DisableWorkflowMutation = {
  __typename?: 'Mutation';
  disableWorkflow?: {
    __typename?: 'DisableWorkflowPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    workflow?: {
      __typename?: 'Workflow';
      enabledAt?: string;
      id: string;
      viewerPermissions: {
        __typename?: 'WorkflowRecordViewerPermissions';
        archive: boolean;
        destroy: boolean;
        disable: boolean;
        enable: boolean;
        publish: boolean;
        unarchive: boolean;
        update: boolean;
      };
    };
  };
};

export type DownloadAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['GlobalID']['input'];
}>;

export type DownloadAttachmentMutation = {
  __typename?: 'Mutation';
  downloadAttachment?: {
    __typename?: 'DownloadAttachmentPayload';
    status: MutationStatus;
    downloadUrl?: { __typename?: 'URL'; url: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type DownloadPurchaseOrderDocumentsMutationVariables = Exact<{
  purchaseOrderId: Scalars['GlobalID']['input'];
}>;

export type DownloadPurchaseOrderDocumentsMutation = {
  __typename?: 'Mutation';
  downloadPurchaseOrderDocuments?: {
    __typename?: 'DownloadPurchaseOrderDocumentsPayload';
    status: MutationStatus;
    downloadUrl?: { __typename?: 'URL'; url: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type DuplicateDataViewMutationVariables = Exact<{
  dataViewId: Scalars['GlobalID']['input'];
}>;

export type DuplicateDataViewMutation = {
  __typename?: 'Mutation';
  duplicateDataView?: {
    __typename?: 'DuplicateDataViewPayload';
    status: MutationStatus;
    dataView?: { __typename?: 'DataView'; id: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type Enable2faMutationVariables = Exact<{ [key: string]: never }>;

export type Enable2faMutation = {
  __typename?: 'Mutation';
  enable2fa?: {
    __typename?: 'Enable2faPayload';
    codes: Array<string>;
    otpProvisioningUri: string;
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type EnableSupplierMutationVariables = Exact<{
  supplierId: Scalars['GlobalID']['input'];
}>;

export type EnableSupplierMutation = {
  __typename?: 'Mutation';
  enableSupplier?: {
    __typename?: 'EnableSupplierPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    supplier?: {
      __typename?: 'SupplierAccount';
      enableStatus?: SupplierAccountEnableStatus;
      id: string;
      viewerPermissions: {
        __typename?: 'SupplierAccountRecordViewerPermissions';
        enable: boolean;
        remove: boolean;
        showDetails?: boolean;
      };
    };
  };
};

export type EnableWorkflowMutationVariables = Exact<{
  workflowId: Scalars['GlobalID']['input'];
}>;

export type EnableWorkflowMutation = {
  __typename?: 'Mutation';
  enableWorkflow?: {
    __typename?: 'EnableWorkflowPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    workflow?: {
      __typename?: 'Workflow';
      enabledAt?: string;
      id: string;
      viewerPermissions: {
        __typename?: 'WorkflowRecordViewerPermissions';
        archive: boolean;
        destroy: boolean;
        disable: boolean;
        enable: boolean;
        publish: boolean;
        unarchive: boolean;
        update: boolean;
      };
    };
  };
};

export type GetImportFileMetadataMutationVariables = Exact<{
  blobId: Scalars['GlobalID']['input'];
}>;

export type GetImportFileMetadataMutation = {
  __typename?: 'Mutation';
  getImportFileMetadata?: {
    __typename?: 'GetImportFileMetadataPayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    importFileMetadata?: { __typename?: 'ImportFileData'; columnsNames: Array<string>; rowCount: number };
  };
};

export type ImportCategoriesMutationVariables = Exact<{
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  categorySetId: Scalars['GlobalID']['input'];
  mappings: CategoriesImportMappingsAttributesInput;
}>;

export type ImportCategoriesMutation = {
  __typename?: 'Mutation';
  importCategories?: {
    __typename?: 'ImportCategoriesPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    importResult?: {
      __typename?: 'ImportResult';
      createdRecordsCount: number;
      updatedRecordsCount: number;
      failures: {
        __typename?: 'Failures';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
      skipped: {
        __typename?: 'Skipped';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
    };
  };
};

export type ImportCostCentresMutationVariables = Exact<{
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  mappings: CostCentresImportMappingsAttributesInput;
}>;

export type ImportCostCentresMutation = {
  __typename?: 'Mutation';
  importCostCentres?: {
    __typename?: 'ImportCostCentresPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    importResult?: {
      __typename?: 'ImportResult';
      createdRecordsCount: number;
      updatedRecordsCount: number;
      failures: {
        __typename?: 'Failures';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
      skipped: {
        __typename?: 'Skipped';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
    };
  };
};

export type ImportDataSourceItemsMutationVariables = Exact<{
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  dataSourceId: Scalars['GlobalID']['input'];
  mappings: DataSourceItemsImportMappingsAttributesInput;
}>;

export type ImportDataSourceItemsMutation = {
  __typename?: 'Mutation';
  importDataSourceItems?: {
    __typename?: 'ImportDataSourceItemsPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    importResult?: {
      __typename?: 'ImportResult';
      createdRecordsCount: number;
      updatedRecordsCount: number;
      failures: {
        __typename?: 'Failures';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
      skipped: {
        __typename?: 'Skipped';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
    };
  };
};

export type InviteAllDraftUsersMutationVariables = Exact<{ [key: string]: never }>;

export type InviteAllDraftUsersMutation = {
  __typename?: 'Mutation';
  inviteAllDraftUsers?: {
    __typename?: 'InviteAllDraftUsersPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type InviteDraftUsersMutationVariables = Exact<{
  userIds: Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input'];
}>;

export type InviteDraftUsersMutation = {
  __typename?: 'Mutation';
  inviteDraftUsers?: {
    __typename?: 'InviteDraftUsersPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    users?: Array<{ __typename?: 'User'; id: string }>;
  };
};

export type LookupCompanyMutationVariables = Exact<{
  countryCode: CountryCode;
  vatNumber: Scalars['String']['input'];
}>;

export type LookupCompanyMutation = {
  __typename?: 'Mutation';
  lookupCompany?: {
    __typename?: 'LookupCompanyPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    result?: {
      __typename?: 'LookupCompanyResult';
      companyLookup?: {
        __typename?: 'CompanyLookup';
        companyName?: string;
        id: string;
        country: { __typename?: 'Country'; code: CountryCode; name: string };
      };
      supplier?: {
        __typename?: 'SupplierAccount';
        companyName: string;
        id: string;
        enableStatus?: SupplierAccountEnableStatus;
        onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
        viewerPermissions: {
          __typename?: 'SupplierAccountRecordViewerPermissions';
          enable: boolean;
          remove: boolean;
          showDetails?: boolean;
        };
        address?: {
          __typename?: 'Address';
          city?: string;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
      };
    };
  };
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllNotificationsAsReadMutation = {
  __typename?: 'Mutation';
  markAllNotificationsAsRead?: {
    __typename?: 'MarkAllNotificationsAsReadPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    notifications: Array<{ __typename?: 'Notification'; id: string; isRead: boolean; readAt?: string }>;
  };
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationId: Scalars['GlobalID']['input'];
}>;

export type MarkNotificationAsReadMutation = {
  __typename?: 'Mutation';
  markNotificationAsRead?: {
    __typename?: 'MarkNotificationAsReadPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    notification: { __typename?: 'Notification'; id: string; isRead: boolean; readAt?: string };
  };
};

export type MarkTransactionAsReceiptMissingMutationVariables = Exact<{
  transactionId: Scalars['GlobalID']['input'];
}>;

export type MarkTransactionAsReceiptMissingMutation = {
  __typename?: 'Mutation';
  markTransactionAsReceiptMissing?: {
    __typename?: 'MarkTransactionAsReceiptMissingPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    transaction: {
      __typename?: 'Transaction';
      id: string;
      reconciliationStatus: TransactionReconciliationStatus;
      viewerPermissions: {
        __typename?: 'TransactionRecordViewerPermissions';
        addDocument?: boolean;
        markAsReceiptMissing: boolean;
        markAsReceiptUnavailable: boolean;
        snoozeReminders: boolean;
      };
    };
  };
};

export type MarkTransactionAsReceiptUnavailableMutationVariables = Exact<{
  transactionId: Scalars['GlobalID']['input'];
}>;

export type MarkTransactionAsReceiptUnavailableMutation = {
  __typename?: 'Mutation';
  markTransactionAsReceiptUnavailable?: {
    __typename?: 'MarkTransactionAsReceiptUnavailablePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    transaction: {
      __typename?: 'Transaction';
      id: string;
      reconciliationStatus: TransactionReconciliationStatus;
      viewerPermissions: {
        __typename?: 'TransactionRecordViewerPermissions';
        addDocument?: boolean;
        markAsReceiptMissing: boolean;
        markAsReceiptUnavailable: boolean;
        snoozeReminders: boolean;
      };
    };
  };
};

export type ProcessOnboardingCaseMutationVariables = Exact<{
  supplierOnboardingCaseId: Scalars['GlobalID']['input'];
}>;

export type ProcessOnboardingCaseMutation = {
  __typename?: 'Mutation';
  processOnboardingCase?: {
    __typename?: 'ProcessOnboardingCasePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    supplierOnboardingCase?: {
      __typename?: 'SupplierOnboardingCase';
      id: string;
      onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
    };
  };
};

export type PublishWorkflowMutationVariables = Exact<{
  publishNotes?: InputMaybe<Scalars['String']['input']>;
  workflowId: Scalars['GlobalID']['input'];
}>;

export type PublishWorkflowMutation = {
  __typename?: 'Mutation';
  publishWorkflow?: {
    __typename?: 'PublishWorkflowPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    workflow?: {
      __typename?: 'Workflow';
      id: string;
      publishedAt?: string;
      publishNotes?: string;
      viewerPermissions: {
        __typename?: 'WorkflowRecordViewerPermissions';
        archive: boolean;
        destroy: boolean;
        disable: boolean;
        enable: boolean;
        publish: boolean;
        unarchive: boolean;
        update: boolean;
      };
    };
  };
};

export type RefreshOnboardingContactMutationVariables = Exact<{
  supplierOnboardingContactId: Scalars['GlobalID']['input'];
}>;

export type RefreshOnboardingContactMutation = {
  __typename?: 'Mutation';
  refreshOnboardingContact?: {
    __typename?: 'RefreshOnboardingContactPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    supplierOnboardingContact?: {
      __typename?: 'SupplierOnboardingContact';
      id: string;
      onboardingStatus: SupplierOnboardingContactOnboardingStatus;
      onboardingUrl?: { __typename?: 'URL'; url: string };
    };
  };
};

export type RejectApprovalMutationVariables = Exact<{
  approvalId: Scalars['GlobalID']['input'];
  commentBody?: InputMaybe<Scalars['String']['input']>;
}>;

export type RejectApprovalMutation = {
  __typename?: 'Mutation';
  rejectApproval?: {
    __typename?: 'RejectApprovalPayload';
    status: MutationStatus;
    approval?: {
      __typename?: 'Approval';
      approvedAt?: string;
      cancelledAt?: string;
      createdAt: string;
      id: string;
      rejectedAt?: string;
      status: ApprovalStatus;
      approvalsUsers: {
        __typename?: 'ApprovalsUserConnection';
        edges?: Array<{
          __typename?: 'ApprovalsUserEdge';
          cursor: string;
          node?: {
            __typename?: 'ApprovalsUser';
            id: string;
            user: { __typename?: 'User'; fullName?: string; id: string };
          };
        }>;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges?: Array<{
          __typename?: 'CommentEdge';
          node?: { __typename?: 'Comment'; body?: string; id: string; key?: string };
        }>;
      };
      confirmedBy?: { __typename?: 'User'; fullName?: string; id: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type RemoveRoleFromUserMutationVariables = Exact<{
  role: UserSecurityRole;
  userId: Scalars['GlobalID']['input'];
}>;

export type RemoveRoleFromUserMutation = {
  __typename?: 'Mutation';
  removeRoleFromUser?: {
    __typename?: 'RemoveRoleFromUserPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    user?: { __typename?: 'User'; id: string; securityRoles: Array<UserSecurityRole> };
  };
};

export type RemoveSupplierMutationVariables = Exact<{
  disableComment: Scalars['String']['input'];
  supplierId: Scalars['GlobalID']['input'];
}>;

export type RemoveSupplierMutation = {
  __typename?: 'Mutation';
  removeSupplier?: {
    __typename?: 'RemoveSupplierPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    supplier?: {
      __typename?: 'SupplierAccount';
      disableComment?: string;
      enableStatus?: SupplierAccountEnableStatus;
      id: string;
      viewerPermissions: {
        __typename?: 'SupplierAccountRecordViewerPermissions';
        enable: boolean;
        remove: boolean;
        showDetails?: boolean;
      };
    };
  };
};

export type RenameWorkflowMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
  label: Scalars['String']['input'];
}>;

export type RenameWorkflowMutation = {
  __typename?: 'Mutation';
  updateWorkflow?: {
    __typename?: 'UpdateWorkflowPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    workflow?: { __typename?: 'Workflow'; id: string; label: string };
  };
};

export type RevokeVirtualCardMutationVariables = Exact<{
  revokeComment: Scalars['String']['input'];
  virtualCardId: Scalars['GlobalID']['input'];
}>;

export type RevokeVirtualCardMutation = {
  __typename?: 'Mutation';
  revokeVirtualCard?: {
    __typename?: 'RevokeVirtualCardPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    virtualCard?: {
      __typename?: 'VirtualCard';
      id: string;
      revokeComment?: string;
      revokedAt?: string;
      status: VirtualCardStatus;
      updatedAt: string;
      revokedBy?: {
        __typename?: 'User';
        id: string;
        fullName?: string;
        profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
      };
      viewerPermissions: {
        __typename?: 'VirtualCardRecordViewerPermissions';
        initiateIframeDelivery: boolean;
        revoke: boolean;
      };
    };
  };
};

export type SnoozeRemindersForTransactionMutationVariables = Exact<{
  input: SnoozeRemindersForTransactionInput;
}>;

export type SnoozeRemindersForTransactionMutation = {
  __typename?: 'Mutation';
  snoozeRemindersForTransaction?: {
    __typename?: 'SnoozeRemindersForTransactionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    transaction?: { __typename?: 'Transaction'; id: string; remindersSnoozed: boolean; remindersSnoozedUntil?: string };
  };
};

export type SubmitDocumentMutationVariables = Exact<{
  documentId: Scalars['GlobalID']['input'];
}>;

export type SubmitDocumentMutation = {
  __typename?: 'Mutation';
  submitDocument?: {
    __typename?: 'SubmitDocumentPayload';
    status: MutationStatus;
    document?: {
      __typename?: 'Document';
      id: string;
      isDraft: boolean;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        reconciliationStatus: TransactionReconciliationStatus;
      }>;
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type SubmitPurchaseRequestItemMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type SubmitPurchaseRequestItemMutation = {
  __typename?: 'Mutation';
  submitPurchaseRequestItem?: {
    __typename?: 'SubmitPurchaseRequestItemPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type SubscribeToNotificationsMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
  userId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type SubscribeToNotificationsMutation = {
  __typename?: 'Mutation';
  subscribeToNotifications?: {
    __typename?: 'SubscribeToNotificationsPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    record?:
      | { __typename?: 'Document'; viewerIsSubscribed: boolean }
      | { __typename?: 'PurchaseOrder'; viewerIsSubscribed: boolean }
      | { __typename?: 'PurchaseRequestItem'; id: string; viewerIsSubscribed: boolean }
      | { __typename?: 'Report'; viewerIsSubscribed: boolean }
      | { __typename?: 'ReportSchedule'; id: string; viewerIsSubscribed: boolean }
      | { __typename?: 'SupplierOnboardingCase'; viewerIsSubscribed: boolean }
      | { __typename?: 'SupplierPayout'; viewerIsSubscribed: boolean }
      | { __typename?: 'SupplierRequest'; viewerIsSubscribed: boolean };
  };
};

export type UnarchiveRecordMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type UnarchiveRecordMutation = {
  __typename?: 'Mutation';
  unarchiveRecord?: {
    __typename?: 'UnarchiveRecordPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    record?:
      | {
          __typename?: 'ApprovalDelegation';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'ApprovalDelegationRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | { __typename?: 'BankAccount'; archived: boolean; archivedAt?: string }
      | {
          __typename?: 'Category';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CategoryRecordViewerPermissions';
            archive: boolean;
            createChild?: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategorySet';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CategorySetRecordViewerPermissions';
            archive: boolean;
            import: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentre';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CostCentreRecordViewerPermissions';
            archive: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DataSource';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DataSourceRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            import: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DataSourceItem';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DataSourceItemRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DataView';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DataViewRecordViewerPermissions';
            archive: boolean;
            duplicate: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'Document';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'DocumentRecordViewerPermissions';
            archive: boolean;
            download: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IssuerIntegration';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'IssuerIntegrationRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'Location';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'LocationRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnit';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'OrganisationUnitRecordViewerPermissions';
            archive: boolean;
            createChild?: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'ReportSchedule';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'ReportScheduleRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | { __typename?: 'SupplierOnboardingContact'; archived: boolean; archivedAt?: string }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'User';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'UserRecordViewerPermissions';
            archive: boolean;
            createDelegationForUser: boolean;
            disable2fa: boolean;
            enable2fa: boolean;
            invite: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
            updatePermissions: boolean;
            updateNotificationSettings: boolean;
          };
        }
      | {
          __typename?: 'Workflow';
          archived: boolean;
          archivedAt?: string;
          id: string;
          viewerPermissions: {
            __typename?: 'WorkflowRecordViewerPermissions';
            archive: boolean;
            destroy: boolean;
            disable: boolean;
            enable: boolean;
            publish: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
  };
};

export type UnsubscribeFromNotificationsMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
  userId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type UnsubscribeFromNotificationsMutation = {
  __typename?: 'Mutation';
  unsubscribeFromNotifications?: {
    __typename?: 'UnsubscribeFromNotificationsPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    record?:
      | { __typename?: 'Document'; viewerIsSubscribed: boolean }
      | { __typename?: 'PurchaseOrder'; viewerIsSubscribed: boolean }
      | { __typename?: 'PurchaseRequestItem'; id: string; viewerIsSubscribed: boolean }
      | { __typename?: 'Report'; viewerIsSubscribed: boolean }
      | { __typename?: 'ReportSchedule'; id: string; viewerIsSubscribed: boolean }
      | { __typename?: 'SupplierOnboardingCase'; viewerIsSubscribed: boolean }
      | { __typename?: 'SupplierPayout'; viewerIsSubscribed: boolean }
      | { __typename?: 'SupplierRequest'; viewerIsSubscribed: boolean };
  };
};

export type UpdateAccountProfileMutationVariables = Exact<{
  profile: UpdateAccountAttributesInput;
}>;

export type UpdateAccountProfileMutation = {
  __typename?: 'Mutation';
  updateAccountProfile?: {
    __typename?: 'UpdateAccountProfilePayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    account: {
      __typename?: 'BuyerAccount';
      accountType: AccountUseType;
      companyName: string;
      id: string;
      publicEmail?: string;
      publicPhone?: string;
      publicWebsite?: string;
      updatedAt: string;
      vatNumber?: string;
      address?: {
        __typename?: 'Address';
        city?: string;
        id: string;
        postalCode?: string;
        streetName?: string;
        streetNumber?: string;
        country: { __typename?: 'Country'; code: CountryCode; name: string };
      };
      coverImage?: {
        __typename?: 'Attachment';
        byteSize: number;
        contentType: string;
        downloadUrl: string;
        filename: string;
        id: string;
        key: string;
        name: string;
      };
      profileImage?: {
        __typename?: 'Attachment';
        byteSize: number;
        contentType: string;
        downloadUrl: string;
        filename: string;
        id: string;
        key: string;
        name: string;
      };
    };
  };
};

export type UpdateAccountSettingsMutationVariables = Exact<{
  settings?: InputMaybe<AccountSettingsInput>;
}>;

export type UpdateAccountSettingsMutation = {
  __typename?: 'Mutation';
  updateAccountSettings?: {
    __typename?: 'UpdateAccountSettingsPayload';
    status: MutationStatus;
    account?: {
      __typename?: 'BuyerAccount';
      id: string;
      settings?: {
        __typename?: 'AccountSettings';
        categorisation: { __typename?: 'CategorisationSettings'; categorySetId?: string };
        localisation: {
          __typename?: 'AccountLocalisationSettings';
          timezone: string;
          interfaceLanguage: { __typename?: 'Language'; code: LanguageCode; englishName: string; nativeName: string };
        };
        payment: {
          __typename?: 'PaymentSettings';
          baseCurrency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          virtualCardPayments: {
            __typename?: 'VirtualCardPaymentSettings';
            multiUseCard: { __typename?: 'MultiUseCard'; defaultTtlDays: number; maxTtlDays: number };
            singleUseCard: { __typename?: 'SingleUseCard'; defaultTtlDays: number; maxTtlDays: number };
          };
        };
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateApprovalDelegationMutationVariables = Exact<{
  approvalDelegationId: Scalars['GlobalID']['input'];
  delegateToId?: InputMaybe<Scalars['GlobalID']['input']>;
  endsOn?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startsOn?: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type UpdateApprovalDelegationMutation = {
  __typename?: 'Mutation';
  updateApprovalDelegation?: {
    __typename?: 'UpdateApprovalDelegationPayload';
    status: MutationStatus;
    approvalDelegation?: {
      __typename?: 'ApprovalDelegation';
      endsOn: string;
      id: string;
      startsOn: string;
      to: { __typename?: 'User'; fullName?: string; id: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateApproverCustomFieldMutationVariables = Exact<{
  input: UpdateApproverCustomFieldInput;
}>;

export type UpdateApproverCustomFieldMutation = {
  __typename?: 'Mutation';
  updateApproverCustomField?: {
    __typename?: 'UpdateApproverCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateAttachmentCustomFieldMutationVariables = Exact<{
  input: UpdateAttachmentCustomFieldInput;
}>;

export type UpdateAttachmentCustomFieldMutation = {
  __typename?: 'Mutation';
  updateAttachmentCustomField?: {
    __typename?: 'UpdateAttachmentCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCardSubscriptionMutationVariables = Exact<{
  input: UpdateCardSubscriptionInput;
}>;

export type UpdateCardSubscriptionMutation = {
  __typename?: 'Mutation';
  updateCardSubscription?: {
    __typename?: 'UpdateCardSubscriptionPayload';
    status: MutationStatus;
    cardSubscription?: {
      __typename?: 'CardSubscription';
      description?: string;
      id: string;
      label: string;
      totalApprovedDisplayAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      totalSpentDisplayAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type UpdateCategoryMutation = {
  __typename?: 'Mutation';
  updateCategory?: {
    __typename?: 'UpdateCategoryPayload';
    status: MutationStatus;
    category?: {
      __typename?: 'Category';
      archived: boolean;
      childrenCount: number;
      code: string;
      id: string;
      label: string;
      parentId?: string;
      viewerPermissions: {
        __typename?: 'CategoryRecordViewerPermissions';
        archive: boolean;
        createChild?: boolean;
        showArchivedCustomFields?: boolean;
        showEmptyCustomFields?: boolean;
        unarchive: boolean;
        update: boolean;
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCategoryCustomFieldMutationVariables = Exact<{
  input: UpdateCategoryCustomFieldInput;
}>;

export type UpdateCategoryCustomFieldMutation = {
  __typename?: 'Mutation';
  updateCategoryCustomField?: {
    __typename?: 'UpdateCategoryCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCategorySetMutationVariables = Exact<{
  input: UpdateCategorySetInput;
}>;

export type UpdateCategorySetMutation = {
  __typename?: 'Mutation';
  updateCategorySet?: {
    __typename?: 'UpdateCategorySetPayload';
    status: MutationStatus;
    categorySet?: { __typename?: 'CategorySet'; id: string; label: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCheckboxCustomFieldMutationVariables = Exact<{
  input: UpdateCheckboxCustomFieldInput;
}>;

export type UpdateCheckboxCustomFieldMutation = {
  __typename?: 'Mutation';
  updateCheckboxCustomField?: {
    __typename?: 'UpdateCheckboxCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCostCentreMutationVariables = Exact<{
  input: UpdateCostCentreInput;
}>;

export type UpdateCostCentreMutation = {
  __typename?: 'Mutation';
  updateCostCentre?: {
    __typename?: 'UpdateCostCentrePayload';
    status: MutationStatus;
    costCentre?: { __typename?: 'CostCentre'; code: string; description?: string; id: string; label: string };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateCostCentreCustomFieldMutationVariables = Exact<{
  input: UpdateCostCentreCustomFieldInput;
}>;

export type UpdateCostCentreCustomFieldMutation = {
  __typename?: 'Mutation';
  updateCostCentreCustomField?: {
    __typename?: 'UpdateCostCentreCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDataFilterMutationVariables = Exact<{
  input: UpdateDataFilterInput;
}>;

export type UpdateDataFilterMutation = {
  __typename?: 'Mutation';
  updateDataFilter?: {
    __typename?: 'UpdateDataFilterPayload';
    status: MutationStatus;
    dataFilter?: {
      __typename?: 'DataFilter';
      comparator: FlowStepConditionComparatorName;
      id: string;
      referenceValue?: any;
      updatedAt: string;
      inputPath?: { __typename?: 'DataPath'; id: string; path: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDataPathMutationVariables = Exact<{
  dataPathId: Scalars['GlobalID']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateDataPathMutation = {
  __typename?: 'Mutation';
  updateDataPath?: {
    __typename?: 'UpdateDataPathPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDataScopingRuleMutationVariables = Exact<{
  dataScopingRuleId: Scalars['GlobalID']['input'];
  operator?: InputMaybe<DataScopingRuleOperator>;
  position?: InputMaybe<Scalars['Int']['input']>;
  scopeByCustomFieldId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type UpdateDataScopingRuleMutation = {
  __typename?: 'Mutation';
  updateDataScopingRule?: {
    __typename?: 'UpdateDataScopingRulePayload';
    status: MutationStatus;
    dataScopingRule?: {
      __typename?: 'DataScopingRule';
      id: string;
      operator: DataScopingRuleOperator;
      position: number;
      scopeByCustomField:
        | { __typename?: 'ApproverCustomField'; id: string; label: string }
        | { __typename?: 'AttachmentCustomField'; id: string; label: string }
        | { __typename?: 'CategoryCustomField'; id: string; label: string }
        | { __typename?: 'CheckboxCustomField'; id: string; label: string }
        | { __typename?: 'CostCentreCustomField'; id: string; label: string }
        | { __typename?: 'DateCustomField'; id: string; label: string }
        | { __typename?: 'IntegerCustomField'; id: string; label: string }
        | { __typename?: 'MoneyCustomField'; id: string; label: string }
        | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
        | { __typename?: 'SelectCustomField'; id: string; label: string }
        | { __typename?: 'TextAreaCustomField'; id: string; label: string }
        | { __typename?: 'TextCustomField'; id: string; label: string }
        | { __typename?: 'UrlCustomField'; id: string; label: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDataSourceMutationVariables = Exact<{
  dataSourceId: Scalars['GlobalID']['input'];
  attributes: UpdateDataSourceAttributesInput;
}>;

export type UpdateDataSourceMutation = {
  __typename?: 'Mutation';
  updateDataSource?: {
    __typename?: 'UpdateDataSourcePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    dataSource?: { __typename?: 'DataSource'; id: string; label: string; description?: string; updatedAt: string };
  };
};

export type UpdateDataSourceItemMutationVariables = Exact<{
  input: UpdateDataSourceItemInput;
}>;

export type UpdateDataSourceItemMutation = {
  __typename?: 'Mutation';
  updateDataSourceItem?: {
    __typename?: 'UpdateDataSourceItemPayload';
    status: MutationStatus;
    dataSourceItem?: {
      __typename?: 'DataSourceItem';
      description?: string;
      externalId?: string;
      id: string;
      label: string;
      dataSource: { __typename?: 'DataSource'; id: string; updatedAt: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDataValueMutationVariables = Exact<{
  attributes: UpdateDataValueInputAttributes;
  dataValueId: Scalars['GlobalID']['input'];
}>;

export type UpdateDataValueMutation = {
  __typename?: 'Mutation';
  updateDataValue?: {
    __typename?: 'UpdateDataValuePayload';
    status: MutationStatus;
    dataValue?: {
      __typename?: 'DataValue';
      dateValue?: string;
      id: string;
      staticValue?: string;
      valueSource: DataValueValueSource;
      viewerPermissions: { __typename?: 'DataValueRecordViewerPermissions'; destroy: boolean; update: boolean };
      amountValue?: { __typename?: 'Money'; universalFormat: string };
      pathValue?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
      recordValue?:
        | { __typename?: 'Category'; code: string; id: string; label: string }
        | { __typename?: 'CostCentre'; code: string; id: string; label: string }
        | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
        | { __typename?: 'User'; fullName?: string; id: string };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDataViewMutationVariables = Exact<{
  dataViewId: Scalars['GlobalID']['input'];
  attributes: UpdateDataViewInputAttributes;
}>;

export type UpdateDataViewMutation = {
  __typename?: 'Mutation';
  updateDataView?: {
    __typename?: 'UpdateDataViewPayload';
    status: MutationStatus;
    dataView?: {
      __typename?: 'DataView';
      description?: string;
      id: string;
      label: string;
      resourceType: DataViewResourceType;
    };
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
  };
};

export type UpdateDataViewFieldMutationVariables = Exact<{
  dataViewFieldId: Scalars['GlobalID']['input'];
  attributes: UpdateDataViewFieldInputAttributes;
}>;

export type UpdateDataViewFieldMutation = {
  __typename?: 'Mutation';
  updateDataViewField?: {
    __typename?: 'UpdateDataViewFieldPayload';
    status: MutationStatus;
    dataViewField?: {
      __typename?: 'DataViewField';
      formatString?: DataViewFieldFormatString;
      id: string;
      label: string;
      position: number;
      updatedAt: string;
      dataValue?: {
        __typename?: 'DataValue';
        dateValue?: string;
        id: string;
        staticValue?: string;
        valueSource: DataValueValueSource;
        amountValue?: { __typename?: 'Money'; universalFormat: string };
        pathValue?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
        recordValue?:
          | { __typename?: 'Category'; code: string; id: string; label: string }
          | { __typename?: 'CostCentre'; code: string; id: string; label: string }
          | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
          | { __typename?: 'User'; fullName?: string; id: string };
      };
      viewerPermissions: {
        __typename?: 'DataViewFieldRecordViewerPermissions';
        destroy: boolean;
        update: boolean;
        updatePosition: boolean;
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDateCustomFieldMutationVariables = Exact<{
  input: UpdateDateCustomFieldInput;
}>;

export type UpdateDateCustomFieldMutation = {
  __typename?: 'Mutation';
  updateDateCustomField?: {
    __typename?: 'UpdateDateCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDocumentMutationVariables = Exact<{
  input: UpdateDocumentInput;
}>;

export type UpdateDocumentMutation = {
  __typename?: 'Mutation';
  updateDocument?: {
    __typename?: 'UpdateDocumentPayload';
    status: MutationStatus;
    document?: {
      __typename?: 'Document';
      id: string;
      totalAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      vatAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateDocumentTypeMutationVariables = Exact<{
  documentId: Scalars['GlobalID']['input'];
  documentType: DocumentClassification;
  totalAmountCents?: InputMaybe<Scalars['BigInt']['input']>;
  vatAmountCents?: InputMaybe<Scalars['BigInt']['input']>;
}>;

export type UpdateDocumentTypeMutation = {
  __typename?: 'Mutation';
  updateDocument?: {
    __typename?: 'UpdateDocumentPayload';
    status: MutationStatus;
    document?: {
      __typename?: 'Document';
      classification: DocumentClassification;
      id: string;
      isDraft: boolean;
      supportedTypes?: Array<DocumentClassification>;
      purchaseRequestItem?: { __typename?: 'PurchaseRequestItem'; id: string; status: PurchaseRequestItemStatus };
      totalAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        supportedDocumentTypes: Array<DocumentClassification>;
        transactionDate: string;
        originalAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      }>;
      vatAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateFlowStepCoordinatesMutationVariables = Exact<{
  attributes: UpdateFlowStepCoordinatesAttributesInput;
  flowStepId: Scalars['GlobalID']['input'];
}>;

export type UpdateFlowStepCoordinatesMutation = {
  __typename?: 'Mutation';
  updateFlowStepCoordinates?: {
    __typename?: 'UpdateFlowStepCoordinatesPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowStep?:
      | {
          __typename?: 'FlowActionApprove';
          id: string;
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
        }
      | {
          __typename?: 'FlowActionComment';
          id: string;
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
        }
      | {
          __typename?: 'FlowActionCreatePurchaseOrder';
          id: string;
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
        }
      | {
          __typename?: 'FlowActionGetApproval';
          id: string;
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
        }
      | {
          __typename?: 'FlowActionReject';
          id: string;
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
        }
      | {
          __typename?: 'FlowStepCondition';
          id: string;
          coordinates: { __typename?: 'Coordinates'; x: number; y: number };
          workflow: { __typename?: 'Workflow'; id: string; updatedAt: string };
        };
  };
};

export type UpdateIntegerCustomFieldMutationVariables = Exact<{
  input: UpdateIntegerCustomFieldInput;
}>;

export type UpdateIntegerCustomFieldMutation = {
  __typename?: 'Mutation';
  updateIntegerCustomField?: {
    __typename?: 'UpdateIntegerCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateIssuerIntegrationMutationVariables = Exact<{
  attributes: UpdateIssuerIntegrationAttributesInput;
  issuerIntegrationId: Scalars['GlobalID']['input'];
}>;

export type UpdateIssuerIntegrationMutation = {
  __typename?: 'Mutation';
  updateIssuerIntegration?: {
    __typename?: 'UpdateIssuerIntegrationPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateIssuerIntegrationConsumerMutationVariables = Exact<{
  attributes: UpdateIssuerIntegrationConsumerInputAttributes;
  issuerIntegrationConsumerId: Scalars['GlobalID']['input'];
}>;

export type UpdateIssuerIntegrationConsumerMutation = {
  __typename?: 'Mutation';
  updateIssuerIntegrationConsumer?: {
    __typename?: 'UpdateIssuerIntegrationConsumerPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateIssuerIntegrationRuleMutationVariables = Exact<{
  attributes: UpdateIssuerIntegrationRuleInputAttributes;
  issuerIntegrationRuleId: Scalars['GlobalID']['input'];
}>;

export type UpdateIssuerIntegrationRuleMutation = {
  __typename?: 'Mutation';
  updateIssuerIntegrationRule?: {
    __typename?: 'UpdateIssuerIntegrationRulePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateLocationMutationVariables = Exact<{
  attributes: UpdateLocationAttributesInput;
  locationId: Scalars['GlobalID']['input'];
}>;

export type UpdateLocationMutation = {
  __typename?: 'Mutation';
  updateLocation?: {
    __typename?: 'UpdateLocationPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    location?: {
      __typename?: 'Location';
      archived: boolean;
      id: string;
      label: string;
      address: {
        __typename?: 'Address';
        city?: string;
        formattedLines: Array<string>;
        id: string;
        postalCode?: string;
        regionName?: string;
        streetName?: string;
        streetNumber?: string;
        country: { __typename?: 'Country'; code: CountryCode; name: string };
      };
      viewerPermissions: {
        __typename?: 'LocationRecordViewerPermissions';
        archive: boolean;
        unarchive: boolean;
        update: boolean;
      };
    };
  };
};

export type UpdateMoneyCustomFieldMutationVariables = Exact<{
  input: UpdateMoneyCustomFieldInput;
}>;

export type UpdateMoneyCustomFieldMutation = {
  __typename?: 'Mutation';
  updateMoneyCustomField?: {
    __typename?: 'UpdateMoneyCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateOrganisationUnitMutationVariables = Exact<{
  attributes: UpdateOrganisationUnitInputAttributes;
  organisationUnitId: Scalars['GlobalID']['input'];
}>;

export type UpdateOrganisationUnitMutation = {
  __typename?: 'Mutation';
  updateOrganisationUnit?: {
    __typename?: 'UpdateOrganisationUnitPayload';
    status: MutationStatus;
    organisationUnit?: {
      __typename?: 'OrganisationUnit';
      archived: boolean;
      childrenCount: number;
      classification: OrganisationUnitClassification;
      descendantUsersCount: number;
      id: string;
      label: string;
      parentId?: string;
      viewerPermissions: {
        __typename?: 'OrganisationUnitRecordViewerPermissions';
        archive: boolean;
        createChild?: boolean;
        showArchivedCustomFields?: boolean;
        showEmptyCustomFields?: boolean;
        unarchive: boolean;
        update: boolean;
      };
    };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateOrganisationUnitCustomFieldMutationVariables = Exact<{
  input: UpdateOrganisationUnitCustomFieldInput;
}>;

export type UpdateOrganisationUnitCustomFieldMutation = {
  __typename?: 'Mutation';
  updateOrganisationUnitCustomField?: {
    __typename?: 'UpdateOrganisationUnitCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdatePurchaseRequestItemMutationVariables = Exact<{
  attributes: UpdatePurchaseRequestItemInputAttributes;
  id: Scalars['GlobalID']['input'];
}>;

export type UpdatePurchaseRequestItemMutation = {
  __typename?: 'Mutation';
  updatePurchaseRequestItem?: {
    __typename?: 'UpdatePurchaseRequestItemPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    purchaseRequestItem?: {
      __typename?: 'PurchaseRequestItem';
      description?: string;
      id: string;
      updatedAt: string;
      customFieldValues?: Array<
        | {
            __typename?: 'AssociationCustomFieldValue';
            id: string;
            updatedAt: string;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            value:
              | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
              | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
              | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
              | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
              | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'AttachmentCustomFieldValue';
            id: string;
            updatedAt: string;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
            files?: Array<{
              __typename?: 'Attachment';
              blobId: string;
              byteSize: number;
              contentType: string;
              downloadUrl: string;
              filename: string;
              id: string;
              key: string;
            }>;
          }
        | {
            __typename?: 'BooleanCustomFieldValue';
            id: string;
            updatedAt: string;
            valueBoolean?: boolean;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'DateTimeCustomFieldValue';
            id: string;
            updatedAt: string;
            valueDateTime?: string;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'IntegerCustomFieldValue';
            id: string;
            updatedAt: string;
            valueInteger?: string;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'MoneyCustomFieldValue';
            id: string;
            updatedAt: string;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            valueMoney?: {
              __typename?: 'Money';
              cents: string;
              universalFormat: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'StringCustomFieldValue';
            id: string;
            updatedAt: string;
            valueString?: string;
            viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
      >;
      requestedAmount?: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      requestedAmountCurrency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      supplier?: {
        __typename?: 'SupplierAccount';
        companyName: string;
        id: string;
        enableStatus?: SupplierAccountEnableStatus;
        onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
        address?: {
          __typename?: 'Address';
          city?: string;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
      };
    };
  };
};

export type UpdateRecordParentMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type UpdateRecordParentMutation = {
  __typename?: 'Mutation';
  updateRecordParent?: {
    __typename?: 'UpdateRecordParentPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    record?:
      | {
          __typename?: 'Category';
          archived: boolean;
          childrenCount: number;
          code: string;
          id: string;
          label: string;
          parentId?: string;
          viewerPermissions: {
            __typename?: 'CategoryRecordViewerPermissions';
            archive: boolean;
            createChild?: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | { __typename?: 'IndustryCode' }
      | {
          __typename?: 'OrganisationUnit';
          archived: boolean;
          childrenCount: number;
          classification: OrganisationUnitClassification;
          descendantUsersCount: number;
          id: string;
          label: string;
          parentId?: string;
          viewerPermissions: {
            __typename?: 'OrganisationUnitRecordViewerPermissions';
            archive: boolean;
            createChild?: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
  };
};

export type UpdateRecordPositionMutationVariables = Exact<{
  id: Scalars['GlobalID']['input'];
  position: Scalars['Int']['input'];
}>;

export type UpdateRecordPositionMutation = {
  __typename?: 'Mutation';
  updateRecordPosition?: {
    __typename?: 'UpdateRecordPositionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateReportScheduleMutationVariables = Exact<{
  reportScheduleId: Scalars['GlobalID']['input'];
  attributes: UpdateReportScheduleInputAttributes;
}>;

export type UpdateReportScheduleMutation = {
  __typename?: 'Mutation';
  updateReportSchedule?: {
    __typename?: 'UpdateReportSchedulePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    reportSchedule?: {
      __typename?: 'ReportSchedule';
      description?: string;
      exportFormat: ReportScheduleExportFormat;
      id: string;
      label: string;
      updatedAt: string;
      dataView: { __typename?: 'DataView'; id: string };
      range?: {
        __typename?: 'Range';
        criterion?: string;
        end?: ReportScheduleRelativeTime;
        start?: ReportScheduleRelativeTime;
      };
    };
  };
};

export type UpdateScheduleMutationVariables = Exact<{
  scheduleId: Scalars['GlobalID']['input'];
  attributes: UpdateScheduleInputAttributes;
}>;

export type UpdateScheduleMutation = {
  __typename?: 'Mutation';
  updateSchedule?: {
    __typename?: 'UpdateSchedulePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    schedule?: {
      __typename?: 'Schedule';
      id: string;
      definition: string;
      endsAt?: string;
      startsAt: string;
      timezone: string;
      createdAt: string;
      updatedAt: string;
    };
  };
};

export type UpdateSelectCustomFieldMutationVariables = Exact<{
  input: UpdateSelectCustomFieldInput;
}>;

export type UpdateSelectCustomFieldMutation = {
  __typename?: 'Mutation';
  updateSelectCustomField?: {
    __typename?: 'UpdateSelectCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          dataSourceId: string;
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateSpendLimitMutationVariables = Exact<{
  attributes: UpdateSpendLimitInputAttributes;
  spendLimitId: Scalars['GlobalID']['input'];
}>;

export type UpdateSpendLimitMutation = {
  __typename?: 'Mutation';
  updateSpendLimit?: {
    __typename?: 'UpdateSpendLimitPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    spendLimit?: {
      __typename?: 'SpendLimit';
      createdAt: string;
      id: string;
      runningPeriodInDays: SpendLimitRunningPeriodInDays;
      scope: SpendLimitScope;
      updatedAt: string;
      amount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      earlyWarningAmount: {
        __typename?: 'Money';
        cents: string;
        universalFormat: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
      spendLimitable:
        | { __typename?: 'OrganisationUnit'; archived: boolean; id: string; label: string }
        | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
    };
  };
};

export type UpdateTextAreaCustomFieldMutationVariables = Exact<{
  input: UpdateTextAreaCustomFieldInput;
}>;

export type UpdateTextAreaCustomFieldMutation = {
  __typename?: 'Mutation';
  updateTextAreaCustomField?: {
    __typename?: 'UpdateTextAreaCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateTextCustomFieldMutationVariables = Exact<{
  input: UpdateTextCustomFieldInput;
}>;

export type UpdateTextCustomFieldMutation = {
  __typename?: 'Mutation';
  updateTextCustomField?: {
    __typename?: 'UpdateTextCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateUrlCustomFieldMutationVariables = Exact<{
  input: UpdateUrlCustomFieldInput;
}>;

export type UpdateUrlCustomFieldMutation = {
  __typename?: 'Mutation';
  updateUrlCustomField?: {
    __typename?: 'UpdateUrlCustomFieldPayload';
    status: MutationStatus;
    customField?:
      | {
          __typename?: 'ApproverCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'AttachmentCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CategoryCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CheckboxCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'CostCentreCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'DateCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'IntegerCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'MoneyCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'OrganisationUnitCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'SelectCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextAreaCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'TextCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        }
      | {
          __typename?: 'UrlCustomField';
          archived: boolean;
          archivedAt?: string;
          description?: string;
          formableClass: CustomFieldFormableClass;
          iconName?: string;
          id: string;
          includeInSearch: boolean;
          label: string;
          placeholder?: string;
          required: boolean;
          tooltip?: string;
          width: number;
          viewerPermissions: {
            __typename?: 'CustomFieldRecordViewerPermissions';
            archive: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
  };
};

export type UpdateUserNotificationSettingMutationVariables = Exact<{
  activityKey: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  target: NotificationTarget;
}>;

export type UpdateUserNotificationSettingMutation = {
  __typename?: 'Mutation';
  updateUserNotificationSetting?: {
    __typename?: 'UpdateUserNotificationSettingPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    setting?: {
      __typename?: 'NotificationSetting';
      activityKey: string;
      email: boolean;
      groupLabel: string;
      helpText?: string;
      mobile: boolean;
      web: boolean;
    };
  };
};

export type UpdateUserProfileMutationVariables = Exact<{
  userId: Scalars['GlobalID']['input'];
  profile: UpdateUserProfileAttributesInput;
}>;

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation';
  updateUserProfile?: {
    __typename?: 'UpdateUserProfilePayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    user: {
      __typename?: 'User';
      firstName?: string;
      id: string;
      lastName?: string;
      customFieldValues?: Array<
        | {
            __typename?: 'AssociationCustomFieldValue';
            id: string;
            updatedAt: string;
            value:
              | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
              | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
              | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
              | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
              | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'AttachmentCustomFieldValue';
            id: string;
            updatedAt: string;
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
            files?: Array<{
              __typename?: 'Attachment';
              blobId: string;
              byteSize: number;
              contentType: string;
              downloadUrl: string;
              filename: string;
              id: string;
              key: string;
            }>;
          }
        | {
            __typename?: 'BooleanCustomFieldValue';
            id: string;
            updatedAt: string;
            valueBoolean?: boolean;
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'DateTimeCustomFieldValue';
            id: string;
            updatedAt: string;
            valueDateTime?: string;
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'IntegerCustomFieldValue';
            id: string;
            updatedAt: string;
            valueInteger?: string;
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'MoneyCustomFieldValue';
            id: string;
            updatedAt: string;
            valueMoney?: {
              __typename?: 'Money';
              cents: string;
              universalFormat: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
        | {
            __typename?: 'StringCustomFieldValue';
            id: string;
            updatedAt: string;
            valueString?: string;
            customField:
              | {
                  __typename?: 'ApproverCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'AttachmentCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CategoryCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CheckboxCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'CostCentreCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'DateCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'IntegerCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'MoneyCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'OrganisationUnitCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'SelectCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextAreaCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'TextCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                }
              | {
                  __typename?: 'UrlCustomField';
                  archived: boolean;
                  archivedAt?: string;
                  iconName?: string;
                  id: string;
                  inputType: CustomFieldInputType;
                  label: string;
                  placeholder?: string;
                  position: number;
                  slug: string;
                  valueType: CustomFieldValueType;
                  width: number;
                };
          }
      >;
      location?: { __typename?: 'Location'; id: string };
      organisationUnit: { __typename?: 'OrganisationUnit'; id: string };
      profileImage?: {
        __typename?: 'Attachment';
        byteSize: number;
        contentType: string;
        downloadUrl: string;
        filename: string;
        id: string;
        key: string;
        name: string;
      };
    };
  };
};

export type UpdateUserSettingsMutationVariables = Exact<{
  settings: UpdateUserSettingsAttributesInput;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: 'Mutation';
  updateUserSettings?: {
    __typename?: 'UpdateUserSettingsPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; path?: string; message: string }>;
    settings?: {
      __typename?: 'UserSettings';
      appearance: { __typename?: 'AppearanceSettings'; theme: Theme };
      localisation: {
        __typename?: 'LocalisationSettings';
        timezone: string;
        interfaceLanguage: { __typename?: 'Language'; code: LanguageCode; englishName: string; nativeName: string };
      };
    };
  };
};

export type UpdateWorkflowActionMutationVariables = Exact<{
  attributes: UpdateFlowStepActionInputAttributes;
  flowActionId: Scalars['GlobalID']['input'];
}>;

export type UpdateWorkflowActionMutation = {
  __typename?: 'Mutation';
  updateWorkflowAction?: {
    __typename?: 'UpdateWorkflowActionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowAction?:
      | {
          __typename?: 'FlowActionApprove';
          id: string;
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionComment';
          commentText?: string;
          id: string;
          commentAuthor?: { __typename?: 'User'; fullName?: string; id: string };
          targetPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionCreatePurchaseOrder';
          id: string;
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionGetApproval';
          approvalType?: FlowActionGetApprovalApprovalType;
          assignAll: boolean;
          id: string;
          approverUsers: {
            __typename?: 'UserConnection';
            totalCount: number;
            edges?: Array<{
              __typename?: 'UserEdge';
              node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
            }>;
          };
          assigneePaths: {
            __typename?: 'DataPathConnection';
            totalCount: number;
            edges?: Array<{
              __typename?: 'DataPathEdge';
              node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
            }>;
          };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionReject';
          id: string;
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        };
  };
};

export type UpdateWorkflowActionDescriptionMutationVariables = Exact<{
  description: Scalars['String']['input'];
  flowStepId: Scalars['GlobalID']['input'];
}>;

export type UpdateWorkflowActionDescriptionMutation = {
  __typename?: 'Mutation';
  updateWorkflowAction?: {
    __typename?: 'UpdateWorkflowActionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowAction?:
      | { __typename?: 'FlowActionApprove'; description?: string; id: string }
      | { __typename?: 'FlowActionComment'; description?: string; id: string }
      | { __typename?: 'FlowActionCreatePurchaseOrder'; description?: string; id: string }
      | { __typename?: 'FlowActionGetApproval'; description?: string; id: string }
      | { __typename?: 'FlowActionReject'; description?: string; id: string };
  };
};

export type UpdateWorkflowActionOutputMutationVariables = Exact<{
  flowActionId: Scalars['GlobalID']['input'];
  outputFalseStepId?: InputMaybe<Scalars['GlobalID']['input']>;
  outputTrueStepId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type UpdateWorkflowActionOutputMutation = {
  __typename?: 'Mutation';
  updateWorkflowAction?: {
    __typename?: 'UpdateWorkflowActionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowAction?:
      | {
          __typename?: 'FlowActionApprove';
          id: string;
          outputFalseStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          outputTrueStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionComment';
          id: string;
          outputFalseStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          outputTrueStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionCreatePurchaseOrder';
          id: string;
          outputFalseStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          outputTrueStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionGetApproval';
          id: string;
          outputFalseStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          outputTrueStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        }
      | {
          __typename?: 'FlowActionReject';
          id: string;
          outputFalseStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          outputTrueStep?:
            | {
                __typename?: 'FlowActionApprove';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionComment';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionCreatePurchaseOrder';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionGetApproval';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowActionReject';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              }
            | {
                __typename?: 'FlowStepCondition';
                id: string;
                softValidation: {
                  __typename?: 'FlowStepSoftValidation';
                  publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                };
              };
          workflow: {
            __typename?: 'Workflow';
            id: string;
            updatedAt: string;
            softValidation: {
              __typename?: 'WorkflowSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
            viewerPermissions: {
              __typename?: 'WorkflowRecordViewerPermissions';
              archive: boolean;
              destroy: boolean;
              disable: boolean;
              enable: boolean;
              publish: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
          softValidation: {
            __typename?: 'FlowStepSoftValidation';
            publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
          };
        };
  };
};

export type UpdateWorkflowConditionMutationVariables = Exact<{
  input: UpdateWorkflowConditionInput;
}>;

export type UpdateWorkflowConditionMutation = {
  __typename?: 'Mutation';
  updateWorkflowCondition?: {
    __typename?: 'UpdateWorkflowConditionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowCondition?: {
      __typename?: 'FlowStepCondition';
      comparator?: FlowStepConditionComparatorName;
      id: string;
      referenceDataType?: FlowStepConditionInputAttributeDataType;
      referencePlaceholder: string;
      referenceUnit?: string;
      referenceValue?: any;
      inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
      referenceAssociations: {
        __typename?: 'ReferenceAssociationConnection';
        totalCount: number;
        edges?: Array<{
          __typename?: 'ReferenceAssociationEdge';
          node?: {
            __typename?: 'ReferenceAssociation';
            id: string;
            referenceRecord:
              | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
              | { __typename?: 'Category'; id: string; label: string }
              | { __typename?: 'CostCentre'; id: string; label: string }
              | { __typename?: 'DataSourceItem'; id: string; label: string }
              | { __typename?: 'IssuerIntegration'; id: string; description: string }
              | { __typename?: 'OrganisationUnit'; id: string; label: string }
              | { __typename?: 'User'; id: string; fullName?: string };
          };
        }>;
      };
      referencePaths: {
        __typename?: 'DataPathConnection';
        totalCount: number;
        edges?: Array<{
          __typename?: 'DataPathEdge';
          node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
        }>;
      };
      workflow: {
        __typename?: 'Workflow';
        id: string;
        updatedAt: string;
        softValidation: {
          __typename?: 'WorkflowSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        viewerPermissions: {
          __typename?: 'WorkflowRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          disable: boolean;
          enable: boolean;
          publish: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
      softValidation: {
        __typename?: 'FlowStepSoftValidation';
        publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
      };
    };
  };
};

export type UpdateWorkflowConditionDescriptionMutationVariables = Exact<{
  description: Scalars['String']['input'];
  flowStepId: Scalars['GlobalID']['input'];
}>;

export type UpdateWorkflowConditionDescriptionMutation = {
  __typename?: 'Mutation';
  updateWorkflowCondition?: {
    __typename?: 'UpdateWorkflowConditionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowCondition?: { __typename?: 'FlowStepCondition'; description?: string; id: string };
  };
};

export type UpdateWorkflowConditionOutputMutationVariables = Exact<{
  flowConditionId: Scalars['GlobalID']['input'];
  outputFalseStepId?: InputMaybe<Scalars['GlobalID']['input']>;
  outputTrueStepId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type UpdateWorkflowConditionOutputMutation = {
  __typename?: 'Mutation';
  updateWorkflowCondition?: {
    __typename?: 'UpdateWorkflowConditionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    flowCondition?: {
      __typename?: 'FlowStepCondition';
      id: string;
      outputFalseStep?:
        | {
            __typename?: 'FlowActionApprove';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionComment';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionCreatePurchaseOrder';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionGetApproval';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionReject';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowStepCondition';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          };
      outputTrueStep?:
        | {
            __typename?: 'FlowActionApprove';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionComment';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionCreatePurchaseOrder';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionGetApproval';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionReject';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowStepCondition';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          };
      workflow: {
        __typename?: 'Workflow';
        id: string;
        updatedAt: string;
        softValidation: {
          __typename?: 'WorkflowSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        viewerPermissions: {
          __typename?: 'WorkflowRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          disable: boolean;
          enable: boolean;
          publish: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
      softValidation: {
        __typename?: 'FlowStepSoftValidation';
        publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
      };
    };
  };
};

export type UpdateWorkflowFirstStepMutationVariables = Exact<{
  attributes: UpdateWorkflowInputAttributes;
  workflowId: Scalars['GlobalID']['input'];
}>;

export type UpdateWorkflowFirstStepMutation = {
  __typename?: 'Mutation';
  updateWorkflow?: {
    __typename?: 'UpdateWorkflowPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    workflow?: {
      __typename?: 'Workflow';
      id: string;
      firstStep?:
        | {
            __typename?: 'FlowActionApprove';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionComment';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionCreatePurchaseOrder';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionGetApproval';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowActionReject';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          }
        | {
            __typename?: 'FlowStepCondition';
            id: string;
            softValidation: {
              __typename?: 'FlowStepSoftValidation';
              publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
            };
          };
      softValidation: {
        __typename?: 'WorkflowSoftValidation';
        publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
      };
    };
  };
};

export type ImportUsersMutationVariables = Exact<{
  allowUnarchive?: InputMaybe<Scalars['Boolean']['input']>;
  blobId: Scalars['GlobalID']['input'];
  defaultRoles?: InputMaybe<Array<UserSecurityRole> | UserSecurityRole>;
  invitationMessage?: InputMaybe<Scalars['String']['input']>;
  mappings: UsersImportMappingsAttributesInput;
}>;

export type ImportUsersMutation = {
  __typename?: 'Mutation';
  importUsers?: {
    __typename?: 'ImportUsersPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
    importResult?: {
      __typename?: 'ImportResult';
      createdRecordsCount: number;
      updatedRecordsCount: number;
      failures: {
        __typename?: 'Failures';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
      skipped: {
        __typename?: 'Skipped';
        count: number;
        rows: Array<{
          __typename?: 'Row';
          rowNumber: number;
          errors: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        }>;
      };
    };
  };
};

export type CountActivitiesQueryVariables = Exact<{
  includeRelated?: InputMaybe<OptionalBoolean>;
  trackableId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type CountActivitiesQuery = {
  __typename?: 'Query';
  activities?: { __typename?: 'ActivityConnection'; totalCount: number };
};

export type CountApprovableApprovalsQueryVariables = Exact<{
  approvableIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountApprovableApprovalsQuery = {
  __typename?: 'Query';
  approvals?: { __typename?: 'ApprovalConnection'; totalCount: number };
};

export type CountApprovalDelegationApprovalsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountApprovalDelegationApprovalsQuery = {
  __typename?: 'Query';
  approvals?: { __typename?: 'ApprovalConnection'; totalCount: number };
};

export type CountApprovalsQueryVariables = Exact<{
  assigneeId: Scalars['GlobalID']['input'];
}>;

export type CountApprovalsQuery = {
  __typename?: 'Query';
  approved?: { __typename?: 'ApprovalConnection'; totalCount: number };
  cancelled?: { __typename?: 'ApprovalConnection'; totalCount: number };
  pending?: { __typename?: 'ApprovalConnection'; totalCount: number };
  rejected?: { __typename?: 'ApprovalConnection'; totalCount: number };
};

export type CountCardAuthorisationsQueryVariables = Exact<{
  dateAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  dateBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
  declined?: InputMaybe<OptionalBoolean>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountCardAuthorisationsQuery = {
  __typename?: 'Query';
  cardAuthorisations?: { __typename?: 'CardAuthorisationConnection'; totalCount: number };
};

export type CountCardSubscriptionDocumentsQueryVariables = Exact<{
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountCardSubscriptionDocumentsQuery = {
  __typename?: 'Query';
  documents?: { __typename?: 'DocumentConnection'; totalCount: number };
};

export type CountCardSubscriptionDraftDocumentsQueryVariables = Exact<{
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountCardSubscriptionDraftDocumentsQuery = {
  __typename?: 'Query';
  documents?: { __typename?: 'DocumentConnection'; totalCount: number };
};

export type CountCardSubscriptionPurchaseOrdersQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountCardSubscriptionPurchaseOrdersQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        purchaseOrders: { __typename?: 'PurchaseOrderConnection'; totalCount: number };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountCardSubscriptionPurchaseRequestItemsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountCardSubscriptionPurchaseRequestItemsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        purchaseRequestItems?: { __typename?: 'PurchaseRequestItemConnection'; totalCount: number };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountCardSubscriptionTransactionsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountCardSubscriptionTransactionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        transactions: { __typename?: 'TransactionConnection'; totalCount: number };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountCardSubscriptionTransactionsMissingReceiptsQueryVariables = Exact<{
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountCardSubscriptionTransactionsMissingReceiptsQuery = {
  __typename?: 'Query';
  transactions?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountCardSubscriptionVirtualCardsQueryVariables = Exact<{
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountCardSubscriptionVirtualCardsQuery = {
  __typename?: 'Query';
  activeCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
  closedVirtualCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
};

export type CountCardSubscriptionsQueryVariables = Exact<{
  ownerIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus> | PurchaseOrderReconciliationStatus>;
}>;

export type CountCardSubscriptionsQuery = {
  __typename?: 'Query';
  cardSubscriptions?: { __typename?: 'CardSubscriptionConnection'; totalCount: number };
};

export type CountCostCentresQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
}>;

export type CountCostCentresQuery = {
  __typename?: 'Query';
  costCentres?: { __typename?: 'CostCentreConnection'; totalCount: number };
};

export type CountCustomFieldsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  formableClasses?: InputMaybe<Array<CustomFieldFormableClass> | CustomFieldFormableClass>;
  formableParentIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  required?: InputMaybe<OptionalBoolean>;
  types?: InputMaybe<Array<CustomFieldType> | CustomFieldType>;
}>;

export type CountCustomFieldsQuery = {
  __typename?: 'Query';
  customFields?: { __typename?: 'CustomFieldConnection'; totalCount: number };
};

export type CountDataScopingRulesQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountDataScopingRulesQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | {
        __typename?: 'ApproverCustomField';
        id: string;
        dataScopingRules: { __typename?: 'DataScopingRuleConnection'; totalCount: number };
      }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; id: string }
    | {
        __typename?: 'CategoryCustomField';
        id: string;
        dataScopingRules: { __typename?: 'DataScopingRuleConnection'; totalCount: number };
      }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | {
        __typename?: 'CostCentreCustomField';
        id: string;
        dataScopingRules: { __typename?: 'DataScopingRuleConnection'; totalCount: number };
      }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; id: string }
    | {
        __typename?: 'OrganisationUnitCustomField';
        id: string;
        dataScopingRules: { __typename?: 'DataScopingRuleConnection'; totalCount: number };
      }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | {
        __typename?: 'SelectCustomField';
        id: string;
        dataScopingRules: { __typename?: 'DataScopingRuleConnection'; totalCount: number };
      }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | { __typename?: 'Workflow'; id: string }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type CountDataSourceItemsQueryVariables = Exact<{
  dataSourceId: Scalars['GlobalID']['input'];
}>;

export type CountDataSourceItemsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource'; id: string; items: { __typename?: 'DataSourceItemConnection'; totalCount: number } }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountDataSourcesQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
}>;

export type CountDataSourcesQuery = {
  __typename?: 'Query';
  dataSources: { __typename?: 'DataSourceConnection'; totalCount: number };
};

export type CountDataViewFieldsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountDataViewFieldsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView'; id: string; fields: { __typename?: 'DataViewFieldConnection'; totalCount: number } }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountDataViewFiltersQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountDataViewFiltersQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView'; id: string; filters: { __typename?: 'DataFilterConnection'; totalCount: number } }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountDocumentsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  documentTypes?: InputMaybe<Array<DocumentClassification> | DocumentClassification>;
  inboxIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  transactionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  withTransactions?: InputMaybe<OptionalBoolean>;
}>;

export type CountDocumentsQuery = {
  __typename?: 'Query';
  connection?: { __typename?: 'DocumentConnection'; totalCount: number };
};

export type CountInboxIncomingEmailsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountInboxIncomingEmailsQuery = {
  __typename?: 'Query';
  incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
};

export type CountIndexTransactionsQueryVariables = Exact<{
  merchantCountryCodes?: InputMaybe<Array<CountryCode> | CountryCode>;
  reconciliationStatus?: InputMaybe<Array<TransactionReconciliationStatus> | TransactionReconciliationStatus>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountIndexTransactionsQuery = {
  __typename?: 'Query';
  transactions?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountIndustryCodeSuppliersQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountIndustryCodeSuppliersQuery = {
  __typename?: 'Query';
  suppliers?: { __typename?: 'SupplierAccountConnection'; totalCount: number };
};

export type CountInvoicePaymentsQueryVariables = Exact<{
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  statuses?: InputMaybe<Array<InvoicePaymentStatus> | InvoicePaymentStatus>;
  supplierIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountInvoicePaymentsQuery = {
  __typename?: 'Query';
  invoicePayments?: { __typename?: 'InvoicePaymentConnection'; totalCount: number };
};

export type CountIssuerIntegrationConsumersQueryVariables = Exact<{
  issuerIntegrationIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountIssuerIntegrationConsumersQuery = {
  __typename?: 'Query';
  issuerIntegrationConsumers?: { __typename?: 'IssuerIntegrationConsumerConnection'; totalCount: number };
};

export type CountIssuerIntegrationsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  cardUseInvoice?: InputMaybe<OptionalBoolean>;
  cardUseMulti?: InputMaybe<OptionalBoolean>;
  cardUseSingle?: InputMaybe<OptionalBoolean>;
  currencyCodes?: InputMaybe<Array<CurrencyCode> | CurrencyCode>;
}>;

export type CountIssuerIntegrationsQuery = {
  __typename?: 'Query';
  issuerIntegrations?: { __typename?: 'IssuerIntegrationConnection'; totalCount: number };
};

export type CountMerchantsQueryVariables = Exact<{
  countryCodes?: InputMaybe<Array<CountryCode> | CountryCode>;
  merchantCategoryCodes?: InputMaybe<
    Array<Scalars['MerchantCategoryCodeCode']['input']> | Scalars['MerchantCategoryCodeCode']['input']
  >;
}>;

export type CountMerchantsQuery = {
  __typename?: 'Query';
  merchants: { __typename?: 'MerchantConnection'; totalCount: number };
};

export type CountOrganisationUnitUsersQueryVariables = Exact<{
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountOrganisationUnitUsersQuery = {
  __typename?: 'Query';
  users: { __typename?: 'UserConnection'; totalCount: number };
};

export type CountOverviewApprovalsQueryVariables = Exact<{
  assigneeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountOverviewApprovalsQuery = {
  __typename?: 'Query';
  approvals?: { __typename?: 'ApprovalConnection'; totalCount: number };
};

export type CountOverviewCardsQueryVariables = Exact<{
  issuedToIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountOverviewCardsQuery = {
  __typename?: 'Query';
  aboutToExpireCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
  activeCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
  expiredCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
  newCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
};

export type CountOverviewRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type CountOverviewRequestsQuery = {
  __typename?: 'Query';
  purchaseRequestItems?: { __typename?: 'PurchaseRequestItemConnection'; totalCount: number };
};

export type CountOverviewTransactionsQueryVariables = Exact<{
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountOverviewTransactionsQuery = {
  __typename?: 'Query';
  transactions?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountPurchaseRequestItemCardAuthorisationsQueryVariables = Exact<{
  purchaseOrderId: Scalars['GlobalID']['input'];
}>;

export type CountPurchaseRequestItemCardAuthorisationsQuery = {
  __typename?: 'Query';
  cardAuthorisations?: { __typename?: 'CardAuthorisationConnection'; totalCount: number };
};

export type CountPurchaseRequestItemInvoicePaymentsQueryVariables = Exact<{
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountPurchaseRequestItemInvoicePaymentsQuery = {
  __typename?: 'Query';
  invoicePayments?: { __typename?: 'InvoicePaymentConnection'; totalCount: number };
};

export type CountPurchaseRequestItemTransactionsQueryVariables = Exact<{
  purchaseOrderId?: InputMaybe<Scalars['GlobalID']['input']>;
  reconciliationStatus?: InputMaybe<Array<TransactionReconciliationStatus> | TransactionReconciliationStatus>;
}>;

export type CountPurchaseRequestItemTransactionsQuery = {
  __typename?: 'Query';
  transactions?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountPurchaseRequestItemTransactionsMissingReceiptsQueryVariables = Exact<{
  purchaseOrderId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type CountPurchaseRequestItemTransactionsMissingReceiptsQuery = {
  __typename?: 'Query';
  transactions?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountPurchaseRequestItemsQueryVariables = Exact<{
  anyPurchaseTypes?: InputMaybe<Array<PurchaseType> | PurchaseType>;
  anyPurchaseRequestItemStatus?: InputMaybe<Array<PurchaseRequestItemStatus> | PurchaseRequestItemStatus>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus> | PurchaseOrderReconciliationStatus>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountPurchaseRequestItemsQuery = {
  __typename?: 'Query';
  purchaseRequestItems?: { __typename?: 'PurchaseRequestItemConnection'; totalCount: number };
};

export type CountPurchaseRequestItemsMissingReceiptsQueryVariables = Exact<{ [key: string]: never }>;

export type CountPurchaseRequestItemsMissingReceiptsQuery = {
  __typename?: 'Query';
  purchaseRequestItems?: { __typename?: 'PurchaseRequestItemConnection'; totalCount: number };
};

export type CountReportScheduleSubscribersQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountReportScheduleSubscribersQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | {
        __typename?: 'ReportSchedule';
        id: string;
        notificationSubscribers?: { __typename?: 'UserConnection'; totalCount: number };
      }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type CountReportsQueryVariables = Exact<{
  generatedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  dataViewIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountReportsQuery = {
  __typename?: 'Query';
  reports?: { __typename?: 'ReportConnection'; totalCount: number };
};

export type CountResourcePreviewTransactionsQueryVariables = Exact<{
  merchantIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  virtualCardId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type CountResourcePreviewTransactionsQuery = {
  __typename?: 'Query';
  transactions?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountSupplierAccountInvoicePaymentsQueryVariables = Exact<{
  supplierId: Scalars['GlobalID']['input'];
}>;

export type CountSupplierAccountInvoicePaymentsQuery = {
  __typename?: 'Query';
  invoicePayments?: { __typename?: 'InvoicePaymentConnection'; totalCount: number };
};

export type CountSupplierPayoutsQueryVariables = Exact<{ [key: string]: never }>;

export type CountSupplierPayoutsQuery = {
  __typename?: 'Query';
  supplierPayouts?: { __typename?: 'SupplierPayoutConnection'; totalCount: number };
};

export type CountSuppliersQueryVariables = Exact<{
  countryCodes?: InputMaybe<Array<CountryCode> | CountryCode>;
  enableStatuses?: InputMaybe<Array<SupplierAccountEnableStatus> | SupplierAccountEnableStatus>;
  industryCodeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  onboardingStatuses?: InputMaybe<
    Array<SupplierOnboardingCaseOnboardingStatus> | SupplierOnboardingCaseOnboardingStatus
  >;
}>;

export type CountSuppliersQuery = {
  __typename?: 'Query';
  suppliers?: { __typename?: 'SupplierAccountConnection'; totalCount: number };
};

export type CountTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type CountTransactionsQuery = {
  __typename?: 'Query';
  failed?: { __typename?: 'CardAuthorisationConnection'; totalCount: number };
  missingReceipts?: { __typename?: 'TransactionConnection'; totalCount: number };
  pending?: { __typename?: 'CardAuthorisationConnection'; totalCount: number };
  processed?: { __typename?: 'TransactionConnection'; totalCount: number };
};

export type CountUserApprovalsQueryVariables = Exact<{
  assigneeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountUserApprovalsQuery = {
  __typename?: 'Query';
  approvals?: { __typename?: 'ApprovalConnection'; totalCount: number };
};

export type CountUserCustomFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type CountUserCustomFieldsQuery = {
  __typename?: 'Query';
  customFields?: { __typename?: 'CustomFieldConnection'; totalCount: number };
};

export type CountUserRequestsQueryVariables = Exact<{
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type CountUserRequestsQuery = {
  __typename?: 'Query';
  purchaseRequestItems?: { __typename?: 'PurchaseRequestItemConnection'; totalCount: number };
};

export type CountUsersQueryVariables = Exact<{
  anyUserStatus?: InputMaybe<Array<UserStatus> | UserStatus>;
  awaitingInvitations?: InputMaybe<OptionalBoolean>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  permissions?: InputMaybe<Array<UserSecurityRole> | UserSecurityRole>;
}>;

export type CountUsersQuery = { __typename?: 'Query'; users: { __typename?: 'UserConnection'; totalCount: number } };

export type CountUsersByStatusQueryVariables = Exact<{ [key: string]: never }>;

export type CountUsersByStatusQuery = {
  __typename?: 'Query';
  active: { __typename?: 'UserConnection'; totalCount: number };
  archived: { __typename?: 'UserConnection'; totalCount: number };
  draft: { __typename?: 'UserConnection'; totalCount: number };
  invalid: { __typename?: 'UserConnection'; totalCount: number };
  invited: { __typename?: 'UserConnection'; totalCount: number };
  locked: { __typename?: 'UserConnection'; totalCount: number };
};

export type CountVirtualCardsQueryVariables = Exact<{
  anyVirtualCardStatus?: InputMaybe<Array<VirtualCardStatus> | VirtualCardStatus>;
}>;

export type CountVirtualCardsQuery = {
  __typename?: 'Query';
  virtualCards?: { __typename?: 'VirtualCardConnection'; totalCount: number };
};

export type CountWorkflowRunsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountWorkflowRunsQuery = {
  __typename?: 'Query';
  workflowRuns?: { __typename?: 'WorkflowRunConnection'; totalCount: number };
};

export type CountWorkflowVersionsQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type CountWorkflowVersionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        id: string;
        isMaster: boolean;
        master?: {
          __typename?: 'Workflow';
          id: string;
          versions: { __typename?: 'WorkflowConnection'; totalCount: number };
        };
        versions: { __typename?: 'WorkflowConnection'; totalCount: number };
      }
    | { __typename?: 'WorkflowRun' };
};

export type CountWorkflowsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  isEnabled?: InputMaybe<OptionalBoolean>;
  isPublished?: InputMaybe<OptionalBoolean>;
}>;

export type CountWorkflowsQuery = {
  __typename?: 'Query';
  workflows?: { __typename?: 'WorkflowConnection'; totalCount: number };
};

export type IndexActivitiesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  includeRelated?: InputMaybe<OptionalBoolean>;
  trackableId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type IndexActivitiesQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ActivityConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ActivityEdge';
      cursor: string;
      node?: {
        __typename?: 'Activity';
        id: string;
        createdAt: string;
        displayStyle: ActivityDisplayStyle;
        icon: ActivityIcon;
        key: string;
        updatedAt: string;
        defaultUrl?: { __typename?: 'URL'; path?: string; query?: string };
        eventSubject?:
          | { __typename?: 'Approval' }
          | { __typename?: 'Attachment' }
          | { __typename?: 'BuyerAccount' }
          | { __typename?: 'Comment'; body?: string; id: string }
          | { __typename?: 'Document' }
          | { __typename?: 'IncomingEmail' }
          | { __typename?: 'PurchaseOrder' }
          | { __typename?: 'PurchaseRequestItem' }
          | { __typename?: 'SupplierAccount' }
          | { __typename?: 'Transaction' }
          | { __typename?: 'User' }
          | { __typename?: 'VirtualCard' }
          | { __typename?: 'Workflow' };
        fragments: Array<
          | {
              __typename?: 'ReferenceFragment';
              urn: string;
              text: string;
              type: StringFragmentFragmentType;
              url?: { __typename?: 'URL'; path?: string; query?: string };
            }
          | { __typename?: 'TextFragment'; text: string; type: StringFragmentFragmentType }
        >;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexApprovalDelegationsFromYouQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  delegateFromIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  delegateToIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  delegationStatuses?: InputMaybe<Array<ApprovalDelegationStatus> | ApprovalDelegationStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexApprovalDelegationsFromYouQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ApprovalDelegationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ApprovalDelegationEdge';
      cursor: string;
      node?: {
        __typename?: 'ApprovalDelegation';
        archived: boolean;
        endsOn: string;
        id: string;
        startsOn: string;
        status: ApprovalDelegationStatus;
        to: { __typename?: 'User'; id: string; fullName?: string };
        viewerPermissions: {
          __typename?: 'ApprovalDelegationRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexApprovalDelegationsToYouQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  delegateFromIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  delegateToIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  delegationStatuses?: InputMaybe<Array<ApprovalDelegationStatus> | ApprovalDelegationStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexApprovalDelegationsToYouQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ApprovalDelegationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ApprovalDelegationEdge';
      cursor: string;
      node?: {
        __typename?: 'ApprovalDelegation';
        archived: boolean;
        endsOn: string;
        id: string;
        startsOn: string;
        status: ApprovalDelegationStatus;
        from: { __typename?: 'User'; id: string; fullName?: string };
        viewerPermissions: {
          __typename?: 'ApprovalDelegationRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexApprovalWorkflowApprovalsQueryVariables = Exact<{
  approvableIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexApprovalWorkflowApprovalsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ApprovalConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ApprovalEdge';
      cursor: string;
      node?: {
        __typename?: 'Approval';
        approvedAt?: string;
        cancelledAt?: string;
        createdAt: string;
        id: string;
        rejectedAt?: string;
        status: ApprovalStatus;
        approvalsUsers: {
          __typename?: 'ApprovalsUserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'ApprovalsUserEdge';
            cursor: string;
            node?: {
              __typename?: 'ApprovalsUser';
              id: string;
              user: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges?: Array<{
            __typename?: 'CommentEdge';
            node?: { __typename?: 'Comment'; body?: string; id: string; key?: string };
          }>;
        };
        confirmedBy?: { __typename?: 'User'; fullName?: string; id: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexApprovalsQueryVariables = Exact<{
  assigneeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<ApprovalStatus> | ApprovalStatus>;
}>;

export type IndexApprovalsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ApprovalConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ApprovalEdge';
      cursor: string;
      node?: {
        __typename?: 'Approval';
        createdAt: string;
        id: string;
        approvable?:
          | {
              __typename?: 'PurchaseRequestItem';
              description?: string;
              id: string;
              purchaseType: PurchaseType;
              status: PurchaseRequestItemStatus;
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
              purchaseOrder?: {
                __typename?: 'PurchaseOrder';
                id: string;
                virtualCard?: {
                  __typename?: 'VirtualCard';
                  actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
                  spentBaseAmount: {
                    __typename?: 'Money';
                    cents: string;
                    universalFormat: string;
                    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                  };
                };
              };
            }
          | {
              __typename?: 'SupplierRequest';
              approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
              id: string;
              companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
            };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexApprovalsResourceCardsQueryVariables = Exact<{
  approvableIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  approvalDelegationIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  assigneeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexApprovalsResourceCardsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ApprovalConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ApprovalEdge';
      cursor: string;
      node?: {
        __typename?: 'Approval';
        approvedAt?: string;
        cancelledAt?: string;
        createdAt: string;
        id: string;
        rejectedAt?: string;
        status: ApprovalStatus;
        approvalsUsers: {
          __typename?: 'ApprovalsUserConnection';
          edges?: Array<{
            __typename?: 'ApprovalsUserEdge';
            cursor: string;
            node?: {
              __typename?: 'ApprovalsUser';
              id: string;
              user: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges?: Array<{
            __typename?: 'CommentEdge';
            node?: { __typename?: 'Comment'; body?: string; id: string; key?: string };
          }>;
        };
        confirmedBy?: { __typename?: 'User'; fullName?: string; id: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexAuditsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexAuditsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | {
        __typename?: 'AssociationCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | {
        __typename?: 'AttachmentCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | {
        __typename?: 'BooleanCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | {
        __typename?: 'DateTimeCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | {
        __typename?: 'IntegerCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | {
        __typename?: 'MoneyCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | {
        __typename?: 'StringCustomFieldValue';
        id: string;
        audits?: {
          __typename?: 'AuditConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'AuditEdge';
            cursor: string;
            node?: {
              __typename?: 'Audit';
              action?: AuditAction;
              createdAt: string;
              auditable:
                | { __typename?: 'ApproverCustomField'; id: string }
                | {
                    __typename?: 'AssociationCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | {
                    __typename?: 'AttachmentCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | {
                    __typename?: 'BooleanCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'BuyerAccount'; id: string }
                | { __typename?: 'Category'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CategorySet'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentre'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DataSource'; id: string }
                | { __typename?: 'DataSourceItem'; id: string }
                | { __typename?: 'DataView'; id: string }
                | { __typename?: 'DataViewField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | {
                    __typename?: 'DateTimeCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'Document'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | {
                    __typename?: 'IntegerCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'MoneyCustomField'; id: string }
                | {
                    __typename?: 'MoneyCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'OrganisationUnit'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'PurchaseOrder'; id: string }
                | { __typename?: 'PurchaseRequestItem'; id: string }
                | { __typename?: 'ReportSchedule'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | {
                    __typename?: 'StringCustomFieldValue';
                    id: string;
                    customField:
                      | { __typename?: 'ApproverCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'AttachmentCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CategoryCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CheckboxCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'CostCentreCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'DateCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'IntegerCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'MoneyCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'OrganisationUnitCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'SelectCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextAreaCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'TextCustomField'; id: string; valueType: CustomFieldValueType }
                      | { __typename?: 'UrlCustomField'; id: string; valueType: CustomFieldValueType };
                  }
                | { __typename?: 'SupplierAccount'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string }
                | { __typename?: 'User'; id: string };
              auditedChanges: Array<{
                __typename?: 'AuditedChange';
                name: string;
                newValue?: { __typename?: 'Value'; description?: string; id?: string };
                oldValue?: { __typename?: 'Value'; description?: string; id?: string };
              }>;
              user?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexBankAccountsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexBankAccountsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'BankAccountConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'BankAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'BankAccount';
        id: string;
        iban: string;
        verifiedAt?: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        viewerPermissions: { __typename?: 'BankAccountRecordViewerPermissions'; destroy: boolean };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCardAuthorisationsQueryVariables = Exact<{
  dateAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  dateBefore?: InputMaybe<Scalars['ISO8601Date']['input']>;
  declined?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CardAuthorisationSortInput> | CardAuthorisationSortInput>;
}>;

export type IndexCardAuthorisationsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CardAuthorisationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CardAuthorisationEdge';
      cursor: string;
      node?: {
        __typename?: 'CardAuthorisation';
        date: string;
        declineCode?: string;
        declined: boolean;
        declineMessage?: string;
        id: string;
        merchantName?: string;
        amount: { __typename?: 'Money'; universalFormat: string };
        virtualCard?: {
          __typename?: 'VirtualCard';
          cardNumberLastFour?: string;
          id: string;
          status: VirtualCardStatus;
          purchaseOrder: {
            __typename?: 'PurchaseOrder';
            id: string;
            purchaseRequestItem: {
              __typename?: 'PurchaseRequestItem';
              description?: string;
              id: string;
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
            };
          };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCardSubscriptionMerchantsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexCardSubscriptionMerchantsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'TransactionConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        id: string;
        merchant?: {
          __typename?: 'Merchant';
          city: string;
          companyName: string;
          id: string;
          merchantCategoryCode?: { __typename?: 'MerchantCategoryCode'; id: string; label: string };
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCardSubscriptionPurchaseRequestItemsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexCardSubscriptionPurchaseRequestItemsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        connection?: {
          __typename?: 'PurchaseRequestItemConnection';
          edges?: Array<{
            __typename?: 'PurchaseRequestItemEdge';
            cursor: string;
            node?: {
              __typename?: 'PurchaseRequestItem';
              id: string;
              status: PurchaseRequestItemStatus;
              submittedAt?: string;
              purchaseOrder?: {
                __typename?: 'PurchaseOrder';
                id: string;
                totalSpent: {
                  __typename?: 'Money';
                  cents: string;
                  universalFormat: string;
                  currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                };
                transactions?: Array<{
                  __typename?: 'Transaction';
                  id: string;
                  reconciliationStatus: TransactionReconciliationStatus;
                }>;
              };
              requestedAmount?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              requestedBaseAmount?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              requestedBy: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexCardSubscriptionTransactionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  reconciliationStatus?: InputMaybe<Array<TransactionReconciliationStatus> | TransactionReconciliationStatus>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<TransactionSortInput> | TransactionSortInput>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexCardSubscriptionTransactionsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'TransactionConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        customReconciliationStatus?: CustomTransactionReconciliationStatus;
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        remindersSnoozed: boolean;
        remindersSnoozedUntil?: string;
        transactionDate: string;
        type: TransactionType;
        billedBaseAmount: { __typename?: 'Money'; universalFormat: string };
        merchant?: {
          __typename?: 'Merchant';
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        originalAmount: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCardSubscriptionVirtualCardsQueryVariables = Exact<{
  anyVirtualCardStatus?: InputMaybe<Array<VirtualCardStatus> | VirtualCardStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexCardSubscriptionVirtualCardsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'VirtualCardConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'VirtualCardEdge';
      cursor: string;
      node?: {
        __typename?: 'VirtualCard';
        cardNumberLastFour?: string;
        id: string;
        revokeComment?: string;
        status: VirtualCardStatus;
        validUntil: string;
        aboutToExpire?: boolean;
        lowAmount: boolean;
        actualLimitBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        remainingBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        spentBaseAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        actualLimit?: { __typename?: 'Money'; cents: string };
        spentAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCardSubscriptionsQueryVariables = Exact<{
  closed?: InputMaybe<OptionalBoolean>;
  anyCardStatus?: InputMaybe<Array<CardSubscriptionCardStatus> | CardSubscriptionCardStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus> | PurchaseOrderReconciliationStatus>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CardSubscriptionSortInput> | CardSubscriptionSortInput>;
}>;

export type IndexCardSubscriptionsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CardSubscriptionConnection';
    edges?: Array<{
      __typename?: 'CardSubscriptionEdge';
      cursor: string;
      node?: {
        __typename?: 'CardSubscription';
        cardStatus: CardSubscriptionCardStatus;
        closedAt?: string;
        id: string;
        label: string;
        reconciliationStatus?: PurchaseOrderReconciliationStatus;
        owner: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        primaryVirtualCard?: {
          __typename?: 'VirtualCard';
          id: string;
          validUntil: string;
          actualLimit?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          spentAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
        totalSpentBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'CardSubscriptionRecordViewerPermissions';
          close: boolean;
          requestRenewal: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCategoriesQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  categorySetId?: InputMaybe<Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexCategoriesQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'CategoryConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CategoryEdge';
      cursor: string;
      node?: {
        __typename?: 'Category';
        archived: boolean;
        childrenCount: number;
        code: string;
        id: string;
        label: string;
        parentId?: string;
        viewerPermissions: {
          __typename?: 'CategoryRecordViewerPermissions';
          archive: boolean;
          createChild?: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'CategoryCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexCategorySetsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexCategorySetsQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'CategorySetConnection';
    edges?: Array<{
      __typename?: 'CategorySetEdge';
      cursor: string;
      node?: {
        __typename?: 'CategorySet';
        archived: boolean;
        id: string;
        label: string;
        updatedAt: string;
        categories: { __typename?: 'CategoryConnection'; totalCount: number };
        createdBy?: { __typename?: 'User'; id: string; fullName?: string };
        viewerPermissions: {
          __typename?: 'CategorySetRecordViewerPermissions';
          archive: boolean;
          import: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'CategorySetCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexConditionReferenceValuesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexConditionReferenceValuesQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | {
        __typename?: 'FlowStepResult';
        id: string;
        connection?: {
          __typename?: 'FlowStepResultValueConnection';
          edges?: Array<{
            __typename?: 'FlowStepResultValueEdge';
            cursor: string;
            node?: {
              __typename?: 'FlowStepResultValue';
              id: string;
              value?: string;
              record?:
                | {
                    __typename?: 'Address';
                    city?: string;
                    id: string;
                    country: { __typename?: 'Country'; code: CountryCode; name: string };
                  }
                | {
                    __typename?: 'Approval';
                    id: string;
                    approvable?:
                      | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
                      | {
                          __typename?: 'SupplierRequest';
                          id: string;
                          companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
                        };
                  }
                | {
                    __typename?: 'ApprovalDelegation';
                    archived: boolean;
                    id: string;
                    from: { __typename?: 'User'; fullName?: string; id: string };
                    to: { __typename?: 'User'; fullName?: string; id: string };
                  }
                | { __typename?: 'BuyerAccount'; companyName: string; id: string }
                | { __typename?: 'CardSubscription'; label: string; id: string }
                | { __typename?: 'Category'; archived: boolean; code: string; label: string; id: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; label: string; id: string }
                | { __typename?: 'DataSource'; archived: boolean; label: string; id: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; label: string; id: string }
                | { __typename?: 'Document'; archived: boolean; documentNumber?: string; id: string }
                | { __typename?: 'DocumentParseResult'; documentNumber?: string; id: string }
                | {
                    __typename?: 'InvoicePayment';
                    id: string;
                    requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
                  }
                | { __typename?: 'IssuerIntegration'; archived: boolean; issuerName: string; id: string }
                | { __typename?: 'Merchant'; merchantName: string; id: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; label: string; id: string }
                | { __typename?: 'PurchaseOrder'; label: string; id: string }
                | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
                | { __typename?: 'SupplierAccount'; companyName: string; id: string }
                | {
                    __typename?: 'SupplierRequest';
                    id: string;
                    companyLookup: { __typename?: 'CompanyLookup'; companyName?: string; id: string };
                  }
                | { __typename?: 'Transaction'; transactionId: string; id: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string }
                | { __typename?: 'VirtualCard'; cardNumberLastFour?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexCostCentresQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CostCentreSortInput> | CostCentreSortInput>;
}>;

export type IndexCostCentresQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CostCentreConnection';
    edges?: Array<{
      __typename?: 'CostCentreEdge';
      cursor: string;
      node?: {
        __typename?: 'CostCentre';
        archived: boolean;
        code: string;
        description?: string;
        id: string;
        label: string;
        viewerPermissions: {
          __typename?: 'CostCentreRecordViewerPermissions';
          archive: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'CostCentreCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexCustomFieldPreviewsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  formableClass: CustomFieldFormableClass;
  formableParentIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexCustomFieldPreviewsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CustomFieldConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CustomFieldEdge';
      cursor: string;
      node?:
        | {
            __typename?: 'ApproverCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'AttachmentCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'CategoryCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'CheckboxCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'CostCentreCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'DateCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'IntegerCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'MoneyCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'OrganisationUnitCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'SelectCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'TextAreaCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'TextCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          }
        | {
            __typename?: 'UrlCustomField';
            archived: boolean;
            archivedAt?: string;
            iconName?: string;
            id: string;
            label: string;
          };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexCustomFieldsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  formableClasses?: InputMaybe<Array<CustomFieldFormableClass> | CustomFieldFormableClass>;
  formableParentIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  required?: InputMaybe<OptionalBoolean>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CustomFieldSortInput> | CustomFieldSortInput>;
  types?: InputMaybe<Array<CustomFieldType> | CustomFieldType>;
}>;

export type IndexCustomFieldsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CustomFieldConnection';
    edges?: Array<{
      __typename?: 'CustomFieldEdge';
      cursor: string;
      node?:
        | {
            __typename?: 'ApproverCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'AttachmentCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'CategoryCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'CheckboxCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'CostCentreCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'DateCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'IntegerCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'MoneyCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'OrganisationUnitCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'SelectCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'TextAreaCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'TextCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          }
        | {
            __typename?: 'UrlCustomField';
            archived: boolean;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            label: string;
            required: boolean;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
            viewerPermissions: {
              __typename?: 'CustomFieldRecordViewerPermissions';
              archive: boolean;
              unarchive: boolean;
              update: boolean;
            };
          };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'CustomFieldCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexDataFiltersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexDataFiltersQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | {
        __typename?: 'DataView';
        id: string;
        connection: {
          __typename?: 'DataFilterConnection';
          edges?: Array<{
            __typename?: 'DataFilterEdge';
            cursor: string;
            node?: {
              __typename?: 'DataFilter';
              comparator: FlowStepConditionComparatorName;
              id: string;
              referenceValue?: any;
              updatedAt: string;
              inputPath?: { __typename?: 'DataPath'; id: string; path: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexDataScopingRulesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexDataScopingRulesQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | {
        __typename?: 'ApproverCustomField';
        id: string;
        dataScopingRules: {
          __typename?: 'DataScopingRuleConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataScopingRuleEdge';
            cursor: string;
            node?: {
              __typename?: 'DataScopingRule';
              id: string;
              operator: DataScopingRuleOperator;
              position: number;
              scopeByCustomField:
                | { __typename?: 'ApproverCustomField'; id: string; label: string }
                | { __typename?: 'AttachmentCustomField'; id: string; label: string }
                | { __typename?: 'CategoryCustomField'; id: string; label: string }
                | { __typename?: 'CheckboxCustomField'; id: string; label: string }
                | { __typename?: 'CostCentreCustomField'; id: string; label: string }
                | { __typename?: 'DateCustomField'; id: string; label: string }
                | { __typename?: 'IntegerCustomField'; id: string; label: string }
                | { __typename?: 'MoneyCustomField'; id: string; label: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
                | { __typename?: 'SelectCustomField'; id: string; label: string }
                | { __typename?: 'TextAreaCustomField'; id: string; label: string }
                | { __typename?: 'TextCustomField'; id: string; label: string }
                | { __typename?: 'UrlCustomField'; id: string; label: string };
              scopeToPath?: {
                __typename?: 'DataPath';
                id: string;
                path: string;
                rootResourceType: MetaModelResourceType;
              };
              viewerPermissions: {
                __typename?: 'DataScopingRuleRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; id: string }
    | {
        __typename?: 'CategoryCustomField';
        id: string;
        dataScopingRules: {
          __typename?: 'DataScopingRuleConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataScopingRuleEdge';
            cursor: string;
            node?: {
              __typename?: 'DataScopingRule';
              id: string;
              operator: DataScopingRuleOperator;
              position: number;
              scopeByCustomField:
                | { __typename?: 'ApproverCustomField'; id: string; label: string }
                | { __typename?: 'AttachmentCustomField'; id: string; label: string }
                | { __typename?: 'CategoryCustomField'; id: string; label: string }
                | { __typename?: 'CheckboxCustomField'; id: string; label: string }
                | { __typename?: 'CostCentreCustomField'; id: string; label: string }
                | { __typename?: 'DateCustomField'; id: string; label: string }
                | { __typename?: 'IntegerCustomField'; id: string; label: string }
                | { __typename?: 'MoneyCustomField'; id: string; label: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
                | { __typename?: 'SelectCustomField'; id: string; label: string }
                | { __typename?: 'TextAreaCustomField'; id: string; label: string }
                | { __typename?: 'TextCustomField'; id: string; label: string }
                | { __typename?: 'UrlCustomField'; id: string; label: string };
              scopeToPath?: {
                __typename?: 'DataPath';
                id: string;
                path: string;
                rootResourceType: MetaModelResourceType;
              };
              viewerPermissions: {
                __typename?: 'DataScopingRuleRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | {
        __typename?: 'CostCentreCustomField';
        id: string;
        dataScopingRules: {
          __typename?: 'DataScopingRuleConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataScopingRuleEdge';
            cursor: string;
            node?: {
              __typename?: 'DataScopingRule';
              id: string;
              operator: DataScopingRuleOperator;
              position: number;
              scopeByCustomField:
                | { __typename?: 'ApproverCustomField'; id: string; label: string }
                | { __typename?: 'AttachmentCustomField'; id: string; label: string }
                | { __typename?: 'CategoryCustomField'; id: string; label: string }
                | { __typename?: 'CheckboxCustomField'; id: string; label: string }
                | { __typename?: 'CostCentreCustomField'; id: string; label: string }
                | { __typename?: 'DateCustomField'; id: string; label: string }
                | { __typename?: 'IntegerCustomField'; id: string; label: string }
                | { __typename?: 'MoneyCustomField'; id: string; label: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
                | { __typename?: 'SelectCustomField'; id: string; label: string }
                | { __typename?: 'TextAreaCustomField'; id: string; label: string }
                | { __typename?: 'TextCustomField'; id: string; label: string }
                | { __typename?: 'UrlCustomField'; id: string; label: string };
              scopeToPath?: {
                __typename?: 'DataPath';
                id: string;
                path: string;
                rootResourceType: MetaModelResourceType;
              };
              viewerPermissions: {
                __typename?: 'DataScopingRuleRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; id: string }
    | {
        __typename?: 'OrganisationUnitCustomField';
        id: string;
        dataScopingRules: {
          __typename?: 'DataScopingRuleConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataScopingRuleEdge';
            cursor: string;
            node?: {
              __typename?: 'DataScopingRule';
              id: string;
              operator: DataScopingRuleOperator;
              position: number;
              scopeByCustomField:
                | { __typename?: 'ApproverCustomField'; id: string; label: string }
                | { __typename?: 'AttachmentCustomField'; id: string; label: string }
                | { __typename?: 'CategoryCustomField'; id: string; label: string }
                | { __typename?: 'CheckboxCustomField'; id: string; label: string }
                | { __typename?: 'CostCentreCustomField'; id: string; label: string }
                | { __typename?: 'DateCustomField'; id: string; label: string }
                | { __typename?: 'IntegerCustomField'; id: string; label: string }
                | { __typename?: 'MoneyCustomField'; id: string; label: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
                | { __typename?: 'SelectCustomField'; id: string; label: string }
                | { __typename?: 'TextAreaCustomField'; id: string; label: string }
                | { __typename?: 'TextCustomField'; id: string; label: string }
                | { __typename?: 'UrlCustomField'; id: string; label: string };
              scopeToPath?: {
                __typename?: 'DataPath';
                id: string;
                path: string;
                rootResourceType: MetaModelResourceType;
              };
              viewerPermissions: {
                __typename?: 'DataScopingRuleRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | {
        __typename?: 'SelectCustomField';
        id: string;
        dataScopingRules: {
          __typename?: 'DataScopingRuleConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataScopingRuleEdge';
            cursor: string;
            node?: {
              __typename?: 'DataScopingRule';
              id: string;
              operator: DataScopingRuleOperator;
              position: number;
              scopeByCustomField:
                | { __typename?: 'ApproverCustomField'; id: string; label: string }
                | { __typename?: 'AttachmentCustomField'; id: string; label: string }
                | { __typename?: 'CategoryCustomField'; id: string; label: string }
                | { __typename?: 'CheckboxCustomField'; id: string; label: string }
                | { __typename?: 'CostCentreCustomField'; id: string; label: string }
                | { __typename?: 'DateCustomField'; id: string; label: string }
                | { __typename?: 'IntegerCustomField'; id: string; label: string }
                | { __typename?: 'MoneyCustomField'; id: string; label: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string; label: string }
                | { __typename?: 'SelectCustomField'; id: string; label: string }
                | { __typename?: 'TextAreaCustomField'; id: string; label: string }
                | { __typename?: 'TextCustomField'; id: string; label: string }
                | { __typename?: 'UrlCustomField'; id: string; label: string };
              scopeToPath?: {
                __typename?: 'DataPath';
                id: string;
                path: string;
                rootResourceType: MetaModelResourceType;
              };
              viewerPermissions: {
                __typename?: 'DataScopingRuleRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | { __typename?: 'Workflow'; id: string }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type IndexDataSourceItemsQueryVariables = Exact<{
  dataSourceId: Scalars['GlobalID']['input'];
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DataSourceItemSortInput> | DataSourceItemSortInput>;
}>;

export type IndexDataSourceItemsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | {
        __typename?: 'DataSource';
        id: string;
        connection: {
          __typename?: 'DataSourceItemConnection';
          edges?: Array<{
            __typename?: 'DataSourceItemEdge';
            cursor: string;
            node?: {
              __typename?: 'DataSourceItem';
              archived: boolean;
              description?: string;
              externalId?: string;
              id: string;
              label: string;
              dataSource: { __typename?: 'DataSource'; id: string };
              viewerPermissions: {
                __typename?: 'DataSourceItemRecordViewerPermissions';
                archive: boolean;
                destroy: boolean;
                showArchivedCustomFields?: boolean;
                showEmptyCustomFields?: boolean;
                unarchive: boolean;
                update: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexDataSourcesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DataSourceSortInput> | DataSourceSortInput>;
}>;

export type IndexDataSourcesQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'DataSourceConnection';
    edges?: Array<{
      __typename?: 'DataSourceEdge';
      cursor: string;
      node?: {
        __typename?: 'DataSource';
        archived: boolean;
        id: string;
        label: string;
        updatedAt: string;
        items: { __typename?: 'DataSourceItemConnection'; totalCount: number };
        parsedId?: { __typename?: 'IParsedGlobalId'; id?: string; type?: string };
        viewerPermissions: {
          __typename?: 'DataSourceRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          import: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'DataSourceCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexDataViewFieldsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexDataViewFieldsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | {
        __typename?: 'DataView';
        id: string;
        connection: {
          __typename?: 'DataViewFieldConnection';
          edges?: Array<{
            __typename?: 'DataViewFieldEdge';
            cursor: string;
            node?: {
              __typename?: 'DataViewField';
              formatString?: DataViewFieldFormatString;
              id: string;
              label: string;
              position: number;
              updatedAt: string;
              dataValue?: {
                __typename?: 'DataValue';
                dateValue?: string;
                id: string;
                staticValue?: string;
                valueSource: DataValueValueSource;
                amountValue?: { __typename?: 'Money'; universalFormat: string };
                pathValue?: {
                  __typename?: 'DataPath';
                  id: string;
                  path: string;
                  rootResourceType: MetaModelResourceType;
                };
                recordValue?:
                  | { __typename?: 'Category'; code: string; id: string; label: string }
                  | { __typename?: 'CostCentre'; code: string; id: string; label: string }
                  | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
                  | { __typename?: 'User'; fullName?: string; id: string };
              };
              viewerPermissions: {
                __typename?: 'DataViewFieldRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexDataViewsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  resourceTypes?: InputMaybe<Array<DataViewResourceType> | DataViewResourceType>;
  sort?: InputMaybe<Array<DataViewSortInput> | DataViewSortInput>;
}>;

export type IndexDataViewsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'DataViewConnection';
    edges?: Array<{
      __typename?: 'DataViewEdge';
      cursor: string;
      node?: {
        __typename?: 'DataView';
        archived: boolean;
        createdAt: string;
        description?: string;
        id: string;
        label: string;
        resourceType: DataViewResourceType;
        updatedAt: string;
        fields: { __typename?: 'DataViewFieldConnection'; totalCount: number };
        parsedId?: { __typename?: 'IParsedGlobalId'; id?: string; type?: string };
        viewerPermissions: {
          __typename?: 'DataViewRecordViewerPermissions';
          archive: boolean;
          duplicate: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'DataViewCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexDataViewsMetadataQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexDataViewsMetadataQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'MetaModelConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'MetaModelEdge';
      cursor: string;
      node?: {
        __typename?: 'MetaModel';
        resourceType: MetaModelResourceType;
        associations?: Array<{
          __typename?: 'MetaModelAssociation';
          archived?: boolean;
          label?: string;
          name?: string;
          resourceType: MetaModelResourceType;
        }>;
        attributes?: Array<{
          __typename?: 'MetaModelAttribute';
          archived?: boolean;
          dataType?: MetaModelAttributeDataType;
          label?: string;
          name?: string;
          options: Array<string>;
        }>;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexDocumentsPanelDocumentsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  inboxIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  transactionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexDocumentsPanelDocumentsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'DocumentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DocumentEdge';
      cursor: string;
      node?: {
        __typename?: 'Document';
        classification: DocumentClassification;
        createdAt: string;
        id: string;
        archived: boolean;
        isDraft: boolean;
        invalidatedAt?: string;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        totalAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexDocumentsTabDocumentsQueryVariables = Exact<{
  cardSubscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  documentTypes?: InputMaybe<Array<DocumentClassification> | DocumentClassification>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  isDraft?: InputMaybe<OptionalBoolean>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DocumentSortInput> | DocumentSortInput>;
  withTransactions?: InputMaybe<OptionalBoolean>;
}>;

export type IndexDocumentsTabDocumentsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'DocumentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DocumentEdge';
      cursor: string;
      node?: {
        __typename?: 'Document';
        archived: boolean;
        classification: DocumentClassification;
        createdAt: string;
        id: string;
        isDraft: boolean;
        paymentDate?: string;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        file?: { __typename?: 'Attachment'; downloadUrl: string; filename: string; id: string };
        totalAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        transactions?: Array<{
          __typename?: 'Transaction';
          id: string;
          transactionDate: string;
          originalAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        }>;
        vatAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'DocumentRecordViewerPermissions';
          archive: boolean;
          download: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexDraftRequestItemsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexDraftRequestItemsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'PurchaseRequestItemConnection';
    edges?: Array<{
      __typename?: 'PurchaseRequestItemEdge';
      cursor: string;
      node?: {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        purchaseType: PurchaseType;
        updatedAt: string;
        customFieldValues?: Array<
          | { __typename?: 'AssociationCustomFieldValue'; id: string }
          | { __typename?: 'AttachmentCustomFieldValue'; id: string }
          | { __typename?: 'BooleanCustomFieldValue'; id: string }
          | { __typename?: 'DateTimeCustomFieldValue'; id: string }
          | { __typename?: 'IntegerCustomFieldValue'; id: string }
          | { __typename?: 'MoneyCustomFieldValue'; id: string }
          | { __typename?: 'StringCustomFieldValue'; id: string }
        >;
        requestedAmount?: { __typename: 'Money' };
        supplier?: { __typename?: 'SupplierAccount'; id: string };
        viewerPermissions: {
          __typename?: 'PurchaseRequestItemRecordViewerPermissions';
          cancel: boolean;
          closePurchaseOrder?: boolean;
          destroy: boolean;
          markGoodsReceived: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          subscribeToNotifications: boolean;
          undoMarkGoodsReceived: boolean;
          unsubscribeFromNotifications: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexFlowActionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexFlowActionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        id: string;
        connection: {
          __typename?: 'FlowStepActionConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepActionEdge';
            cursor: string;
            node?:
              | {
                  __typename?: 'FlowActionApprove';
                  description?: string;
                  id: string;
                  viewerPermissions: {
                    __typename?: 'FlowStepActionRecordViewerPermissions';
                    destroy: boolean;
                    update: boolean;
                    updatePosition: boolean;
                  };
                  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
                  outputFalseStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  outputTrueStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  softValidation: {
                    __typename?: 'FlowStepSoftValidation';
                    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                  };
                }
              | {
                  __typename?: 'FlowActionComment';
                  description?: string;
                  id: string;
                  targetPath?: {
                    __typename?: 'DataPath';
                    id: string;
                    path: string;
                    rootResourceType: MetaModelResourceType;
                  };
                  viewerPermissions: {
                    __typename?: 'FlowStepActionRecordViewerPermissions';
                    destroy: boolean;
                    update: boolean;
                    updatePosition: boolean;
                  };
                  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
                  outputFalseStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  outputTrueStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  softValidation: {
                    __typename?: 'FlowStepSoftValidation';
                    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                  };
                }
              | {
                  __typename?: 'FlowActionCreatePurchaseOrder';
                  description?: string;
                  id: string;
                  viewerPermissions: {
                    __typename?: 'FlowStepActionRecordViewerPermissions';
                    destroy: boolean;
                    update: boolean;
                    updatePosition: boolean;
                  };
                  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
                  outputFalseStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  outputTrueStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  softValidation: {
                    __typename?: 'FlowStepSoftValidation';
                    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                  };
                }
              | {
                  __typename?: 'FlowActionGetApproval';
                  approvalType?: FlowActionGetApprovalApprovalType;
                  assignAll: boolean;
                  description?: string;
                  id: string;
                  approverUsers: {
                    __typename?: 'UserConnection';
                    totalCount: number;
                    edges?: Array<{
                      __typename?: 'UserEdge';
                      node?: {
                        __typename?: 'User';
                        archived: boolean;
                        fullName?: string;
                        id: string;
                        status: UserStatus;
                      };
                    }>;
                  };
                  assigneePaths: {
                    __typename?: 'DataPathConnection';
                    totalCount: number;
                    edges?: Array<{
                      __typename?: 'DataPathEdge';
                      node?: {
                        __typename?: 'DataPath';
                        id: string;
                        path: string;
                        rootResourceType: MetaModelResourceType;
                      };
                    }>;
                  };
                  viewerPermissions: {
                    __typename?: 'FlowStepActionRecordViewerPermissions';
                    destroy: boolean;
                    update: boolean;
                    updatePosition: boolean;
                  };
                  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
                  outputFalseStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  outputTrueStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  softValidation: {
                    __typename?: 'FlowStepSoftValidation';
                    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                  };
                }
              | {
                  __typename?: 'FlowActionReject';
                  description?: string;
                  id: string;
                  viewerPermissions: {
                    __typename?: 'FlowStepActionRecordViewerPermissions';
                    destroy: boolean;
                    update: boolean;
                    updatePosition: boolean;
                  };
                  coordinates: { __typename?: 'Coordinates'; x: number; y: number };
                  outputFalseStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  outputTrueStep?:
                    | { __typename?: 'FlowActionApprove'; id: string }
                    | { __typename?: 'FlowActionComment'; id: string }
                    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                    | { __typename?: 'FlowActionGetApproval'; id: string }
                    | { __typename?: 'FlowActionReject'; id: string }
                    | { __typename?: 'FlowStepCondition'; id: string };
                  softValidation: {
                    __typename?: 'FlowStepSoftValidation';
                    publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
                  };
                };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'WorkflowRun' };
};

export type IndexFlowConditionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexFlowConditionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        id: string;
        connection: {
          __typename?: 'FlowStepConditionConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'FlowStepConditionEdge';
            cursor: string;
            node?: {
              __typename?: 'FlowStepCondition';
              comparator?: FlowStepConditionComparatorName;
              referenceDataType?: FlowStepConditionInputAttributeDataType;
              referencePlaceholder: string;
              referenceUnit?: string;
              referenceValue?: any;
              description?: string;
              id: string;
              inputPath?: {
                __typename?: 'DataPath';
                id: string;
                path: string;
                rootResourceType: MetaModelResourceType;
              };
              referenceAssociations: {
                __typename?: 'ReferenceAssociationConnection';
                totalCount: number;
                edges?: Array<{
                  __typename?: 'ReferenceAssociationEdge';
                  node?: {
                    __typename?: 'ReferenceAssociation';
                    id: string;
                    referenceRecord:
                      | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
                      | { __typename?: 'Category'; id: string; label: string }
                      | { __typename?: 'CostCentre'; id: string; label: string }
                      | { __typename?: 'DataSourceItem'; id: string; label: string }
                      | { __typename?: 'IssuerIntegration'; id: string; description: string }
                      | { __typename?: 'OrganisationUnit'; id: string; label: string }
                      | { __typename?: 'User'; id: string; fullName?: string };
                  };
                }>;
              };
              referencePaths: {
                __typename?: 'DataPathConnection';
                totalCount: number;
                edges?: Array<{
                  __typename?: 'DataPathEdge';
                  node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
                }>;
              };
              viewerPermissions: {
                __typename?: 'FlowStepConditionRecordViewerPermissions';
                destroy: boolean;
                update: boolean;
                updatePosition: boolean;
              };
              coordinates: { __typename?: 'Coordinates'; x: number; y: number };
              outputFalseStep?:
                | { __typename?: 'FlowActionApprove'; id: string }
                | { __typename?: 'FlowActionComment'; id: string }
                | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                | { __typename?: 'FlowActionGetApproval'; id: string }
                | { __typename?: 'FlowActionReject'; id: string }
                | { __typename?: 'FlowStepCondition'; id: string };
              outputTrueStep?:
                | { __typename?: 'FlowActionApprove'; id: string }
                | { __typename?: 'FlowActionComment'; id: string }
                | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                | { __typename?: 'FlowActionGetApproval'; id: string }
                | { __typename?: 'FlowActionReject'; id: string }
                | { __typename?: 'FlowStepCondition'; id: string };
              softValidation: {
                __typename?: 'FlowStepSoftValidation';
                publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'WorkflowRun' };
};

export type IndexFlowStepResultsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexFlowStepResultsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | {
        __typename?: 'WorkflowRun';
        id: string;
        connection: {
          __typename?: 'FlowStepResultConnection';
          edges?: Array<{
            __typename?: 'FlowStepResultEdge';
            cursor: string;
            node?: {
              __typename?: 'FlowStepResult';
              conditionResult?: boolean;
              createdAt: string;
              id: string;
              status: FlowStepResultStatus;
              commentTargetValue?: {
                __typename?: 'FlowStepResultValue';
                id: string;
                value?: string;
                record?:
                  | {
                      __typename?: 'Address';
                      city?: string;
                      id: string;
                      country: { __typename?: 'Country'; code: CountryCode; name: string };
                    }
                  | {
                      __typename?: 'Approval';
                      id: string;
                      approvable?:
                        | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
                        | {
                            __typename?: 'SupplierRequest';
                            id: string;
                            companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
                          };
                    }
                  | {
                      __typename?: 'ApprovalDelegation';
                      archived: boolean;
                      id: string;
                      from: { __typename?: 'User'; fullName?: string; id: string };
                      to: { __typename?: 'User'; fullName?: string; id: string };
                    }
                  | { __typename?: 'BuyerAccount'; companyName: string; id: string }
                  | { __typename?: 'CardSubscription'; label: string; id: string }
                  | { __typename?: 'Category'; archived: boolean; code: string; label: string; id: string }
                  | { __typename?: 'CostCentre'; archived: boolean; code: string; label: string; id: string }
                  | { __typename?: 'DataSource'; archived: boolean; label: string; id: string }
                  | { __typename?: 'DataSourceItem'; archived: boolean; label: string; id: string }
                  | { __typename?: 'Document'; archived: boolean; documentNumber?: string; id: string }
                  | { __typename?: 'DocumentParseResult'; documentNumber?: string; id: string }
                  | {
                      __typename?: 'InvoicePayment';
                      id: string;
                      requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
                    }
                  | { __typename?: 'IssuerIntegration'; archived: boolean; issuerName: string; id: string }
                  | { __typename?: 'Merchant'; merchantName: string; id: string }
                  | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; label: string; id: string }
                  | { __typename?: 'PurchaseOrder'; label: string; id: string }
                  | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
                  | { __typename?: 'SupplierAccount'; companyName: string; id: string }
                  | {
                      __typename?: 'SupplierRequest';
                      id: string;
                      companyLookup: { __typename?: 'CompanyLookup'; companyName?: string; id: string };
                    }
                  | { __typename?: 'Transaction'; transactionId: string; id: string }
                  | { __typename?: 'User'; archived: boolean; fullName?: string; id: string }
                  | { __typename?: 'VirtualCard'; cardNumberLastFour?: string; id: string };
              };
              conditionInputValue?: {
                __typename?: 'FlowStepResultValue';
                id: string;
                value?: string;
                record?:
                  | {
                      __typename?: 'Address';
                      city?: string;
                      id: string;
                      country: { __typename?: 'Country'; code: CountryCode; name: string };
                    }
                  | {
                      __typename?: 'Approval';
                      id: string;
                      approvable?:
                        | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
                        | {
                            __typename?: 'SupplierRequest';
                            id: string;
                            companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
                          };
                    }
                  | {
                      __typename?: 'ApprovalDelegation';
                      archived: boolean;
                      id: string;
                      from: { __typename?: 'User'; fullName?: string; id: string };
                      to: { __typename?: 'User'; fullName?: string; id: string };
                    }
                  | { __typename?: 'BuyerAccount'; companyName: string; id: string }
                  | { __typename?: 'CardSubscription'; label: string; id: string }
                  | { __typename?: 'Category'; archived: boolean; code: string; label: string; id: string }
                  | { __typename?: 'CostCentre'; archived: boolean; code: string; label: string; id: string }
                  | { __typename?: 'DataSource'; archived: boolean; label: string; id: string }
                  | { __typename?: 'DataSourceItem'; archived: boolean; label: string; id: string }
                  | { __typename?: 'Document'; archived: boolean; documentNumber?: string; id: string }
                  | { __typename?: 'DocumentParseResult'; documentNumber?: string; id: string }
                  | {
                      __typename?: 'InvoicePayment';
                      id: string;
                      requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
                    }
                  | { __typename?: 'IssuerIntegration'; archived: boolean; issuerName: string; id: string }
                  | { __typename?: 'Merchant'; merchantName: string; id: string }
                  | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; label: string; id: string }
                  | { __typename?: 'PurchaseOrder'; label: string; id: string }
                  | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
                  | { __typename?: 'SupplierAccount'; companyName: string; id: string }
                  | {
                      __typename?: 'SupplierRequest';
                      id: string;
                      companyLookup: { __typename?: 'CompanyLookup'; companyName?: string; id: string };
                    }
                  | { __typename?: 'Transaction'; transactionId: string; id: string }
                  | { __typename?: 'User'; archived: boolean; fullName?: string; id: string }
                  | { __typename?: 'VirtualCard'; cardNumberLastFour?: string; id: string };
              };
              flowStep?:
                | { __typename?: 'FlowActionApprove'; id: string }
                | {
                    __typename?: 'FlowActionComment';
                    id: string;
                    commentAuthor?: { __typename?: 'User'; fullName?: string; id: string };
                  }
                | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
                | { __typename?: 'FlowActionGetApproval'; id: string }
                | { __typename?: 'FlowActionReject'; id: string }
                | {
                    __typename?: 'FlowStepCondition';
                    id: string;
                    inputPath?: {
                      __typename?: 'DataPath';
                      id: string;
                      path: string;
                      rootResourceType: MetaModelResourceType;
                    };
                  };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      };
};

export type IndexFormCustomFieldsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  formableClasses?: InputMaybe<Array<CustomFieldFormableClass> | CustomFieldFormableClass>;
  formableParentIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  sort?: InputMaybe<Array<CustomFieldSortInput> | CustomFieldSortInput>;
}>;

export type IndexFormCustomFieldsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CustomFieldConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CustomFieldEdge';
      cursor: string;
      node?:
        | {
            __typename?: 'ApproverCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'AttachmentCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'CategoryCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'CheckboxCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'CostCentreCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'DateCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'IntegerCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'MoneyCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'OrganisationUnitCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'SelectCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'TextAreaCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'TextCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          }
        | {
            __typename?: 'UrlCustomField';
            description?: string;
            formableClass: CustomFieldFormableClass;
            iconName?: string;
            id: string;
            inputType: CustomFieldInputType;
            label: string;
            placeholder?: string;
            position: number;
            required: boolean;
            slug: string;
            tooltip?: string;
            valueType: CustomFieldValueType;
            width: number;
            formableParent?: { __typename?: 'DataSource'; id: string; label: string };
          };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexInboxIncomingEmailsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  inboxId: Scalars['GlobalID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexInboxIncomingEmailsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'IncomingEmailConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'IncomingEmailEdge';
      cursor: string;
      node?: {
        __typename?: 'IncomingEmail';
        id: string;
        receivedAt: string;
        subject?: string;
        incomingEmailAttachments: { __typename?: 'IncomingEmailAttachmentConnection'; totalCount: number };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexIncomingEmailAttachmentsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexIncomingEmailAttachmentsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | {
        __typename?: 'IncomingEmail';
        id: string;
        incomingEmailAttachments: {
          __typename?: 'IncomingEmailAttachmentConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'IncomingEmailAttachmentEdge';
            cursor: string;
            node?: {
              __typename?: 'IncomingEmailAttachment';
              id: string;
              processingCompletedAt?: string;
              processingFailedAt?: string;
              attachment?: { __typename?: 'Attachment'; filename: string; id: string };
              document?: {
                __typename?: 'Document';
                classification: DocumentClassification;
                id: string;
                transactions?: Array<{
                  __typename?: 'Transaction';
                  id: string;
                  billedAmount: { __typename?: 'Money'; universalFormat: string };
                }>;
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexInvoicePaymentsQueryVariables = Exact<{
  createdByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InvoicePaymentStatus> | InvoicePaymentStatus>;
  sort?: InputMaybe<Array<InvoicePaymentSortInput> | InvoicePaymentSortInput>;
  supplierIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexInvoicePaymentsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'InvoicePaymentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'InvoicePaymentEdge';
      cursor: string;
      node?: {
        __typename?: 'InvoicePayment';
        id: string;
        requestedPayoutDate: string;
        status: InvoicePaymentStatus;
        createdBy?: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
        };
        purchaseRequestItem?: {
          __typename?: 'PurchaseRequestItem';
          approvedAt?: string;
          description?: string;
          id: string;
        };
        requestedPayoutAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        supplier?: {
          __typename?: 'SupplierAccount';
          companyName: string;
          id: string;
          address?: {
            __typename?: 'Address';
            city?: string;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexIssuerIntegrationConsumersQueryVariables = Exact<{
  consumerRelation?: InputMaybe<IssuerIntegrationConsumerConsumerRelation>;
  consumerTypes?: InputMaybe<Array<IssuerIntegrationConsumerConsumerType> | IssuerIntegrationConsumerConsumerType>;
  costCentreIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  issuerIntegrationIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  sort?: InputMaybe<Array<IssuerIntegrationConsumerSortInput> | IssuerIntegrationConsumerSortInput>;
  userIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexIssuerIntegrationConsumersQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'IssuerIntegrationConsumerConnection';
    edges?: Array<{
      __typename?: 'IssuerIntegrationConsumerEdge';
      cursor: string;
      node?: {
        __typename?: 'IssuerIntegrationConsumer';
        cardUseInvoiceFallback: boolean;
        cardUseMultiFallback: boolean;
        cardUseSingleFallback: boolean;
        consumerName: string;
        id: string;
        costCentre?: { __typename?: 'CostCentre'; id: string };
        issuerIntegration: {
          __typename?: 'IssuerIntegration';
          cardUseInvoice: boolean;
          cardUseMulti: boolean;
          cardUseSingle: boolean;
          description: string;
          id: string;
        };
        organisationUnit?: { __typename?: 'OrganisationUnit'; id: string };
        user?: { __typename?: 'User'; id: string };
        viewerPermissions: {
          __typename?: 'IssuerIntegrationConsumerRecordViewerPermissions';
          destroy: boolean;
          makeInvoiceFallback?: boolean;
          makeMultiFallback?: boolean;
          makeSingleFallback?: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexIssuerIntegrationRulesQueryVariables = Exact<{
  cardUseTypes?: InputMaybe<Array<IssuerIntegrationRuleCardUseType> | IssuerIntegrationRuleCardUseType>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<IssuerIntegrationRuleSortInput> | IssuerIntegrationRuleSortInput>;
}>;

export type IndexIssuerIntegrationRulesQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'IssuerIntegrationRuleConnection';
    edges?: Array<{
      __typename?: 'IssuerIntegrationRuleEdge';
      cursor: string;
      node?: {
        __typename?: 'IssuerIntegrationRule';
        cardUseType: IssuerIntegrationRuleCardUseType;
        criteria: IssuerIntegrationRuleCriteria;
        id: string;
        position: number;
        strict: boolean;
        updatedAt: string;
        viewerPermissions: {
          __typename?: 'IssuerIntegrationRuleRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'IssuerIntegrationRuleCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexIssuerIntegrationsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  cardUseInvoice?: InputMaybe<OptionalBoolean>;
  cardUseMulti?: InputMaybe<OptionalBoolean>;
  cardUseSingle?: InputMaybe<OptionalBoolean>;
  currencyCodes?: InputMaybe<Array<CurrencyCode> | CurrencyCode>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<IssuerIntegrationSortInput> | IssuerIntegrationSortInput>;
}>;

export type IndexIssuerIntegrationsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'IssuerIntegrationConnection';
    edges?: Array<{
      __typename?: 'IssuerIntegrationEdge';
      cursor: string;
      node?: {
        __typename?: 'IssuerIntegration';
        cardUseInvoice: boolean;
        cardUseMulti: boolean;
        cardUseSingle: boolean;
        description: string;
        id: string;
        consumers: {
          __typename?: 'IssuerIntegrationConsumerConnection';
          totalCount: number;
          nodes?: Array<{ __typename?: 'IssuerIntegrationConsumer'; consumerName: string; id: string }>;
        };
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexLocationsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<LocationSortInput> | LocationSortInput>;
}>;

export type IndexLocationsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'LocationConnection';
    edges?: Array<{
      __typename?: 'LocationEdge';
      cursor: string;
      node?: {
        __typename?: 'Location';
        archived: boolean;
        id: string;
        label: string;
        address: {
          __typename?: 'Address';
          city?: string;
          formattedLines: Array<string>;
          id: string;
          postalCode?: string;
          regionName?: string;
          streetName?: string;
          streetNumber?: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'LocationRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'LocationCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexMeNotificationsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexMeNotificationsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Me';
    id: string;
    notifications?: {
      __typename?: 'NotificationConnection';
      totalCount: number;
      totalUnreadCount: number;
      edges?: Array<{
        __typename?: 'NotificationEdge';
        cursor: string;
        node?: {
          __typename?: 'Notification';
          createdAt: string;
          id: string;
          isRead: boolean;
          readAt?: string;
          updatedAt: string;
          activity: {
            __typename?: 'Activity';
            id: string;
            createdAt: string;
            displayStyle: ActivityDisplayStyle;
            icon: ActivityIcon;
            key: string;
            updatedAt: string;
            defaultUrl?: { __typename?: 'URL'; path?: string; query?: string };
            eventSubject?:
              | { __typename?: 'Approval' }
              | { __typename?: 'Attachment' }
              | { __typename?: 'BuyerAccount' }
              | { __typename?: 'Comment'; body?: string; id: string }
              | { __typename?: 'Document' }
              | { __typename?: 'IncomingEmail' }
              | { __typename?: 'PurchaseOrder' }
              | { __typename?: 'PurchaseRequestItem' }
              | { __typename?: 'SupplierAccount' }
              | { __typename?: 'Transaction' }
              | { __typename?: 'User' }
              | { __typename?: 'VirtualCard' }
              | { __typename?: 'Workflow' };
            fragments: Array<
              | {
                  __typename?: 'ReferenceFragment';
                  urn: string;
                  text: string;
                  type: StringFragmentFragmentType;
                  url?: { __typename?: 'URL'; path?: string; query?: string };
                }
              | { __typename?: 'TextFragment'; text: string; type: StringFragmentFragmentType }
            >;
          };
        };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string;
      };
    };
  };
};

export type IndexMerchantCategoryCodesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexMerchantCategoryCodesQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'MerchantCategoryCodeConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'MerchantCategoryCodeEdge';
      cursor: string;
      node?: { __typename?: 'MerchantCategoryCode'; code: any; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexMerchantsQueryVariables = Exact<{
  countryCodes?: InputMaybe<Array<CountryCode> | CountryCode>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  merchantCategoryCodes?: InputMaybe<
    Array<Scalars['MerchantCategoryCodeCode']['input']> | Scalars['MerchantCategoryCodeCode']['input']
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MerchantSortInput> | MerchantSortInput>;
}>;

export type IndexMerchantsQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'MerchantConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'MerchantEdge';
      cursor: string;
      node?: {
        __typename?: 'Merchant';
        companyName: string;
        city: string;
        id: string;
        lastUsedAt?: string;
        merchantName: string;
        country: { __typename?: 'Country'; code: CountryCode; name: string };
        merchantCategoryCode?: { __typename?: 'MerchantCategoryCode'; code: any; id: string; label: string };
        totalSpent?: { __typename?: 'Money'; universalFormat: string };
        transactions: { __typename?: 'TransactionConnection'; totalCount: number };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexOrganisationUnitsQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexOrganisationUnitsQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'OrganisationUnitConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'OrganisationUnitEdge';
      cursor: string;
      node?: {
        __typename?: 'OrganisationUnit';
        archived: boolean;
        childrenCount: number;
        classification: OrganisationUnitClassification;
        descendantUsersCount: number;
        id: string;
        label: string;
        parentId?: string;
        viewerPermissions: {
          __typename?: 'OrganisationUnitRecordViewerPermissions';
          archive: boolean;
          createChild?: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'OrganisationUnitCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexOverviewApprovalsQueryVariables = Exact<{
  assigneeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexOverviewApprovalsQuery = {
  __typename?: 'Query';
  approvals?: {
    __typename?: 'ApprovalConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ApprovalEdge';
      cursor: string;
      node?: {
        __typename?: 'Approval';
        id: string;
        status: ApprovalStatus;
        approvable?:
          | {
              __typename?: 'PurchaseRequestItem';
              description?: string;
              id: string;
              submittedAt?: string;
              purchaseType: PurchaseType;
              status: PurchaseRequestItemStatus;
              requestedAmount?: { __typename?: 'Money'; universalFormat: string };
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
              purchaseOrder?: {
                __typename?: 'PurchaseOrder';
                id: string;
                virtualCard?: {
                  __typename?: 'VirtualCard';
                  actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
                  spentBaseAmount: {
                    __typename?: 'Money';
                    cents: string;
                    universalFormat: string;
                    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                  };
                };
              };
            }
          | {
              __typename?: 'SupplierRequest';
              createdAt: string;
              id: string;
              approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
              companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
            };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexOverviewCardsQueryVariables = Exact<{
  aboutToExpire?: InputMaybe<OptionalBoolean>;
  anyVirtualCardStatus?: InputMaybe<Array<VirtualCardStatus> | VirtualCardStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  hasTransactions?: InputMaybe<OptionalBoolean>;
  issuedToIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexOverviewCardsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'VirtualCardConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'VirtualCardEdge';
      cursor: string;
      node?: {
        __typename?: 'VirtualCard';
        aboutToExpire?: boolean;
        cardNumberLastFour?: string;
        createdAt: string;
        id: string;
        lowAmount: boolean;
        status: VirtualCardStatus;
        validUntil: string;
        actualLimitBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        purchaseOrder: {
          __typename?: 'PurchaseOrder';
          id: string;
          purchaseType: PurchaseType;
          purchaseRequestItem: { __typename?: 'PurchaseRequestItem'; description?: string; id: string };
          transactions?: Array<{ __typename?: 'Transaction'; id: string }>;
        };
        spentBaseAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'VirtualCardRecordViewerPermissions';
          initiateIframeDelivery: boolean;
          revoke: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexOverviewRequestsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexOverviewRequestsQuery = {
  __typename?: 'Query';
  purchaseRequestItems?: {
    __typename?: 'PurchaseRequestItemConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'PurchaseRequestItemEdge';
      cursor: string;
      node?: {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        submittedAt?: string;
        requestedAmount?: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexOverviewTransactionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexOverviewTransactionsQuery = {
  __typename?: 'Query';
  transactions?: {
    __typename?: 'TransactionConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        transactionDate: string;
        merchant?: { __typename?: 'Merchant'; id: string };
        originalAmount: { __typename?: 'Money'; universalFormat: string };
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          purchaseRequestItem: { __typename?: 'PurchaseRequestItem'; description?: string; id: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexPurchaseRequestItemCardAuthorisationsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderId: Scalars['GlobalID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CardAuthorisationSortInput> | CardAuthorisationSortInput>;
}>;

export type IndexPurchaseRequestItemCardAuthorisationsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CardAuthorisationConnection';
    edges?: Array<{
      __typename?: 'CardAuthorisationEdge';
      cursor: string;
      node?: {
        __typename?: 'CardAuthorisation';
        date: string;
        declined: boolean;
        id: string;
        merchantName?: string;
        amount: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexPurchaseRequestItemInvoicePaymentsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type IndexPurchaseRequestItemInvoicePaymentsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'InvoicePaymentConnection';
    edges?: Array<{
      __typename?: 'InvoicePaymentEdge';
      cursor: string;
      node?: {
        __typename?: 'InvoicePayment';
        cancelledAt?: string;
        createdAt: string;
        id: string;
        requestedPayoutDate: string;
        status: InvoicePaymentStatus;
        cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
        cardPayment?: { __typename?: 'CardPayment'; completedAt?: string; scheduledAt?: string };
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        document: {
          __typename?: 'Document';
          id: string;
          file?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
          viewerPermissions: {
            __typename?: 'DocumentRecordViewerPermissions';
            archive: boolean;
            download: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            unarchive: boolean;
            update: boolean;
          };
        };
        requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
        supplier?: { __typename?: 'SupplierAccount'; companyName: string; id: string };
        supplierPayment: { __typename?: 'SupplierPayment'; completedAt?: string; transactionReference?: string };
        viewerPermissions: {
          __typename?: 'InvoicePaymentRecordViewerPermissions';
          cancel: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexPurchaseRequestItemTransactionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderId?: InputMaybe<Scalars['GlobalID']['input']>;
  reconciliationStatus?: InputMaybe<Array<TransactionReconciliationStatus> | TransactionReconciliationStatus>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<TransactionSortInput> | TransactionSortInput>;
}>;

export type IndexPurchaseRequestItemTransactionsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'TransactionConnection';
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        customReconciliationStatus?: CustomTransactionReconciliationStatus;
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        remindersSnoozed: boolean;
        remindersSnoozedUntil?: string;
        transactionDate: string;
        type: TransactionType;
        billedBaseAmount: { __typename?: 'Money'; universalFormat: string };
        merchant?: {
          __typename?: 'Merchant';
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        originalAmount: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexPurchaseRequestItemTransactionsOverviewQueryVariables = Exact<{
  purchaseOrderId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type IndexPurchaseRequestItemTransactionsOverviewQuery = {
  __typename?: 'Query';
  transactions?: {
    __typename?: 'TransactionConnection';
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        customReconciliationStatus?: CustomTransactionReconciliationStatus;
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        remindersSnoozed: boolean;
        remindersSnoozedUntil?: string;
        transactionDate: string;
        type: TransactionType;
        billedBaseAmount: { __typename?: 'Money'; universalFormat: string };
        merchant?: {
          __typename?: 'Merchant';
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        originalAmount: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexPurchaseRequestItemsQueryVariables = Exact<{
  anyPurchaseRequestItemStatus?: InputMaybe<Array<PurchaseRequestItemStatus> | PurchaseRequestItemStatus>;
  anyPurchaseTypes?: InputMaybe<Array<PurchaseType> | PurchaseType>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  reconciliationStatuses?: InputMaybe<Array<PurchaseOrderReconciliationStatus> | PurchaseOrderReconciliationStatus>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<RequestItemSortInput> | RequestItemSortInput>;
}>;

export type IndexPurchaseRequestItemsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'PurchaseRequestItemConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'PurchaseRequestItemEdge';
      cursor: string;
      node?: {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        purchaseType: PurchaseType;
        status: PurchaseRequestItemStatus;
        submittedAt?: string;
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          currencyConversionRate?: number;
          reconciliationStatus: PurchaseOrderReconciliationStatus;
          status: PurchaseOrderStatus;
          purchaseAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          totalSpent: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            merchantName: string;
            transactionDate: string;
          }>;
          virtualCard?: {
            __typename?: 'VirtualCard';
            id: string;
            lowAmount: boolean;
            currency?: { __typename?: 'Currency'; code: CurrencyCode };
          };
        };
        requestedAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedBy: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexReceiptMissingPanelDocumentsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['GlobalID']['input'];
}>;

export type IndexReceiptMissingPanelDocumentsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | {
        __typename?: 'Transaction';
        id: string;
        connection: {
          __typename?: 'DocumentConnection';
          edges?: Array<{
            __typename?: 'DocumentEdge';
            cursor: string;
            node?: {
              __typename?: 'Document';
              archived: boolean;
              classification: DocumentClassification;
              id: string;
              updatedAt: string;
              totalAmount?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexReportScheduleSubscriberNamesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexReportScheduleSubscriberNamesQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | {
        __typename?: 'ReportSchedule';
        id: string;
        connection?: {
          __typename?: 'UserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'UserEdge';
            cursor: string;
            node?: { __typename?: 'User'; fullName?: string; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexReportScheduleSubscriberResourcesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexReportScheduleSubscriberResourcesQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | {
        __typename?: 'ReportSchedule';
        id: string;
        connection?: {
          __typename?: 'UserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'UserEdge';
            cursor: string;
            node?: {
              __typename?: 'User';
              fullName?: string;
              id: string;
              status: UserStatus;
              organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
              profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexReportSchedulesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
}>;

export type IndexReportSchedulesQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ReportScheduleConnection';
    edges?: Array<{
      __typename?: 'ReportScheduleEdge';
      cursor: string;
      node?: {
        __typename?: 'ReportSchedule';
        archived: boolean;
        id: string;
        label: string;
        dataView: { __typename?: 'DataView'; id: string; label: string; resourceType: DataViewResourceType };
        parsedId?: { __typename?: 'IParsedGlobalId'; id?: string; type?: string };
        range?: {
          __typename?: 'Range';
          criterion?: string;
          end?: ReportScheduleRelativeTime;
          start?: ReportScheduleRelativeTime;
        };
        viewerPermissions: {
          __typename?: 'ReportScheduleRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
    viewerPermissions: { __typename?: 'ReportScheduleCollectionViewerPermissions'; create: boolean };
  };
};

export type IndexReportsQueryVariables = Exact<{
  generatedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  dataViewIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ReportSortInput> | ReportSortInput>;
}>;

export type IndexReportsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ReportConnection';
    edges?: Array<{
      __typename?: 'ReportEdge';
      cursor: string;
      node?: {
        __typename?: 'Report';
        createdAt: string;
        criterion?: string;
        id: string;
        label: string;
        resource: DataViewResourceType;
        createdBy?: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        dataView: { __typename?: 'DataView'; id: string; label: string };
        date: { __typename?: 'Date'; from?: string; to?: string };
        exportFile?: { __typename?: 'Attachment'; filename: string; id: string };
        viewerPermissions: {
          __typename?: 'ReportRecordViewerPermissions';
          destroy: boolean;
          download: boolean;
          show?: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexRequestFormSettingsDataSourcesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexRequestFormSettingsDataSourcesQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'DataSourceConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataSourceEdge';
      cursor: string;
      node?: { __typename?: 'DataSource'; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexResourcePreviewTransactionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  merchantIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  subscriptionIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  virtualCardId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type IndexResourcePreviewTransactionsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'TransactionConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        id: string;
        merchantName: string;
        transactionDate: string;
        originalAmount: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexSpendLimitsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SpendLimitSortInput> | SpendLimitSortInput>;
  spendLimitable?: InputMaybe<SpendLimitableType>;
}>;

export type IndexSpendLimitsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'SpendLimitConnection';
    edges?: Array<{
      __typename?: 'SpendLimitEdge';
      cursor: string;
      node?: {
        __typename?: 'SpendLimit';
        createdAt: string;
        id: string;
        runningPeriodInDays: SpendLimitRunningPeriodInDays;
        scope: SpendLimitScope;
        updatedAt: string;
        amount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        earlyWarningAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        spendLimitable:
          | { __typename?: 'OrganisationUnit'; archived: boolean; id: string; label: string }
          | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexSubscriptionPurchaseOrdersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexSubscriptionPurchaseOrdersQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        id: string;
        connection: {
          __typename?: 'PurchaseOrderConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'PurchaseOrderEdge';
            cursor: string;
            node?: { __typename?: 'PurchaseOrder'; createdAt: string; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type IndexSupplierAccountInvoicePaymentResourcesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['GlobalID']['input'];
}>;

export type IndexSupplierAccountInvoicePaymentResourcesQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'InvoicePaymentConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'InvoicePaymentEdge';
      cursor: string;
      node?: {
        __typename?: 'InvoicePayment';
        cancelledAt?: string;
        id: string;
        requestedPayoutDate: string;
        status: InvoicePaymentStatus;
        requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
        supplierPayment: { __typename?: 'SupplierPayment'; completedAt?: string; transactionReference?: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexSupplierOnboardingUsersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type IndexSupplierOnboardingUsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        email: string;
        fullName?: string;
        id: string;
        onboardingStatus?: SupplierOnboardingContactOnboardingStatus;
      };
    }>;
  };
};

export type IndexSupplierPayoutsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierPayoutSortInput> | SupplierPayoutSortInput>;
}>;

export type IndexSupplierPayoutsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'SupplierPayoutConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SupplierPayoutEdge';
      cursor: string;
      node?: {
        __typename?: 'SupplierPayout';
        buyerName: string;
        id: string;
        initiatedAt: string;
        invoiceNumber?: string;
        amount: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexSuppliersQueryVariables = Exact<{
  countryCodes?: InputMaybe<Array<CountryCode> | CountryCode>;
  enableStatuses?: InputMaybe<Array<SupplierAccountEnableStatus> | SupplierAccountEnableStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  industryCodeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  onboardingStatuses?: InputMaybe<
    Array<SupplierOnboardingCaseOnboardingStatus> | SupplierOnboardingCaseOnboardingStatus
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierAccountSortInput> | SupplierAccountSortInput>;
}>;

export type IndexSuppliersQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'SupplierAccountConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SupplierAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'SupplierAccount';
        companyName: string;
        id: string;
        vatNumber?: string;
        enableStatus?: SupplierAccountEnableStatus;
        onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
        address?: {
          __typename?: 'Address';
          city?: string;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        industryCodes: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
        viewerPermissions: {
          __typename?: 'SupplierAccountRecordViewerPermissions';
          enable: boolean;
          remove: boolean;
          showDetails?: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexSuppliersSelectModalQueryVariables = Exact<{
  enableStatuses?: InputMaybe<Array<SupplierAccountEnableStatus> | SupplierAccountEnableStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  industryCodeIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  onboardingStatuses?: InputMaybe<
    Array<SupplierOnboardingCaseOnboardingStatus> | SupplierOnboardingCaseOnboardingStatus
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierAccountSortInput> | SupplierAccountSortInput>;
}>;

export type IndexSuppliersSelectModalQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'SupplierAccountConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SupplierAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'SupplierAccount';
        companyName: string;
        id: string;
        vatNumber?: string;
        enableStatus?: SupplierAccountEnableStatus;
        onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
        address?: {
          __typename?: 'Address';
          city?: string;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        industryCodes: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
        viewerPermissions: {
          __typename?: 'SupplierAccountRecordViewerPermissions';
          enable: boolean;
          remove: boolean;
          showDetails?: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexTransactionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  merchantCountryCodes?: InputMaybe<Array<CountryCode> | CountryCode>;
  reconciliationStatus?: InputMaybe<Array<TransactionReconciliationStatus> | TransactionReconciliationStatus>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<TransactionSortInput> | TransactionSortInput>;
}>;

export type IndexTransactionsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'TransactionConnection';
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        customReconciliationStatus?: CustomTransactionReconciliationStatus;
        id: string;
        merchantName: string;
        reconciliationStatus: TransactionReconciliationStatus;
        remindersSnoozed: boolean;
        remindersSnoozedUntil?: string;
        transactionDate: string;
        type: TransactionType;
        billedBaseAmount: { __typename?: 'Money'; universalFormat: string };
        merchant?: {
          __typename?: 'Merchant';
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        originalAmount: { __typename?: 'Money'; universalFormat: string };
        parsedId?: { __typename?: 'IParsedGlobalId'; id?: string; type?: string };
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          label: string;
          requestedBy?: { __typename?: 'User'; fullName?: string; id: string };
        };
        viewerPermissions: {
          __typename?: 'TransactionRecordViewerPermissions';
          addDocument?: boolean;
          markAsReceiptMissing: boolean;
          markAsReceiptUnavailable: boolean;
          snoozeReminders: boolean;
        };
        virtualCard?: {
          __typename?: 'VirtualCard';
          cardNumberLastFour?: string;
          id: string;
          status: VirtualCardStatus;
          issuerIntegration?: { __typename?: 'IssuerIntegration'; id: string; name: IssuerIntegrationServiceClass };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexUsersQueryVariables = Exact<{
  anyUserStatus?: InputMaybe<Array<UserStatus> | UserStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  permissions?: InputMaybe<Array<UserSecurityRole> | UserSecurityRole>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<UserSortInput> | UserSortInput>;
}>;

export type IndexUsersQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: {
        __typename?: 'User';
        email: string;
        fullName?: string;
        id: string;
        securityRoles: Array<UserSecurityRole>;
        status: UserStatus;
        location?: {
          __typename?: 'Location';
          id: string;
          address: {
            __typename?: 'Address';
            city?: string;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
        };
        organisationUnit: {
          __typename?: 'OrganisationUnit';
          id: string;
          label: string;
          legalEntity: { __typename?: 'OrganisationUnit'; id: string; label: string };
        };
        profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexVirtualCardsQueryVariables = Exact<{
  anyVirtualCardStatus?: InputMaybe<Array<VirtualCardStatus> | VirtualCardStatus>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<VirtualCardSortInput> | VirtualCardSortInput>;
}>;

export type IndexVirtualCardsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'VirtualCardConnection';
    edges?: Array<{
      __typename?: 'VirtualCardEdge';
      cursor: string;
      node?: {
        __typename?: 'VirtualCard';
        id: string;
        actualExpiryDate?: string;
        actualNameOnCard?: string;
        cardNumberLastFour?: string;
        cardUseType: VirtualCardCardUseType;
        status: VirtualCardStatus;
        actualLimit?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedLimit?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'VirtualCardRecordViewerPermissions';
          initiateIframeDelivery: boolean;
          revoke: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexWorkflowMasterVersionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexWorkflowMasterVersionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        id: string;
        master?: {
          __typename?: 'Workflow';
          id: string;
          versions: {
            __typename?: 'WorkflowConnection';
            totalCount: number;
            edges?: Array<{
              __typename?: 'WorkflowEdge';
              cursor: string;
              node?: {
                __typename?: 'Workflow';
                id: string;
                publishedAt?: string;
                publishNotes?: string;
                version: number;
                publishedBy?: { __typename?: 'User'; fullName?: string; id: string };
              };
            }>;
            pageInfo: {
              __typename?: 'PageInfo';
              endCursor?: string;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor?: string;
            };
          };
        };
      }
    | { __typename?: 'WorkflowRun' };
};

export type IndexWorkflowRunsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexWorkflowRunsQuery = {
  __typename?: 'Query';
  workflowRuns?: {
    __typename?: 'WorkflowRunConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'WorkflowRunEdge';
      cursor: string;
      node?: {
        __typename?: 'WorkflowRun';
        completedAt?: string;
        failedAt?: string;
        id: string;
        status: WorkflowRunStatus;
        workflow?: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          label: string;
          triggerEvent: { __typename?: 'TriggerEvent'; key: WorkflowTriggerEventKey };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type IndexWorkflowVersionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type IndexWorkflowVersionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        id: string;
        versions: {
          __typename?: 'WorkflowConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'WorkflowEdge';
            cursor: string;
            node?: {
              __typename?: 'Workflow';
              id: string;
              publishedAt?: string;
              publishNotes?: string;
              version: number;
              publishedBy?: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'WorkflowRun' };
};

export type IndexWorkflowsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<OptionalBoolean>;
  isEnabled?: InputMaybe<OptionalBoolean>;
  isPublished?: InputMaybe<OptionalBoolean>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<WorkflowSortInput> | WorkflowSortInput>;
}>;

export type IndexWorkflowsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'WorkflowConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'WorkflowEdge';
      cursor: string;
      node?: {
        __typename?: 'Workflow';
        archived: boolean;
        baseResource: WorkflowBaseResourceName;
        id: string;
        label: string;
        publishedAt?: string;
        updatedAt: string;
        archivedAt?: string;
        enabledAt?: string;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        triggerEvent: { __typename?: 'TriggerEvent'; key: WorkflowTriggerEventKey };
        updatedBy?: { __typename?: 'User'; fullName?: string; id: string };
        viewerPermissions: {
          __typename?: 'WorkflowRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          disable: boolean;
          enable: boolean;
          publish: boolean;
          unarchive: boolean;
          update: boolean;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Me';
    id: string;
    account:
      | {
          __typename?: 'BuyerAccount';
          createdAt: string;
          updatedAt: string;
          accountType: AccountUseType;
          companyName: string;
          id: string;
          publicEmail?: string;
          publicPhone?: string;
          publicWebsite?: string;
          vatNumber?: string;
          settings?: {
            __typename?: 'AccountSettings';
            categorisation: { __typename?: 'CategorisationSettings'; categorySetId?: string };
            localisation: {
              __typename?: 'AccountLocalisationSettings';
              timezone: string;
              interfaceLanguage: {
                __typename?: 'Language';
                code: LanguageCode;
                englishName: string;
                nativeName: string;
              };
            };
            payment: {
              __typename?: 'PaymentSettings';
              baseCurrency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              virtualCardPayments: {
                __typename?: 'VirtualCardPaymentSettings';
                multiUseCard: { __typename?: 'MultiUseCard'; defaultTtlDays: number; maxTtlDays: number };
                singleUseCard: { __typename?: 'SingleUseCard'; defaultTtlDays: number; maxTtlDays: number };
              };
            };
          };
          address?: {
            __typename?: 'Address';
            id: string;
            city?: string;
            postalCode?: string;
            streetName?: string;
            streetNumber?: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
          coverImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
          profileImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
        }
      | {
          __typename?: 'SupplierAccount';
          createdAt: string;
          onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
          updatedAt: string;
          accountType: AccountUseType;
          companyName: string;
          id: string;
          publicEmail?: string;
          publicPhone?: string;
          publicWebsite?: string;
          vatNumber?: string;
          address?: {
            __typename?: 'Address';
            id: string;
            city?: string;
            postalCode?: string;
            streetName?: string;
            streetNumber?: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
          coverImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
          profileImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
        };
    profile: {
      __typename?: 'User';
      email: string;
      fullName?: string;
      hasCardDeliveryPin?: boolean;
      id: string;
      securityRoles: Array<UserSecurityRole>;
      profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
    };
    settings: {
      __typename?: 'UserSettings';
      appearance: { __typename?: 'AppearanceSettings'; theme: Theme };
      localisation: {
        __typename?: 'LocalisationSettings';
        timezone: string;
        interfaceLanguage: { __typename?: 'Language'; code: LanguageCode; englishName: string; nativeName: string };
      };
      notifications: Array<{
        __typename?: 'NotificationSetting';
        activityKey: string;
        email: boolean;
        groupLabel: string;
        helpText?: string;
        label: string;
        mobile: boolean;
        web: boolean;
      }>;
    };
  };
};

export type MyAccountQueryVariables = Exact<{ [key: string]: never }>;

export type MyAccountQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Me';
    id: string;
    account:
      | {
          __typename?: 'BuyerAccount';
          createdAt: string;
          updatedAt: string;
          accountType: AccountUseType;
          companyName: string;
          id: string;
          publicEmail?: string;
          publicPhone?: string;
          publicWebsite?: string;
          vatNumber?: string;
          address?: {
            __typename?: 'Address';
            id: string;
            city?: string;
            postalCode?: string;
            streetName?: string;
            streetNumber?: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
          coverImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
          profileImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
        }
      | {
          __typename?: 'SupplierAccount';
          createdAt: string;
          updatedAt: string;
          accountType: AccountUseType;
          companyName: string;
          id: string;
          publicEmail?: string;
          publicPhone?: string;
          publicWebsite?: string;
          vatNumber?: string;
          address?: {
            __typename?: 'Address';
            id: string;
            city?: string;
            postalCode?: string;
            streetName?: string;
            streetNumber?: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
          coverImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
          profileImage?: {
            __typename?: 'Attachment';
            byteSize: number;
            contentType: string;
            downloadUrl: string;
            filename: string;
            id: string;
            key: string;
            name: string;
          };
        };
  };
};

export type MyAccountSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MyAccountSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Me';
    id: string;
    account:
      | {
          __typename?: 'BuyerAccount';
          id: string;
          settings?: {
            __typename?: 'AccountSettings';
            categorisation: { __typename?: 'CategorisationSettings'; categorySetId?: string };
            localisation: {
              __typename?: 'AccountLocalisationSettings';
              timezone: string;
              interfaceLanguage: {
                __typename?: 'Language';
                code: LanguageCode;
                englishName: string;
                nativeName: string;
              };
            };
            payment: {
              __typename?: 'PaymentSettings';
              baseCurrency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              virtualCardPayments: {
                __typename?: 'VirtualCardPaymentSettings';
                multiUseCard: { __typename?: 'MultiUseCard'; defaultTtlDays: number; maxTtlDays: number };
                singleUseCard: { __typename?: 'SingleUseCard'; defaultTtlDays: number; maxTtlDays: number };
              };
            };
          };
        }
      | { __typename?: 'SupplierAccount'; id: string };
  };
};

export type MySettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MySettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Me';
    id: string;
    settings: {
      __typename?: 'UserSettings';
      appearance: { __typename?: 'AppearanceSettings'; theme: Theme };
      localisation: {
        __typename?: 'LocalisationSettings';
        timezone: string;
        interfaceLanguage: { __typename?: 'Language'; code: LanguageCode; englishName: string; nativeName: string };
      };
      notifications: Array<{
        __typename?: 'NotificationSetting';
        activityKey: string;
        email: boolean;
        groupLabel: string;
        helpText?: string;
        label: string;
        mobile: boolean;
        web: boolean;
      }>;
    };
  };
};

export type PickBankAccountsViewerPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type PickBankAccountsViewerPermissionsQuery = {
  __typename?: 'Query';
  bankAccounts?: {
    __typename?: 'BankAccountConnection';
    viewerPermissions: { __typename?: 'BankAccountCollectionViewerPermissions'; create: boolean };
  };
};

export type PickCardAuthorisationsViewerPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type PickCardAuthorisationsViewerPermissionsQuery = {
  __typename?: 'Query';
  cardAuthorisations?: {
    __typename?: 'CardAuthorisationConnection';
    viewerPermissions: { __typename?: 'CardAuthorisationCollectionViewerPermissions'; index: boolean };
  };
};

export type PickChildrenCountQueryVariables = Exact<{
  ids: Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input'];
}>;

export type PickChildrenCountQuery = {
  __typename?: 'Query';
  selection: Array<
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; childrenCount: number; code: string; label: string; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | {
        __typename?: 'OrganisationUnit';
        childrenCount: number;
        code: string;
        descendantUsersCount: number;
        label: string;
        id: string;
      }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | { __typename?: 'Workflow'; id: string }
    | { __typename?: 'WorkflowRun'; id: string }
  >;
};

export type PickFirstStepQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickFirstStepQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        id: string;
        firstStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | { __typename?: 'WorkflowRun' };
};

export type PickFlowStepQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickFlowStepQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | {
        __typename?: 'FlowActionApprove';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | {
        __typename?: 'FlowActionComment';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | {
        __typename?: 'FlowActionCreatePurchaseOrder';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | {
        __typename?: 'FlowActionReject';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        outputFalseStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        outputTrueStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
      }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | { __typename?: 'Workflow'; id: string }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type PickFlowStepSoftValidationQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickFlowStepSoftValidationQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | {
        __typename?: 'FlowActionApprove';
        id: string;
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
      }
    | {
        __typename?: 'FlowActionComment';
        id: string;
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
      }
    | {
        __typename?: 'FlowActionCreatePurchaseOrder';
        id: string;
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
      }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
      }
    | {
        __typename?: 'FlowActionReject';
        id: string;
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
      }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        softValidation: {
          __typename?: 'FlowStepSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
      }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | { __typename?: 'Workflow'; id: string }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type PickIntercomUserHashQueryVariables = Exact<{ [key: string]: never }>;

export type PickIntercomUserHashQuery = {
  __typename?: 'Query';
  me: { __typename?: 'Me'; id: string; intercomUserHash: string };
};

export type PickPurchaseRequestItemMerchantQueryVariables = Exact<{
  purchaseOrderId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type PickPurchaseRequestItemMerchantQuery = {
  __typename?: 'Query';
  transactions?: {
    __typename?: 'TransactionConnection';
    edges?: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node?: {
        __typename?: 'Transaction';
        id: string;
        merchant?: {
          __typename?: 'Merchant';
          city: string;
          companyName: string;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type PickReportConnectionViewerPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type PickReportConnectionViewerPermissionsQuery = {
  __typename?: 'Query';
  reports?: {
    __typename?: 'ReportConnection';
    viewerPermissions: { __typename?: 'ReportCollectionViewerPermissions'; create: boolean; index: boolean };
  };
};

export type PickSupplierOnboardingContactQueryVariables = Exact<{ [key: string]: never }>;

export type PickSupplierOnboardingContactQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Me';
    id: string;
    account:
      | { __typename?: 'BuyerAccount'; id: string }
      | { __typename?: 'SupplierAccount'; onboardingStatus: SupplierOnboardingCaseOnboardingStatus; id: string };
    supplierOnboardingContact?: {
      __typename?: 'SupplierOnboardingContact';
      id: string;
      onboardingStatus: SupplierOnboardingContactOnboardingStatus;
      onboardingUrl?: { __typename?: 'URL'; url: string };
      supplierOnboardingCase: { __typename?: 'SupplierOnboardingCase'; id: string };
    };
  };
};

export type PickTitleQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickTitleQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | {
        __typename?: 'Address';
        city?: string;
        id: string;
        country: { __typename?: 'Country'; code: CountryCode; name: string };
      }
    | {
        __typename?: 'Approval';
        id: string;
        approvable?:
          | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
          | {
              __typename?: 'SupplierRequest';
              id: string;
              companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
            };
      }
    | {
        __typename?: 'ApprovalDelegation';
        archived: boolean;
        id: string;
        from: { __typename?: 'User'; fullName?: string; id: string };
        to: { __typename?: 'User'; fullName?: string; id: string };
      }
    | { __typename?: 'ApprovalsUser'; id: string }
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; companyName: string; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; label: string; id: string }
    | { __typename?: 'Category'; archived: boolean; code: string; label: string; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CategorySet'; archived: boolean; label: string; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; archived: boolean; code: string; label: string; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; archived: boolean; label: string; id: string }
    | { __typename?: 'DataSourceItem'; archived: boolean; label: string; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; archived: boolean; label: string; resourceType: DataViewResourceType; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; archived: boolean; documentNumber?: string; id: string }
    | { __typename?: 'DocumentParseResult'; documentNumber?: string; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | {
        __typename?: 'InvoicePayment';
        id: string;
        requestedPayoutAmount: { __typename?: 'Money'; universalFormat: string };
      }
    | { __typename?: 'IssuerIntegration'; archived: boolean; issuerName: string; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; archived: boolean; label: string; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; merchantName: string; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; label: string; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; label: string; id: string }
    | { __typename?: 'PurchaseRequestItem'; description?: string; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; archived: boolean; label: string; id: string }
    | { __typename?: 'Schedule'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; companyName: string; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | {
        __typename?: 'SupplierRequest';
        id: string;
        companyLookup: { __typename?: 'CompanyLookup'; companyName?: string; id: string };
      }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; transactionId: string; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; archived: boolean; fullName?: string; id: string }
    | { __typename?: 'VirtualCard'; cardNumberLastFour?: string; id: string }
    | { __typename?: 'Workflow'; archived: boolean; label: string; id: string }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type PickTransactionOriginalAmountQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickTransactionOriginalAmountQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | {
        __typename?: 'Transaction';
        id: string;
        reconciliationStatus: TransactionReconciliationStatus;
        supportedDocumentTypes: Array<DocumentClassification>;
        type: TransactionType;
        originalAmount: {
          __typename?: 'Money';
          cents: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode };
        };
      }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PickUsersViewerPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type PickUsersViewerPermissionsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    viewerPermissions: { __typename?: 'UserCollectionViewerPermissions'; create: boolean };
  };
};

export type PickWorkflowUpdatedAtQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PickWorkflowUpdatedAtQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | { __typename?: 'ApproverCustomField'; id: string }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; id: string }
    | { __typename?: 'CategoryCustomField'; id: string }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | { __typename?: 'CostCentreCustomField'; id: string }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; id: string }
    | { __typename?: 'OrganisationUnitCustomField'; id: string }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | { __typename?: 'SelectCustomField'; id: string }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | {
        __typename?: 'Workflow';
        updatedAt: string;
        id: string;
        viewerPermissions: {
          __typename?: 'WorkflowRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          disable: boolean;
          enable: boolean;
          publish: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type PreviewAccountQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewAccountQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | {
        __typename?: 'BuyerAccount';
        accountType: AccountUseType;
        companyName: string;
        id: string;
        publicEmail?: string;
        publicPhone?: string;
        publicWebsite?: string;
        vatNumber?: string;
        address?: {
          __typename?: 'Address';
          city?: string;
          formattedLines: Array<string>;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
      }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | {
        __typename?: 'SupplierAccount';
        accountType: AccountUseType;
        companyName: string;
        id: string;
        publicEmail?: string;
        publicPhone?: string;
        publicWebsite?: string;
        vatNumber?: string;
        enableStatus?: SupplierAccountEnableStatus;
        onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
        address?: {
          __typename?: 'Address';
          city?: string;
          formattedLines: Array<string>;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        addedBy?: { __typename?: 'User'; fullName?: string; id: string };
        industryCodes: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
        viewerPermissions: {
          __typename?: 'SupplierAccountRecordViewerPermissions';
          enable: boolean;
          remove: boolean;
          showDetails?: boolean;
        };
      }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewApprovalQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewApprovalQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | {
        __typename?: 'Approval';
        approvedAt?: string;
        cancelledAt?: string;
        createdAt: string;
        id: string;
        rejectedAt?: string;
        status: ApprovalStatus;
        approvable?:
          | {
              __typename?: 'PurchaseRequestItem';
              description?: string;
              id: string;
              status: PurchaseRequestItemStatus;
              purchaseType: PurchaseType;
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
              purchaseOrder?: {
                __typename?: 'PurchaseOrder';
                id: string;
                virtualCard?: {
                  __typename?: 'VirtualCard';
                  actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
                  spentBaseAmount: {
                    __typename?: 'Money';
                    cents: string;
                    universalFormat: string;
                    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                  };
                };
              };
            }
          | {
              __typename?: 'SupplierRequest';
              id: string;
              companyLookup: {
                __typename?: 'CompanyLookup';
                companyName?: string;
                id: string;
                supplier?: {
                  __typename?: 'SupplierAccount';
                  companyName: string;
                  id: string;
                  enableStatus?: SupplierAccountEnableStatus;
                  onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
                  address?: {
                    __typename?: 'Address';
                    city?: string;
                    id: string;
                    country: { __typename?: 'Country'; code: CountryCode; name: string };
                  };
                };
              };
            };
        confirmedBy?: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        viewerPermissions: { __typename?: 'ApprovalRecordViewerPermissions'; approve: boolean; reject: boolean };
      }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewApprovalAssigneesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewApprovalAssigneesQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | {
        __typename?: 'Approval';
        id: string;
        approvalsUsers: {
          __typename?: 'ApprovalsUserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'ApprovalsUserEdge';
            cursor: string;
            node?: {
              __typename?: 'ApprovalsUser';
              id: string;
              user: {
                __typename?: 'User';
                fullName?: string;
                id: string;
                status: UserStatus;
                organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
                profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewApprovalDelegationQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewApprovalDelegationQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | {
        __typename?: 'ApprovalDelegation';
        archived: boolean;
        archivedAt?: string;
        endsOn: string;
        id: string;
        startsOn: string;
        from: { __typename?: 'User'; fullName?: string; id: string };
        to: { __typename?: 'User'; id: string };
        viewerPermissions: {
          __typename?: 'ApprovalDelegationRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewApprovalDelegationViewQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewApprovalDelegationViewQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | {
        __typename?: 'ApprovalDelegation';
        archived: boolean;
        archivedAt?: string;
        endsOn: string;
        id: string;
        startsOn: string;
        status: ApprovalDelegationStatus;
        from: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        to: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        viewerPermissions: {
          __typename?: 'ApprovalDelegationRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewApprovalWorkflowQueryVariables = Exact<{
  approvableId: Scalars['GlobalID']['input'];
}>;

export type PreviewApprovalWorkflowQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | {
        __typename?: 'PurchaseRequestItem';
        approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
        cancelComment?: string;
        cancelledAt?: string;
        description?: string;
        id: string;
        submittedAt?: string;
        cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
        purchaseOrder?: { __typename?: 'PurchaseOrder'; createdAt: string; id: string };
        requestedBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
      }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | {
        __typename?: 'SupplierRequest';
        approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
        cancelledAt?: string;
        createdAt: string;
        id: string;
        companyLookup: { __typename?: 'CompanyLookup'; companyName?: string };
        requestedBy: { __typename?: 'User'; fullName?: string; id: string };
      }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewAttachmentQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewAttachmentQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | {
        __typename?: 'Attachment';
        byteSize: number;
        contentType: string;
        createdAt: string;
        downloadUrl: string;
        filename: string;
        id: string;
      }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewBankAccountQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewBankAccountQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | {
        __typename?: 'BankAccount';
        createdAt: string;
        iban: string;
        id: string;
        verifiedAt?: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        viewerPermissions: { __typename?: 'BankAccountRecordViewerPermissions'; destroy: boolean };
      }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCardAuthorisationQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCardAuthorisationQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | {
        __typename?: 'CardAuthorisation';
        authorisationId: string;
        date: string;
        declineCode?: string;
        declined: boolean;
        declineMessage?: string;
        id: string;
        amount: { __typename?: 'Money'; universalFormat: string };
        virtualCard?: {
          __typename?: 'VirtualCard';
          cardNumberLastFour?: string;
          id: string;
          revokeComment?: string;
          status: VirtualCardStatus;
          validUntil: string;
          aboutToExpire?: boolean;
          lowAmount: boolean;
          purchaseOrder: {
            __typename?: 'PurchaseOrder';
            id: string;
            purchaseRequestItem: {
              __typename?: 'PurchaseRequestItem';
              description?: string;
              id: string;
              status: PurchaseRequestItemStatus;
              purchaseType: PurchaseType;
              requestedBy: { __typename?: 'User'; fullName?: string; id: string };
              purchaseOrder?: {
                __typename?: 'PurchaseOrder';
                id: string;
                virtualCard?: {
                  __typename?: 'VirtualCard';
                  actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
                  spentBaseAmount: {
                    __typename?: 'Money';
                    cents: string;
                    universalFormat: string;
                    currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                  };
                };
              };
            };
          };
          actualLimitBaseAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          remainingBaseAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          spentBaseAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          actualLimit?: { __typename?: 'Money'; cents: string };
          spentAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
      }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCardSubscriptionQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCardSubscriptionQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        cardStatus: CardSubscriptionCardStatus;
        closedAt?: string;
        id: string;
        label: string;
        inboxForKey?: {
          __typename?: 'Inbox';
          id: string;
          incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
        };
        owner: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          location?: { __typename?: 'Location'; id: string; label: string };
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
        };
        viewerPermissions: {
          __typename?: 'CardSubscriptionRecordViewerPermissions';
          close: boolean;
          requestRenewal: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCardSubscriptionLatestTransactionQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCardSubscriptionLatestTransactionQuery = {
  __typename?: 'Query';
  transactions?: {
    __typename?: 'TransactionConnection';
    edges?: Array<{
      __typename?: 'TransactionEdge';
      node?: {
        __typename?: 'Transaction';
        id: string;
        merchant?: {
          __typename?: 'Merchant';
          id: string;
          merchantName: string;
          address?: {
            __typename?: 'PublicAddress';
            formattedLines: Array<string>;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
          merchantCategoryCode?: { __typename?: 'MerchantCategoryCode'; code: any; id: string; label: string };
        };
      };
    }>;
  };
};

export type PreviewCardSubscriptionPurchaseOrderQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCardSubscriptionPurchaseOrderQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | {
        __typename?: 'PurchaseOrder';
        createdAt: string;
        id: string;
        poNumber: string;
        totalSpent: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        virtualCard?: {
          __typename?: 'VirtualCard';
          actualNameOnCard?: string;
          cardNumberLastFour?: string;
          id: string;
          actualLimit?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
      }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCategoryQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCategoryQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | {
        __typename?: 'Category';
        archived: boolean;
        archivedAt?: string;
        code: string;
        description?: string;
        id: string;
        label: string;
        parentId?: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        viewerPermissions: {
          __typename?: 'CategoryRecordViewerPermissions';
          archive: boolean;
          createChild?: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCategoryEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCategoryEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | {
        __typename?: 'Category';
        archived: boolean;
        archivedAt?: string;
        code: string;
        description?: string;
        id: string;
        label: string;
        parentId?: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        viewerPermissions: {
          __typename?: 'CategoryRecordViewerPermissions';
          archive: boolean;
          createChild?: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCategorySetQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCategorySetQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | {
        __typename?: 'CategorySet';
        archived: boolean;
        archivedAt?: string;
        createdAt: string;
        id: string;
        label: string;
        updatedAt: string;
        categories: { __typename?: 'CategoryConnection'; totalCount: number };
        viewerPermissions: {
          __typename?: 'CategorySetRecordViewerPermissions';
          archive: boolean;
          import: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCostCentreQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCostCentreQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | {
        __typename?: 'CostCentre';
        archived: boolean;
        archivedAt?: string;
        code: string;
        description?: string;
        id: string;
        label: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        viewerPermissions: {
          __typename?: 'CostCentreRecordViewerPermissions';
          archive: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCostCentreEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCostCentreEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | {
        __typename?: 'CostCentre';
        archived: boolean;
        archivedAt?: string;
        code: string;
        description?: string;
        id: string;
        label: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        viewerPermissions: {
          __typename?: 'CostCentreRecordViewerPermissions';
          archive: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCustomFieldQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCustomFieldQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
        options: {
          __typename?: 'CategoryConnection';
          edges?: Array<{
            __typename?: 'CategoryEdge';
            cursor: string;
            node?: {
              __typename?: 'Category';
              id: string;
              categorySet?: { __typename?: 'CategorySet'; id: string; label: string };
            };
          }>;
        };
      }
    | { __typename?: 'CategorySet' }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
        dataSource?: { __typename?: 'DataSource'; id: string; label: string };
      }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Transaction' }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        description?: string;
        formableClass: CustomFieldFormableClass;
        id: string;
        includeInSearch: boolean;
        label: string;
        required: boolean;
        tooltip?: string;
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewCustomFieldEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewCustomFieldEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CategorySet' }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | {
        __typename?: 'SelectCustomField';
        dataSourceId: string;
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Transaction' }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        formableClass: CustomFieldFormableClass;
        iconName?: string;
        id: string;
        includeInSearch: boolean;
        label: string;
        placeholder?: string;
        required: boolean;
        tooltip?: string;
        width: number;
        formableParent?: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'CustomFieldRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataFilterQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDataFilterQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | {
        __typename?: 'DataFilter';
        comparator: FlowStepConditionComparatorName;
        id: string;
        referenceValue?: any;
        updatedAt: string;
        dataView: { __typename?: 'DataView'; id: string; resourceType: DataViewResourceType };
        inputPath?: { __typename?: 'DataPath'; id: string; path: string };
      }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataSourceQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDataSourceQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | {
        __typename?: 'DataSource';
        archived: boolean;
        archivedAt?: string;
        createdAt: string;
        description?: string;
        id: string;
        label: string;
        updatedAt: string;
        items: { __typename?: 'DataSourceItemConnection'; totalCount: number };
        viewerPermissions: {
          __typename?: 'DataSourceRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          import: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataSourceItemQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDataSourceItemQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | {
        __typename?: 'DataSourceItem';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        externalId?: string;
        id: string;
        label: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        dataSource: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'DataSourceItemRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataSourceItemEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDataSourceItemEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | {
        __typename?: 'DataSourceItem';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        externalId?: string;
        id: string;
        label: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        dataSource: { __typename?: 'DataSource'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'DataSourceItemRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataSourceItemsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type PreviewDataSourceItemsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | {
        __typename?: 'DataSource';
        id: string;
        connection: {
          __typename?: 'DataSourceItemConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataSourceItemEdge';
            cursor: string;
            node?: { __typename?: 'DataSourceItem'; externalId?: string; id: string; label: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataViewQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDataViewQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | {
        __typename?: 'DataView';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        id: string;
        label: string;
        resourceType: DataViewResourceType;
        viewerPermissions: {
          __typename?: 'DataViewRecordViewerPermissions';
          archive: boolean;
          duplicate: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDataViewFieldQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDataViewFieldQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | {
        __typename?: 'DataViewField';
        formatString?: DataViewFieldFormatString;
        id: string;
        label: string;
        updatedAt: string;
        dataValue?: {
          __typename?: 'DataValue';
          dateValue?: string;
          id: string;
          staticValue?: string;
          valueSource: DataValueValueSource;
          viewerPermissions: { __typename?: 'DataValueRecordViewerPermissions'; destroy: boolean; update: boolean };
          amountValue?: { __typename?: 'Money'; universalFormat: string };
          pathValue?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          recordValue?:
            | { __typename?: 'Category'; code: string; id: string; label: string }
            | { __typename?: 'CostCentre'; code: string; id: string; label: string }
            | { __typename?: 'OrganisationUnit'; code: string; id: string; label: string }
            | { __typename?: 'User'; fullName?: string; id: string };
        };
        dataView: { __typename?: 'DataView'; id: string; resourceType: DataViewResourceType };
        viewerPermissions: {
          __typename?: 'DataViewFieldRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
      }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDocumentQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDocumentQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | {
        __typename?: 'Document';
        archived: boolean;
        archivedAt?: string;
        classification: DocumentClassification;
        createdAt: string;
        documentNumber?: string;
        id: string;
        isDraft: boolean;
        invalidatedAt?: string;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        file?: { __typename?: 'Attachment'; contentType: string; downloadUrl: string; filename: string; id: string };
        incomingEmailAttachment?: {
          __typename?: 'IncomingEmailAttachment';
          id: string;
          inbox: {
            __typename?: 'Inbox';
            id: string;
            incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
          };
        };
        invoicePayments?: Array<{
          __typename?: 'InvoicePayment';
          id: string;
          requestedPayoutDate: string;
          status: InvoicePaymentStatus;
          viewerPermissions: {
            __typename?: 'InvoicePaymentRecordViewerPermissions';
            cancel: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            update: boolean;
          };
        }>;
        purchaseRequestItem?: {
          __typename?: 'PurchaseRequestItem';
          description?: string;
          id: string;
          status: PurchaseRequestItemStatus;
          purchaseType: PurchaseType;
          requestedBy: { __typename?: 'User'; fullName?: string; id: string };
          purchaseOrder?: {
            __typename?: 'PurchaseOrder';
            id: string;
            virtualCard?: {
              __typename?: 'VirtualCard';
              actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
              spentBaseAmount: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
            };
          };
        };
        totalAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        transactions?: Array<{
          __typename?: 'Transaction';
          customReconciliationStatus?: CustomTransactionReconciliationStatus;
          id: string;
          merchantName: string;
          reconciliationStatus: TransactionReconciliationStatus;
          transactionDate: string;
          type: TransactionType;
          originalAmount: { __typename?: 'Money'; universalFormat: string };
        }>;
        vatAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'DocumentRecordViewerPermissions';
          archive: boolean;
          download: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDocumentEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDocumentEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | {
        __typename?: 'Document';
        archived: boolean;
        archivedAt?: string;
        isDraft: boolean;
        classification: DocumentClassification;
        documentNumber?: string;
        id: string;
        issuedOn?: string;
        messageToSupplier?: string;
        paymentDate?: string;
        receiverIban?: string;
        supportedTypes?: Array<DocumentClassification>;
        viewerPermissions: {
          __typename?: 'DocumentRecordViewerPermissions';
          archive: boolean;
          download: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
        currency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        file?: { __typename?: 'Attachment'; contentType: string; downloadUrl: string; filename: string; id: string };
        incomingEmailAttachment?: {
          __typename?: 'IncomingEmailAttachment';
          id: string;
          inbox: { __typename?: 'Inbox'; record: { __typename?: 'CardSubscription'; id: string; label: string } };
          incomingEmail: { __typename?: 'IncomingEmail'; id: string; receivedAt: string; subject?: string };
        };
        invoicePayments?: Array<{
          __typename?: 'InvoicePayment';
          id: string;
          requestedPayoutDate: string;
          status: InvoicePaymentStatus;
          viewerPermissions: {
            __typename?: 'InvoicePaymentRecordViewerPermissions';
            cancel: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            update: boolean;
          };
        }>;
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          purchaseType: PurchaseType;
          purchaseRequestItem: {
            __typename?: 'PurchaseRequestItem';
            id: string;
            supplier?: { __typename?: 'SupplierAccount'; companyName: string; id: string };
          };
        };
        supplier?: { __typename?: 'SupplierAccount'; id: string };
        totalAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        transactions?: Array<{
          __typename?: 'Transaction';
          id: string;
          merchantName: string;
          reconciliationStatus: TransactionReconciliationStatus;
          supportedDocumentTypes: Array<DocumentClassification>;
          transactionDate: string;
          originalAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        }>;
        vatAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewDraftDocumentQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewDraftDocumentQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | {
        __typename?: 'Document';
        classification: DocumentClassification;
        documentNumber?: string;
        id: string;
        issuedOn?: string;
        messageToSupplier?: string;
        paymentDate?: string;
        receiverIban?: string;
        supportedTypes?: Array<DocumentClassification>;
        currency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        file?: { __typename?: 'Attachment'; contentType: string; downloadUrl: string; filename: string; id: string };
        incomingEmailAttachment?: {
          __typename?: 'IncomingEmailAttachment';
          id: string;
          inbox: { __typename?: 'Inbox'; record: { __typename?: 'CardSubscription'; id: string; label: string } };
          incomingEmail: { __typename?: 'IncomingEmail'; id: string; receivedAt: string; subject?: string };
        };
        invoicePayments?: Array<{
          __typename?: 'InvoicePayment';
          id: string;
          requestedPayoutDate: string;
          status: InvoicePaymentStatus;
          viewerPermissions: {
            __typename?: 'InvoicePaymentRecordViewerPermissions';
            cancel: boolean;
            showArchivedCustomFields?: boolean;
            showEmptyCustomFields?: boolean;
            update: boolean;
          };
        }>;
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          purchaseType: PurchaseType;
          purchaseRequestItem: {
            __typename?: 'PurchaseRequestItem';
            id: string;
            supplier?: { __typename?: 'SupplierAccount'; companyName: string; id: string };
          };
        };
        supplier?: { __typename?: 'SupplierAccount'; id: string };
        totalAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        transactions?: Array<{
          __typename?: 'Transaction';
          id: string;
          merchantName: string;
          reconciliationStatus: TransactionReconciliationStatus;
          supportedDocumentTypes: Array<DocumentClassification>;
          transactionDate: string;
          originalAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        }>;
        vatAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewFlowActionApproveQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewFlowActionApproveQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | {
        __typename?: 'FlowActionApprove';
        createdAt: string;
        description?: string;
        id: string;
        updatedAt: string;
        createdBy: { __typename?: 'User'; fullName?: string; id: string };
        viewerPermissions: {
          __typename?: 'FlowStepActionRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
        workflow: {
          __typename?: 'Workflow';
          id: string;
          isMaster: boolean;
          label: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
        };
      }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewFlowActionCommentQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewFlowActionCommentQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | {
        __typename?: 'FlowActionComment';
        commentText?: string;
        createdAt: string;
        description?: string;
        id: string;
        updatedAt: string;
        commentAuthor?: { __typename?: 'User'; fullName?: string; id: string };
        createdBy: { __typename?: 'User'; fullName?: string; id: string };
        targetPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
        viewerPermissions: {
          __typename?: 'FlowStepActionRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
        workflow: {
          __typename?: 'Workflow';
          id: string;
          isMaster: boolean;
          label: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
        };
      }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewFlowActionCreatePurchaseOrderQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewFlowActionCreatePurchaseOrderQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | {
        __typename?: 'FlowActionCreatePurchaseOrder';
        createdAt: string;
        description?: string;
        id: string;
        updatedAt: string;
        createdBy: { __typename?: 'User'; fullName?: string; id: string };
        viewerPermissions: {
          __typename?: 'FlowStepActionRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
        workflow: {
          __typename?: 'Workflow';
          id: string;
          isMaster: boolean;
          label: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
        };
      }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewFlowActionGetApprovalQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewFlowActionGetApprovalQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | {
        __typename?: 'FlowActionGetApproval';
        approvalType?: FlowActionGetApprovalApprovalType;
        assignAll: boolean;
        createdAt: string;
        description?: string;
        id: string;
        updatedAt: string;
        createdBy: { __typename?: 'User'; fullName?: string; id: string };
        viewerPermissions: {
          __typename?: 'FlowStepActionRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          isMaster: boolean;
          label: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
        };
      }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewFlowActionRejectQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewFlowActionRejectQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | {
        __typename?: 'FlowActionReject';
        createdAt: string;
        description?: string;
        id: string;
        updatedAt: string;
        createdBy: { __typename?: 'User'; fullName?: string; id: string };
        viewerPermissions: {
          __typename?: 'FlowStepActionRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
        workflow: {
          __typename?: 'Workflow';
          id: string;
          isMaster: boolean;
          label: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
        };
      }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewFlowStepConditionQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewFlowStepConditionQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | {
        __typename?: 'FlowStepCondition';
        comparator?: FlowStepConditionComparatorName;
        createdAt: string;
        description?: string;
        id: string;
        referenceDataType?: FlowStepConditionInputAttributeDataType;
        referenceValue?: any;
        updatedAt: string;
        createdBy: { __typename?: 'User'; fullName?: string; id: string };
        inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
        viewerPermissions: {
          __typename?: 'FlowStepConditionRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
        workflow: {
          __typename?: 'Workflow';
          baseResource: WorkflowBaseResourceName;
          id: string;
          isMaster: boolean;
          label: string;
          firstStep?:
            | { __typename?: 'FlowActionApprove'; id: string }
            | { __typename?: 'FlowActionComment'; id: string }
            | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
            | { __typename?: 'FlowActionGetApproval'; id: string }
            | { __typename?: 'FlowActionReject'; id: string }
            | { __typename?: 'FlowStepCondition'; id: string };
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewInboxQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewInboxQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | {
        __typename?: 'Inbox';
        address: string;
        id: string;
        key: string;
        owner: { __typename?: 'User'; fullName?: string; id: string };
        record: {
          __typename?: 'CardSubscription';
          id: string;
          cardStatus: CardSubscriptionCardStatus;
          closedAt?: string;
          label: string;
          owner: { __typename?: 'User'; fullName?: string; id: string };
          primaryVirtualCard?: {
            __typename?: 'VirtualCard';
            id: string;
            validUntil: string;
            actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
            spentBaseAmount: { __typename?: 'Money'; cents: string };
          };
        };
      }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewIncomingEmailQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewIncomingEmailQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | {
        __typename?: 'IncomingEmail';
        bodyText: string;
        id: string;
        receivedAt: string;
        senderEmail: string;
        subject?: string;
        inbox: { __typename?: 'Inbox'; address: string; id: string };
      }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewIndustryCodeQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewIndustryCodeQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | {
        __typename?: 'IndustryCode';
        code: string;
        id: string;
        name: string;
        parentId?: string;
        system: IndustryCodeSystem;
        ancestors?: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
      }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewIndustryCodeChildrenQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['GlobalID']['input']>;
}>;

export type PreviewIndustryCodeChildrenQuery = {
  __typename?: 'Query';
  industryCodes?: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
};

export type PreviewIndustryCodeSuppliersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewIndustryCodeSuppliersQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'SupplierAccountConnection';
    edges?: Array<{
      __typename?: 'SupplierAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'SupplierAccount';
        companyName: string;
        id: string;
        address?: {
          __typename?: 'Address';
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type PreviewInvoicePaymentQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewInvoicePaymentQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | {
        __typename?: 'InvoicePayment';
        cancellationComment?: string;
        cancelledAt?: string;
        createdAt: string;
        id: string;
        requestedPayoutDate: string;
        status: InvoicePaymentStatus;
        cancelledBy?: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        cardPayment?: { __typename?: 'CardPayment'; completedAt?: string; scheduledAt?: string };
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        document: {
          __typename?: 'Document';
          documentNumber?: string;
          id: string;
          issuedOn?: string;
          customFieldValues?: Array<
            | {
                __typename?: 'AssociationCustomFieldValue';
                id: string;
                value:
                  | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                  | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                  | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                  | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                  | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              }
            | {
                __typename?: 'AttachmentCustomFieldValue';
                id: string;
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                files?: Array<{
                  __typename?: 'Attachment';
                  blobId: string;
                  byteSize: number;
                  contentType: string;
                  downloadUrl: string;
                  filename: string;
                  id: string;
                  key: string;
                }>;
              }
            | {
                __typename?: 'BooleanCustomFieldValue';
                id: string;
                valueBoolean?: boolean;
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              }
            | {
                __typename?: 'DateTimeCustomFieldValue';
                id: string;
                valueDateTime?: string;
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              }
            | {
                __typename?: 'IntegerCustomFieldValue';
                id: string;
                valueInteger?: string;
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              }
            | {
                __typename?: 'MoneyCustomFieldValue';
                id: string;
                valueMoney?: {
                  __typename?: 'Money';
                  cents: string;
                  universalFormat: string;
                  currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                };
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              }
            | {
                __typename?: 'StringCustomFieldValue';
                id: string;
                valueString?: string;
                customField:
                  | { __typename?: 'ApproverCustomField'; id: string }
                  | { __typename?: 'AttachmentCustomField'; id: string }
                  | { __typename?: 'CategoryCustomField'; id: string }
                  | { __typename?: 'CheckboxCustomField'; id: string }
                  | { __typename?: 'CostCentreCustomField'; id: string }
                  | { __typename?: 'DateCustomField'; id: string }
                  | { __typename?: 'IntegerCustomField'; id: string }
                  | { __typename?: 'MoneyCustomField'; id: string }
                  | { __typename?: 'OrganisationUnitCustomField'; id: string }
                  | { __typename?: 'SelectCustomField'; id: string }
                  | { __typename?: 'TextAreaCustomField'; id: string }
                  | { __typename?: 'TextCustomField'; id: string }
                  | { __typename?: 'UrlCustomField'; id: string };
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              }
          >;
          file?: { __typename?: 'Attachment'; contentType: string; downloadUrl: string; filename: string; id: string };
          transactions?: Array<{
            __typename?: 'Transaction';
            customReconciliationStatus?: CustomTransactionReconciliationStatus;
            id: string;
            merchantName: string;
            reconciliationStatus: TransactionReconciliationStatus;
            transactionDate: string;
            type: TransactionType;
            originalAmount: { __typename?: 'Money'; universalFormat: string };
          }>;
          vatAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
        purchaseRequestItem?: {
          __typename?: 'PurchaseRequestItem';
          description?: string;
          id: string;
          status: PurchaseRequestItemStatus;
          purchaseType: PurchaseType;
          requestedBy: { __typename?: 'User'; fullName?: string; id: string };
          purchaseOrder?: {
            __typename?: 'PurchaseOrder';
            id: string;
            virtualCard?: {
              __typename?: 'VirtualCard';
              actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
              spentBaseAmount: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
            };
          };
        };
        requestedPayoutAmount: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        supplier?: {
          __typename?: 'SupplierAccount';
          companyName: string;
          id: string;
          enableStatus?: SupplierAccountEnableStatus;
          onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
          address?: {
            __typename?: 'Address';
            city?: string;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
        };
        supplierPayment: {
          __typename?: 'SupplierPayment';
          completedAt?: string;
          iban?: string;
          transactionReference?: string;
        };
        viewerPermissions: {
          __typename?: 'InvoicePaymentRecordViewerPermissions';
          cancel: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewIssuerIntegrationEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewIssuerIntegrationEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | {
        __typename?: 'IssuerIntegration';
        archived: boolean;
        archivedAt?: string;
        cardUseInvoice: boolean;
        cardUseMulti: boolean;
        cardUseSingle: boolean;
        description: string;
        id: string;
        issuerName: string;
        billingEntity?: { __typename?: 'OrganisationUnit'; id: string; label: string };
        currency: { __typename?: 'Currency'; code: CurrencyCode };
        viewerPermissions: {
          __typename?: 'IssuerIntegrationRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewIssuerIntegrationRuleQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewIssuerIntegrationRuleQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | {
        __typename?: 'IssuerIntegrationRule';
        cardUseType: IssuerIntegrationRuleCardUseType;
        criteria: IssuerIntegrationRuleCriteria;
        id: string;
        position: number;
        strict: boolean;
        updatedAt: string;
        viewerPermissions: {
          __typename?: 'IssuerIntegrationRuleRecordViewerPermissions';
          destroy: boolean;
          update: boolean;
          updatePosition: boolean;
        };
      }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewIssuerIntegrationViewQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewIssuerIntegrationViewQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | {
        __typename?: 'IssuerIntegration';
        archived: boolean;
        archivedAt?: string;
        cardUseInvoice: boolean;
        cardUseMulti: boolean;
        cardUseSingle: boolean;
        description: string;
        id: string;
        issuerName: string;
        currency: { __typename?: 'Currency'; code: CurrencyCode };
        viewerPermissions: {
          __typename?: 'IssuerIntegrationRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewLocationQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewLocationQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | {
        __typename?: 'Location';
        archived: boolean;
        archivedAt?: string;
        id: string;
        label: string;
        address: {
          __typename?: 'Address';
          city?: string;
          id: string;
          postalCode?: string;
          regionName?: string;
          streetName?: string;
          streetNumber?: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'LocationRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewMerchantQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewMerchantQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | {
        __typename?: 'Merchant';
        companyName: string;
        establishedOn?: string;
        id: string;
        legalCorporateName?: string;
        merchantName: string;
        address?: {
          __typename?: 'PublicAddress';
          city: string;
          id: string;
          postalCode?: string;
          regionName?: string;
          streetAddress: string;
        };
        country: { __typename?: 'Country'; code: CountryCode; name: string };
        merchantCategoryCode?: { __typename?: 'MerchantCategoryCode'; code: any; id: string; label: string };
      }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewOrganisationUnitEditQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewOrganisationUnitEditQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | {
        __typename?: 'OrganisationUnit';
        directVatNumber?: string;
        inheritedVatNumber: string;
        archived: boolean;
        archivedAt?: string;
        classification: OrganisationUnitClassification;
        code: string;
        description?: string;
        id: string;
        label: string;
        parentId?: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        directLocation?: { __typename?: 'Location'; id: string };
        inheritedLocation?: { __typename?: 'Location'; id: string; label: string };
        legalEntity: { __typename?: 'OrganisationUnit'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'OrganisationUnitRecordViewerPermissions';
          archive: boolean;
          createChild?: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewOrganisationUnitUsersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type PreviewOrganisationUnitUsersQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: { __typename?: 'User'; fullName?: string; id: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type PreviewOrganisationUnitViewQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewOrganisationUnitViewQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | {
        __typename?: 'OrganisationUnit';
        archived: boolean;
        archivedAt?: string;
        classification: OrganisationUnitClassification;
        code: string;
        description?: string;
        id: string;
        label: string;
        parentId?: string;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        directLocation?: {
          __typename?: 'Location';
          id: string;
          label: string;
          address: { __typename?: 'Address'; formattedLines: Array<string>; id: string };
        };
        inheritedLocation?: {
          __typename?: 'Location';
          id: string;
          label: string;
          address: { __typename?: 'Address'; formattedLines: Array<string>; id: string };
        };
        legalEntity: { __typename?: 'OrganisationUnit'; id: string; label: string };
        viewerPermissions: {
          __typename?: 'OrganisationUnitRecordViewerPermissions';
          archive: boolean;
          createChild?: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewPurchaseOrderQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewPurchaseOrderQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | {
        __typename?: 'PurchaseOrder';
        id: string;
        label: string;
        poNumber: string;
        purchaseType: PurchaseType;
        reconciliationStatus: PurchaseOrderReconciliationStatus;
        status: PurchaseOrderStatus;
        purchaseRequestItem: {
          __typename?: 'PurchaseRequestItem';
          description?: string;
          id: string;
          submittedAt?: string;
          requestedBaseAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          requestedBy: { __typename?: 'User'; fullName?: string; id: string };
          requestedAmount?: { __typename?: 'Money'; cents: string };
        };
        transactions?: Array<{
          __typename?: 'Transaction';
          id: string;
          merchantName: string;
          transactionDate: string;
          originalAmount: { __typename?: 'Money'; universalFormat: string };
        }>;
        virtualCard?: {
          __typename?: 'VirtualCard';
          actualNameOnCard?: string;
          cardNumberLastFour?: string;
          id: string;
          issuedAt?: string;
        };
        totalSpent: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
      }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewPurchaseRequestItemQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewPurchaseRequestItemQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | {
        __typename?: 'PurchaseRequestItem';
        cancelComment?: string;
        cancelledAt?: string;
        createdAt: string;
        description?: string;
        id: string;
        purchaseType: PurchaseType;
        status: PurchaseRequestItemStatus;
        submittedAt?: string;
        cancelledBy?: {
          __typename?: 'User';
          id: string;
          fullName?: string;
          profileImage?: { __typename?: 'Attachment'; id: string; downloadUrl: string };
        };
        cardSubscription?: {
          __typename?: 'CardSubscription';
          cardStatus: CardSubscriptionCardStatus;
          closedAt?: string;
          id: string;
          label: string;
          inboxForKey?: {
            __typename?: 'Inbox';
            id: string;
            incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
          };
          owner: { __typename?: 'User'; fullName?: string; id: string };
          primaryVirtualCard?: {
            __typename?: 'VirtualCard';
            id: string;
            validUntil: string;
            actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
            spentBaseAmount: { __typename?: 'Money'; cents: string };
          };
        };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          label: string;
          status: PurchaseOrderStatus;
          viewerPermissions: { __typename?: 'PurchaseOrderRecordViewerPermissions'; close: boolean };
          virtualCard?: {
            __typename?: 'VirtualCard';
            cardNumberLastFour?: string;
            id: string;
            status: VirtualCardStatus;
            spentAmount: {
              __typename?: 'Money';
              universalFormat: string;
              cents: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            spentBaseAmount: {
              __typename?: 'Money';
              universalFormat: string;
              cents: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
          };
        };
        requestedAmount?: { __typename?: 'Money'; universalFormat: string };
        requestedBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedBy: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        supplier?: {
          __typename?: 'SupplierAccount';
          companyName: string;
          id: string;
          enableStatus?: SupplierAccountEnableStatus;
          onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
          address?: {
            __typename?: 'Address';
            city?: string;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
        };
        viewerPermissions: {
          __typename?: 'PurchaseRequestItemRecordViewerPermissions';
          cancel: boolean;
          closePurchaseOrder?: boolean;
          destroy: boolean;
          markGoodsReceived: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          subscribeToNotifications: boolean;
          undoMarkGoodsReceived: boolean;
          unsubscribeFromNotifications: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewReportQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewReportQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | {
        __typename?: 'Report';
        attachmentCount: number;
        createdAt: string;
        criterion?: string;
        id: string;
        label: string;
        recordCount: number;
        resource: DataViewResourceType;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        dataView: { __typename?: 'DataView'; id: string; label: string };
        date: { __typename?: 'Date'; from?: string; to?: string };
        exportFile?: { __typename?: 'Attachment'; byteSize: number; filename: string; id: string };
        viewerPermissions: {
          __typename?: 'ReportRecordViewerPermissions';
          destroy: boolean;
          download: boolean;
          show?: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewReportScheduleQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewReportScheduleQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | {
        __typename?: 'ReportSchedule';
        archived: boolean;
        archivedAt?: string;
        createdAt: string;
        description?: string;
        exportFormat: ReportScheduleExportFormat;
        id: string;
        label: string;
        updatedAt: string;
        dataView: {
          __typename?: 'DataView';
          createdAt: string;
          description?: string;
          id: string;
          label: string;
          updatedAt: string;
        };
        range?: {
          __typename?: 'Range';
          criterion?: string;
          end?: ReportScheduleRelativeTime;
          start?: ReportScheduleRelativeTime;
        };
        schedule?: { __typename?: 'Schedule'; definition: string; endsAt?: string; id: string; startsAt: string };
        viewerPermissions: {
          __typename?: 'ReportScheduleRecordViewerPermissions';
          archive: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewSupplierPayoutQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewSupplierPayoutQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | {
        __typename?: 'SupplierPayout';
        buyerName: string;
        id: string;
        initiatedAt: string;
        invoiceNumber?: string;
        amount: { __typename?: 'Money'; universalFormat: string };
        bankAccount: { __typename?: 'BankAccount'; iban: string; id: string };
      }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewSupplierRequestQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewSupplierRequestQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | {
        __typename?: 'SupplierRequest';
        id: string;
        companyLookup: {
          __typename?: 'CompanyLookup';
          id: string;
          supplier?: {
            __typename?: 'SupplierAccount';
            accountType: AccountUseType;
            companyName: string;
            id: string;
            publicEmail?: string;
            publicPhone?: string;
            publicWebsite?: string;
            vatNumber?: string;
            enableStatus?: SupplierAccountEnableStatus;
            onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
            address?: {
              __typename?: 'Address';
              city?: string;
              formattedLines: Array<string>;
              id: string;
              country: { __typename?: 'Country'; code: CountryCode; name: string };
            };
            addedBy?: { __typename?: 'User'; fullName?: string; id: string };
            industryCodes: Array<{ __typename?: 'IndustryCode'; code: string; id: string; name: string }>;
            viewerPermissions: {
              __typename?: 'SupplierAccountRecordViewerPermissions';
              enable: boolean;
              remove: boolean;
              showDetails?: boolean;
            };
          };
        };
      }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewSupplierUserQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewSupplierUserQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | {
        __typename?: 'User';
        archived: boolean;
        archivedAt?: string;
        email: string;
        firstName?: string;
        fullName?: string;
        id: string;
        lastName?: string;
        status: UserStatus;
        coverImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        viewerPermissions: {
          __typename?: 'UserRecordViewerPermissions';
          archive: boolean;
          createDelegationForUser: boolean;
          disable2fa: boolean;
          enable2fa: boolean;
          invite: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
          updatePermissions: boolean;
          updateNotificationSettings: boolean;
        };
      }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewTransactionQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewTransactionQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | {
        __typename?: 'Transaction';
        customReconciliationStatus?: CustomTransactionReconciliationStatus;
        id: string;
        postingDate?: string;
        reconciliationStatus: TransactionReconciliationStatus;
        remindersSnoozed: boolean;
        remindersSnoozedUntil?: string;
        supportedDocumentTypes: Array<DocumentClassification>;
        transactionDate: string;
        transactionId: string;
        type: TransactionType;
        billedAmount: { __typename?: 'Money'; universalFormat: string };
        merchant?: {
          __typename?: 'Merchant';
          city: string;
          companyName: string;
          id: string;
          country: { __typename?: 'Country'; code: CountryCode; name: string };
        };
        originalAmount: { __typename?: 'Money'; universalFormat: string };
        purchaseDocumentation?: {
          __typename?: 'Document';
          classification: DocumentClassification;
          createdAt: string;
          id: string;
          archived: boolean;
          isDraft: boolean;
          invalidatedAt?: string;
          createdBy?: { __typename?: 'User'; fullName?: string; id: string };
          totalAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          id: string;
          purchaseRequestItem: {
            __typename?: 'PurchaseRequestItem';
            description?: string;
            id: string;
            status: PurchaseRequestItemStatus;
            purchaseType: PurchaseType;
            requestedBy: { __typename?: 'User'; fullName?: string; id: string };
            purchaseOrder?: {
              __typename?: 'PurchaseOrder';
              id: string;
              virtualCard?: {
                __typename?: 'VirtualCard';
                actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
                spentBaseAmount: {
                  __typename?: 'Money';
                  cents: string;
                  universalFormat: string;
                  currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                };
              };
            };
          };
        };
        viewerPermissions: {
          __typename?: 'TransactionRecordViewerPermissions';
          addDocument?: boolean;
          markAsReceiptMissing: boolean;
          markAsReceiptUnavailable: boolean;
          snoozeReminders: boolean;
        };
        virtualCard?: {
          __typename?: 'VirtualCard';
          cardNumberLastFour?: string;
          id: string;
          revokeComment?: string;
          status: VirtualCardStatus;
          validUntil: string;
          aboutToExpire?: boolean;
          lowAmount: boolean;
          actualLimitBaseAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          remainingBaseAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          spentBaseAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          actualLimit?: { __typename?: 'Money'; cents: string };
          spentAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
      }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewUserQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewUserQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | {
        __typename?: 'User';
        archived: boolean;
        archivedAt?: string;
        email: string;
        firstName?: string;
        fullName?: string;
        id: string;
        lastName?: string;
        securityRoles: Array<UserSecurityRole>;
        status: UserStatus;
        coverImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        currentSpend: Array<{
          __typename?: 'SpendSummary';
          runningPeriodInDays: SpendLimitRunningPeriodInDays;
          amount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        }>;
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              valueBoolean?: boolean;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              valueDateTime?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              valueInteger?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              valueString?: string;
              customField:
                | { __typename?: 'ApproverCustomField'; id: string }
                | { __typename?: 'AttachmentCustomField'; id: string }
                | { __typename?: 'CategoryCustomField'; id: string }
                | { __typename?: 'CheckboxCustomField'; id: string }
                | { __typename?: 'CostCentreCustomField'; id: string }
                | { __typename?: 'DateCustomField'; id: string }
                | { __typename?: 'IntegerCustomField'; id: string }
                | { __typename?: 'MoneyCustomField'; id: string }
                | { __typename?: 'OrganisationUnitCustomField'; id: string }
                | { __typename?: 'SelectCustomField'; id: string }
                | { __typename?: 'TextAreaCustomField'; id: string }
                | { __typename?: 'TextCustomField'; id: string }
                | { __typename?: 'UrlCustomField'; id: string };
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
            }
        >;
        location?: { __typename?: 'Location'; id: string; label: string };
        organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
        profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        spendLimits: {
          __typename?: 'SpendLimitConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'SpendLimitEdge';
            cursor: string;
            node?: {
              __typename?: 'SpendLimit';
              id: string;
              runningPeriodInDays: SpendLimitRunningPeriodInDays;
              scope: SpendLimitScope;
              amount: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
        viewerPermissions: {
          __typename?: 'UserRecordViewerPermissions';
          archive: boolean;
          createDelegationForUser: boolean;
          disable2fa: boolean;
          enable2fa: boolean;
          invite: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
          updatePermissions: boolean;
          updateNotificationSettings: boolean;
        };
      }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewUserRequestsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  requestedByIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type PreviewUserRequestsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'PurchaseRequestItemConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'PurchaseRequestItemEdge';
      cursor: string;
      node?: {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        submittedAt?: string;
        requestedAmount?: { __typename?: 'Money'; universalFormat: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type PreviewVirtualCardQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewVirtualCardQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | {
        __typename?: 'VirtualCard';
        actualExpiryDate?: string;
        actualNameOnCard?: string;
        cardNumberLastFour?: string;
        cardUseType: VirtualCardCardUseType;
        id: string;
        issuedAt?: string;
        revokeComment?: string;
        revokedAt?: string;
        status: VirtualCardStatus;
        updatedAt: string;
        validUntil: string;
        aboutToExpire?: boolean;
        lowAmount: boolean;
        actualLimit?: {
          __typename?: 'Money';
          universalFormat: string;
          cents: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        currency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        issuerIntegration?: { __typename?: 'IssuerIntegration'; id: string; issuerName: string };
        issuedTo: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        purchaseOrder: {
          __typename?: 'PurchaseOrder';
          id: string;
          cardSubscription?: {
            __typename?: 'CardSubscription';
            cardStatus: CardSubscriptionCardStatus;
            closedAt?: string;
            id: string;
            label: string;
            owner: { __typename?: 'User'; fullName?: string; id: string };
            primaryVirtualCard?: {
              __typename?: 'VirtualCard';
              id: string;
              validUntil: string;
              actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
              spentBaseAmount: { __typename?: 'Money'; cents: string };
            };
          };
          purchaseRequestItem: {
            __typename?: 'PurchaseRequestItem';
            description?: string;
            id: string;
            status: PurchaseRequestItemStatus;
            purchaseType: PurchaseType;
            requestedBy: { __typename?: 'User'; fullName?: string; id: string };
            purchaseOrder?: {
              __typename?: 'PurchaseOrder';
              id: string;
              virtualCard?: {
                __typename?: 'VirtualCard';
                actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
                spentBaseAmount: {
                  __typename?: 'Money';
                  cents: string;
                  universalFormat: string;
                  currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                };
              };
            };
          };
        };
        remainingAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        revokedBy?: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        spentAmount: {
          __typename?: 'Money';
          universalFormat: string;
          cents: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'VirtualCardRecordViewerPermissions';
          initiateIframeDelivery: boolean;
          revoke: boolean;
        };
      }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type PreviewWorkflowQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type PreviewWorkflowQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        archived: boolean;
        baseResource: WorkflowBaseResourceName;
        createdAt: string;
        id: string;
        label: string;
        updatedAt: string;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
      }
    | { __typename?: 'WorkflowRun' };
};

export type SelectAddressQueryVariables = Exact<{
  archived?: InputMaybe<OptionalBoolean>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectAddressQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'LocationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'LocationEdge';
      cursor: string;
      node?: {
        __typename?: 'Location';
        id: string;
        address: { __typename?: 'Address'; id: string; formattedLines: Array<string> };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectApproverUsersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type SelectApproverUsersQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        connection: {
          __typename?: 'UserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'UserEdge';
            cursor: string;
            node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type SelectAssigneePathsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type SelectAssigneePathsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | {
        __typename?: 'FlowActionGetApproval';
        id: string;
        connection: {
          __typename?: 'DataPathConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataPathEdge';
            cursor: string;
            node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type SelectAvailableBaseResourcesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectAvailableBaseResourcesQuery = {
  __typename?: 'Query';
  availableOptions: {
    __typename?: 'AvailableOptions';
    workflowBaseResources: Array<{
      __typename?: 'WorkflowBaseResource';
      resource: WorkflowBaseResourceName;
      triggerEvents: Array<{ __typename?: 'TriggerEvent'; key: WorkflowTriggerEventKey }>;
    }>;
  };
};

export type SelectAvailableInvoicePayoutCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectAvailableInvoicePayoutCurrenciesQuery = {
  __typename?: 'Query';
  availableOptions: {
    __typename?: 'AvailableOptions';
    invoicePayoutCurrencies: Array<{
      __typename?: 'Currency';
      code: CurrencyCode;
      decimalPlaces: number;
      name: string;
    }>;
  };
};

export type SelectAvailablePurchaseRequestTypesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectAvailablePurchaseRequestTypesQuery = {
  __typename?: 'Query';
  availableOptions: { __typename?: 'AvailableOptions'; purchaseRequestTypes?: Array<PurchaseType> };
};

export type SelectCategoriesQueryVariables = Exact<{
  categorySetId?: InputMaybe<Scalars['GlobalID']['input']>;
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CategorySortInput> | CategorySortInput>;
}>;

export type SelectCategoriesQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'CategoryConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CategoryEdge';
      cursor: string;
      node?: { __typename?: 'Category'; code: string; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectCategorySetsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectCategorySetsQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'CategorySetConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CategorySetEdge';
      cursor: string;
      node?: { __typename?: 'CategorySet'; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectComparatorsQueryVariables = Exact<{
  dataType: FlowStepConditionInputAttributeDataType;
}>;

export type SelectComparatorsQuery = { __typename?: 'Query'; comparators: Array<FlowStepConditionComparatorName> };

export type SelectCostCentresQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CostCentreSortInput> | CostCentreSortInput>;
}>;

export type SelectCostCentresQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CostCentreConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CostCentreEdge';
      cursor: string;
      node?: { __typename?: 'CostCentre'; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectCostCentresWithRelationQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CostCentreSortInput> | CostCentreSortInput>;
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
}>;

export type SelectCostCentresWithRelationQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CostCentreConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CostCentreEdge';
      cursor: string;
      node?: { __typename?: 'CostCentre'; id: string; label: string; relationExists: boolean };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectCountriesQuery = {
  __typename?: 'Query';
  selection: Array<{ __typename?: 'Country'; code: CountryCode; name: string }>;
};

export type SelectCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectCurrenciesQuery = {
  __typename?: 'Query';
  selection: Array<{ __typename?: 'Currency'; code: CurrencyCode; decimalPlaces: number; name: string }>;
};

export type SelectCustomFieldsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  formableClasses?: InputMaybe<Array<CustomFieldFormableClass> | CustomFieldFormableClass>;
  formableParentIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
}>;

export type SelectCustomFieldsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'CustomFieldConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'CustomFieldEdge';
      cursor: string;
      node?:
        | { __typename?: 'ApproverCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'AttachmentCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'CategoryCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'CheckboxCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'CostCentreCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'DateCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'IntegerCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'MoneyCustomField'; description?: string; id: string; label: string; required: boolean }
        | {
            __typename?: 'OrganisationUnitCustomField';
            description?: string;
            id: string;
            label: string;
            required: boolean;
          }
        | { __typename?: 'SelectCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'TextAreaCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'TextCustomField'; description?: string; id: string; label: string; required: boolean }
        | { __typename?: 'UrlCustomField'; description?: string; id: string; label: string; required: boolean };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectDataSourcesQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectDataSourcesQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'DataSourceConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataSourceEdge';
      cursor: string;
      node?: { __typename?: 'DataSource'; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectDataViewsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectDataViewsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'DataViewConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DataViewEdge';
      cursor: string;
      node?: {
        __typename?: 'DataView';
        id: string;
        label: string;
        parsedId?: { __typename?: 'IParsedGlobalId'; id?: string; type?: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectFeatureTogglesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectFeatureTogglesQuery = {
  __typename?: 'Query';
  featureToggles?: {
    __typename?: 'FeatureToggles';
    goodsReceivedTracking: { __typename?: 'FeatureToggle'; enabled: boolean };
    intercom: { __typename?: 'FeatureToggle'; enabled: boolean };
    invoiceManagement: { __typename?: 'FeatureToggle'; enabled: boolean };
  };
};

export type SelectFlowStepResourcesQueryVariables = Exact<{
  ids: Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input'];
}>;

export type SelectFlowStepResourcesQuery = {
  __typename?: 'Query';
  nodes: Array<
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | {
        __typename?: 'FlowActionGetApproval';
        approvalType?: FlowActionGetApprovalApprovalType;
        assignAll: boolean;
        id: string;
        approverUsers: {
          __typename?: 'UserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'UserEdge';
            node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
          }>;
        };
        assigneePaths: {
          __typename?: 'DataPathConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataPathEdge';
            node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          }>;
        };
      }
    | { __typename?: 'FlowActionReject'; id: string }
    | {
        __typename?: 'FlowStepCondition';
        comparator?: FlowStepConditionComparatorName;
        referenceUnit?: string;
        referenceValue?: any;
        id: string;
        inputPath?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
        referenceAssociations: {
          __typename?: 'ReferenceAssociationConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'ReferenceAssociationEdge';
            node?: {
              __typename?: 'ReferenceAssociation';
              id: string;
              referenceRecord:
                | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
                | { __typename?: 'Category'; id: string; label: string }
                | { __typename?: 'CostCentre'; id: string; label: string }
                | { __typename?: 'DataSourceItem'; id: string; label: string }
                | { __typename?: 'IssuerIntegration'; id: string; description: string }
                | { __typename?: 'OrganisationUnit'; id: string; label: string }
                | { __typename?: 'User'; id: string; fullName?: string };
            };
          }>;
        };
        referencePaths: {
          __typename?: 'DataPathConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataPathEdge';
            node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          }>;
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' }
  >;
};

export type SelectIndustryCodesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectIndustryCodesQuery = {
  __typename?: 'Query';
  industryCodes?: Array<{ __typename?: 'IndustryCode'; id: string; name: string }>;
};

export type SelectIssuerIntegrationsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectIssuerIntegrationsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'IssuerIntegrationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'IssuerIntegrationEdge';
      cursor: string;
      node?: { __typename?: 'IssuerIntegration'; description: string; id: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectLocationsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectLocationsQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'LocationConnection';
    totalCount: number;
    edges?: Array<{ __typename?: 'LocationEdge'; node?: { __typename?: 'Location'; id: string; label: string } }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectOptionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
  scopeToFormableId?: InputMaybe<Scalars['GlobalID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectOptionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity'; id: string }
    | { __typename?: 'Address'; id: string }
    | { __typename?: 'Approval'; id: string }
    | { __typename?: 'ApprovalDelegation'; id: string }
    | { __typename?: 'ApprovalsUser'; id: string }
    | {
        __typename?: 'ApproverCustomField';
        id: string;
        connection: {
          __typename?: 'UserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'UserEdge';
            cursor: string;
            node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'AssociationCustomFieldValue'; id: string }
    | { __typename?: 'Attachment'; id: string }
    | { __typename?: 'AttachmentCustomField'; id: string }
    | { __typename?: 'AttachmentCustomFieldValue'; id: string }
    | { __typename?: 'BankAccount'; id: string }
    | { __typename?: 'Blob'; id: string }
    | { __typename?: 'BooleanCustomFieldValue'; id: string }
    | { __typename?: 'BuyerAccount'; id: string }
    | { __typename?: 'CardAuthorisation'; id: string }
    | { __typename?: 'CardSubscription'; id: string }
    | { __typename?: 'Category'; id: string }
    | {
        __typename?: 'CategoryCustomField';
        id: string;
        connection: {
          __typename?: 'CategoryConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CategoryEdge';
            cursor: string;
            node?: { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'CategorySet'; id: string }
    | { __typename?: 'CheckboxCustomField'; id: string }
    | { __typename?: 'Comment'; id: string }
    | { __typename?: 'CompanyLookup'; id: string }
    | { __typename?: 'CostCentre'; id: string }
    | {
        __typename?: 'CostCentreCustomField';
        id: string;
        connection: {
          __typename?: 'CostCentreConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'CostCentreEdge';
            cursor: string;
            node?: { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DataFilter'; id: string }
    | { __typename?: 'DataPath'; id: string }
    | { __typename?: 'DataScopingRule'; id: string }
    | { __typename?: 'DataSource'; id: string }
    | { __typename?: 'DataSourceItem'; id: string }
    | { __typename?: 'DataValue'; id: string }
    | { __typename?: 'DataView'; id: string }
    | { __typename?: 'DataViewField'; id: string }
    | { __typename?: 'DateCustomField'; id: string }
    | { __typename?: 'DateTimeCustomFieldValue'; id: string }
    | { __typename?: 'Document'; id: string }
    | { __typename?: 'DocumentParseResult'; id: string }
    | { __typename?: 'DocumentTransaction'; id: string }
    | { __typename?: 'FlowActionApprove'; id: string }
    | { __typename?: 'FlowActionComment'; id: string }
    | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
    | { __typename?: 'FlowActionGetApproval'; id: string }
    | { __typename?: 'FlowActionReject'; id: string }
    | { __typename?: 'FlowStepCondition'; id: string }
    | { __typename?: 'FlowStepResult'; id: string }
    | { __typename?: 'FlowStepResultValue'; id: string }
    | { __typename?: 'Inbox'; id: string }
    | { __typename?: 'IncomingEmail'; id: string }
    | { __typename?: 'IncomingEmailAttachment'; id: string }
    | { __typename?: 'IndustryCode'; id: string }
    | { __typename?: 'IntegerCustomField'; id: string }
    | { __typename?: 'IntegerCustomFieldValue'; id: string }
    | { __typename?: 'InvoicePayment'; id: string }
    | { __typename?: 'IssuerIntegration'; id: string }
    | { __typename?: 'IssuerIntegrationConsumer'; id: string }
    | { __typename?: 'IssuerIntegrationRule'; id: string }
    | { __typename?: 'Location'; id: string }
    | { __typename?: 'Me'; id: string }
    | { __typename?: 'Merchant'; id: string }
    | { __typename?: 'MerchantCategoryCode'; id: string }
    | { __typename?: 'MoneyCustomField'; id: string }
    | { __typename?: 'MoneyCustomFieldValue'; id: string }
    | { __typename?: 'Notification'; id: string }
    | { __typename?: 'OrganisationUnit'; id: string }
    | {
        __typename?: 'OrganisationUnitCustomField';
        id: string;
        connection: {
          __typename?: 'OrganisationUnitConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'OrganisationUnitEdge';
            cursor: string;
            node?: { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'PublicAddress'; id: string }
    | { __typename?: 'PurchaseOrder'; id: string }
    | { __typename?: 'PurchaseRequestItem'; id: string }
    | { __typename?: 'ReferenceAssociation'; id: string }
    | { __typename?: 'Report'; id: string }
    | { __typename?: 'ReportSchedule'; id: string }
    | { __typename?: 'Schedule'; id: string }
    | {
        __typename?: 'SelectCustomField';
        id: string;
        connection: {
          __typename?: 'DataSourceItemConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataSourceItemEdge';
            cursor: string;
            node?: { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'SpendLimit'; id: string }
    | { __typename?: 'StringCustomFieldValue'; id: string }
    | { __typename?: 'SupplierAccount'; id: string }
    | { __typename?: 'SupplierOnboardingCase'; id: string }
    | { __typename?: 'SupplierOnboardingContact'; id: string }
    | { __typename?: 'SupplierPayout'; id: string }
    | { __typename?: 'SupplierRequest'; id: string }
    | { __typename?: 'TextAreaCustomField'; id: string }
    | { __typename?: 'TextCustomField'; id: string }
    | { __typename?: 'Transaction'; id: string }
    | { __typename?: 'UrlCustomField'; id: string }
    | { __typename?: 'User'; id: string }
    | { __typename?: 'VirtualCard'; id: string }
    | { __typename?: 'Workflow'; id: string }
    | { __typename?: 'WorkflowRun'; id: string };
};

export type SelectOrganisationUnitsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<OrganisationUnitSortInput> | OrganisationUnitSortInput>;
}>;

export type SelectOrganisationUnitsQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'OrganisationUnitConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'OrganisationUnitEdge';
      cursor: string;
      node?: { __typename?: 'OrganisationUnit'; code: string; id: string; label: string };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectOrganisationUnitsWithRelationQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<OrganisationUnitSortInput> | OrganisationUnitSortInput>;
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
}>;

export type SelectOrganisationUnitsWithRelationQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'OrganisationUnitConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'OrganisationUnitEdge';
      cursor: string;
      node?: { __typename?: 'OrganisationUnit'; code: string; id: string; label: string; relationExists: boolean };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectReferenceAssociationsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type SelectReferenceAssociationsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        connection: {
          __typename?: 'ReferenceAssociationConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'ReferenceAssociationEdge';
            cursor: string;
            node?: {
              __typename?: 'ReferenceAssociation';
              id: string;
              referenceRecord:
                | { __typename?: 'Address'; id: string; formattedLines: Array<string> }
                | { __typename?: 'Category'; id: string; label: string }
                | { __typename?: 'CostCentre'; id: string; label: string }
                | { __typename?: 'DataSourceItem'; id: string; label: string }
                | { __typename?: 'IssuerIntegration'; id: string; description: string }
                | { __typename?: 'OrganisationUnit'; id: string; label: string }
                | { __typename?: 'User'; id: string; fullName?: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type SelectReferencePathsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type SelectReferencePathsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | {
        __typename?: 'FlowStepCondition';
        id: string;
        connection: {
          __typename?: 'DataPathConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'DataPathEdge';
            cursor: string;
            node?: { __typename?: 'DataPath'; id: string; path: string; rootResourceType: MetaModelResourceType };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type SelectRequestersWithRelationQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  recordId: Scalars['GlobalID']['input'];
  relation: Scalars['String']['input'];
}>;

export type SelectRequestersWithRelationQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: {
        __typename?: 'User';
        fullName?: string;
        id: string;
        relationExists: boolean;
        parsedId?: { __typename?: 'IParsedGlobalId'; id?: string; type?: string };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectSuppliersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectSuppliersQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'SupplierAccountConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SupplierAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'SupplierAccount';
        companyName: string;
        id: string;
        onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
        vatNumber?: string;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SelectSupportedTransactionsQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['GlobalID']['input'];
}>;

export type SelectSupportedTransactionsQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | {
        __typename?: 'Document';
        id: string;
        connection?: {
          __typename?: 'TransactionConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'TransactionEdge';
            cursor: string;
            node?: {
              __typename?: 'Transaction';
              id: string;
              merchantName: string;
              reconciliationStatus: TransactionReconciliationStatus;
              supportedDocumentTypes: Array<DocumentClassification>;
              transactionDate: string;
              originalAmount: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
      }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type SelectTimezonesQueryVariables = Exact<{ [key: string]: never }>;

export type SelectTimezonesQuery = {
  __typename?: 'Query';
  selection: Array<{
    __typename?: 'Timezone';
    id: string;
    localName: string;
    region: TimezoneRegion;
    utcOffsetFormatted: string;
  }>;
};

export type SelectUsersQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars['String']['input']>;
  anyStatus?: InputMaybe<Array<UserStatus> | UserStatus>;
  organisationUnitIds?: InputMaybe<Array<Scalars['GlobalID']['input']> | Scalars['GlobalID']['input']>;
  permissions?: InputMaybe<Array<UserSecurityRole> | UserSecurityRole>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<UserSortInput> | UserSortInput>;
}>;

export type SelectUsersQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: { __typename?: 'User'; archived: boolean; fullName?: string; id: string; status: UserStatus };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type ShowApprovalQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowApprovalQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | {
        __typename?: 'Approval';
        createdAt: string;
        id: string;
        status: ApprovalStatus;
        approvable?:
          | { __typename?: 'PurchaseRequestItem'; id: string }
          | { __typename?: 'SupplierRequest'; id: string };
        approvalsUsers: {
          __typename?: 'ApprovalsUserConnection';
          totalCount: number;
          edges?: Array<{
            __typename?: 'ApprovalsUserEdge';
            cursor: string;
            node?: {
              __typename?: 'ApprovalsUser';
              id: string;
              approvalDelegation?: {
                __typename?: 'ApprovalDelegation';
                id: string;
                from: { __typename?: 'User'; fullName?: string; id: string };
              };
              user: { __typename?: 'User'; fullName?: string; id: string };
            };
          }>;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string;
          };
        };
        viewerPermissions: { __typename?: 'ApprovalRecordViewerPermissions'; approve: boolean; reject: boolean };
      }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowCategorySetQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowCategorySetQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | {
        __typename?: 'CategorySet';
        archived: boolean;
        id: string;
        label: string;
        viewerPermissions: {
          __typename?: 'CategorySetRecordViewerPermissions';
          archive: boolean;
          import: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowCustomFieldQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowCustomFieldQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | {
        __typename?: 'ApproverCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | {
        __typename?: 'AttachmentCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | {
        __typename?: 'CategoryCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'CategorySet' }
    | {
        __typename?: 'CheckboxCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | {
        __typename?: 'CostCentreCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | {
        __typename?: 'DateCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | {
        __typename?: 'IntegerCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | {
        __typename?: 'MoneyCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | {
        __typename?: 'OrganisationUnitCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | {
        __typename?: 'SelectCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | {
        __typename?: 'TextAreaCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | {
        __typename?: 'TextCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'Transaction' }
    | {
        __typename?: 'UrlCustomField';
        archived: boolean;
        description?: string;
        iconName?: string;
        id: string;
        inputType: CustomFieldInputType;
        label: string;
        placeholder?: string;
        position: number;
        required: boolean;
        slug: string;
        valueType: CustomFieldValueType;
        width: number;
      }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowCustomFieldValueQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowCustomFieldValueQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | {
        __typename?: 'AssociationCustomFieldValue';
        updatedAt: string;
        id: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        value:
          | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
          | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
          | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
      }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | {
        __typename?: 'AttachmentCustomFieldValue';
        updatedAt: string;
        id: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
        files?: Array<{
          __typename?: 'Attachment';
          blobId: string;
          byteSize: number;
          contentType: string;
          downloadUrl: string;
          filename: string;
          id: string;
          key: string;
        }>;
      }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | {
        __typename?: 'BooleanCustomFieldValue';
        updatedAt: string;
        id: string;
        valueBoolean?: boolean;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
      }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | {
        __typename?: 'DateTimeCustomFieldValue';
        updatedAt: string;
        id: string;
        valueDateTime?: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
      }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | {
        __typename?: 'IntegerCustomFieldValue';
        updatedAt: string;
        id: string;
        valueInteger?: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
      }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | {
        __typename?: 'MoneyCustomFieldValue';
        updatedAt: string;
        id: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        valueMoney?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
      }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | {
        __typename?: 'StringCustomFieldValue';
        updatedAt: string;
        id: string;
        valueString?: string;
        customField:
          | {
              __typename?: 'ApproverCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'AttachmentCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CategoryCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CheckboxCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'CostCentreCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'DateCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'IntegerCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'MoneyCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'OrganisationUnitCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'SelectCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextAreaCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'TextCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            }
          | {
              __typename?: 'UrlCustomField';
              id: string;
              archived: boolean;
              description?: string;
              iconName?: string;
              inputType: CustomFieldInputType;
              label: string;
              placeholder?: string;
              position: number;
              required: boolean;
              slug: string;
              valueType: CustomFieldValueType;
              width: number;
            };
        viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
      }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowDataSourceQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowDataSourceQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | {
        __typename?: 'DataSource';
        archived: boolean;
        description?: string;
        id: string;
        label: string;
        updatedAt: string;
        createdBy?: { __typename?: 'User'; fullName?: string; id: string };
        viewerPermissions: {
          __typename?: 'DataSourceRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          import: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowDataViewQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowDataViewQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | {
        __typename?: 'DataView';
        archived: boolean;
        archivedAt?: string;
        description?: string;
        id: string;
        label: string;
        resourceType: DataViewResourceType;
        createdBy: { __typename?: 'User'; id: string; fullName?: string };
        viewerPermissions: {
          __typename?: 'DataViewRecordViewerPermissions';
          archive: boolean;
          duplicate: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowDraftRequestItemQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowDraftRequestItemQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | {
        __typename?: 'PurchaseRequestItem';
        description?: string;
        id: string;
        isDraft: boolean;
        purchaseType: PurchaseType;
        updatedAt: string;
        cardSubscription?: { __typename?: 'CardSubscription'; id: string; label: string };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        requestedAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedAmountCurrency?: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        supplier?: {
          __typename?: 'SupplierAccount';
          companyName: string;
          id: string;
          enableStatus?: SupplierAccountEnableStatus;
          onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
          address?: {
            __typename?: 'Address';
            city?: string;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
        };
        viewerPermissions: {
          __typename?: 'PurchaseRequestItemRecordViewerPermissions';
          cancel: boolean;
          closePurchaseOrder?: boolean;
          destroy: boolean;
          markGoodsReceived: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          subscribeToNotifications: boolean;
          undoMarkGoodsReceived: boolean;
          unsubscribeFromNotifications: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowPurchaseRequestItemQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowPurchaseRequestItemQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | {
        __typename?: 'PurchaseRequestItem';
        id: string;
        status: PurchaseRequestItemStatus;
        description?: string;
        submittedAt?: string;
        viewerIsSubscribed: boolean;
        approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
        approvedAt?: string;
        cancelComment?: string;
        cancelledAt?: string;
        createdAt: string;
        purchaseMadeAt?: string;
        purchaseType: PurchaseType;
        rejectedAt?: string;
        purchaseOrder?: {
          __typename?: 'PurchaseOrder';
          currencyConversionRate?: number;
          id: string;
          label: string;
          reconciliationStatus: PurchaseOrderReconciliationStatus;
          status: PurchaseOrderStatus;
          poNumber: string;
          supportedDocumentTypes: Array<DocumentClassification>;
          purchaseAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          totalSpent: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          viewerPermissions: { __typename?: 'PurchaseOrderRecordViewerPermissions'; close: boolean };
          virtualCard?: {
            __typename?: 'VirtualCard';
            id: string;
            lowAmount: boolean;
            actualExpiryDate?: string;
            deliveryMethod: VirtualCardDeliveryMethod;
            issuedAt?: string;
            revokedAt?: string;
            smsDeliveryStatus: VirtualCardSmsDeliveryStatus;
            cardNumberLastFour?: string;
            revokeComment?: string;
            status: VirtualCardStatus;
            validUntil: string;
            aboutToExpire?: boolean;
            currency?: { __typename?: 'Currency'; code: CurrencyCode };
            issuedTo: { __typename?: 'User'; fullName?: string; id: string };
            issuerIntegration?: {
              __typename?: 'IssuerIntegration';
              id: string;
              billingEntity?: {
                __typename?: 'OrganisationUnit';
                id: string;
                label: string;
                vatNumber: string;
                location: {
                  __typename?: 'Location';
                  id: string;
                  address: {
                    __typename?: 'Address';
                    city?: string;
                    id: string;
                    postalCode?: string;
                    streetName?: string;
                    country: { __typename?: 'Country'; code: CountryCode; name: string };
                  };
                };
              };
            };
            viewerPermissions: {
              __typename?: 'VirtualCardRecordViewerPermissions';
              initiateIframeDelivery: boolean;
              revoke: boolean;
            };
            actualLimitBaseAmount?: {
              __typename?: 'Money';
              universalFormat: string;
              cents: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            spentBaseAmount: {
              __typename?: 'Money';
              universalFormat: string;
              cents: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            remainingBaseAmount?: {
              __typename?: 'Money';
              cents: string;
              universalFormat: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
            actualLimit?: { __typename?: 'Money'; cents: string };
            spentAmount: {
              __typename?: 'Money';
              cents: string;
              universalFormat: string;
              currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
            };
          };
        };
        requestedAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedBaseAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        requestedBy: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        viewerPermissions: {
          __typename?: 'PurchaseRequestItemRecordViewerPermissions';
          cancel: boolean;
          closePurchaseOrder?: boolean;
          destroy: boolean;
          markGoodsReceived: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          subscribeToNotifications: boolean;
          undoMarkGoodsReceived: boolean;
          unsubscribeFromNotifications: boolean;
          update: boolean;
        };
        cancelledBy?: { __typename?: 'User'; fullName?: string; id: string };
        cardSubscription?: {
          __typename?: 'CardSubscription';
          cardStatus: CardSubscriptionCardStatus;
          closedAt?: string;
          id: string;
          label: string;
          inboxForKey?: {
            __typename?: 'Inbox';
            address: string;
            id: string;
            incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
          };
          owner: { __typename?: 'User'; fullName?: string; id: string };
          primaryVirtualCard?: {
            __typename?: 'VirtualCard';
            id: string;
            validUntil: string;
            actualLimitBaseAmount?: { __typename?: 'Money'; cents: string };
            spentBaseAmount: { __typename?: 'Money'; cents: string };
          };
        };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        supplier?: {
          __typename?: 'SupplierAccount';
          companyName: string;
          id: string;
          enableStatus?: SupplierAccountEnableStatus;
          onboardingStatus: SupplierOnboardingCaseOnboardingStatus;
          address?: {
            __typename?: 'Address';
            city?: string;
            id: string;
            country: { __typename?: 'Country'; code: CountryCode; name: string };
          };
        };
      }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowSubscriptionQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowSubscriptionQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | {
        __typename?: 'CardSubscription';
        cardStatus: CardSubscriptionCardStatus;
        closedAt?: string;
        createdAt: string;
        description?: string;
        id: string;
        label: string;
        inboxForKey?: {
          __typename?: 'Inbox';
          id: string;
          incomingEmails?: { __typename?: 'IncomingEmailConnection'; totalCount: number };
        };
        owner: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
        totalApprovedDisplayAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        totalSpentDisplayAmount?: {
          __typename?: 'Money';
          cents: string;
          universalFormat: string;
          currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
        };
        viewerPermissions: {
          __typename?: 'CardSubscriptionRecordViewerPermissions';
          close: boolean;
          requestRenewal: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowSubscriptionPurchaseOrderQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowSubscriptionPurchaseOrderQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | {
        __typename?: 'PurchaseOrder';
        id: string;
        label: string;
        poNumber: string;
        supportedDocumentTypes: Array<DocumentClassification>;
        purchaseRequestItem: {
          __typename?: 'PurchaseRequestItem';
          description?: string;
          id: string;
          purchaseType: PurchaseType;
          status: PurchaseRequestItemStatus;
          submittedAt?: string;
          customFieldValues?: Array<
            | {
                __typename?: 'AssociationCustomFieldValue';
                id: string;
                updatedAt: string;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                value:
                  | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                  | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                  | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                  | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                  | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
              }
            | {
                __typename?: 'AttachmentCustomFieldValue';
                id: string;
                updatedAt: string;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
                files?: Array<{
                  __typename?: 'Attachment';
                  blobId: string;
                  byteSize: number;
                  contentType: string;
                  downloadUrl: string;
                  filename: string;
                  id: string;
                  key: string;
                }>;
              }
            | {
                __typename?: 'BooleanCustomFieldValue';
                id: string;
                updatedAt: string;
                valueBoolean?: boolean;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
              }
            | {
                __typename?: 'DateTimeCustomFieldValue';
                id: string;
                updatedAt: string;
                valueDateTime?: string;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
              }
            | {
                __typename?: 'IntegerCustomFieldValue';
                id: string;
                updatedAt: string;
                valueInteger?: string;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
              }
            | {
                __typename?: 'MoneyCustomFieldValue';
                id: string;
                updatedAt: string;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                valueMoney?: {
                  __typename?: 'Money';
                  cents: string;
                  universalFormat: string;
                  currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
                };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
              }
            | {
                __typename?: 'StringCustomFieldValue';
                id: string;
                updatedAt: string;
                valueString?: string;
                viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
                customField:
                  | {
                      __typename?: 'ApproverCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'AttachmentCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CategoryCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CheckboxCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'CostCentreCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'DateCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'IntegerCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'MoneyCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'OrganisationUnitCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'SelectCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextAreaCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'TextCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    }
                  | {
                      __typename?: 'UrlCustomField';
                      archived: boolean;
                      archivedAt?: string;
                      iconName?: string;
                      id: string;
                      inputType: CustomFieldInputType;
                      label: string;
                      placeholder?: string;
                      position: number;
                      slug: string;
                      valueType: CustomFieldValueType;
                      width: number;
                    };
              }
          >;
          requestedAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          requestedBaseAmount?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          requestedBy: { __typename?: 'User'; fullName?: string; id: string };
        };
        virtualCard?: {
          __typename?: 'VirtualCard';
          id: string;
          validUntil: string;
          actualLimit?: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
          spentAmount: {
            __typename?: 'Money';
            cents: string;
            universalFormat: string;
            currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
          };
        };
      }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowSupplierRequestQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowSupplierRequestQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | {
        __typename?: 'SupplierRequest';
        approvalStatus: ApprovableGraphqlInterfaceApprovalStatus;
        createdAt: string;
        id: string;
        messageToSupplier?: string;
        supplierContactEmail?: string;
        supplierContactName?: string;
        supplierContactPhone?: string;
        companyLookup: {
          __typename?: 'CompanyLookup';
          companyName?: string;
          id: string;
          supplier?: {
            __typename?: 'SupplierAccount';
            id: string;
            vatNumber?: string;
            address?: { __typename?: 'Address'; formattedLines: Array<string>; id: string };
          };
        };
        requestedBy: {
          __typename?: 'User';
          fullName?: string;
          id: string;
          status: UserStatus;
          organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
          profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        };
      }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowUserQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowUserQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | {
        __typename?: 'User';
        archived: boolean;
        archivedAt?: string;
        createdAt: string;
        email: string;
        firstName?: string;
        fullName?: string;
        hasCardDeliveryPin?: boolean;
        id: string;
        is2faEnabled?: boolean;
        lastName?: string;
        securityRoles: Array<UserSecurityRole>;
        status: UserStatus;
        updatedAt: string;
        coverImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        customFieldValues?: Array<
          | {
              __typename?: 'AssociationCustomFieldValue';
              id: string;
              updatedAt: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              value:
                | { __typename?: 'Category'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'CostCentre'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'DataSourceItem'; archived: boolean; id: string; label: string }
                | { __typename?: 'OrganisationUnit'; archived: boolean; code: string; id: string; label: string }
                | { __typename?: 'User'; archived: boolean; fullName?: string; id: string };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'AttachmentCustomFieldValue';
              id: string;
              updatedAt: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
              files?: Array<{
                __typename?: 'Attachment';
                blobId: string;
                byteSize: number;
                contentType: string;
                downloadUrl: string;
                filename: string;
                id: string;
                key: string;
              }>;
            }
          | {
              __typename?: 'BooleanCustomFieldValue';
              id: string;
              updatedAt: string;
              valueBoolean?: boolean;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'DateTimeCustomFieldValue';
              id: string;
              updatedAt: string;
              valueDateTime?: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'IntegerCustomFieldValue';
              id: string;
              updatedAt: string;
              valueInteger?: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'MoneyCustomFieldValue';
              id: string;
              updatedAt: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              valueMoney?: {
                __typename?: 'Money';
                cents: string;
                universalFormat: string;
                currency: { __typename?: 'Currency'; code: CurrencyCode; name: string };
              };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
          | {
              __typename?: 'StringCustomFieldValue';
              id: string;
              updatedAt: string;
              valueString?: string;
              viewerPermissions: { __typename?: 'CustomFieldValueRecordViewerPermissions'; update: boolean };
              customField:
                | {
                    __typename?: 'ApproverCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'AttachmentCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CategoryCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CheckboxCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'CostCentreCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'DateCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'IntegerCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'MoneyCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'OrganisationUnitCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'SelectCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextAreaCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'TextCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  }
                | {
                    __typename?: 'UrlCustomField';
                    archived: boolean;
                    archivedAt?: string;
                    iconName?: string;
                    id: string;
                    inputType: CustomFieldInputType;
                    label: string;
                    placeholder?: string;
                    position: number;
                    slug: string;
                    valueType: CustomFieldValueType;
                    width: number;
                  };
            }
        >;
        location?: { __typename?: 'Location'; id: string; label: string };
        organisationUnit: { __typename?: 'OrganisationUnit'; id: string; label: string };
        profileImage?: { __typename?: 'Attachment'; downloadUrl: string; id: string };
        viewerPermissions: {
          __typename?: 'UserRecordViewerPermissions';
          archive: boolean;
          createDelegationForUser: boolean;
          disable2fa: boolean;
          enable2fa: boolean;
          invite: boolean;
          showArchivedCustomFields?: boolean;
          showEmptyCustomFields?: boolean;
          unarchive: boolean;
          update: boolean;
          updatePermissions: boolean;
          updateNotificationSettings: boolean;
        };
      }
    | { __typename?: 'VirtualCard' }
    | { __typename?: 'Workflow' }
    | { __typename?: 'WorkflowRun' };
};

export type ShowWorkflowQueryVariables = Exact<{
  id: Scalars['GlobalID']['input'];
}>;

export type ShowWorkflowQuery = {
  __typename?: 'Query';
  node?:
    | { __typename?: 'Activity' }
    | { __typename?: 'Address' }
    | { __typename?: 'Approval' }
    | { __typename?: 'ApprovalDelegation' }
    | { __typename?: 'ApprovalsUser' }
    | { __typename?: 'ApproverCustomField' }
    | { __typename?: 'AssociationCustomFieldValue' }
    | { __typename?: 'Attachment' }
    | { __typename?: 'AttachmentCustomField' }
    | { __typename?: 'AttachmentCustomFieldValue' }
    | { __typename?: 'BankAccount' }
    | { __typename?: 'Blob' }
    | { __typename?: 'BooleanCustomFieldValue' }
    | { __typename?: 'BuyerAccount' }
    | { __typename?: 'CardAuthorisation' }
    | { __typename?: 'CardSubscription' }
    | { __typename?: 'Category' }
    | { __typename?: 'CategoryCustomField' }
    | { __typename?: 'CategorySet' }
    | { __typename?: 'CheckboxCustomField' }
    | { __typename?: 'Comment' }
    | { __typename?: 'CompanyLookup' }
    | { __typename?: 'CostCentre' }
    | { __typename?: 'CostCentreCustomField' }
    | { __typename?: 'DataFilter' }
    | { __typename?: 'DataPath' }
    | { __typename?: 'DataScopingRule' }
    | { __typename?: 'DataSource' }
    | { __typename?: 'DataSourceItem' }
    | { __typename?: 'DataValue' }
    | { __typename?: 'DataView' }
    | { __typename?: 'DataViewField' }
    | { __typename?: 'DateCustomField' }
    | { __typename?: 'DateTimeCustomFieldValue' }
    | { __typename?: 'Document' }
    | { __typename?: 'DocumentParseResult' }
    | { __typename?: 'DocumentTransaction' }
    | { __typename?: 'FlowActionApprove' }
    | { __typename?: 'FlowActionComment' }
    | { __typename?: 'FlowActionCreatePurchaseOrder' }
    | { __typename?: 'FlowActionGetApproval' }
    | { __typename?: 'FlowActionReject' }
    | { __typename?: 'FlowStepCondition' }
    | { __typename?: 'FlowStepResult' }
    | { __typename?: 'FlowStepResultValue' }
    | { __typename?: 'Inbox' }
    | { __typename?: 'IncomingEmail' }
    | { __typename?: 'IncomingEmailAttachment' }
    | { __typename?: 'IndustryCode' }
    | { __typename?: 'IntegerCustomField' }
    | { __typename?: 'IntegerCustomFieldValue' }
    | { __typename?: 'InvoicePayment' }
    | { __typename?: 'IssuerIntegration' }
    | { __typename?: 'IssuerIntegrationConsumer' }
    | { __typename?: 'IssuerIntegrationRule' }
    | { __typename?: 'Location' }
    | { __typename?: 'Me' }
    | { __typename?: 'Merchant' }
    | { __typename?: 'MerchantCategoryCode' }
    | { __typename?: 'MoneyCustomField' }
    | { __typename?: 'MoneyCustomFieldValue' }
    | { __typename?: 'Notification' }
    | { __typename?: 'OrganisationUnit' }
    | { __typename?: 'OrganisationUnitCustomField' }
    | { __typename?: 'PublicAddress' }
    | { __typename?: 'PurchaseOrder' }
    | { __typename?: 'PurchaseRequestItem' }
    | { __typename?: 'ReferenceAssociation' }
    | { __typename?: 'Report' }
    | { __typename?: 'ReportSchedule' }
    | { __typename?: 'Schedule' }
    | { __typename?: 'SelectCustomField' }
    | { __typename?: 'SpendLimit' }
    | { __typename?: 'StringCustomFieldValue' }
    | { __typename?: 'SupplierAccount' }
    | { __typename?: 'SupplierOnboardingCase' }
    | { __typename?: 'SupplierOnboardingContact' }
    | { __typename?: 'SupplierPayout' }
    | { __typename?: 'SupplierRequest' }
    | { __typename?: 'TextAreaCustomField' }
    | { __typename?: 'TextCustomField' }
    | { __typename?: 'Transaction' }
    | { __typename?: 'UrlCustomField' }
    | { __typename?: 'User' }
    | { __typename?: 'VirtualCard' }
    | {
        __typename?: 'Workflow';
        archived: boolean;
        archivedAt?: string;
        baseResource: WorkflowBaseResourceName;
        id: string;
        isMaster: boolean;
        label: string;
        publishedAt?: string;
        publishNotes?: string;
        updatedAt: string;
        firstStep?:
          | { __typename?: 'FlowActionApprove'; id: string }
          | { __typename?: 'FlowActionComment'; id: string }
          | { __typename?: 'FlowActionCreatePurchaseOrder'; id: string }
          | { __typename?: 'FlowActionGetApproval'; id: string }
          | { __typename?: 'FlowActionReject'; id: string }
          | { __typename?: 'FlowStepCondition'; id: string };
        master?: { __typename?: 'Workflow'; id: string };
        softValidation: {
          __typename?: 'WorkflowSoftValidation';
          publish: Array<{ __typename?: 'ErrorMessage'; message: string; path?: string }>;
        };
        triggerEvent: { __typename?: 'TriggerEvent'; description: string; key: WorkflowTriggerEventKey };
        viewerPermissions: {
          __typename?: 'WorkflowRecordViewerPermissions';
          archive: boolean;
          destroy: boolean;
          disable: boolean;
          enable: boolean;
          publish: boolean;
          unarchive: boolean;
          update: boolean;
        };
      }
    | { __typename?: 'WorkflowRun' };
};

export type NotificationCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NotificationCreatedSubscription = {
  __typename?: 'Subscription';
  notificationCreated: {
    __typename?: 'NotificationCreatedPayload';
    notification: {
      __typename?: 'Notification';
      createdAt: string;
      id: string;
      isRead: boolean;
      readAt?: string;
      updatedAt: string;
      activity: {
        __typename?: 'Activity';
        id: string;
        createdAt: string;
        displayStyle: ActivityDisplayStyle;
        icon: ActivityIcon;
        key: string;
        updatedAt: string;
        defaultUrl?: { __typename?: 'URL'; path?: string; query?: string };
        eventSubject?:
          | { __typename?: 'Approval' }
          | { __typename?: 'Attachment' }
          | { __typename?: 'BuyerAccount' }
          | { __typename?: 'Comment'; body?: string; id: string }
          | { __typename?: 'Document' }
          | { __typename?: 'IncomingEmail' }
          | { __typename?: 'PurchaseOrder' }
          | { __typename?: 'PurchaseRequestItem' }
          | { __typename?: 'SupplierAccount' }
          | { __typename?: 'Transaction' }
          | { __typename?: 'User' }
          | { __typename?: 'VirtualCard' }
          | { __typename?: 'Workflow' };
        fragments: Array<
          | {
              __typename?: 'ReferenceFragment';
              urn: string;
              text: string;
              type: StringFragmentFragmentType;
              url?: { __typename?: 'URL'; path?: string; query?: string };
            }
          | { __typename?: 'TextFragment'; text: string; type: StringFragmentFragmentType }
        >;
      };
    };
  };
};
