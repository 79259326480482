import {
  mpBreakpointMaxDesktop,
  mpBreakpointMinDesktop,
  mpBreakpointMinDesktopLg,
  mpBreakpointMinTablet,
} from '@dknmz/mp_ui/dist/tokens/web/js/breakpoints';

import { mpBreakpointMaxMobile, mpBreakpointMaxTablet } from 'presentation/app_context/Layout/WithControlledNavProps';
import { IDevice } from '../types';

/* eslint-disable sort-keys */
export const ViewportSizeBreakpoints = {
  [IDevice.Mobile]: {
    min: 0,
    max: mpBreakpointMaxMobile,
  },
  [IDevice.Tablet]: {
    min: mpBreakpointMinTablet,
    max: mpBreakpointMaxTablet,
  },
  [IDevice.Desktop]: {
    min: mpBreakpointMinDesktop,
    max: mpBreakpointMaxDesktop,
  },
  [IDevice.DesktopLg]: {
    min: mpBreakpointMinDesktopLg,
  },
};
/* eslint-enable sort-keys */
