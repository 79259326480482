import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashMap } from '@appsignal/types';

import { sendError } from 'core/errors/sendError';
import { trace } from 'core/errors/trace';
import { scrubSensitiveSearchParams } from 'utils/search/scrubSensitiveSearchParams';

export const Integrations = (): ReactElement => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.onsecuritypolicyviolation = err => {
      const { blockedURI, documentURI, referrer, violatedDirective } = err;
      const tags: HashMap<string> = {
        blockedURI,
        documentURI,
        referrer,
        violatedDirective,
      };
      sendError({
        action: 'ContentSecurityPolicyViolation',
        error: 'Network requests blocked by CSP policy.',
        tags,
      });
    };
  }, []);

  useEffect(() => {
    const url = pathname + decodeURIComponent(scrubSensitiveSearchParams(search));
    trace('Router', `Change location: ${url}`);
  }, [pathname, search]);

  return <></>;
};
