import { HttpLink } from '@apollo/client';

import { APP_RC } from 'config/APP_RC';

/**
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-http/
 */
export const Http = new HttpLink({
  credentials: 'include',
  uri: APP_RC.graphql_url,
});
