import { SendErrorProps } from 'types/core/errors';
import { MeQuery } from 'src/generated/graphql';
import { appSignalTags } from 'core/errors/appSignalTags';
import { apolloClient } from 'config/apollo';
import { appsignalInstance } from 'config/_global_context/appsignal';
import { AppError } from './AppError';
import { Me } from 'graphql-api/queries';

/**
 * Sends error to AppSignal, with user info and location.
 */
export const sendError = ({ action, error, tags }: SendErrorProps) => {
  appsignalInstance.sendError(typeof error === 'string' ? new AppError(error) : error, span => {
    const me = apolloClient.readQuery<MeQuery>({ query: Me })?.me;
    span.setAction(action);
    span.setTags({ ...appSignalTags({ me }), ...tags });
  });
};
