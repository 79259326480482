export const AppUpdatedErrorMessageRegexp: Array<RegExp> = [
  // Chrome
  /Failed to fetch dynamically imported module/,
  /Unable to preload CSS/,

  // Firefox
  /error loading dynamically imported module/,

  // Safari
  /'text\/html' is not a valid JavaScript MIME type/,
];
