import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { axiosInstance } from 'config/_global_context/axios';
import { ISignInSuccess } from 'providers/AuthProvider';
import { auth } from 'providers/AuthProvider/auth';
import { APP_RC } from 'config/APP_RC';

const refetchToken = () => {
  return new Promise<string>((resolve, reject) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('client_id', APP_RC.oauth_client_id);
    urlSearchParams.set('grant_type', 'refresh_token');
    const data = urlSearchParams.toString();
    axiosInstance({
      data,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      url: '/oauth2/token',
    }).then(async (e: ISignInSuccess) => {
      auth.access_token = e.data.access_token;
      resolve(e.data.access_token);
    }, reject);
  });
};

/**
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-error/
 */
export const RefreshToken = onError(({ forward, operation }) => {
  const { response } = operation.getContext();
  if (response?.status !== 401) {
    return forward(operation);
  } else {
    return fromPromise(
      refetchToken().then(
        token => {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${token}` || '',
            },
          }));
          return token;
        },
        () => {
          const nextLocation: Partial<Pick<Location, 'pathname' | 'search'>> = {
            pathname: window.location.pathname,
            search: window.location.search || undefined,
          };
          localStorage.setItem('next_location', JSON.stringify(nextLocation));
          window.location.href = '/signin';
        }
      )
    )
      .filter(value => Boolean(value))
      .flatMap(() => forward(operation));
  }
});
