import { AppRcKey } from '../../generated/app_rc_keys';
import { ReleaseEnvName } from '../../generated/release_env_names';
import { replaceValues } from '../utils/object/replaceValues';
import { AppRcTemplateRegexp } from './regexp/AppRcTemplate';

/**
 * TypeScript version of window.APP_RC
 */
export const APP_RC = replaceValues(window.APP_RC ?? {}, AppRcTemplateRegexp, '') as {
  [key in Extract<AppRcKey, 'env'>]: ReleaseEnvName | 'dev';
} & {
  [key in Exclude<AppRcKey, 'env'>]: string;
};
