import { IErrorWithDescription } from '../types';

export const isInvalidGrant = (response: unknown): response is IErrorWithDescription => {
  return (
    !!response &&
    typeof response === 'object' &&
    (response as IErrorWithDescription).response?.data?.error === 'invalid_grant' &&
    !!(response as IErrorWithDescription).response?.data?.error_description
  );
};
