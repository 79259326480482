import { ISsoFlow } from '../types';

export const isSsoFlow = (response: unknown): response is ISsoFlow => {
  return (
    !!response &&
    typeof response === 'object' &&
    (response as ISsoFlow).data?.flow === 'sso' &&
    typeof (response as ISsoFlow).data?.redirect_url === 'string'
  );
};
