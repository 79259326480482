/**
 * Generated by graphql-ruby-client
 *
 */

/**
 * Map local operation names to persisted keys on the server
 * @return {Object}
 * @private
 */
const _aliases = {
  CountComments: "f9aee4f97a07bb2e1edcbb4f3e20deca",
  IndexComments: "e0aacd7e98c18531e44c737cdf48c1ef",
  IndexFlowStepConnectionsPanelInputsFalse: "8319b669e4d69002f7a2c64ca3e24255",
  IndexFlowStepConnectionsPanelInputsTrue: "dc6a711e91fc07de3f85a96851dd99d0",
  PickFlowStepOutputs: "9177acba23f2e2cb344b4fbfbf0bc4b1",
  AddRoleToUser: "79df3ff624f59ecd0468e484ac261319",
  ApproveApproval: "9d80ef7ff6562adc8bf28f95cea1b3c7",
  ArchiveRecord: "528e096837d9ddf95dde85848b99539f",
  AttachFileToCustomFieldValue: "627454db1002b3e6b67e566abe61ed33",
  CancelInvoicePayment: "b11a1bd0334c83cb79f435178c947a9d",
  CancelPurchaseRequestItem: "284bb4f914414f7aa8905d50ea2ddd1c",
  ChangeCardDeliveryPin: "40516b97f909e1e9717a1d8793dffb64",
  CloseCardSubscription: "b866008cbc13843f1dc206038f4ad711",
  ClosePurchaseOrder: "385d0b8abf5f3e7b5ec6dd4439f78d9b",
  ConfirmEnable2fa: "c0866945a5c6adada00210134e5f8c79",
  CreateApprovalDelegation: "49589deeb939ad007937d2fca87e241b",
  CreateApproverCustomField: "02309a55e05879d24b8a9399ddd2ea4e",
  CreateAttachmentCustomField: "e7421669fafb32465ebc07db73409a44",
  CreateBankAccount: "d5a4a77c94ebb947a41b31497c509ee8",
  CreateCategory: "60ade325a2925d285248cc30b8bedce8",
  CreateCategoryCustomField: "9b5531411c65640ee7f629b3eb3c6fb4",
  CreateCategorySet: "5c2c3b7997534452361637ebe81164b3",
  CreateCheckboxCustomField: "af4d270483f82493b8231975e94dcd51",
  CreateComment: "3ec515e5dbbd55894e481e0028fe60b5",
  CreateCostCentre: "c3f7cd91650b17dc078c2024049e49c0",
  CreateCostCentreCustomField: "dcfe2b4f8a92f00bad38bd21a0ef2427",
  CreateDataFilter: "6bc29d4e4701e18c12a782f74e4a29c1",
  CreateDataPath: "e6ecc54af6f87df82c55c0c6bcacab5f",
  CreateDataScopingRule: "04d6e861ec17df59ad9ebf2de9ebd663",
  CreateDataSource: "8edc814dec7d8f5b3a199fb40a68c614",
  CreateDataSourceItem: "0af8be081cc98641cc84e4c1e8e9a5f6",
  CreateDataValue: "0265b0146c082f9388ae08a6552509dd",
  CreateDataView: "19f9cadb6d249b28262349a20b12f728",
  CreateDataViewField: "ff65a3c6ae86aa6cc864b724477d6872",
  CreateDateCustomField: "be61edc70fb0b6aa78aab3414365bdaa",
  CreateDocumentTransaction: "7e0f7a2f10bb637888daea7ec8146ec0",
  CreateDraftDocument: "77ab7b2d810ae62798e64fd2fcaa3555",
  CreateIntegerCustomField: "fb09b73426e98a9e476ad52f0f68c2b8",
  CreateInvoicePayment: "39daf009702ae8df9fae8dc8101d004a",
  CreateIssuerIntegrationConsumer: "bae399cbd3fe013b0801ed65ce41ba58",
  CreateIssuerIntegrationRule: "2a1648aba4b35889c736ad380e892849",
  CreateLocation: "2c1bd215d419b596af3c8a9be5c7a2db",
  CreateMoneyCustomField: "6fa326a90c9bdc65afa630c627d9230c",
  CreateOrganisationUnit: "c5226593e1f4b7a1ee3e8f101f13b284",
  CreateOrganisationUnitCustomField: "44a5ab6eed67d85a2a40a3ff484334ff",
  CreatePurchaseRequestItem: "c2d1b01b923d456815b29207662629ca",
  CreateReferenceAssociation: "c4a476a4d41c4a7806555aef6c2bc80b",
  CreateReport: "ea749966b9b08cca829369b1baa92791",
  CreateReportSchedule: "d8462af75aa5d6c28104dc73330c747a",
  CreateSelectCustomField: "578fbb7c9c374105264cc5853ead308b",
  CreateSpendLimit: "580526f4bc4187c393e76fe8b9f52516",
  CreateSubscriptionRenewalRequest: "b7c71099509510a2e1c806daa3457a79",
  CreateSupplierRequest: "b5c9a5dfd8012cfc91019b06edd22414",
  CreateTextAreaCustomField: "b0422b6cf23a4a79209d4d0b0d33e8c8",
  CreateTextCustomField: "97bfdf2e1edd8f9fa94b4063f2af0da6",
  CreateUrlCustomField: "7fa3bb2fad0356d10f70fc26cdb05b6b",
  CreateUser: "820f561381c479e98d1f0ce109d7e0db",
  CreateWorkflow: "69b834f7c95553d1c426616ad51dc2b8",
  CreateWorkflowAction: "9f5c4dc816232de26b56a601d8f4b750",
  CreateWorkflowCondition: "becb5fa375fd6532528ebdec90e6d24d",
  DeleteDocumentTransaction: "3028d89d9fbbc558b26c559d96ccda72",
  DeleteRecord: "2e9149acb1f6e582c5562c70b76e1c38",
  DeliverVirtualCardInApp: "8f6ba7213b315ca4e9dc61d275d8fbd2",
  DetachFileFromCustomFieldValue: "9b8370a7425373821ba4c472a5c17559",
  Disable2fa: "e4f075f424126b06d963a5c84f51cbe8",
  DisableWorkflow: "d640c62744bea6ade01a2c65b3b841b0",
  DownloadAttachment: "99cd1b9c73cf8c25e8113e145c1a4098",
  DownloadPurchaseOrderDocuments: "b65883d1dc58fffff3f7734c93efc931",
  DuplicateDataView: "6764b1dd015c863cd0d55c8419e44812",
  Enable2fa: "d9baf7b279248cf75abe7004ca724a0b",
  EnableSupplier: "e268d0359446d1cddc62117fe15862dd",
  EnableWorkflow: "2239276482d8321aa6a1c8657b17f527",
  GetImportFileMetadata: "3f83a51cc90bcf84772f7990e0a29cee",
  ImportCategories: "3ebe35c5e8314a8534c33ed6024b4336",
  ImportCostCentres: "be8c6c94e4115b4f5fa968509870c662",
  ImportDataSourceItems: "e8cfcf6ea8ff7e38176a5d7981d2b73b",
  InviteAllDraftUsers: "a4a68d39fb0982d1ace784bf3ec86ac6",
  InviteDraftUsers: "303a356ad27e07c9c47d55cfa77fb4c8",
  LookupCompany: "5188dc075c055c7a014ebe8ea84973ea",
  MarkAllNotificationsAsRead: "26b947ad3bfe105e85da4b8106cc2701",
  MarkNotificationAsRead: "221a7895454f6d75dfa4eaa5d1740ae7",
  MarkTransactionAsReceiptMissing: "197df9d2ba7b9b83ba4d606a96513b54",
  MarkTransactionAsReceiptUnavailable: "4b850faf2ee2e39853143fb064159444",
  ProcessOnboardingCase: "59fd7548569deb7f0da65fe8e91ae31f",
  PublishWorkflow: "bd3cdda9424d9eb986763d516ee2f41c",
  RefreshOnboardingContact: "528188a6db54060f83e5efa7127fb067",
  RejectApproval: "8b772e49df914187d73abcd084421772",
  RemoveRoleFromUser: "9e0f0607e247e6030ca0ad268b4816d6",
  RemoveSupplier: "c580e62b71dae29248ced135e1dcdf3b",
  RenameWorkflow: "3002d26e8895a3711c77245ab853f2cf",
  RevokeVirtualCard: "3ff298ff753d67b847ecbef72ce8c32d",
  SnoozeRemindersForTransaction: "c061eb9bb4f1a8b048892aba71c2b117",
  SubmitDocument: "19a84065d09d875502bc113fcebd0e15",
  SubmitPurchaseRequestItem: "1b0f3cb32a6651ec3ff84b1d6b1fbcc6",
  SubscribeToNotifications: "f68485e2e708a99ffaad61073273d25f",
  UnarchiveRecord: "cd852dc97e4168ebface9a5d61f3db65",
  UnsubscribeFromNotifications: "e84b5d83b7048407d7cee8a5c4162f87",
  UpdateAccountProfile: "2cb059ec272b61d166e1a169948340fa",
  UpdateAccountSettings: "d80aa702716c205d90be576c1e33a1af",
  UpdateApprovalDelegation: "5b9f87fccd20ffbd8c34258c61021a6d",
  UpdateApproverCustomField: "d0e757f7fe975f39fc024fcc6230b85b",
  UpdateAttachmentCustomField: "ee79b5e080fab394f6be391ea5019351",
  UpdateCardSubscription: "e4ae96d4770760fdf542cec77a3a1b42",
  UpdateCategory: "b56f3001bda91a1dc06a7fa9fedd6fb7",
  UpdateCategoryCustomField: "f0c79b76b0584958180a965be92db351",
  UpdateCategorySet: "6254ae3d9013cb56c751d6ef22c3630a",
  UpdateCheckboxCustomField: "e4124171259f7c15f647dfe667683dfd",
  UpdateCostCentre: "f7cc6efd4c7c1535a7d89a43dae3e5e3",
  UpdateCostCentreCustomField: "84bece8ea1b470e1a3c975e8b9e04eef",
  UpdateDataFilter: "4838576e37834380440a1ad0a8f6e85a",
  UpdateDataPath: "76d0412c060f5c207925f094e3546025",
  UpdateDataScopingRule: "1d5230d694de1cb2e68c22e9c46e960c",
  UpdateDataSource: "4e1a04678c126dedb9ae80ac8b90893c",
  UpdateDataSourceItem: "9d9728176a1f863c52c293d59e1f7b7a",
  UpdateDataValue: "a6e3c9c4c12a58d5681b4343d452b6f3",
  UpdateDataView: "8e1b8922cf3f16bfc94f8560af01da4e",
  UpdateDataViewField: "8a13374a716ba2a88c665a290e0f64d0",
  UpdateDateCustomField: "e282d7852876bb582bcf6a2bb1ebf687",
  UpdateDocument: "77e10f7b8856f242f5c1a9235c7eb484",
  UpdateDocumentType: "242d3bc2d97e1ada452d1114256309e3",
  UpdateFlowStepCoordinates: "c18d4403dc12a746020790abc005373d",
  UpdateIntegerCustomField: "18f7ec45de833c0cdddf8cb045a84caa",
  UpdateIssuerIntegration: "bd385b589a44ac6e6cfe52579fca969f",
  UpdateIssuerIntegrationConsumer: "19c3b2e17ede74473f72d159dea52e36",
  UpdateIssuerIntegrationRule: "5adeaa9c7fddd8931a01b2f2bf425e25",
  UpdateLocation: "4fae2b85d9a8222039ac0516cc554fe1",
  UpdateMoneyCustomField: "8fdf8b55aaea4a760434e133eff12cdc",
  UpdateOrganisationUnit: "21dc424cafc51c4be8f3ca584a0d0037",
  UpdateOrganisationUnitCustomField: "b9bea46ec64dd718ab002d4c7dcbfe2c",
  UpdatePurchaseRequestItem: "e42a67a0f4ccf9f7c6eefcc98b51716b",
  UpdateRecordParent: "1d10ac91039c3d8b5b58218dd74417ae",
  UpdateRecordPosition: "116114a63d61b1b79d873356d97fe535",
  UpdateReportSchedule: "2b854286c4aa790b5a36a9349f22281f",
  UpdateSchedule: "9dcb4b7574cc323ce85ecf16a28f5ac5",
  UpdateSelectCustomField: "f73e8243531a9ef16990d4cf51d58fa7",
  UpdateSpendLimit: "e28c8e61051e492ee7251561ddb66fd1",
  UpdateTextAreaCustomField: "c93c73f12b6b52c72f5bab3f991e4c93",
  UpdateTextCustomField: "dd84685a9b8028ebbe73e12d69bc161b",
  UpdateUrlCustomField: "2012376af365cf673b37301cac1d66c9",
  UpdateUserNotificationSetting: "2fcf603e3da13474d655063b21b22396",
  UpdateUserProfile: "6b5d1db8cd291c27b8a3102fa5b95358",
  UpdateUserSettings: "0ea93f13a4e4a41c10225df14cf419d9",
  UpdateWorkflowAction: "8660dcc593454116f19a7903a6de8c50",
  UpdateWorkflowActionDescription: "e0ff575bbafd33e8b2377e200edb2e72",
  UpdateWorkflowActionOutput: "36435608018d1eaf7b0b9d7e7785a30a",
  UpdateWorkflowCondition: "091d800d8e729f7e8af7b9891c60b990",
  UpdateWorkflowConditionDescription: "667b45e8203910d013db4fea171d29a1",
  UpdateWorkflowConditionOutput: "87ea3449021dc2d25c4bd25ceeb09b24",
  UpdateWorkflowFirstStep: "00bfda8915a5ca6ab1637a269845b670",
  ImportUsers: "62307059981e8c0e4c8640e303ebf004",
  CountActivities: "7747b5f9c2256194c881c935a961d8a7",
  CountApprovableApprovals: "35d310d723cd8c9ff6c2f4ceba1d3a18",
  CountApprovalDelegationApprovals: "2f96af44995607e8a91d1fc7e0be82e5",
  CountApprovals: "01f70646763eb8e07f009ee2837c7ba1",
  CountCardAuthorisations: "45be6a8e695aa52de7984c11e83e6787",
  CountCardSubscriptionDocuments: "0cd87e97ae2490d8ae78d60088a0b6ec",
  CountCardSubscriptionDraftDocuments: "4c180fe3459fb10f0f135e724fa0b2a0",
  CountCardSubscriptionPurchaseOrders: "07bedeb49a4e9f66fba22f77045fb95e",
  CountCardSubscriptionPurchaseRequestItems: "db5bd9f00fa03db75fa5f519cf1f0e4e",
  CountCardSubscriptionTransactions: "a9063d9c11b082bba722d8e5c82caa76",
  CountCardSubscriptionTransactionsMissingReceipts:
    "4aa9cdbde6ab1973bb1c4ac45a3085a9",
  CountCardSubscriptionVirtualCards: "5de96ee22c24b4182fcf90728061e9d5",
  CountCardSubscriptions: "10429b954483c6f7d043f93a4f4b0915",
  CountCostCentres: "07403ae47117c32159162da4985abb02",
  CountCustomFields: "48802afaa5a088d4200b15c350f4d6e8",
  CountDataScopingRules: "0dfb2f1730f5c70c00e05ae3e7c15df9",
  CountDataSourceItems: "be82ae1ccf125a4ec6415c7141340b29",
  CountDataSources: "957764c25902d2ebbb2658bf82c34d34",
  CountDataViewFields: "54885ac6ee25044ab5c4667e9667a170",
  CountDataViewFilters: "5f24d2288ecb531465d238dd0e665565",
  CountDocuments: "0bb0329b43828e9e7f951d06282186f0",
  CountInboxIncomingEmails: "3300e42990766cb70541a67e8a70a8bd",
  CountIndexTransactions: "c02bbb1ad26ef7c397fcbaab5e8e0740",
  CountIndustryCodeSuppliers: "dae58eb584977cc829c423515c85958c",
  CountInvoicePayments: "fc724748e365fc885845673e0697d92b",
  CountIssuerIntegrationConsumers: "75f9c6859ca0dc52f940bc96c10aefe2",
  CountIssuerIntegrations: "c17dc5f9420ad66b91799cf7a67b94ef",
  CountMerchants: "560b50a087077e4fac2ab8aeb7316df3",
  CountOrganisationUnitUsers: "4673862cfda5073bb087153c50bc34e7",
  CountOverviewApprovals: "b6cdb15224f4a46b1812566b5c5ef043",
  CountOverviewCards: "cdb434414435f6af4a3807d6476148ba",
  CountOverviewRequests: "bc8064bc5878f6e1122ccaebde9f8965",
  CountOverviewTransactions: "a3ecbf7fa857fcf8c4fd7f54b869c9b6",
  CountPurchaseRequestItemCardAuthorisations:
    "6354922efa9d324065cf7ab83750a187",
  CountPurchaseRequestItemInvoicePayments: "a2f734f6d5ac0efecf77ac2c41461389",
  CountPurchaseRequestItemTransactions: "7c8ce8ddb1fdff54bc1669e0950d024e",
  CountPurchaseRequestItemTransactionsMissingReceipts:
    "519660f6f1b3d38ff1532f690034029c",
  CountPurchaseRequestItems: "54bacc1a8640d3ccc95628e4d96d24cf",
  CountPurchaseRequestItemsMissingReceipts: "650477669a246f25a1c062489cbf2b77",
  CountReportScheduleSubscribers: "59cb109129d195696a80306a94c4163d",
  CountReports: "ebe149b0f013e7235acecc5d6222b009",
  CountResourcePreviewTransactions: "be600e2a916771a71986c8052706e578",
  CountSupplierAccountInvoicePayments: "efb8e79a81a5336af557989728fa45b2",
  CountSupplierPayouts: "3170071a42501c62b66fe4936a7c3398",
  CountSuppliers: "5f858e74d826cf90a13735ca89c54a4b",
  CountTransactions: "18f26094e74089e99e99c4a626b3700f",
  CountUserApprovals: "79284f10bc77bc757290bda82565777d",
  CountUserCustomFields: "43180c4e3dfda86096ab0b60136c28fc",
  CountUserRequests: "987fc98c08f075d15b0444cfbf3acd84",
  CountUsers: "ec7477752d5c192c4db9ef3a55e39979",
  CountUsersByStatus: "20eb68d50a11e5b67d179328b54498df",
  CountVirtualCards: "cf517be91471c0434b3743d4205cdb8f",
  CountWorkflowRuns: "cc84a9794ed2433102d92b0b883ce3f3",
  CountWorkflowVersions: "3d4d62ca4ecf7edb3254167e8929b828",
  CountWorkflows: "8caa08055970a2eabc611dd308b5c872",
  IndexActivities: "72c017f5aa91a969c8d6a8bb4c4cdca1",
  IndexApprovalDelegationsFromYou: "926f88198a102fbe40477ded409955b0",
  IndexApprovalDelegationsToYou: "051309ac12b8783f7d7670e25362e94b",
  IndexApprovalWorkflowApprovals: "e2d74c6eb513e6c0053cf9be8abf78fd",
  IndexApprovals: "b27aab2b5b7360ecb11600dc35d62933",
  IndexApprovalsResourceCards: "e99e733c8243a066f6418b280c43586b",
  IndexAudits: "796ebd16b31300cd09191f400047ee79",
  IndexBankAccounts: "9c3627e2b01492e0e673e9676f3c7c4c",
  IndexCardAuthorisations: "dc0e6a0d172103d277dbc7fea67d40f4",
  IndexCardSubscriptionMerchants: "bdcc70f1fbb6b15ee14390d70950f948",
  IndexCardSubscriptionPurchaseRequestItems: "377e9d02ee4ef5e1579dffe1506b5e1e",
  IndexCardSubscriptionTransactions: "ecd633a94c1634073a6bd129920751ab",
  IndexCardSubscriptionVirtualCards: "20ff3ceeba225322b0402ae6a33b4854",
  IndexCardSubscriptions: "7c907ba2cb2a57f606f9fd311fafd2ca",
  IndexCategories: "70bf01dd77605f18f84b509b2a8bab62",
  IndexCategorySets: "46e4d1a46884850ea81695932cba9429",
  IndexConditionReferenceValues: "da2ffa6c30b66becf551beb5bac2cc5e",
  IndexCostCentres: "4344f7e1ae8109f05e3654204c769c3a",
  IndexCustomFieldPreviews: "4455329c58af213f2fd8963ddc60cb7c",
  IndexCustomFields: "f30aa3a8fe6700f9086155e552198162",
  IndexDataFilters: "d86864e915c4f031ec05bafcbca21e89",
  IndexDataScopingRules: "7f6bd1bd5cce3fd8c2aabda5d22e6aa7",
  IndexDataSourceItems: "7af716a6ce3e56878c6607278f461f0a",
  IndexDataSources: "45cb4f6bc355fcc7e8a692e0b3bda099",
  IndexDataViewFields: "92cd16c4d0a3bf29b202b3adaaad1863",
  IndexDataViews: "0a6fd3387473add9753e5a777001c9e3",
  IndexDataViewsMetadata: "0f22f65079dae5fa406a29be594ecff0",
  IndexDocumentsPanelDocuments: "907a31b196eeab115dc734c86338ba49",
  IndexDocumentsTabDocuments: "18b79644e094e06348e3feb98b288d39",
  IndexDraftRequestItems: "f6186a92518b6b7781dbdf8f1b6c4fdf",
  IndexFlowActions: "a01eae2d8b403b7091503775f3adc89d",
  IndexFlowConditions: "cc8b9c449cf2e0a8d8fb62f6a09cd65e",
  IndexFlowStepResults: "09243cdd1beaa91774b0b9282f50d773",
  IndexFormCustomFields: "0cee968e1d3f6d37ee3010533cd00701",
  IndexInboxIncomingEmails: "0e35e726d2df26992e44129fff706d93",
  IndexIncomingEmailAttachments: "9defcbdbecf157dbb520e95214385326",
  IndexInvoicePayments: "7dc2d87f02074fda0b2a7e8ac651e70a",
  IndexIssuerIntegrationConsumers: "a732f006bd52a851f5a9621af764eb72",
  IndexIssuerIntegrationRules: "6051877dbbf9aab88b700b17299238ce",
  IndexIssuerIntegrations: "edb3877da892f1f2978c30bcfe5f23ea",
  IndexLocations: "45707bade49b4f5cfabac9aeddb563e1",
  IndexMeNotifications: "c44b81401c729b5645e9d663f271b6af",
  IndexMerchantCategoryCodes: "71b1d1660facef6c50efabdf6b2c5419",
  IndexMerchants: "bb216e13bd426847f752150c61c65505",
  IndexOrganisationUnits: "28e680602bf2c884f56c47b8913d2525",
  IndexOverviewApprovals: "aa11d43c7ebfc5e401ce7146c57abbe6",
  IndexOverviewCards: "46b61a8771aa89c3ab9d9588c941feca",
  IndexOverviewRequests: "1735344a83ea9dbf13a5671a636e1bb8",
  IndexOverviewTransactions: "5d2be9de2fbef630ab5250bc89597b9a",
  IndexPurchaseRequestItemCardAuthorisations:
    "9dc3ab14f0462acc3e6e3c4657d49ecf",
  IndexPurchaseRequestItemInvoicePayments: "d3b979f1cea3c1dd088a938b85ef9b9b",
  IndexPurchaseRequestItemTransactions: "bead1b7b50766619d82c9e5fdc4dcd08",
  IndexPurchaseRequestItemTransactionsOverview:
    "db1a026715d6147c710633721bc7a435",
  IndexPurchaseRequestItems: "6aaecbc6666c208351382ce4f63b69b2",
  IndexReceiptMissingPanelDocuments: "9621ec2eae8a050635b5495e78ea23e5",
  IndexReportScheduleSubscriberNames: "730b6be6cb7de29dbf98214785bdfc28",
  IndexReportScheduleSubscriberResources: "138a3d651b0d66c85bddb12391ce2b69",
  IndexReportSchedules: "5ec6256069a4971f0ea15d70d6776786",
  IndexReports: "fd3f8c8da950fdd75445c975dc47a4b6",
  IndexRequestFormSettingsDataSources: "04e2b73496162d42611d4a1443dcca08",
  IndexResourcePreviewTransactions: "4786ec758bc1a27d82c637e81058f71b",
  IndexSpendLimits: "083d9b19737780e8371f1f828fea0730",
  IndexSubscriptionPurchaseOrders: "822af6ef903e8f2c6ec7a42660303a01",
  IndexSupplierAccountInvoicePaymentResources:
    "a83e4fc6c0f4a770b92d4174142b1691",
  IndexSupplierOnboardingUsers: "eecf66d8f7fc1de1ab352254ab023368",
  IndexSupplierPayouts: "975cdd3f4d6316e996230a8e6535444a",
  IndexSuppliers: "1caa33a5e8e8eb5968d4ece23cff6ff2",
  IndexSuppliersSelectModal: "186a9f40a7bb7bca63ec19822ec0f60e",
  IndexTransactions: "f5cf99c1cbb1b9418f2a5126c80efead",
  IndexUsers: "6cc1b9f9f2c214320e1388effd948c7b",
  IndexVirtualCards: "b3555f14a0725de035737e46ba4660ad",
  IndexWorkflowMasterVersions: "e5fcc572956198734f6f09aedff4778c",
  IndexWorkflowRuns: "ce4dcc24514c8e4ccd81b7401af50225",
  IndexWorkflowVersions: "5238b2e712c5bc492109418abfab5201",
  IndexWorkflows: "84637fda5768ce149cf261897ff046be",
  Me: "2c50f44990d121af08f48533e076bb89",
  MyAccount: "42f6e557f9110793f41fd5e21a779ae0",
  MyAccountSettings: "648dce928f0a230bc92367a2547e46aa",
  MySettings: "3005765be440489eeb25155dc80ce03f",
  PickBankAccountsViewerPermissions: "1c23a28d00b39a7a6422539524bd4aec",
  PickCardAuthorisationsViewerPermissions: "6ffa3aa60b5cc53374c9f820af873902",
  PickChildrenCount: "ced2deff02e96b9dbb34a48ab3c32c6f",
  PickFirstStep: "b702394bfb6bb0aed59deab5ab00e750",
  PickFlowStep: "40035dd48abaec7f5f7523913f7b950d",
  PickFlowStepSoftValidation: "fb87ef781c96453596f2606c1cf995b0",
  PickIntercomUserHash: "b50987861f34a61ae291e22107c51e21",
  PickPurchaseRequestItemMerchant: "2aac7f4687fe5d2d76ea982823248c4f",
  PickReportConnectionViewerPermissions: "2043549d2ebaa7eefdcd7082800562ce",
  PickSupplierOnboardingContact: "3147f4319c010adf30f9cea34b556638",
  PickTitle: "4a4caf8c936f6b01e1b900f2967e3ce3",
  PickTransactionOriginalAmount: "b9c1901302f01c6b85a992b34f627bef",
  PickUsersViewerPermissions: "76c88b5582ad5879f6f4762baa67fc4b",
  PickWorkflowUpdatedAt: "1a88f77b4102c6f80c0bb5794b76a8b3",
  PreviewAccount: "3e2accdb7d88621246fadb4cc3416b1e",
  PreviewApproval: "f3e51a55cae474fd335e3a1b8860b34d",
  PreviewApprovalAssignees: "1ef7bb0b61e0a562efe22becea944756",
  PreviewApprovalDelegation: "c54441df35b192a6248fcada4126982a",
  PreviewApprovalDelegationView: "abf5062e4109fbaafc8c22c2990fa908",
  PreviewApprovalWorkflow: "5b62b0c577c8673116a6f674de7cb2b2",
  PreviewAttachment: "bc94039530244d16cb72f5cf5f6dbf63",
  PreviewBankAccount: "b46048c2c13183c42c24a16b48c25bff",
  PreviewCardAuthorisation: "ef2e61f62c9eeab3dddb086dded68c2b",
  PreviewCardSubscription: "41d34da96bacdef8a1f74708f4c283ae",
  PreviewCardSubscriptionLatestTransaction: "3e747c9d22462808f739adf495133fd3",
  PreviewCardSubscriptionPurchaseOrder: "c0dfbc7f11539b17860b642dcda1b817",
  PreviewCategory: "9badfb1432acee2f20f4aedcc6f594d4",
  PreviewCategoryEdit: "0cda84ecc9aefea2e6a2d3d2f36778c3",
  PreviewCategorySet: "f2e7eef8bc2c50130a7564bed6c45e26",
  PreviewCostCentre: "ef28bcd5fc014d85775ca794513d4b3b",
  PreviewCostCentreEdit: "15673403fa79479f56004fab9dd22450",
  PreviewCustomField: "a8a5d0f09a715b09e774f7fb60a7d253",
  PreviewCustomFieldEdit: "123184e6a6c2b3ffd645818f5ce90c6e",
  PreviewDataFilter: "29667e0e8c1e756265626403f4d3ac9c",
  PreviewDataSource: "aa13fc4a621b4f99ada92e4344ee5bfc",
  PreviewDataSourceItem: "f1fc6ddc260e4fda6836f3f2341a5d0d",
  PreviewDataSourceItemEdit: "ac6b6436f1b9041071d96160b5209848",
  PreviewDataSourceItems: "c595ee7e6cf651470d88529fcd8c1d26",
  PreviewDataView: "4d714bb3b821cca696de7c3fec990cb9",
  PreviewDataViewField: "edc4a82f3b57480340b2884bfafd63cd",
  PreviewDocument: "87da3ff996f5efa4b4d72d640aca6a9c",
  PreviewDocumentEdit: "44954bb5230595fd1b32f6b623fcfcb9",
  PreviewDraftDocument: "951ee80d667b2b0495a1e61db383d3d6",
  PreviewFlowActionApprove: "c553bd9997eef668094016f2435deb1a",
  PreviewFlowActionComment: "3fc8f12886c3e3a2ec90cf6c80c8f0b4",
  PreviewFlowActionCreatePurchaseOrder: "8fca145897da9e6bdd4d5eb15e1539a8",
  PreviewFlowActionGetApproval: "59f8e062ee5fbe482c8b3ceea5ac1ac3",
  PreviewFlowActionReject: "9ef89e4c5a262e243ce9765df8569f75",
  PreviewFlowStepCondition: "d3c092ede8ec59bffdfab547d37d42b2",
  PreviewInbox: "6375d066ef8b34d28fb7e594815b6cd4",
  PreviewIncomingEmail: "73b9c7dc7ad2039f8b2fc45ff48cc954",
  PreviewIndustryCode: "30f3af995373cf1073dfe3015f1cfb33",
  PreviewIndustryCodeChildren: "f141fbdcb8adc01aa647fb62eacdfdee",
  PreviewIndustryCodeSuppliers: "863a70e351e1c1a136058de233932d1e",
  PreviewInvoicePayment: "0677862ad99613b4ab253c865803437d",
  PreviewIssuerIntegrationEdit: "468d73d2da4939729135b30bb22969d9",
  PreviewIssuerIntegrationRule: "f1c4713e4f49a432071eb1a462726af1",
  PreviewIssuerIntegrationView: "76936b8e1d2ea549dd4d7876e066565e",
  PreviewLocation: "dd006b6f3118215a99dae55c3e0e9cf0",
  PreviewMerchant: "ba370a4523e02bb45ca2e27b2c404a0e",
  PreviewOrganisationUnitEdit: "034f85ac2c435c525e22eebb6fa62c91",
  PreviewOrganisationUnitUsers: "032dab1127d0e64e80c1ff62bbb41399",
  PreviewOrganisationUnitView: "0581cc69e2a6bf4daca0bcced194e5e3",
  PreviewPurchaseOrder: "873722a627738a510b27d77ace310776",
  PreviewPurchaseRequestItem: "c61ccada2223b79f78a2ce48635ce040",
  PreviewReport: "55c238571972801ab1a79b576c8fcbc8",
  PreviewReportSchedule: "15ec36b06c54de9d7142520b2a7da574",
  PreviewSupplierPayout: "cd4cd95a96a34960e0baefcf13542594",
  PreviewSupplierRequest: "88f60241bafdeb9570315df18723e126",
  PreviewSupplierUser: "81938c60de74da8b14e8f1f24deb0f26",
  PreviewTransaction: "4a00704a9ea5da4b72afe736293b65d5",
  PreviewUser: "62a9f497fbb1879d7a7f7e6d1b714587",
  PreviewUserRequests: "6265d6d8711bab82744450f73b9dfabe",
  PreviewVirtualCard: "4725a468268d3217728987ae3f047f56",
  PreviewWorkflow: "939b491b6bd2d0c8dc258c1096b70487",
  SelectAddress: "09510cf23e397029cbac85596d3a105d",
  SelectApproverUsers: "6ebcea77692dd8e3e22454f815e4383a",
  SelectAssigneePaths: "51fc65b21954e8ba20954df53911065c",
  SelectAvailableBaseResources: "2bdc6a884c30ccbdcdf3c36dd4104f30",
  SelectAvailableInvoicePayoutCurrencies: "fc9566da552dbc01cdc3e333a542631e",
  SelectAvailablePurchaseRequestTypes: "b11d41964d7ea9583a0a135ec4e1da36",
  SelectCategories: "a52daf1367b2921f5d8faa94c71c9d79",
  SelectCategorySets: "0247cdce7b09fcbc67ca91e582b84634",
  SelectComparators: "e5f180e45279d4ad1c8298e6ec03fba2",
  SelectCostCentres: "a1034a097825e19cae0be3939d7f1678",
  SelectCostCentresWithRelation: "e800b400d6aea331e40d3bcc42fa2fdf",
  SelectCountries: "547d1fca8a9b872937d0a8fc2d7a1956",
  SelectCurrencies: "8d4526398357332867b616f1f4f7f1b6",
  SelectCustomFields: "d9cdd42187fdf2cc694773841ce63503",
  SelectDataSources: "dadadf24df28466775847032d63b0da8",
  SelectDataViews: "a765a973d260fdea710b60297ac16ed5",
  SelectFeatureToggles: "680806789257deccad228bb6267b2e55",
  SelectFlowStepResources: "763a206b09e4082a59e1fab78cac1f7f",
  SelectIndustryCodes: "5abb5bbf8aa210b3ca087fcf7fac7d17",
  SelectIssuerIntegrations: "e565fd26e3134ab190b9a8979b9cbd83",
  SelectLocations: "1fd1fc886d9411b408eba74e02d5ce68",
  SelectOptions: "cab951d6b6fd402ec0dc9c3a567b6304",
  SelectOrganisationUnits: "e8d2b2c7d6da4ad9a8cdbd269bb5a103",
  SelectOrganisationUnitsWithRelation: "c3edbcb5b28200d1ca123eb3e0c674ba",
  SelectReferenceAssociations: "005858b2ea7e91018ac3d36fed992a92",
  SelectReferencePaths: "1604ec161407de7c578b4c589ed87d98",
  SelectRequestersWithRelation: "1156bd3ae0549c130daa36a3a5e26973",
  SelectSuppliers: "543675040283f9e3db7594c6852e3b07",
  SelectSupportedTransactions: "d730550536c75ff0dd387e3908df5b4a",
  SelectTimezones: "b79796a22d6d8b95dd698de75c87461c",
  SelectUsers: "be4d117efffc75ac4152a65abf62d5fe",
  ShowApproval: "44044099fcd9445461094e6b3f090400",
  ShowCategorySet: "ab9a86756ae3afb13344e9ab457d2751",
  ShowCustomField: "b4a9eb1f054c2c82fc300b9e0ad3ed39",
  ShowCustomFieldValue: "3e84bcf6015f8225989fc83d7f4774b7",
  ShowDataSource: "4ddf3a6d2049a8514bed544676e656af",
  ShowDataView: "477acb27e89fe2e01f3104c4a1e055f3",
  ShowDraftRequestItem: "f549c38a8aabde0e7c72c9228182b4b4",
  ShowPurchaseRequestItem: "4458a25dad4c45072cd2053af7813d51",
  ShowSubscription: "3caa1015c350bc023ad80ca6c5fcaf1e",
  ShowSubscriptionPurchaseOrder: "e2e68b9a029fe0164310c2af86d059da",
  ShowSupplierRequest: "e064025407f6136b86d0bdafde779008",
  ShowUser: "d31d8a74c134c749b0ca69e8f1460616",
  ShowWorkflow: "cb09fc031c998f44e35e3a08742e680a",
  NotificationCreated: "d45d15eeea35d167957972eeb26571c3",
};

/**
 * The client who synced these operations with the server
 * @return {String}
 * @private
 */
const _client = "frontend";

const OperationStoreClient = {
  /**
   * Build a string for `params[:operationId]`
   * @param {String} operationName
   * @return {String} stored operation ID
   */
  getOperationId: function (operationName) {
    return (
      _client + "/" + OperationStoreClient.getPersistedQueryAlias(operationName)
    );
  },

  /**
   * Fetch a persisted alias from a local operation name
   * @param {String} operationName
   * @return {String} persisted alias
   */
  getPersistedQueryAlias: function (operationName) {
    const persistedAlias = _aliases[operationName];
    if (!persistedAlias) {
      throw new Error(
        "Failed to find persisted alias for operation name: " + operationName,
      );
    } else {
      return persistedAlias;
    }
  },

  /**
   * Satisfy the Apollo Link API.
   * This link checks for an operation name, and if it's present,
   * sets the HTTP context to _not_ include the query,
   * and instead, include `extensions.operationId`.
   * (This is inspired by apollo-link-persisted-queries.)
   */
  apolloLink: function (operation, forward) {
    if (operation.operationName) {
      const operationId = OperationStoreClient.getOperationId(
        operation.operationName,
      );
      operation.setContext({
        http: {
          includeQuery: false,
          includeExtensions: true,
        },
      });
      operation.extensions.operationId = operationId;
    }
    return forward(operation);
  },
  /**
   * Satisfy the Apollo middleware API.
   * Replace the query with an operationId
   */
  apolloMiddleware: {
    applyBatchMiddleware: function (options, next) {
      options.requests.forEach(function (req) {
        // Fetch the persisted alias for this operation
        req.operationId = OperationStoreClient.getOperationId(
          req.operationName,
        );
        // Remove the now-unused query string
        delete req.query;
        return req;
      });
      // Continue the request
      next();
    },

    applyMiddleware: function (options, next) {
      const req = options.request;
      // Fetch the persisted alias for this operation
      req.operationId = OperationStoreClient.getOperationId(req.operationName);
      // Remove the now-unused query string
      delete req.query;
      // Continue the request
      next();
    },
  },
};

export default OperationStoreClient;
