import { FieldPolicy } from '@apollo/client/cache/inmemory/policies';

import { IParsedGlobalId } from 'types/urn';
import { parseGlobalId } from 'utils/urn/parseGlobalId';
import {
  CustomTransactionReconciliationStatus,
  Transaction,
  TransactionReconciliationStatus,
  TransactionType,
} from 'src/generated/graphql';

/**
 * Dummy field-policy for strictly-typed {@link ActionDropdown}
 */
export const _true: FieldPolicy<boolean> = {
  read: () => true,
};

/**
 * Custom merge function.
 * Always replaces array with incoming value.
 * Prevents error "Cache data may be lost when replacing ...".
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
 */
export const _mergeArray: FieldPolicy<boolean> = {
  merge: (existing, incoming) => {
    return incoming;
  },
};

/**
 * We use parsed ID in URL search parameters.
 * It looks nicer than URN.
 */
export const parsedId: FieldPolicy<IParsedGlobalId> = {
  read: (_, { readField }) => {
    return parseGlobalId(readField('id'));
  },
};

const _reconciliationStatus: keyof Pick<Transaction, 'reconciliationStatus'> = 'reconciliationStatus';
const _type: keyof Pick<Transaction, 'type'> = 'type';

export const customTransactionReconciliationStatus: FieldPolicy<CustomTransactionReconciliationStatus | undefined> = {
  read: (_, { readField }) => {
    const status = readField<TransactionReconciliationStatus>(_reconciliationStatus);
    const type = readField<TransactionType>(_type);
    switch (status) {
      case TransactionReconciliationStatus.Reconciled:
        return CustomTransactionReconciliationStatus.Reconciled;
      case TransactionReconciliationStatus.ReceiptMissing: {
        if (type === TransactionType.Charge) {
          return CustomTransactionReconciliationStatus.ReceiptMissing;
        } else {
          return CustomTransactionReconciliationStatus.CreditNoteMissing;
        }
      }
      case TransactionReconciliationStatus.ReceiptUnavailable: {
        if (type === TransactionType.Charge) {
          return CustomTransactionReconciliationStatus.ReceiptUnavailable;
        } else {
          return CustomTransactionReconciliationStatus.CreditNoteUnavailable;
        }
      }
    }
  },
};
