import { setContext } from '@apollo/client/link/context';

import { auth } from 'providers/AuthProvider/auth';
import changesetVersion from 'src/generated/changesetVersion';

/**
 * https://www.apollographql.com/docs/react/networking/authentication/#header
 */
export const Auth = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: auth.access_token ? `Bearer ${auth.access_token}` : '',
      'X-Schema-Version': changesetVersion,
    },
  };
});
