import { RetryLink } from '@apollo/client/link/retry';

/**
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
 */
export const Retry = new RetryLink({
  attempts: {
    max: 3,
    retryIf: error => {
      /*
       * Error 500 will have:
       * - statusCode 500
       * - name "ServerError"
       * - message "Response not successful: Received status code 500"
       */
      if (error.statusCode === 500) return false;

      return !error.data?.status || (error.data.status !== 401 && error.data.status !== 400);
    },
  },
  delay: {
    initial: 1000,
    jitter: true,
    max: 5000,
  },
});
