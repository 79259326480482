/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IAuthProviderProps,
  IAuthService,
  IAuthState,
  ICheckLoginStatus,
  IFetchToken,
  IPreAuth,
  ISignIn,
  ISignIn2fa,
  ISignInSuccess,
  ISignInWithSuccessData,
  ISignOut,
  IVerifySsoSession,
} from './types';
import { sendError } from 'core/errors/sendError';
import { APP_RC } from 'config/APP_RC';
import { axiosInstance } from 'config/_global_context/axios';
import { auth } from './auth';
import { AuthStateContext } from './AuthStateContext';
import { AuthServiceContext } from './AuthServiceContext';
import {
  isChallengeFailed,
  isCredentialsNotAccepted,
  isErrorWithDescription,
  isMfaRequired,
  isPasswordFlow,
  isSsoFlow,
} from './typeGuards';
import { parseToken } from './parseToken';
import { parseLocation } from './parseLocation';

export const AuthProvider = ({ children }: IAuthProviderProps): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState<IAuthState>({});

  const getNextLocation = useCallback(() => {
    const nextLocation = parseLocation(localStorage.getItem('next_location'));
    localStorage.removeItem('next_location');
    return nextLocation;
  }, []);

  const resetForms = useCallback(() => {
    setState({
      isSignedIn: false,
    });
  }, []);

  const checkLoginStatus = useCallback<ICheckLoginStatus>(() => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('client_id', APP_RC.oauth_client_id);
    urlSearchParams.set('grant_type', 'refresh_token');
    const data = urlSearchParams.toString();
    fetchToken({ data }).catch(() => {
      setState({
        isSignedIn: false,
      });
    });
  }, []);

  const preAuth = useCallback<IPreAuth>(
    ({ email }) =>
      new Promise<void>((resolve, reject) => {
        axiosInstance({
          data: {
            client_id: APP_RC.oauth_client_id,
            email,
          },
          method: 'POST',
          url: '/auth/preauth',
        }).then(
          (e: unknown) => {
            if (isPasswordFlow(e)) {
              setState({
                email,
                isSignedIn: false,
                preAuthPassword: e.data,
              });
            } else if (isSsoFlow(e)) {
              const nextLocation = getNextLocation();
              if (nextLocation) {
                localStorage.setItem('next_location', JSON.stringify(nextLocation));
              }
              window.location.href = e.data.redirect_url;
            }
            resolve();
          },
          (e?: AxiosError<unknown, unknown>) => {
            setState({
              isSignedIn: false,
              signInError: isErrorWithDescription(e) ? e.response.data.error_description : '',
            });
            if (!isErrorWithDescription(e)) {
              sendError({
                action: 'Auth',
                error: 'Pre-auth error without description',
                tags: !e?.response ? {} : { data: JSON.stringify(e.response.data) },
              });
            }
            reject();
          }
        );
      }),
    []
  );

  const signInWithSuccessData = useCallback<ISignInWithSuccessData>(({ data }) => {
    auth.access_token = data.access_token;
    const accountType = parseToken(data.access_token);
    setState({
      accountType,
      isSignedIn: true,
      signInSuccessData: data,
    });
  }, []);

  const fetchToken = useCallback<IFetchToken>(
    ({ data }) =>
      new Promise<void>((resolve, reject) => {
        axiosInstance({
          data,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          method: 'POST',
          url: '/oauth2/token',
        }).then(
          (e: ISignInSuccess) => {
            signInWithSuccessData({ data: e.data });
            resolve();
          },
          (e?: AxiosError<unknown, unknown>) => {
            if (isChallengeFailed(e)) {
              setState(prevState => ({
                challengeFailed: e.response.data,
                email: prevState.email,
                isSignedIn: false,
                mfaRequired: prevState.mfaRequired,
                preAuthPassword: prevState.preAuthPassword,
              }));
            } else if (isCredentialsNotAccepted(e)) {
              setState(prevState => ({
                credentialsNotAccepted: e.response.data,
                email: prevState.email,
                isSignedIn: false,
                preAuthPassword: prevState.preAuthPassword,
              }));
            } else if (isMfaRequired(e)) {
              setState(prevState => ({
                email: prevState.email,
                isSignedIn: false,
                mfaRequired: e.response.data,
                preAuthPassword: prevState.preAuthPassword,
              }));
            } else {
              setState(prevState => ({
                email: prevState.email,
                isSignedIn: false,
                preAuthPassword: prevState.preAuthPassword,
                signInError: isErrorWithDescription(e) ? e.response.data.error_description : '',
              }));
              if (!isErrorWithDescription(e)) {
                sendError({
                  action: 'Auth',
                  error: 'Auth error without description',
                  tags: !e?.response ? {} : { data: JSON.stringify(e.response.data) },
                });
              }
            }
            reject();
          }
        );
      }),
    []
  );

  const navigateToNextLocation = useCallback(() => {
    const nextLocation = getNextLocation() || '/';
    navigate(nextLocation, { replace: true });
  }, []);

  const signIn = useCallback<ISignIn>(({ password, username }) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('client_id', APP_RC.oauth_client_id);
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('username', username);
    urlSearchParams.set('password', password);
    const data = urlSearchParams.toString();
    return fetchToken({ data }).then(() => {
      navigateToNextLocation();
    });
  }, []);

  const signIn2fa = useCallback<ISignIn2fa>(({ mfa_session_id, otp }) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('client_id', APP_RC.oauth_client_id);
    urlSearchParams.set('grant_type', 'mfa');
    urlSearchParams.set('mfa_session_id', mfa_session_id);
    urlSearchParams.set('otp', otp);
    const data = urlSearchParams.toString();
    return fetchToken({ data }).then(() => {
      navigateToNextLocation();
    });
  }, []);

  const verifySsoSession = useCallback<IVerifySsoSession>(({ code, redirect_uri, ssoState }) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('assertion', code);
    urlSearchParams.set('client_id', APP_RC.oauth_client_id);
    urlSearchParams.set('grant_type', 'assertion');
    urlSearchParams.set('redirect_uri', redirect_uri);
    urlSearchParams.set('state', ssoState);
    const data = urlSearchParams.toString();
    return fetchToken({ data }).then(
      () => {
        navigateToNextLocation();
      },
      () => {
        navigate('/', { replace: true });
      }
    );
  }, []);

  const signOut = useCallback<ISignOut>(() => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('client_id', APP_RC.oauth_client_id);
    urlSearchParams.set('token', auth.access_token);
    return axiosInstance({
      data: urlSearchParams.toString(),
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      url: '/oauth2/revoke',
    }).then(() => {
      auth.access_token = '';
      setState(prevState => ({
        ...prevState,
        isSignedIn: false,
      }));
    });
  }, []);

  const service = useMemo<IAuthService>(
    () => ({
      preAuth,
      resetForms,
      signIn,
      signIn2fa,
      signInWithSuccessData,
      signOut,
      verifySsoSession,
    }),
    []
  );

  useEffect(() => {
    if (location.pathname.includes('sso_callback')) {
      setState({
        isSignedIn: false,
      });
    } else {
      checkLoginStatus();
    }
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthServiceContext.Provider value={service}>
        {state.isSignedIn !== undefined && children}
      </AuthServiceContext.Provider>
    </AuthStateContext.Provider>
  );
};
