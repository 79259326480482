import { ApolloLink } from '@apollo/client';

import { trace } from 'core/errors/trace';

/**
 * Adds AppSignal breadcrumb after every query and mutation.
 *
 * @see https://docs.appsignal.com/front-end/breadcrumbs.html
 */
export const Trace = new ApolloLink((operation, forward) => {
  const { operationName } = operation;
  const isMutation = !!operation.query.definitions.find(
    def => def.kind === 'OperationDefinition' && def.operation === 'mutation'
  );
  const category = isMutation ? 'Mutation' : 'Query';
  trace(category, operationName, operation.variables);
  return forward(operation);
});
