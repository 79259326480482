import Appsignal from '@appsignal/javascript';
import { plugin as windowEventsPlugin } from '@appsignal/plugin-window-events';

import { AppUpdatedErrorMessageRegexp } from '../regexp/AppUpdatedErrorMessage';
import { APP_RC } from '../APP_RC';

const appsignalInstance = new Appsignal({
  ignoreErrors: [
    /boundary_fallback/,
    /component unmounted/,
    /expected expression, got '<'/,
    /HEAD ipv4\.icanhazip\.com/,
    /Invalid or unexpected token/,
    /Loading chunk/,
    /ResizeObserver loop limit exceeded/,
    /ResizeObserver loop completed with undelivered notifications/,
    /Unexpected token '<'/,
    /Failed to fetch dynamically imported module/, // App updated in Chrome

    /**
     * GraphQL node query's 404 error
     * @example
     * {
     *     "errors": [
     *         {
     *             "message": "Variable $id of type GlobalID! was provided invalid value",
     *             "locations": [{ "line": 1, "column": 22 }],
     *             "extensions": {
     *                 "value": "urn:mp:data_source:h.12341234",
     *                 "problems": [{ "path": [], "explanation": "Invalid hash" }]
     *             }
     *         }
     *     ]
     * }
     */
    /Invalid hash/,
    ...AppUpdatedErrorMessageRegexp,
  ],
  /**
   * Empty key would cause appsignal to start in development mode
   * which obstructs unit tests and prevents testing payloads of appsignal reports in playwright.
   * Development mode is only useful for working locally with open console.
   */
  key: !APP_RC.env || window.playwright ? 'none' : APP_RC.appsignal_token,
  revision: APP_RC.git_sha,
});

/**
 * plugin-window-events catches errors that are not caught elsewhere in the code
 * @see https://docs.appsignal.com/front-end/plugins/plugin-window-events.html
 */
appsignalInstance.use(windowEventsPlugin());

export { appsignalInstance };
