/**
 * Changes values that are matched by regexp.
 * Nullifies values by default.
 * @example
 * const git_sha = '%VITE_GIT_SHA%';
 * const regexp = /%.*%/;
 * const result1 = replaceValues({ git_sha }, regexp);
 * // result1.git_sha is undefined
 * const result2 = replaceValues({ git_sha }, regexp, '');
 * // result2.git_sha is empty string
 */
export const replaceValues = (input: { [key in string]?: string }, template: RegExp, replacement?: string) =>
  !input
    ? {}
    : Object.keys(input).reduce((acc, key) => {
        const value = input?.[key];
        if (value && !value.match(template)) {
          acc[key] = input?.[key];
        } else {
          acc[key] = replacement;
        }
        return acc;
      }, {} as { [key in string]?: string });
