import { CountryCode } from 'graphql-api/generated-types';
import { FixedLengthArrayLike } from 'types';

export type IEurozoneMember =
  | CountryCode.At
  | CountryCode.Be
  | CountryCode.Bg
  | CountryCode.Cy
  | CountryCode.Cz
  | CountryCode.De
  | CountryCode.Dk
  | CountryCode.Ee
  | CountryCode.Gr
  | CountryCode.Es
  | CountryCode.Fi
  | CountryCode.Fr
  | CountryCode.Gb
  | CountryCode.Hr
  | CountryCode.Hu
  | CountryCode.Ie
  | CountryCode.It
  | CountryCode.Lt
  | CountryCode.Lu
  | CountryCode.Lv
  | CountryCode.Mt
  | CountryCode.Nl
  | CountryCode.Pl
  | CountryCode.Pt
  | CountryCode.Ro
  | CountryCode.Se
  | CountryCode.Si
  | CountryCode.Sk;

export const IEurozone: FixedLengthArrayLike<28, CountryCode> = [
  CountryCode.At,
  CountryCode.Be,
  CountryCode.Bg,
  CountryCode.Cy,
  CountryCode.Cz,
  CountryCode.De,
  CountryCode.Dk,
  CountryCode.Ee,
  CountryCode.Gr,
  CountryCode.Es,
  CountryCode.Fi,
  CountryCode.Fr,
  CountryCode.Gb,
  CountryCode.Hr,
  CountryCode.Hu,
  CountryCode.Ie,
  CountryCode.It,
  CountryCode.Lt,
  CountryCode.Lu,
  CountryCode.Lv,
  CountryCode.Mt,
  CountryCode.Nl,
  CountryCode.Pl,
  CountryCode.Pt,
  CountryCode.Ro,
  CountryCode.Se,
  CountryCode.Si,
  CountryCode.Sk,
] as const;
