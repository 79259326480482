import { onError } from '@apollo/client/link/error';

import { isInvalidGrant } from 'providers/AuthProvider/typeGuards';
import { sendError } from 'core/errors/sendError';
import { GraphQLError } from 'core/errors/GraphQLError';

/**
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-error/
 * @see https://www.apollographql.com/docs/react/data/error-handling/#retrying-operations
 */
export const Error = onError(({ forward, graphQLErrors, networkError, operation }) => {
  const { response } = operation.getContext();
  if (response?.status === 401 || isInvalidGrant(response)) {
    window.location.href = '/signin';
  } else if (networkError) {
    return forward(operation);
  } else if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      sendError({
        action: operation.operationName,
        error: new GraphQLError(JSON.stringify(graphQLError)),
        tags: {
          variables: JSON.stringify(operation.variables),
        },
      });
    });
  }
});
