import { ApolloClient, InMemoryCache } from '@apollo/client';

import fragmentMatcher from '../../../generated/fragment_matcher';
import { connectToDevTools } from './connectToDevTools';
import { defaultOptions } from './defaultOptions';
import { link } from './link';
import { typePolicies } from './typePolicies';

/**
 * @see https://www.graphql-code-generator.com/plugins/other/fragment-matcher
 */
const possibleTypes = fragmentMatcher.possibleTypes;

/**
 * @see https://www.apollographql.com/docs/react/caching/cache-configuration/
 */
const cache = new InMemoryCache({ possibleTypes, typePolicies });

/**
 * @see https://www.apollographql.com/docs/react/api/core/ApolloClient/
 */
export const apolloClient = new ApolloClient({ cache, connectToDevTools, defaultOptions, link });
