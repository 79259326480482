import { GlobalIdRegexp } from 'regexp/GlobalId';
import { IParsedGlobalId } from 'types';

export const parseGlobalId = (input?: string): IParsedGlobalId | undefined => {
  if (!input) return undefined;
  const match = input.match(GlobalIdRegexp);
  if (!match) return undefined;
  return {
    type: match[1],
    id: match[2],
  };
};
