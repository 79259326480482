import { Location } from 'history';

export const parseLocation = (input?: string | null): Pick<Location, 'pathname' | 'search'> | undefined => {
  if (!input) return undefined;
  let result: unknown;
  try {
    result = JSON.parse(input);
  } catch (e) {
    return undefined;
  }
  if (typeof result !== 'object') return undefined;
  if (Object.keys(result as object).length > 2) return undefined;
  if (Object.keys(result as object).length === 1 && (result as Pick<Location, 'pathname' | 'search'>).pathname)
    return result as Pick<Location, 'pathname' | 'search'>;
  if (
    Object.keys(result as object).length === 2 &&
    (result as Pick<Location, 'pathname' | 'search'>).pathname &&
    (result as Pick<Location, 'pathname' | 'search'>).search
  )
    return result as Pick<Location, 'pathname' | 'search'>;
  return undefined;
};
