import React, { FunctionComponent, useEffect, useState } from 'react';

import { ScreenSize } from '../../../../types/ui';
import { IInjectResponsiveProps } from '../WithResponsiveProps/WithResponsiveProps';
import { IInjectResizeProps } from '../WithResizeProps/WithResizeProps';
import { IInjectDropdownProps } from '../WithDropdownProps/WithDropdownProps';

export interface IEnhanceWithControlledNavProps {
  /**
   * <p>Use this property to take over control of the nav.</p>
   * <p>When `undefined`, layout will be uncontrolled.
   * It means layout will automatically expand and collapse nav depending on viewport size and according to breakpoints.
   * This is preferred way for most of the pages in app.</p>
   * <p>When enabled, layout will use property `navIsExpanded` instead of internal state,
   * and it will call function `onNavToggle` when it wants to use automation.
   * It is up to app whether to take suggestions of layout into account.</p>
   */
  navIsExpanded?: boolean;
  onClickNavToggle?: (value: boolean) => void;
  /**
   * <p>Function to be called when nav is controlled, and when layout wants to use automation to expand and collapse nav.</p>
   * <p>Can be used as suggestions in managing nav state.</p>
   * <p>In uncontrolled mode this property can be safely omitted.</p>
   */
  onNavToggle?: (e: { navIsExpanded: boolean }) => void;
}
export interface IInjectControlledNavProps {
  iconicNavWidth: number;
  injectedNavIsExpanded: boolean;
  navIsVisibleAlways: boolean;
  screenSize: ScreenSize;
  setNavIsExpanded: (navIsExpanded: boolean) => void;
  tabsAreVisible: boolean;
}
interface INavState {
  navIsExpanded: boolean;
  navIsVisibleAlways: boolean;
  screenSize: ScreenSize;
}

export const mpBreakpointMaxTablet = 992;
export const mpBreakpointMaxMobile = 688;

export const withControlledNavProps = <P extends IInjectControlledNavProps>(WrappedComponent: FunctionComponent<P>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithControlledNavProps = (
    props: Omit<
      P & IInjectResizeProps & IInjectResponsiveProps & IInjectDropdownProps & IEnhanceWithControlledNavProps,
      keyof IInjectControlledNavProps
    >
  ) => {
    const { notificationsDropdownIsOpen, onClickNavToggle, onNavToggle, profileDropdownIsOpen, width } = props;

    const [navState, setNavState] = useState<INavState>({
      navIsExpanded: width >= mpBreakpointMaxTablet,
      navIsVisibleAlways: width >= mpBreakpointMaxMobile,
      screenSize: width > mpBreakpointMaxTablet ? 'desktop' : width > mpBreakpointMaxMobile ? 'tablet' : 'mobile',
    });

    useEffect(() => {
      if (typeof onNavToggle === 'function') onNavToggle({ navIsExpanded: navState.navIsExpanded });
    }, [navState.navIsExpanded]);

    useEffect(() => {
      const nextState: Partial<INavState> = {};
      const screenSize =
        width > mpBreakpointMaxTablet ? 'desktop' : width > mpBreakpointMaxMobile ? 'tablet' : 'mobile';
      if (navState.screenSize !== screenSize) nextState.screenSize = screenSize;

      if (navState.screenSize !== 'desktop' && screenSize === 'desktop') {
        if (!navState.navIsVisibleAlways) nextState.navIsVisibleAlways = true;
        if (!navState.navIsExpanded) nextState.navIsExpanded = true;
      }
      if (navState.screenSize !== 'tablet' && screenSize === 'tablet') {
        if (!navState.navIsVisibleAlways) nextState.navIsVisibleAlways = true;
        if (navState.navIsExpanded) nextState.navIsExpanded = false;
      }
      if (navState.screenSize !== 'mobile' && screenSize === 'mobile') {
        if (navState.navIsVisibleAlways) nextState.navIsVisibleAlways = false;
        if (navState.navIsExpanded) nextState.navIsExpanded = false;
      }
      if (Object.keys(nextState).length > 0) {
        setNavState(prevState => ({
          ...prevState,
          ...nextState,
        }));
      }
    }, [width]);

    useEffect(() => {
      if (profileDropdownIsOpen && !navState.navIsVisibleAlways && navState.navIsExpanded) {
        setNavState(prevState => ({
          ...prevState,
          navIsExpanded: false,
        }));
      }
    }, [profileDropdownIsOpen]);

    useEffect(() => {
      if (notificationsDropdownIsOpen && !navState.navIsVisibleAlways && navState.navIsExpanded) {
        setNavState(prevState => ({
          ...prevState,
          navIsExpanded: false,
        }));
      }
    }, [notificationsDropdownIsOpen]);

    const tabsAreVisible = width > mpBreakpointMaxMobile;

    return (
      <WrappedComponent
        {...(props as P & IEnhanceWithControlledNavProps)}
        iconicNavWidth={70}
        injectedNavIsExpanded={props.navIsExpanded === undefined ? navState.navIsExpanded : props.navIsExpanded}
        navIsVisibleAlways={navState.navIsVisibleAlways}
        onClickNavToggle={onClickNavToggle}
        onNavToggle={onNavToggle}
        screenSize={navState.screenSize}
        setNavIsExpanded={navIsExpanded =>
          setNavState(prevState => ({
            ...prevState,
            navIsExpanded,
          }))
        }
        tabsAreVisible={tabsAreVisible}
      />
    );
  };
  ComponentWithControlledNavProps.displayName = `withControlledNavProps(${displayName})`;

  return ComponentWithControlledNavProps;
};
