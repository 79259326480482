export const scrubSensitiveSearchParams = (searchParams: string | null): string => {
  if (!searchParams) return '';
  const urlSearchParams = new URLSearchParams(searchParams);
  for (const key of urlSearchParams.keys()) {
    if (key.includes('token')) {
      urlSearchParams.set(key, 'scrubbed');
    }
  }
  const result = urlSearchParams.toString();
  return result ? '?' + result : '';
};
