import { ViewportSizeBreakpoints } from '../../../config/ViewportSizeBreakpoints';
import { IMinMax } from '../../../types/components/CustomFieldsGrid';
import { ViewportSizeProviderBreakpoints, IDevice } from '../../../types/providers/ViewportSizeProvider';

type IGetDeviceProps = {
  breakpoints?: ViewportSizeProviderBreakpoints;
  width?: number | null;
};

export const getDevice = ({ breakpoints = ViewportSizeBreakpoints, width }: IGetDeviceProps): IDevice | undefined => {
  return width === undefined || width === null
    ? undefined
    : Object.values(breakpoints)
        .map(e => e as IMinMax)
        .reduce<IDevice | undefined>((gridBreakpoint, minMax) => {
          if (width >= minMax.min && (minMax.max === undefined || width <= minMax.max)) {
            return Object.keys(breakpoints)
              .map(e => e as IDevice)
              .reduce<IDevice | undefined>((acc, key) => {
                if (breakpoints[key].min === minMax.min && breakpoints[key].max === minMax.max) return key;
                return acc;
              }, undefined);
          } else {
            return gridBreakpoint;
          }
        }, undefined);
};
