import { UseMetaModelsProps } from 'presentation/useMetaModels/types';

type AppSignalTagsProps = {
  location?: string;
  me: UseMetaModelsProps['me'] | undefined;
};

export const appSignalTags = ({ location, me }: AppSignalTagsProps) => {
  const result: Record<string, string> = {};

  if (me) {
    result.account_type = me.account.accountType;
    result.account_urn = me.account.id;
    result.user_security_roles = me.profile.securityRoles.join(', ');
    result.user_urn = me.profile.id;
  }

  result.location = location ?? window.location.toString();

  return result;
};
