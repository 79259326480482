import { IPasswordFlow } from '../types';

export const isPasswordFlow = (response: unknown): response is IPasswordFlow => {
  return (
    !!response &&
    typeof response === 'object' &&
    (response as IPasswordFlow).data?.flow === 'password' &&
    typeof (response as IPasswordFlow).data?.submit_url === 'string'
  );
};
