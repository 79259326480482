import { IMinMax } from '../components';

export enum IDevice {
  Desktop = '2',
  DesktopLg = '3',
  Mobile = '0',
  Tablet = '1',
}

export type ViewportSizeProviderBreakpoints = {
  [BP in IDevice]: IMinMax;
};
