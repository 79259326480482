import React, { useEffect } from 'react';

import { AppError } from 'core/errors/AppError';
import { AppSpinner as Presentation } from 'presentation/_global_context/AppSpinner';
import { appsignalInstance } from 'config/_global_context';

/**
 * @todo use sendError in AppSpinner
 */
export const AppSpinner = () => {
  useEffect(() => {
    let themeClassName;
    try {
      themeClassName = localStorage.getItem('mp_theme_class_name');
    } catch (e) {
      appsignalInstance.sendError(new AppError('Unable to read local storage'), span => {
        span.setAction('AppSpinner');
      });
    }
    if (themeClassName && !document.body.classList.contains(themeClassName)) {
      document.body.classList.add(themeClassName);
    }
  }, []);
  useEffect(() => {
    document.querySelector('.spinner_outer')?.remove();
  }, []);
  return <Presentation />;
};
