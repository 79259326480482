import { from } from '@apollo/client';

import { Auth } from './Auth';
import { Error } from './Error';
import { RefreshToken } from './RefreshToken';
import { Retry } from './Retry';
import { Trace } from './Trace';
import { WebSocket } from './WebSocket';
import OperationStore from '../../../../generated/OperationStoreClientEs';

/**
 * @see https://www.apollographql.com/docs/react/api/link/introduction/
 */
export const link = from([Trace, Auth, OperationStore.apolloLink, Retry, Error, RefreshToken, WebSocket]);
