import React from 'react';

import { Spinner } from 'presentation/app_context/Spinner';
import { Logo } from 'presentation/app_context/Logo';
import style from './AppSpinner.module.scss';

/**
 * Spinner with Mazepay logo.
 * Usage:
 * - fallback for {@link RouteSwitch}
 * - fallback for {@link App} suspenders
 */
export const AppSpinner = () => {
  return (
    <div className={style.spinner_outer}>
      <Logo color='#b6b6b6' />
      <Spinner color='#b6b6b6' size={16} />
    </div>
  );
};
