import React, { CSSProperties, ReactElement, useMemo } from 'react';
import classNames from 'classnames';

import { DesignTheme } from '../../../types/ui';
import styles from './Spinner.module.scss';

type SpinnerProps = {
  className?: string;
  color?: string;
  customAttrs?: Record<string, string>;
  infoText?: string;
  size?: number;
  stretch?: boolean;
  style?: CSSProperties;
  theme?: DesignTheme;
};

export const Spinner = ({
  className,
  color,
  customAttrs,
  infoText,
  size = 16,
  stretch,
  style,
  theme = 'primary',
}: SpinnerProps): ReactElement => {
  const spinner = useMemo(
    () => (
      <div
        className={classNames({
          [styles.spinner]: true,
          [className as string]: !!className,
        })}
        style={{
          borderColor: color ? color : `var(--mp-color-${theme})`,
          height: `${size}px`,
          width: `${size}px`,
        }}
        {...customAttrs}
      />
    ),
    [className, color, JSON.stringify(customAttrs), size, theme]
  );

  return (
    <>
      {stretch ? (
        <div className={styles.stretch} style={style}>
          {spinner}
          {infoText && <div className={styles.info_text}>{infoText}</div>}
        </div>
      ) : (
        spinner
      )}
    </>
  );
};
