import axios from 'axios';
import axiosRetry from 'axios-retry';

import { APP_RC } from 'config/APP_RC';

const axiosInstance = axios.create({
  baseURL: APP_RC.api_url_base,
});

axiosRetry(axiosInstance, {
  retryCondition: error => {
    return !!error.response?.status && error.response.status >= 500;
  },
  retryDelay: retryCount => {
    return retryCount * 1000;
  },
});

axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.request.use(request => request);
axiosInstance.interceptors.response.use(
  response => response,
  response => Promise.reject(response)
);

export { axiosInstance };
