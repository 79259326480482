import common from '../../../../locales/it/common.json';
import component__confirmation_modal from '../../../../locales/it/component__confirmation_modal.json';
import component__custom_field_sidebar from '../../../../locales/it/component__custom_field_sidebar.json';
import component__custom_field_value_modal from '../../../../locales/it/component__custom_field_value_modal.json';
import component__dropzone from '../../../../locales/it/component__dropzone.json';
import component__error_boundary from '../../../../locales/it/component__error_boundary.json';
import component__files_field from '../../../../locales/it/component__files_field.json';
import component__pin_modal from '../../../../locales/it/component__pin_modal.json';
import component__preview_sidebar from '../../../../locales/it/component__preview_sidebar.json';
import component__snooze_reminder_modal from '../../../../locales/it/component__snooze_reminder_modal.json';
import component__status_history from '../../../../locales/it/component__status_history.json';
import component__user_sidebar from '../../../../locales/it/component__user_sidebar.json';
import component__workflow_run from '../../../../locales/it/component__workflow_run.json';
import container__approval from '../../../../locales/it/container__approval.json';
import container__approval_flow from '../../../../locales/it/container__approval_flow.json';
import container__approvals from '../../../../locales/it/container__approvals.json';
import container__new_request_details from '../../../../locales/it/container__new_request_details.json';
import container__new_request_start from '../../../../locales/it/container__new_request_start.json';
import container__overview from '../../../../locales/it/container__overview.json';
import container__request from '../../../../locales/it/container__request.json';
import container__subscription from '../../../../locales/it/container__subscription.json';
import container__subscriptions from '../../../../locales/it/container__subscriptions.json';
import container__suppliers from '../../../../locales/it/container__suppliers.json';
import container__user from '../../../../locales/it/container__user.json';
import container__users from '../../../../locales/it/container__users.json';
import container__workflows from '../../../../locales/it/container__workflows.json';
import enums from '../../../../locales/it/enums.json';
import interfaces from '../../../../locales/it/interfaces.json';
import landing from '../../../../locales/it/landing.json';
import layout from '../../../../locales/it/layout.json';
import rrule from '../../../../locales/it/rrule.json';
import supplier_container__bank_accounts from '../../../../locales/it/supplier_container__bank_accounts.json';
import supplier_container__overview from '../../../../locales/it/supplier_container__overview.json';
import toasts from '../../../../locales/it/toasts.json';

export const it = {
  common,
  component__confirmation_modal,
  component__custom_field_sidebar,
  component__custom_field_value_modal,
  component__dropzone,
  component__error_boundary,
  component__files_field,
  component__pin_modal,
  component__preview_sidebar,
  component__snooze_reminder_modal,
  component__status_history,
  component__user_sidebar,
  component__workflow_run,
  container__approval,
  container__approval_flow,
  container__approvals,
  container__new_request_details,
  container__new_request_start,
  container__overview,
  container__request,
  container__subscription,
  container__subscriptions,
  container__suppliers,
  container__user,
  container__users,
  container__workflows,
  enums,
  interfaces,
  landing,
  layout,
  rrule,
  supplier_container__bank_accounts,
  supplier_container__overview,
  toasts,
};
