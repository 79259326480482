import React, { lazy, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuthState } from 'providers/AuthProvider/useAuthState';
import { AccountUseType } from 'src/generated/graphql';

const BuyerRouteSwitch = lazy(() => import('application_layer/app_context/buyer_context/BuyerRouteSwitch'));
const ConfirmInvite = lazy(() => import('containers/landing_context/ConfirmInvite'));
const LandingScreen = lazy(() => import('application_layer/landing_context/LandingScreen'));
const MissedRoute = lazy(() => import('containers/landing_context/MissedRoute'));
const ResetPassword = lazy(() => import('containers/landing_context/ResetPassword/ResetPassword'));
const SignIn = lazy(() => import('containers/landing_context/SignIn'));
const SsoCallback = lazy(() => import('providers/AuthProvider/SsoCallback'));
const SupplierRouteSwitch = lazy(() => import('application_layer/app_context/supplier_context/SupplierRouteSwitch'));

export const RouteSwitch = (): ReactElement => {
  const { accountType, isSignedIn } = useAuthState();
  return (
    <Routes>
      {isSignedIn === false && <Route element={<SsoCallback />} path={'/sso_callback'} />}
      {isSignedIn === false && <Route element={<LandingScreen component={<Navigate to={'/signin'} />} />} path={'/'} />}
      {isSignedIn !== undefined && (
        <>
          <Route element={<LandingScreen component={<SignIn />} />} path={'/signin'} />
          <Route element={<LandingScreen component={<ResetPassword />} />} path={'/reset_password'} />
          <Route element={<LandingScreen component={<ConfirmInvite />} />} path={'/accept_invitation'} />
        </>
      )}
      {isSignedIn === true && (
        <>
          {accountType === AccountUseType.Buyer && <Route element={<BuyerRouteSwitch />} path={'/*'} />}
          {accountType === AccountUseType.Supplier && <Route element={<SupplierRouteSwitch />} path={'/*'} />}
        </>
      )}
      {isSignedIn === false && <Route element={<MissedRoute />} path={'/*'} />}
    </Routes>
  );
};
